export const BaseAPI = {}
//env setup
  BaseAPI.nodeAPI = process.env.REACT_APP_BASE_URL

  //production
  //nodeAPI:"https://admin.warranty.express/api/"
  // localaws
  // nodeAPI:"http://43.205.62.74:4000/"
  //devenv
 // BaseAPI.nodeAPI = "https://api.warranty.express/"
//uat 
  //nodeAPI: "https://uat.auxill.in/api/"
  //test local link backend
  //nodeAPI:"https://2c34-2409-4042-259d-ba0a-ca0d-b32d-fa6c-ff0a.in.ngrok.io/"


