/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const EwPlans = () => {
  const [isActive, setisActive] = useState("Indian")
  const adminauth = JSON.parse(localStorage.getItem('adminauth'));
   // date time 
   const CurrentTime = moment().format('LT'); // htmlFor current Time
   const CurrentDate = moment().format('L'); // htmlFor current Date
  //  WE_PLAN_LISTING
   const [EW_PlanList, setEW_PlanList] = useState()
   const [Loading, setLoading] = useState(false)
   const [EWPlanType, setEWPlanType] = useState()
   const params = useParams();


// Api call for EW_plan listing
useEffect(() => {
  if(!params.ID === "planid"){
    handleSubmitEWplanList(params.planid)
  }else{
    handleSubmitEWplanList("Indian")
  }
}, [])



  // function to go on indian from on click
  const IndianEWplans = () => {
    setisActive('Indian')
    handleSubmitEWplanList("Indian")

  }
  // function to go on nri from on click
  const NRIEWPlans = () => {
    setisActive('NRI')
    handleSubmitEWplanList("Nri")
  
  }

const handleSubmitEWplanList =(type)=>{
  console.log(type)
  setLoading(true)
  setEWPlanType(type)
  var data = JSON.stringify({
    "filterData": {
      "citizen": type,
        "status": 1
    }
  });
  
  var config = {
    method: 'post',
    url: `${BaseAPI.nodeAPI}ew_plan/list`,
    headers: { 
      'Authorization':adminauth?.data?.token,
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    console.log(response?.data);
    setEW_PlanList(response?.data?.data?.reverse());
        setLoading(false)
      
  })
  .catch(function (error) {
    console.log(error);
    setLoading(false)
  });
  
  
}

  
  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Extended Warranty Plans</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap">
                     {isActive ==='Indian'?
                      <NavLink
                        to={AdminRoutes.AddNewIndianPlan}
                        type="button"
                        className="btn addBtns waves-effect waves-light ms-3 mt-4"
                      >
                        <i className="fas fa-plus"></i> Add New Indian Plan
                      </NavLink>
                      :
                      
                      <NavLink
                      to={AdminRoutes.AddNewNRIPlans}
                        type="button"
                        className="btn addBtns waves-effect waves-light ms-3 mt-4"
                      >
                        <i className="fas fa-plus"></i> Add New NRI Plan
                      </NavLink>
                      }
                    </div>
                    {/* <!-- Nav tabs --> */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 reportsTabs mt-5 mt-md-0 ewPlans"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className= "nav-link active"
                          data-bs-toggle="tab"
                          href="#indian"
                          role="tab"
                          onClick={() => IndianEWplans("Indian")}
                        >
                          <span>Indian</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className= "nav-link rounded-0"
                          data-bs-toggle="tab"
                          href="#nri"
                          role="tab"
                          onClick={() => NRIEWPlans("Nri")}
                        >
                          <span>NRI</span>
                        </a>
                      </li>
                    </ul>

                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-3">
                      <div
                        className="tab-pane active"
                        id="indian"
                        role="tabpanel"
                      >
                        <div className="mb-3 col-12 position-relative">
                        {Loading ? (
                                <div className="spinner-grow text-info position-absolute tableSpinner" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                                ) : (
                                  <>
                                    {!EW_PlanList?.length
                                    
                                      ?
                                      <>
                                        <span className="d-block text-center p-2 border">Data not found</span>
                                      </>
                                      :
                          <div className="row mt-5">
                          {EW_PlanList?.length && EW_PlanList?.map((val)=>{
                                  return(
                                    <>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                              <div className="card">
                              <div className="card-body box-shadow">
                               
                            
                                <div className="row">    
                               <div className="mb-2 col-12 pageHeading">
                                 <h5 className="fw-bold text-center text-dark">
                                   {val?.title}
                                 </h5>
                               </div>
                               {/* <div className="col-12">
                                 <div className="col-6 float-start px-2">
                                   <p className="text-end">Users :</p>
                                 </div>
                                 <div className="col-6 float-start px-2">
                                   <p className="fw-bold text-start">
                                     20045
                                   </p>
                                 </div>
                               </div> */}
                               <div className="col-12 d-flex justify-content-center">
                                 <NavLink
                                    to={EWPlanType === "Indian" ? "/admin-indian-details/" +val?._id : EWPlanType === "Nri" ? "/admin-nri-details/" +val?._id:null}
                                  //  to={EWPlanType === 1 ? '/admin-indian-details/' + val._id : EWPlanType === 2 ? '/admin-nri-details/' + val?._id}
                                   className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                                 >
                                   View Details
                                 </NavLink>
                               </div>
                               </div>
                              
                              
                                </div>
                              </div>
                            </div>
                            </>
                                  ) } )}
                          </div>
                            } </>)}  
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EwPlans;
