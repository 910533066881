/*eslint-disable*/
import axios from "axios";
import React, { useEffect} from "react";
import { useParams } from "react-router-dom";
import { BaseAPI } from "../../../../api/admin/BaseAPI";

const EmailVerifiedConsumer = () => {
  const { consumer_id } = useParams();
  //Api call for cinsumer verification id
  useEffect(() => {
    var data = '';
    var config = {
      method: 'get',
      url: `${BaseAPI.nodeAPI}/users/varification/${consumer_id}`,
      headers: {
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])



  //JSX
  return (
    <div className="emailTemWrap w-100">
      <div className="d-flex align-items-center justify-content-center h-100 w-100">
        <div className="account-pages col-md-4 mx-auto w-100">
          <div className="paymentMessages text-center mx-auto">
            <img
              src="images/we-logo-consumer.jpg"
              alt=""
              width="130"
              className="mb-4"
            />
            <h1 className="fw-bold py-0">
              <i className="fas fa-envelope greenText"></i>
            </h1>
            <h1 className="fw-bold greenText poppins">Your Email is Verified</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerifiedConsumer;
