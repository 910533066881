/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";

const AddNewNRISubscription = () => {
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const navigate = useNavigate();
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  //  Features Variables
  const [Title, setTitle] = useState();
  const [Validity, setValidity] = useState();
  const [MonYer, setMonYer] = useState("");
  const [submitNRIForm, setsubmitNRIForm] = useState();
  const [vaultNRI, setvaultNRI] = useState(null);
  const [AsktheExpertNRI, setAsktheExpertNRI] = useState(null);
  const [SearchNRI, setSearchNRI] = useState(null);
  const [WarrantyTransferNRI, setWarrantyTransferNRI] = useState(null);
  const [MultipleAddressNRI, setMultipleAddressNRI] = useState(null);
  const [WithoutAdvtsNRI, setWithoutAdvtsNRI] = useState(null);
  const [FamilyAccessNRI, setFamilyAccessNRI] = useState("");
  const [DirectConnectNRI, setDirectConnectNRI] = useState("");
  const [PriorityServicesNRI, setPriorityServicesNRI] = useState("");
  //  Free EW Offers Variables
  const [warrPlanNRI, setwarrPlanNRI] = useState();
  const [offer6NRI, setoffer6NRI] = useState();
  const [offer12NRI, setoffer12NRI] = useState();
  const [offer18NRI, setoffer18NRI] = useState();
  const [offer24NRI, setoffer24NRI] = useState();
  const [IntroductoryOfferNRI, setIntroductoryOfferNRI] = useState();
  //  Charges Variables
  const [weCoinsNRI, setweCoinsNRI] = useState();
  const [weCashNRI, setweCashNRI] = useState();
  const [EWNRI, setEWNRI] = useState();
  const [EmgVisitChargesNRI, setEmgVisitChargesNRI] = useState();
  const [PickupDropNRI, setPickupDropNRI] = useState();
  //  Rewards & Discounts Variables
  const [weCoinssubNRI, setweCoinssubNRI] = useState();
  const [weCashsubNRI, setweCashsubNRI] = useState();
  const [weCoinstranNRI, setweCoinstranNRI] = useState();
  const [weCashtranNRI, setweCashtranNRI] = useState();
  const [weCoinsRefsubNRI, setweCoinsRefsubNRI] = useState();
  //  Pricing Plans  Variables
  const [SubscriptionFeesNRI, setSubscriptionFeesNRI] = useState();
  const [day03offerNRI, setday03offerNRI] = useState();
  const [day47offerNRI, setday47offerNRI] = useState();
  const [day15offerNRI, setday15offerNRI] = useState();
  const [day30offerNRI, setday30offerNRI] = useState();

  // API call for Add New Plan for Indian
  const handleSubmitNRIForm = () => {
    try {
      var data = JSON.stringify({
        subscription_for: 2,
        title: Title,
        validity: Validity,
        mon_yr: MonYer,
        multiple_adress: MultipleAddressNRI,
        without_advts: WithoutAdvtsNRI,
        direct_connect: DirectConnectNRI,
        we_coin_subscription: weCoinssubNRI,
        status: 1,
        volt: vaultNRI,
        ask_the_expert: AsktheExpertNRI,
        search: SearchNRI,
        warrenty_transfer: WarrantyTransferNRI,
        family_access: FamilyAccessNRI,
        priority_service: PriorityServicesNRI,
        subscription_offer: warrPlanNRI,
        offer_6: offer6NRI,
        offer_12: offer12NRI,
        offer_18: offer18NRI,
        offer_24: offer24NRI,
        introductory_offer: IntroductoryOfferNRI,
        tf_for_we_coin: weCoinsNRI,
        tf_for_we_cash: weCashNRI,
        tf_for_we_ew: EWNRI,
        emergency_visit_charges: EmgVisitChargesNRI,
        pick_up_and_drop_charges: PickupDropNRI,
        we_cash_subscription: weCashsubNRI,
        we_coin_transaction: weCoinstranNRI,
        we_cash_transaction: weCashtranNRI,
        we_coin_ref_subscriber: weCoinsRefsubNRI,
        subscription_fees: SubscriptionFeesNRI,
        days_0_3_offer: day03offerNRI,
        days_4_7_offer: day47offerNRI,
        days_8_15_offer: day15offerNRI,
        days_16_30_offer: day30offerNRI,
        product_margin: 0,
        alliance_fees: 0,
        expert_leads_per_yr: 0,
        banner_advertisment: 0,
        break_advertisment: 0,
        priority_listing: "Null",
        verified_listing: 0,
        payment_schedule: 0,
        we_coin_cust_sale: 0,
        we_cash_ref_seller_sub: 0,
        we_coin_ref_seller_sub: 0,
        seller_or_sc_one_time_sign_up_free: 0,
        seller_or_sc_annual_subscription_charges: 0,
        seller_or_sc_refundable_security_deposite: 0,
        seller_or_sc_introductory_annual_subscription_Charges: 0,
        seller_or_sc_total_payble: 0,
        seller_and_sc_one_time_sign_up_free: 0,
        seller_and_sc_annual_subscription_charges: 0,
        seller_and_sc_refundable_security_deposite: 0,
        seller_and_sc_introductory_annual_subscription_Charges: 0,
        seller_and_sc_total_payble: 0,
        WA_annual_sbscription_charges: 0,
        WA_total_payable: 0,
      });

      var config = {
        method: "post",
        // url: `https://41e0-103-239-87-226.ngrok.io/subscription/add`,
        url: `${BaseAPI.nodeAPI}subscription/add`,
        headers: {
          Authorization: adminauth?.data?.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config).then(function (response) {
        setsubmitNRIForm(response?.data?.data);
        toast.success(response.data.message, { position: "top-right" });
        console.log("submitNRIform", response?.data?.data);
        navigate("/admin-subscription/" + "2");
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, { position: "top-right" });
    }
  };

  useEffect(() => {
    console.log("submitNRIForm---", submitNRIForm);
  }, [submitNRIForm]);

  console.log("DirectConnectNRI", DirectConnectNRI);

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Subscription</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 pageBack d-flex">
                    <NavLink to={AdminRoutes.AdminSubscription}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      Add New Plan for NRI
                    </p>
                  </div>

                  <div className="col-7 d-flex justify-content-end">
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Duplicate
                    </button> */}
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Save as Draft
                    </button> */}
                    <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      onClick={handleSubmitNRIForm}
                    >
                      Submit
                    </NavLink>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="bookTitle">
                            Subscription Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bookTitle"
                            placeholder="Enter Subscription Title"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Validity
                          </label>
                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control me-3"
                              id="noOfPages"
                              placeholder="Enter Validity"
                              onChange={(e) =>
                                setValidity(parseInt(e.target.value))
                              }
                            />
                            <select
                              className="form-select planSelect w-25"
                              aria-label="Default select example"
                              onChange={(e) => setMonYer(e.target.value)}
                            >
                              <option selected>Select</option>
                              <option value="Hrs">Hrs</option>
                              <option value="Days">Days</option>
                              <option value="Months">Months</option>
                              <option value="Years">Years</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start mb-2">
                            <h6 className="fw-bold">Features</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>VAULT</td>
                                          <td>
                                            This enables you to save all Product
                                            and Warranty details along with
                                            saving original invoice and warranty
                                            card at one place
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="vault"
                                                id="vaultyes"
                                                defaultValue="vaultyesoption1"
                                                onChange={() => setvaultNRI(1)}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="vault"
                                                id="vaultno"
                                                defaultValue="vaultoption2"
                                                onChange={() => setvaultNRI(0)}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>ASK THE EXPERT</td>
                                          <td>
                                            Access to product purchase
                                            assistance feature than enables the
                                            user to get product details and
                                            offers available locally
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ask expert"
                                                id="askExpertyes"
                                                defaultValue="askExpertyesoption1"
                                                onChange={() =>
                                                  setAsktheExpertNRI(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ask expert"
                                                id="askExpertno"
                                                defaultValue="askExpertoption2"
                                                onChange={() =>
                                                  setAsktheExpertNRI(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>SEARCH</td>
                                          <td>
                                            Enables the user to search and
                                            directly connect with local service
                                            providers incase of prducts outside
                                            warranty
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="search"
                                                id="searchyes"
                                                defaultValue="searchyesoption1"
                                                onChange={() => setSearchNRI(1)}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="search"
                                                id="searchno"
                                                defaultValue="searchoption2"
                                                onChange={() => setSearchNRI(0)}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>WARRANTY TRANSFER</td>
                                          <td>
                                            Gives the right of transferring EW
                                            plans to a third party
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="warranty transfer"
                                                id="warrantytransferyes"
                                                defaultValue="warrantytransferyesoption1"
                                                onChange={() =>
                                                  setWarrantyTransferNRI(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="warranty transfer"
                                                id="warrantytransferno"
                                                defaultValue="warrantytransferoption2"
                                                onChange={() =>
                                                  setWarrantyTransferNRI(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>MULTIPLE ADDRESS</td>
                                          <td>
                                            Enables users to add more than 1
                                            address in the same app/log-in
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiple address"
                                                id="multipleaddressyes"
                                                defaultValue="multipleaddressyesoption1"
                                                onChange={() =>
                                                  setMultipleAddressNRI(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiple address"
                                                id="multipleaddressno"
                                                defaultValue="multipleaddressoption2"
                                                onChange={() =>
                                                  setMultipleAddressNRI(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>WITHOUT ADVTS</td>
                                          <td>
                                            App without third party break out
                                            advertisements
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadsyes"
                                                defaultValue="withoutadsoption1"
                                                onChange={() =>
                                                  setWithoutAdvtsNRI(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadssno"
                                                defaultValue="withoutadsoption2"
                                                onChange={() =>
                                                  setWithoutAdvtsNRI(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>7</td>
                                          <td>FAMILY ACCESS</td>
                                          <td>
                                            Enables user to share uploaded
                                            details with family members
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="FAMILY ACCESS"
                                              onChange={(e) =>
                                                setFamilyAccessNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">Self Access</option>
                                              <option value="1">
                                                1 member
                                              </option>
                                              <option value="3">
                                                3 member
                                              </option>
                                              <option value="5">
                                                5 member
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>8</td>
                                          <td>DIRECT CONNECT</td>
                                          <td>
                                            Gives direct access to a dedicated
                                            replationship manager for al the
                                            needs
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="DIRECT CONNECT"
                                              onChange={(e) =>
                                                setDirectConnectNRI(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="No">No</option>
                                              <option value="Self">Self</option>
                                              <option value="Self + Family">
                                                Self + Family
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>9</td>
                                          <td>PRIORITY SERVICE</td>
                                          <td>Time taken to attend a call</td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="PRIORITY SERVICE"
                                              onChange={(e) =>
                                                setPriorityServicesNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="48">
                                                Within 48 working hours
                                              </option>
                                              <option value="36">
                                                Within 36 working hours
                                              </option>
                                              <option value="24">
                                                Within 24 working hours
                                              </option>
                                              <option value="12">
                                                Within 12 working hours
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Free EW Offers</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Subscription Offer</td>
                                          <td>Free Extended Warranty Plan</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setwarrPlanNRI(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="NA">NA</option>
                                              <option value="1 prod / 3 months - CARE">
                                                1 prod / 3 months - CARE
                                              </option>
                                              <option value="1 prod / 6 months - CARE">
                                                1 prod / 6 months - CARE
                                              </option>
                                              <option value="1 prod / 12 months - CARE">
                                                1 prod / 12 months - CARE
                                              </option>
                                              <option value="1 prod / 18 months - CARE">
                                                1 prod / 18 months - CARE
                                              </option>
                                              <option value="1 prod / 24 months - CARE">
                                                1 prod / 24 months - CARE
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>Offer - 6</td>
                                          <td>
                                            Valid for 15 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setoffer6NRI(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="300 We COINS + 30 WE CASH">
                                                300 We COINS + 30 WE CASH
                                              </option>
                                              <option value="400 We COINS + 40 WE CASH">
                                                400 We COINS + 40 WE CASH
                                              </option>
                                              <option value="500 We COINS + 50 WE CASH">
                                                500 We COINS + 50 WE CASH
                                              </option>
                                              <option value="600 We COINS + 60 WE CASH">
                                                600 We COINS + 60 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>Offer - 12</td>
                                          <td>
                                            Valid for 30 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setoffer12NRI(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="700 WE COINS + 70 WE CASH">
                                                700 WE COINS + 70 WE CASH
                                              </option>
                                              <option value="900 WE COINS + 90 WE CASH">
                                                900 WE COINS + 90 WE CASH
                                              </option>
                                              <option value="1000 WE COINS + 100 WE CASH">
                                                1000 WE COINS + 100 WE CASH
                                              </option>
                                              <option value="1200 WE COINS + 120 WE CASH">
                                                1200 WE COINS + 120 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>Offer - 18</td>
                                          <td>
                                            Valid for 45 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setoffer18NRI(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="1000 WE COINS + 100 WE CASH">
                                                1000 WE COINS + 100 WE CASH
                                              </option>
                                              <option value="1250 WE COINS + 125 WE CASH">
                                                1250 WE COINS + 125 WE CASH
                                              </option>
                                              <option value="1500 WE COINS + 150 WE CASH">
                                                1500 WE COINS + 150 WE CASH
                                              </option>
                                              <option value="1800 WE COINS + 180 WE CASH">
                                                1800 WE COINS + 180 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>Offer - 24</td>
                                          <td>
                                            Valid for 90 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setoffer24NRI(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="1500 WE COINS + 150 WE CASH">
                                                1500 WE COINS + 150 WE CASH
                                              </option>
                                              <option value="1750 WE COINS + 175 WE CASH">
                                                1750 WE COINS + 175 WE CASH
                                              </option>
                                              <option value="2000 WE COINS + 200 WE CASH">
                                                2000 WE COINS + 200 WE CASH
                                              </option>
                                              <option value="2400 WE COINS + 240 WE CASH">
                                                2400 WE COINS + 240 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>6</td>
                                          <td>Introductory Offer</td>
                                          <td>
                                            BUY 1 year WE GUARD within 30 days
                                            of the product purchase and get WE
                                            CARE free for another product
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setIntroductoryOfferNRI(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="For 6 months - CARE">
                                                For 6 months - CARE
                                              </option>
                                              <option value="For 12 months - CARE">
                                                For 12 months - CARE
                                              </option>
                                              <option value="For 6 months - ASSURE">
                                                For 6 months - ASSURE
                                              </option>
                                              <option value="For 12 months - ASSURE">
                                                For 12 months - ASSURE
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Charges</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Transfer Fees for We Coins</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCoinsNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="49">INR 49</option>
                                              <option value="39">INR 39</option>
                                              <option value="24">INR 29</option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>Transfer Fees for We Cash</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCashNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="99">INR 99</option>
                                              <option value="69">INR 69</option>
                                              <option value="49">INR 49</option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>Transfer Fee for EW</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setEWNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="249">
                                                INR 249
                                              </option>
                                              <option value="199">
                                                INR 199
                                              </option>
                                              <option value="149">
                                                INR 149
                                              </option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>Emergency Visit Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setEmgVisitChargesNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="799">
                                                INR 799
                                              </option>
                                              <option value="499">
                                                INR 499
                                              </option>
                                              <option value="249">
                                                INR 249
                                              </option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>Pick Up & Drop Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setPickupDropNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="999">
                                                INR 999
                                              </option>
                                              <option value="699">
                                                INR 699
                                              </option>
                                              <option value="499">
                                                INR 499
                                              </option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Rewards & Discounts</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>We COINS on Subscription</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCoinssubNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="10%">10%</option>
                                              <option value="15%">15%</option>
                                              <option value="25%">25%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>We CASH on Subscription</td>
                                          <td>Cash Back</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCashsubNRI(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="2%">2%</option>
                                              <option value="3%">3%</option>
                                              <option value="5%">5%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>We COINS on transactions</td>
                                          <td>
                                            Net amount after discount. Does not
                                            include taxes
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCoinstranNRI(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="5%">5%</option>
                                              <option value=".50%">
                                                7.50%
                                              </option>
                                              <option value="10%">10%</option>
                                              <option value="12.50%">
                                                12.50%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>4</td>
                                          <td>We CASH on transactions</td>
                                          <td>
                                            Cash Back on every transaction
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCashtranNRI(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="1.5">1.5%</option>
                                              <option value="2">2%</option>
                                              <option value="3">3%</option>
                                              <option value="5">5%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>5</td>
                                          <td>We COINS on Ref Subscriber</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCoinsRefsubNRI(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="2.50">
                                                2.50%
                                              </option>
                                              <option value="5">5%</option>
                                              <option value="7.50">
                                                7.50%
                                              </option>
                                              <option value="10">10%</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Pricing Plans</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Subscription Fees</td>
                                          <td>Annual Subscription Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setSubscriptionFeesNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="4999">4999</option>
                                              <option value="7999">7999</option>
                                              <option value="9999">9999</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>0-3 Day Offer</td>
                                          <td>
                                            0/40/45/50 - Subscriotion done
                                            within 72 hours of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setday03offerNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="2999">2999</option>
                                              <option value="4399">4399</option>
                                              <option value="5000">5000</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>4-7 Day Offer</td>
                                          <td>
                                            0/35/40/45 - Subscriotion done
                                            within 7 days of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setday47offerNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="3249">3249</option>
                                              <option value="4799">4799</option>
                                              <option value="5499">5499</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>4</td>
                                          <td>8-15 Day Offer</td>
                                          <td>
                                            0/30/35/40 - Subscriotion done
                                            within 15 days of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setday15offerNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="3499">3499</option>
                                              <option value="5199">5199</option>
                                              <option value="5999">5999</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>5</td>
                                          <td>16-30 Day Offer</td>
                                          <td>
                                            0/25/30/35 - Subscriotion done
                                            within 30 days of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setday30offerNRI(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="3749">3749</option>
                                              <option value="5599">5599</option>
                                              <option value="6499">6499</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Duplicate
                    </button>
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Save as Draft
                    </button> */}
                    <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <button
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      onClick={handleSubmitNRIForm}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewNRISubscription;
