/*eslint-disable*/
const AdminRoutes = {
  AdminLogin: "/admin-login",
  AdminWendiansLogin: "/admin-wendians-login",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password/:userid/:usertoken",
  AdminDashboard: "/",

  //for mobile app
  EmailVerifiedConsumer: "/email-verified-consumer/:consumer_id",
  EmailVerifiedPartner: "/email-verified-partner/:partner_id",

  //for pdf viewer partner
  FaqChannelPaartnerpdf: "/Faq-Channel-Paartner-pdf",
  TermsAndConditionpdf: "/Terms-And-Condition-pdf",
  PartnerMobileApppdf: "/Partner-Mobile-App-pdf",
  PrivacyPolicypdf: "/Privacy-Policy-pdf",
  DisclaimerApplicationpdf: "/Disclaimer-Application-pdf",

  //for pdf viewer consumer
  ConsumerFAQPDF: "/Consumer-FAQ-PDF",
  ConsumerDisclaimerPDF: "/Consumer-Disclaimer-PDF",
  ConsumerAppPrivacyPolicyPDF: "/Consumer-AppPrivacy-Policy-PDF",
  ConsumerOverallCondpdf: "/Consumer-Overall-Cond-pdf",
  ConsumerEmailFailed: "/Consumer-Email-Failed",
  ConsumerMobileapppdf: "/Consumer-Mobile-app-pdf",

  ReffralConsumer: "/Reffral-Consumer",
  ReffralPartner: "/Reffral-Partner",

  Profile: "/profile",
  AdminUserManagementConsumer: "/admin-user-management-consumer",
  ConsumerDetails: "/consumer-details/:consumerid",
  AdminUserManagementMsme: "/admin-user-management-msme",
  AdminUserManagementPartners: "/admin-user-management-partners",
  PartnerDetails: "/partner-details/:partnerid",
  AdminUserManagementWeStaff: "/admin-user-management-we-staff",
  AddNewWeStaff: "/add-new-we-staff",
  WeStaffDetails: "/we-staff-details/:westaffuserid",
  PartnerLinkdata: "/Partner-Link-data",
  AdminConfigaration: "/admin-configaration",

  AdminSubscription: "/admin-subscription/:ID",
  AddNewSubscription: "/add-new-subscription",
  AddNewNRISubscription: "/add-new-nri-subscription",
  AddNewPartnerSubscription: "/add-new-partner-subscription",
  AdminSubscriptionDetails: "/admin-subscription-details/:indianid",
  AdminSubscriptionNRIDetails: "/admin-subscription-nri-details/:NRIid",
  AdminSubscriptionPartnerDetails:
    "/admin-subscription-partner-details/:Partnerid",

  AdminProducts: "/admin-products",
  AdminProductsCategoryDetails:
    "/admin-products-category-details/:Productid/:Productname",
  AdminProductsDetails:
    "/admin-products-details/:Subcatid/:Subcatname/:pid/:pname",

  AdminOrderManagement: "/admin-order-management",
  EwPlans: "/ew-plans/:planid",
  AddNewIndianPlan: "/add-new-indian-plan",
  AddNewNRIPlans: "/add-new-nri-plan",

  AddIndianPlansDetails: "/admin-indian-details/:ewplanindianid",
  AddNRIPlansDetails: "admin-nri-details/:ewplannriid",

  AdminWeProducts: "/admin-we-products",
  AdminServiceProvider: "/admin-service-provider",
  AddNewServiceProvider: "/add-new-service-provider",
  AdminServiceProviderDetails:
    "/admin-service-provider-details/:serviceproviderid",

  PartnerData: "/partner-data",
  PartnerLinkDetails: "/Partner-Link-Details",

  AdminAddNewRM: "/Admin-AddNew-RM",

  AdminRMMapping: "/admin-rm-mapping",
  AdminRMDetails: "/admin-rm-details/:rmid/:accesslogin/:citizentype",
  AdminRequestDetails: "/admin-request-details",

  AdminSupport: "/admin-support",
  BannerAdsDetails: "/bannerads-details",
  BannerAds: "/banner-ads",

  AskExpert: "/ask-expert",
  AskExpertDetails: "/ask-expert-details/:AskID",

  Notifications: "/notifications",
  CreateNewNotification: "/CreateNewNotification",

  OfferData: "/offer-data",
  CreateOffer: "/Create-Offer",

  ServicePackList: "/service-pack-list",
  ServicePackDetails: "/service-pack-details/:ServicepkID",
  CreateServicePack: "/create-service-pack",

  AdminLogout: "/admin-logout",
  AdminBorrowerStatus: "/admin-borrower-status",
};

export default AdminRoutes;
