/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const AdminServiceProviderDetails = () => {
  // Variables
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const [checklist, setCheckList] = useState("");
  const [checksubcatg, setCheckSubcatgList] = useState("");
  const [brandlist, setBrandList] = useState("");
  const [catg, Setcatg] = useState([]);
  const [subcatg, SetSubcatg] = useState([]);
  const [brand, SetBrand] = useState([]);
  const params = useParams();
  const [serviceProviderDetails, setserviceProviderDetails] = useState([]);
  const initialValues = {
    shopname: serviceProviderDetails?.shop_name,
    firstname: serviceProviderDetails?.name,
    contactno: serviceProviderDetails?.phone,
  };
  const [formValues, setformValues] = useState(initialValues);
  const [isEditFirstName, setIsEditFirstName] = useState(true);
  const [isEditLastName, setIsEditLastName] = useState(true);
  const [isEditContact, setIsEditContact] = useState(true);
  const [isEditEmail, setIsEditEmail] = useState(true);
  const [isEditAddress, setIsEditAddress] = useState(true);
  const [isEditPincode, setIsEditPincode] = useState();
  // console.log("brandid",serviceProviderDetails?.user_data?.brand)

  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date

  // Api call htmlFor category list
  useEffect(() => {
    var data = JSON.stringify({
      filterData: {
        access_login: 2,
        authorized: 1,
        status: 1,
      },
    });
    var config = {
      method: "POST",
      url: `${BaseAPI.nodeAPI}category/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setCheckList(response?.data.data.rows);
        console.log("category", response?.data.data.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // API Call htmlFor Subcategory list

  useEffect(() => {
    var data = JSON.stringify({
      filterData: {
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}subcategory/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setCheckSubcatgList(response?.data?.data?.rows);
        // console.log("subcatg", response?.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // API Call htmlFor Brand List

  useEffect(() => {
    var data = JSON.stringify({
      filterData: {},
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}brand/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setBrandList(response?.data?.data?.rows);
        // console.log("brandlist", response?.data?.data?.rows);;
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // function htmlFor category
  const handleCheckboxCatg = (e) => {
    if (e.target.checked === true) {
      catg.push(e.target.id);
    }
    if (e.target.checked === false) {
      Setcatg(catg.filter((item) => item !== e.target.id));
    }
  };
  // function htmlFor subcategory
  const handleCheckboxSubcatg = (e) => {
    if (e.target.checked === true) {
      subcatg.push(e.target.id);
    }
    if (e.target.checked === false) {
      SetSubcatg(subcatg.filter((item) => item !== e.target.id));
    }
  };

  // function htmlFor brands
  const handleCheckboxBrands = (e) => {
    if (e.target.checked === true) {
      brand.push(e.target.id);
    }
    if (e.target.checked === false) {
      SetBrand(brand.filter((item) => item !== e.target.id));
    }
  };

  // Api call For Service Center Details (View More)
  useEffect(() => {
    const serviceProviderDetails = () => {
      var data = "";

      var config = {
        method: "get",
        url: `${BaseAPI.nodeAPI}serv_center/${params.serviceproviderid}`,
        headers: {
          Authorization: adminauth?.data?.token,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setserviceProviderDetails(response?.data?.data);
          console.log("serviceproviderdetails", response?.data?.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    serviceProviderDetails();
  }, []);

  // Api call for handle save and update details for service provider

  const handleSave = () => {
    console.log("formvalur", formValues);
    var data = JSON.stringify({
      name: formValues.firstname,
      phone: formValues.contactno,
      last_name: "gg",
      status: serviceProviderDetails.status,
      address: serviceProviderDetails.address,
      image: serviceProviderDetails.image,
      access_login: 2,
      roles: [],
      category: serviceProviderDetails.category,
      subcategory: serviceProviderDetails.subcategory,
      brand: serviceProviderDetails.brand,
    });
    var config = {
      method: "put",
      url: `${BaseAPI.nodeAPI}serv_center/update_detail/${params.serviceproviderid}`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        toast.success(response.data.message, { position: "top-right" });
        console.log(response.data);
        Navigate("/admin-service-provider");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };
  // Function for update values in form
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e);
    setformValues({ ...formValues, [name]: value });
  };

  // Edit onclick for firstname
  const isEditFirstHandle = () => {
    setIsEditFirstName(!isEditFirstName);
  };
  //  Edit onclick for lastname
  // const isEditLasthandle = () => {
  //   setIsEditLastName(!isEditLastName)
  // }
  // / Edit onclick for contact
  const isEditContacthandle = () => {
    setIsEditContact(!isEditContact);
  };
  // / Edit onclick for email
  const isEditEmailhandle = () => {
    setIsEditEmail(!isEditEmail);
  };

  const isEditPincodehandle = () => {
    setIsEditPincode(!isEditPincode);
  };

  const isEditAddresshandle = () => {
    setIsEditAddress(!isEditAddress);
  };

  useEffect(() => {
    console.log(serviceProviderDetails?.user_data?.category);
  }, [serviceProviderDetails]);

  // jsx
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Service Center</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row pageBack mb-4">
                  <div className="float-start col-6">
                    <NavLink to={AdminRoutes.AdminServiceProvider}>
                      <i className="fas fa-arrow-left text-dark"></i>
                    </NavLink>
                    <span className="fw-bold h5 ps-3">
                      Service Center Details
                    </span>
                  </div>
                  <div className="float-start col-6 text-end">
                    <span
                      className={
                        serviceProviderDetails?.user_data?.status === 1
                          ? "badge bg-success px-2 py-1"
                          : "badge rounded bg-warning"
                      }
                    >
                      {serviceProviderDetails?.status === 1
                        ? " Active"
                        : " Inactive"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="col-md-4 col-sm-12 col-12 float-start">
                      <div className="col-12 float-start mt-2 mt-md-4 mb-4">
                        <div className="user-wid profilePic text-center pt-2 pb-2">
                          <div className="user-img">
                            <img
                              src={serviceProviderDetails?.user_data?.image}
                              alt=""
                              className="mx-auto rounded-circle"
                            />
                          </div>
                        </div>
                        <p className="text-center font14 mb-1 pt-3">
                          Profile Picture
                        </p>
                      </div>
                    </div>

                    <div className="col-md-8 col-sm-12 col-12 float-start mb-4">
                      <div className="row ps-0 ps-md-4">
                        <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="shopname">
                            Outlet/Shop Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="shopname"
                            name="shopname"
                            placeholder="Enter Shop Name"
                            defaultValue={
                              serviceProviderDetails?.user_data?.shop_name
                            }
                            readOnly
                            onChange={handleChange}
                          />
                          {/* <span className="formIcons" onClick={isEditShophandle} >
                            <i className="fas fa-pen"></i>
                           </span> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="firstname">
                            Contact Person
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            name="firstname"
                            placeholder="Enter Contact Person Name"
                            defaultValue={
                              serviceProviderDetails?.user_data?.name
                            }
                            readOnly={isEditFirstName}
                            onChange={handleChange}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditFirstHandle}
                          >
                            <i className="fas fa-pen"></i>
                          </span>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="lastname">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastname"
                            name="lastname"
                            placeholder="Enter Last Name"
                            readOnly={isEditLastName}
                            defaultValue={serviceProviderDetails?.user_data?.last_name}
                            onChange={handleChange}
                          />
                          <span className="formIcons" onClick={isEditLasthandle}>
                            <i className="fas fa-pen"></i>
                          </span>
                        </div> */}
                        <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="contact">
                            Contact No.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="contact"
                            name="contactno"
                            placeholder="Enter Contact No"
                            readOnly={isEditContact}
                            defaultValue={
                              serviceProviderDetails?.user_data?.phone
                            }
                            onChange={handleChange}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditContacthandle}
                          >
                            <i className="fas fa-pen"></i>
                          </span>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="useremail">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="useremail"
                            placeholder="Enter email"
                            readOnly={isEditEmail}
                            defaultValue={
                              serviceProviderDetails?.user_data?.email
                            }
                            onChange={handleChange}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditEmailhandle}
                          >
                            {/* <i className="fas fa-pen"></i> */}
                          </span>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="useremail">
                            Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="useraddress"
                            name="address"
                            placeholder="Enter address"
                            defaultValue={
                              serviceProviderDetails?.user_data?.address
                            }
                            readOnly={isEditAddress}
                            onChange={handleChange}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditAddresshandle}
                          >
                            <i className="fas fa-pen"></i>
                          </span>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="useremail">
                            Pincode
                          </label>
                          <input
                            type="pincode"
                            className="form-control"
                            id="userpincode"
                            placeholder="Enter pincode"
                            readOnly={isEditPincode}
                            defaultValue={
                              serviceProviderDetails?.user_data?.pincode
                            }
                            onChange={handleChange}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditPincodehandle}
                          >
                            <i className="fas fa-pen"></i>
                          </span>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="useremail">
                            City
                          </label>
                          <input
                            type="city"
                            className="form-control"
                            id="usercity"
                            placeholder="Enter city"
                            readOnly
                            defaultValue={
                              serviceProviderDetails?.user_data?.city
                            }
                            // onChange={handleChange}
                          />
                          {/* <span className="formIcons"   onClick={isEditEmailhandle}>
                            <i className="fas fa-pen"></i>
                          </span> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="useremail">
                            State
                          </label>
                          <input
                            type="state"
                            className="form-control"
                            id="userstate"
                            placeholder="Enter state"
                            readOnly
                            defaultValue={
                              serviceProviderDetails?.user_data?.state
                            }
                            // onChange={handleChange}
                          />
                          {/* <span className="formIcons"  onClick={isEditEmailhandle} >
                            <i className="fas fa-pen"></i>
                          </span>  */}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 float-start">
                      <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                          <div className="col-12 mb-3">
                            <h6 className="heliosRounded robotofont fw-bold regSecondHead">
                              CATEGORY
                            </h6>
                          </div>
                          <>
                            {checklist &&
                              serviceProviderDetails &&
                              checklist.map((arr) => {
                                return (
                                  <>
                                    <div className="form-check mb-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={arr?._id}
                                        required
                                        onChange={(e) => handleCheckboxCatg(e)}
                                        // checked={serviceProviderDetails?.user_data?.category?.find(
                                        //   (items) =>
                                        //     items === arr._id ? true : false
                                        // )}
                                        checked={
                                          serviceProviderDetails?.user_data
                                            ?.category === arr._id
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="category1"
                                      >
                                        {arr?.name}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                          <div className="col-12 mb-3">
                            <h6 className="heliosRounded robotofont fw-bold regSecondHead">
                              SUB CATEGORY
                            </h6>
                          </div>
                          <>
                            {checksubcatg.length &&
                              checksubcatg?.map((arr) => {
                                return (
                                  <>
                                    <div className="form-check mb-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={arr?._id}
                                        required
                                        onChange={(e) =>
                                          handleCheckboxSubcatg(e)
                                        }
                                        checked={
                                          serviceProviderDetails?.user_data
                                            ?.subcategory === arr._id
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="subcategory1"
                                      >
                                        {arr?.name}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                          <div className="col-12 mb-3">
                            <h6 className="heliosRounded robotofont fw-bold regSecondHead">
                              BRANDS
                            </h6>
                          </div>
                          <div className="row">
                            <>
                              {brandlist.length &&
                                brandlist?.map((arr) => {
                                  return (
                                    <>
                                      <div className="col-4">
                                        <div className="form-check mb-2">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={arr?._id}
                                            required
                                            onChange={(e) =>
                                              handleCheckboxBrands(e)
                                            }
                                            checked={
                                              serviceProviderDetails?.user_data
                                                ?.brand === arr._id
                                            }
                                          />

                                          <label
                                            className="form-check-label"
                                            htmlFor="brand1"
                                          >
                                            {arr?.name}
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 float-start px-0  text-end">
                      <button
                        type="submit"
                        className="btn successBtns waves-effect waves-light me-2 px-4"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminServiceProviderDetails;
