/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const AskExpert = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const [AsktheexpertList, setAsktheexpertList] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    getAsktheexpertList("In Progress");
  }, []);

  const getAsktheexpertList = (va) => {
    setLoading(true);
    var data = JSON.stringify({
      filterData: {
        query_status: va,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ask_expert/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("=================>", response?.data?.data);
        setAsktheexpertList(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Ask Expert</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h3 className="fw-bold text-grey fs-4">Search Query</h3>
                    <div className="tableSearchWrap">
                      <form className="app-search">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />
                          <span className="bx bx-search-alt"></span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-12">
                    {/* <!-- Nav tabs --> */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#InProcess"
                          role="tab"
                          onClick={() => getAsktheexpertList("In Progress")}
                        >
                          <span>In Process</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#Completed"
                          role="tab"
                          onClick={() => getAsktheexpertList("Completed")}
                        >
                          <span>Completed</span>
                        </a>
                      </li>
                    </ul>

                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-3">
                      <div
                        className="tab-pane active serviceProvider"
                        id="InProcess"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>Query ID</th>
                                <th>Date/Time</th>
                                <th>Name</th>
                                <th>Mobile No.</th>
                                <th>Subscription Type</th>
                                <th>Category</th>
                                <th>Sub Category</th>
                                <th>Brand</th>
                                <th>State</th>
                                <th>City</th>
                                <th>Query Status</th>
                                <th>Details</th>
                              </tr>
                            </thead>

                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                                {!AsktheexpertList?.length ? (
                                  <>
                                    <span className="d-block text-center p-2 border">
                                      Data not found
                                    </span>
                                  </>
                                ) : (
                                  <tbody>
                                    <>
                                      {AsktheexpertList?.length
                                        ? AsktheexpertList?.map(
                                            (val, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {moment(
                                                    val?.createdAt
                                                  ).format("LLLL")}
                                                </td>
                                                <td>
                                                  {val?.consumer_data[0]?.name}{" "}
                                                  {
                                                    val?.consumer_data[0]
                                                      ?.last_name
                                                  }
                                                </td>
                                                <td>
                                                  {val?.consumer_data[0]?.phone}
                                                </td>
                                                <td>
                                                  {val?.subsc_data[0]?.title}
                                                </td>
                                                <td>{val?.category_name}</td>
                                                <td>{val?.subcategory_name}</td>
                                                <td>{val?.brand_name}</td>
                                                <td>
                                                  {val?.consumer_data[0]?.state}
                                                </td>
                                                <td>
                                                  {val?.consumer_data[0]?.city}
                                                </td>
                                                <td>
                                                  <span
                                                    className={
                                                      val?.query_status ===
                                                      "In Progress"
                                                        ? "badge bg-warning"
                                                        : "badge rounded bg-success"
                                                    }
                                                  >
                                                    {val?.query_status ===
                                                    "In Progress"
                                                      ? "In Process"
                                                      : "Completed"}
                                                  </span>
                                                </td>
                                                <td>
                                                  <NavLink
                                                    to={
                                                      "/ask-expert-details/" +
                                                      val?._id
                                                    }
                                                    type="button"
                                                    className="btn btn-sm waves-effect waves-light"
                                                  >
                                                    View More
                                                  </NavLink>
                                                </td>
                                              </tr>
                                            )
                                          )
                                        : null}
                                    </>
                                  </tbody>
                                )}{" "}
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskExpert;
