/*eslint-disable*/
import moment from "moment";
import React from "react";


const AdminUserManagementMsme = () => {
// date time 
const CurrentTime = moment().format('LT'); // htmlFor current Time
const CurrentDate = moment().format('L'); // htmlFor current Date


  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">MSME</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap d-flex">
                      {/* <!-- App Search--> */}
                      <form className="app-search d-none d-xl-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />
                          <span className="bx bx-search-alt"></span>
                        </div>
                      </form>

                      <div className="dropdown d-inline-block d-xl-none ms-2">
                        <button
                          type="button"
                          className="btn header-item noti-icon waves-effect"
                          id="page-header-search-dropdown"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="mdi mdi-magnify"></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                          aria-labelledby="page-header-search-dropdown"
                        >
                          <form className="p-3">
                            <div className="m-0">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  aria-label="Recipient's username"
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    <i className="mdi mdi-magnify"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-5">
                      <div
                        className="tab-pane active"
                        id="indian"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>Company Name</th>
                                <th>Mobile Number</th>
                                <th>Email ID</th>
                                <th>Subscription</th>
                                <th>Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>QQ Technologies</td>
                                <td>+91 9922665588</td>
                                <td>qq@gmail.com</td>
                                <td>Elite</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-sm waves-effect waves-light"
                                  >
                                    View More
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>Auxil Technologies</td>
                                <td>+91 9922665588</td>
                                <td>auxil@gmail.com</td>
                                <td>Privilege</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-sm waves-effect waves-light"
                                  >
                                    View More
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>QQ Technologies</td>
                                <td>+91 9922665588</td>
                                <td>qq@gmail.com</td>
                                <td>Basic</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-sm waves-effect waves-light"
                                  >
                                    View More
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>Auxil Technologies</td>
                                <td>+91 9922665588</td>
                                <td>auxil@gmail.com</td>
                                <td>Premium</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-sm waves-effect waves-light"
                                  >
                                    View More
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>QQ Technologies</td>
                                <td>+91 9922665588</td>
                                <td>qq@gmail.com</td>
                                <td>Privilege</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-sm waves-effect waves-light"
                                  >
                                    View More
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>Auxil Technologies</td>
                                <td>+91 9922665588</td>
                                <td>auxil@gmail.com</td>
                                <td>Elite</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-sm waves-effect waves-light"
                                  >
                                    View More
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>QQ Technologies</td>
                                <td>+91 9922665588</td>
                                <td>qq@gmail.com</td>
                                <td>Privilege</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-sm waves-effect waves-light"
                                  >
                                    View More
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserManagementMsme;
