/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";

const AdminSubscriptionNRIDetails = () => {

    // date time 
const CurrentTime = moment().format('LT'); // htmlFor current Time
const CurrentDate = moment().format('L'); // htmlFor current Date
// auth
const adminauth = JSON.parse(localStorage.getItem('adminauth'));
const params = useParams();
const [NRIDetails, setNRIDetails] = useState()
console.log(params.NRIid)
// API CALL FOR INDIAN SUBSCRIPTION VIEW MORE
    useEffect(() => {
    var data = '';
    var config = {
      method: 'post',
      url: `${BaseAPI.nodeAPI}subscription/${params.NRIid}`,
      headers: { 
        'Authorization':adminauth?.data?.token,
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      setNRIDetails(response?.data?.data)
      console.log(response.data);
    })
    .catch (function (error) {
      console.log(error);
    });
      
    }, [])
console.log("ViewMoreNRI",NRIDetails)

// JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Subscription</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 pageBack d-flex">
                    <NavLink to={AdminRoutes.AdminSubscription}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      NRI Subscription Details
                    </p>
                  </div>

                  <div className="col-6 d-flex justify-content-end">
                    {/* <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                      Save
                    </NavLink> */}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="bookTitle">
                            Subscription Title :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bookTitle"
                            placeholder="Enter Subscription Title :"
                            defaultValue={NRIDetails?.title}
                          />
                        </div>
                        <div className="col-md-3 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Validity :
                          </label>
                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control"
                              id="noOfPages"
                              placeholder="Enter Validity"
                              defaultValue={NRIDetails?.validity}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Duration :
                          </label>
                          <div className="d-flex">
                            <select
                              className="form-select planSelect w-25"
                              aria-label="Default select example"
                            >
                              <option value="Hrs"selected = {NRIDetails?.mon_yr === 'Hrs'}>Hrs</option>
                              <option value="Days"selected = {NRIDetails?.mon_yr === 'Days'}>Days</option>
                              <option value="Months"selected = {NRIDetails?.mon_yr === 'Months'}>Months</option>
                              <option value="Years"selected = {NRIDetails?.mon_yr === 'Years'}>Years</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start mb-2">
                            <h6 className="fw-bold">Features</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>VAULT</td>
                                          <td>
                                            This enables you to save all Product
                                            and Warranty details along with
                                            saving original invoice and warranty
                                            card at one place
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="vault"
                                                id="vaultyes"
                                                value="vaultyesoption1"
                                               checked= {NRIDetails?.volt === 1 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="vault"
                                                id="vaultno"
                                                value="vaultoption2"
                                                checked={NRIDetails?.volt === 0 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>ASK THE EXPERT</td>
                                          <td>
                                            Access to product purchase
                                            assistance feature than enables the
                                            user to get product details and
                                            offers available locally
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ask expert"
                                                id="askExpertyes"
                                                value="askExpertyesoption1"
                                                checked={NRIDetails?.ask_the_expert === 1 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ask expert"
                                                id="askExpertno"
                                                value="askExpertoption2"
                                                checked={NRIDetails?.ask_the_expert === 0 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>SEARCH</td>
                                          <td>
                                            Enables the user to search and
                                            directly connect with local service
                                            providers incase of prducts outside
                                            warranty
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="search"
                                                id="searchyes"
                                                value="searchyesoption1"
                                                checked={NRIDetails?.search === 1 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="search"
                                                id="searchno"
                                                value="searchoption2"
                                                checked={NRIDetails?.search === 0 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>WARRANTY TRANSFER</td>
                                          <td>
                                            Gives the right of transferring EW
                                            plans to a third party
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="warranty transfer"
                                                id="warrantytransferyes"
                                                value="warrantytransferyesoption1"
                                                checked={NRIDetails?.warrenty_transfer === 1 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="warranty transfer"
                                                id="warrantytransferno"
                                                value="warrantytransferoption2"
                                                checked={NRIDetails?.warrenty_transfer === 0 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>MULTIPLE ADDRESS</td>
                                          <td>
                                            Enables users to add more than 1
                                            address in the same app/log-in
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiple address"
                                                id="multipleaddressyes"
                                                value="multipleaddressyesoption1"
                                                checked={NRIDetails?.multiple_adress === 1 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiple address"
                                                id="multipleaddressno"
                                                value="multipleaddressoption2"
                                                checked={NRIDetails?.multiple_adress === 1 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>WITHOUT ADVTS</td>
                                          <td>
                                            App without third party break out
                                            advertisements
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadsyes"
                                                value="withoutadsoption1"
                                                checked={NRIDetails?.without_advts === 1 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadssno"
                                                value="withoutadsoption2"
                                                checked={NRIDetails?.without_advts === 0 ? true : false}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>7</td>
                                          <td>FAMILY ACCESS</td>
                                          <td>
                                            Enables user to share uploaded
                                            details with family members
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0" selected = {NRIDetails?.family_access=== 0}>Self Access</option>
                                              <option value=" 1 member" selected = {NRIDetails?.family_access=== 1}>
                                                1 member
                                              </option>
                                              <option value="3 member"selected = {NRIDetails?.family_access=== 3}>
                                                3 member
                                              </option>
                                              <option value="5 member"selected = {NRIDetails?.family_access=== 5}>
                                                5 member
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>8</td>
                                          <td>DIRECT CONNECT</td>
                                          <td>
                                            Gives direct access to a dedicated
                                            replationship manager for al the
                                            needs
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="No"selected = {NRIDetails?.direct_connect === "No"}>No</option>
                                              <option value="Self"selected = {NRIDetails?.direct_connect === "Self"}>Self</option>
                                              <option value="Self + Family"selected = {NRIDetails?.direct_connect === "Self + Family"}>
                                                Self + Family
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>9</td>
                                          <td>PRIORITY SERVICE</td>
                                          <td>Time taken to attend a call</td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="48" selected = {NRIDetails?.priority_service === 48}>
                                                Within 48 working hours
                                              </option>
                                              <option value="36"selected = {NRIDetails?.priority_service === 36}>
                                                Within 36 working hours
                                              </option>
                                              <option value="24"selected = {NRIDetails?.priority_service === 24}>
                                                Within 24 working hours
                                              </option>
                                              <option value="12"selected = {NRIDetails?.priority_service === 12}>
                                                Within 12 working hours
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Free EW Offers</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Subscription Offer</td>
                                          <td>Free Extended Warranty Plan</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="NA">NA</option>
                                              <option value="1 prod / 12 months - CARE"selected = {NRIDetails?.subscription_offer=== "1 prod / 12 months - CARE"}>
                                                1 prod / 12 months - CARE
                                              </option>
                                              <option value="1 prod / 18 months - CARE" selected = {NRIDetails?.subscription_offer=== "1 prod / 18 months - CARE"}>
                                                1 prod / 18 months - CARE
                                              </option>
                                              <option value="1 prod / 24 months - CARE"selected = {NRIDetails?.subscription_offer=== "1 prod / 24 months - CARE"}>
                                                1 prod / 24 months - CARE
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>Offer - 6</td>
                                          <td>
                                            Valid for 15 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="300 We COINS + 30 WE CASH" selected = {NRIDetails?.offer_6=== "300 We COINS + 30 WE CASH"}>
                                                300 We COINS + 30 WE CASH
                                              </option>
                                              <option value="400 We COINS + 40 WE CASH" selected = {NRIDetails?.offer_6=== "400 We COINS + 40 WE CASH"}>
                                                400 We COINS + 40 WE CASH
                                              </option>
                                              <option value="500 We COINS + 50 WE CASH"selected = {NRIDetails?.offer_6=== "500 We COINS + 50 WE CASH"}>
                                                500 We COINS + 50 WE CASH
                                              </option>
                                              <option value="600 We COINS + 60 WE CASH" selected = {NRIDetails?.offer_6=== "600 We COINS + 60 WE CASH"}>
                                                600 We COINS + 60 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>Offer - 12</td>
                                          <td>
                                            Valid for 30 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="700 WE COINS + 70 WE CASH" selected = {NRIDetails?.offer_12=== "700 WE COINS + 70 WE CASH"}>
                                                700 WE COINS + 70 WE CASH
                                              </option>
                                              <option value="900 WE COINS + 90 WE CASH" selected = {NRIDetails?.offer_12=== "900 WE COINS + 90 WE CASH"}>
                                                900 WE COINS + 90 WE CASH
                                              </option>
                                              <option value="1000 WE COINS + 100 WE CASH"selected = {NRIDetails?.offer_12=== "1000 WE COINS + 100 WE CASH"}>
                                                1000 WE COINS + 100 WE CASH
                                              </option>
                                              <option value="1200 WE COINS + 120 WE CASH"selected = {NRIDetails?.offer_12=== "1200 WE COINS + 120 WE CASH"}>
                                                1200 WE COINS + 120 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>Offer - 18</td>
                                          <td>
                                            Valid for 45 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="1000 WE COINS + 100 WE CASH"selected = {NRIDetails?.offer_18==="1000 WE COINS + 100 WE CASH"}>
                                                1000 WE COINS + 100 WE CASH
                                              </option>
                                              <option value="1250 WE COINS + 125 WE CASH" selected = {NRIDetails?.offer_18==="1250 WE COINS + 125 WE CASH"}>
                                                1250 WE COINS + 125 WE CASH
                                              </option>
                                              <option value="1500 WE COINS + 150 WE CASH" selected = {NRIDetails?.offer_18==="1500 WE COINS + 150 WE CASH"}>
                                                1500 WE COINS + 150 WE CASH
                                              </option>
                                              <option value="1800 WE COINS + 180 WE CASH" selected = {NRIDetails?.offer_18==="1800 WE COINS + 180 WE CASH"}>
                                                1800 WE COINS + 180 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>Offer - 24</td>
                                          <td>
                                            Valid for 90 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="1500 WE COINS + 150 WE CASH" selected = {NRIDetails?.offer_24==="1500 WE COINS + 150 WE CASH"}>
                                                1500 WE COINS + 150 WE CASH
                                              </option>
                                              <option value="1750 WE COINS + 175 WE CASH"selected = {NRIDetails?.offer_24==="1750 WE COINS + 175 WE CASH"}>
                                                1750 WE COINS + 175 WE CASH
                                              </option>
                                              <option value="2000 WE COINS + 200 WE CASH" selected = {NRIDetails?.offer_24==="2000 WE COINS + 200 WE CASH"}>
                                                2000 WE COINS + 200 WE CASH
                                              </option>
                                              <option value="2400 WE COINS + 240 WE CASH" selected = {NRIDetails?.offer_24==="2400 WE COINS + 240 WE CASH"}>
                                                2400 WE COINS + 240 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>6</td>
                                          <td>Introductory Offer</td>
                                          <td>
                                            BUY 1 year WE GUARD within 30 days
                                            of the product purchase and get WE
                                            CARE free for another product
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="For 6 months - CARE"selected = {NRIDetails?.introductory_offer==="For 6 months - CARE"}>
                                                For 6 months - CARE
                                              </option>
                                              <option value="For 12 months - CARE"selected = {NRIDetails?.introductory_offer==="For 12 months - CARE"}>
                                                For 12 months - CARE
                                              </option>
                                              <option value="For 6 months - ASSURE"selected = {NRIDetails?.introductory_offer==="For 6 months -  ASSURE"}>
                                                For 6 months - ASSURE
                                              </option>
                                              <option value="For 12 months - ASSURE"selected = {NRIDetails?.introductory_offer==="For 12 months -  ASSURE"}>
                                                For 12 months - ASSURE
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Charges</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Transfer Fees for We Coins</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Within 48 working hours" selected = {NRIDetails?.tf_for_we_coin=== 49}>
                                                INR 49
                                              </option>
                                              <option value=" Within 36 working hours"selected = {NRIDetails?.tf_for_we_coin=== 39}>
                                                INR 39
                                              </option>
                                              <option value="Within 24 working hours"selected = {NRIDetails?.tf_for_we_coin=== 29}>
                                                INR 29
                                              </option>
                                              <option value="Within 12 working hours"selected = {NRIDetails?.tf_for_we_coin=== 0}>
                                                INR 0
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>Transfer Fees for We Cash</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="INR 99"selected = {NRIDetails?.tf_for_we_cash=== 99}>
                                                INR 99
                                              </option>
                                              <option value="INR 69" selected = {NRIDetails?.tf_for_we_cash=== 69}>
                                                INR 69
                                              </option>
                                              <option value="INR 49" selected = {NRIDetails?.tf_for_we_cash=== 49}>
                                                INR 49
                                              </option>
                                              <option value="INR 0"selected = {NRIDetails?.tf_for_we_cash=== 0}>
                                                INR 0
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>Transfer Fee for EW</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="INR 249" selected = {NRIDetails?.tf_for_we_ew=== 249}>
                                                INR 249
                                              </option>
                                              <option value="INR 199"selected = {NRIDetails?.tf_for_we_ew=== 199}>
                                                INR 199
                                              </option>
                                              <option value="INR 149"selected = {NRIDetails?.tf_for_we_ew=== 149}>
                                                INR 149
                                              </option>
                                              <option value="INR 0"selected = {NRIDetails?.tf_for_we_ew=== 0}>
                                                INR 0
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>Emergency Visit Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="INR 799"selected = {NRIDetails?.emergency_visit_charges=== 799}>
                                                INR 799
                                              </option>
                                              <option value=" INR 499"selected = {NRIDetails?.emergency_visit_charges=== 499}>
                                                INR 499
                                              </option>
                                              <option value="INR 249"selected = {NRIDetails?.emergency_visit_charges=== 249}>
                                                INR 249
                                              </option>
                                              <option value=" INR 0"selected = {NRIDetails?.emergency_visit_charges=== 0}>
                                                INR 0
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>Pick Up & Drop Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value=" INR 999"selected = {NRIDetails?.pick_up_and_drop_charges=== 999}>
                                                INR 999
                                              </option>
                                              <option value="INR 699"selected = {NRIDetails?.pick_up_and_drop_charges=== 699}>
                                                INR 699
                                              </option>
                                              <option value="INR 499"selected = {NRIDetails?.pick_up_and_drop_charges=== 499}>
                                                INR 499
                                              </option>
                                              <option value="INR 0"selected = {NRIDetails?.pick_up_and_drop_charges=== 0}>
                                                INR 0
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Rewards & Discounts</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>We COINS on Subscription</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0"selected = {NRIDetails?.we_coin_subscription===0}>
                                                0
                                              </option>
                                              <option value="10"selected = {NRIDetails?.we_coin_subscription===10}>
                                                10%
                                              </option>
                                              <option value="15"selected = {NRIDetails?.we_coin_subscription===15}>
                                                15%
                                              </option>
                                              <option value="25"selected = {NRIDetails?.we_coin_subscription===25}>
                                                25%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>We CASH on Subscription</td>
                                          <td>Cash Back</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0"selected = {NRIDetails?.we_cash_subscription===0}>0</option>
                                              <option value="2%"selected = {NRIDetails?.we_cash_subscription===2}>2%</option>
                                              <option value="3%"selected = {NRIDetails?.we_cash_subscription===3}>3%</option>
                                              <option value="5%"selected = {NRIDetails?.we_cash_subscription===5}>5%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>We COINS on transactions</td>
                                          <td>
                                            Net amount after discount. Does not
                                            include taxes
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="5%"selected = {NRIDetails?.we_coin_transaction===5}>5%</option>
                                              <option value=".50%"selected = {NRIDetails?.we_coin_transaction===.50}> 7.50% </option>
                                              <option value="10%"selected = {NRIDetails?.we_coin_transaction===10}>10%</option>
                                              <option value="12.50%"selected = {NRIDetails?.we_coin_transaction===12.50}>12.50%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>4</td>
                                          <td>We CASH on transactions</td>
                                          <td>
                                            Cash Back on every transaction
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="1.5" selected = {NRIDetails?.we_cash_transaction===1.5}>1.5%</option>
                                              <option value="2"selected = {NRIDetails?.we_cash_transaction===2}>2%</option>
                                              <option value="3"selected = {NRIDetails?.we_cash_transaction===3}>3%</option>
                                              <option value="5"selected = {NRIDetails?.we_cash_transaction===5}>5%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>5</td>
                                          <td>We COINS on Ref Subscriber</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="2.50"selected = {NRIDetails?.we_coin_ref_subscriber===2.5}>2.50% </option>
                                              <option value="5"selected = {NRIDetails?.we_coin_ref_subscriber===5}>5%</option>
                                              <option value="7.50"selected = {NRIDetails?.we_coin_ref_subscriber===7.5}>7.50%</option>
                                              <option value="10"selected = {NRIDetails?.we_coin_ref_subscriber===10}>10%</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Pricing Plans</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Subscription Fees</td>
                                          <td>Annual Subscription Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0"selected = {NRIDetails?.subscription_fees===0}>0</option>
                                              <option value="4999"selected = {NRIDetails?.subscription_fees===4999}>4999</option>
                                              <option value="7999"selected = {NRIDetails?.subscription_fees===7999}>7999</option>
                                              <option value="9999"selected = {NRIDetails?.subscription_fees===9999}>9999</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>0-3 Day Offer</td>
                                          <td>
                                            0/40/45/50 - Subscriotion done
                                            within 72 hours of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0"selected = {NRIDetails?.days_0_3_offer===0}>0</option>
                                              <option value="2999"selected = {NRIDetails?.days_0_3_offer===2999}>2999</option>
                                              <option value="4399"selected = {NRIDetails?.days_0_3_offer===4399}>4399</option>
                                              <option value="5000"selected = {NRIDetails?.days_0_3_offer===5000}>5000</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>4-7 Day Offer</td>
                                          <td>
                                            0/35/40/45 - Subscriotion done
                                            within 7 days of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0"selected = {NRIDetails?.days_4_7_offer===0}>0</option>
                                              <option value="3249"selected = {NRIDetails?.days_4_7_offer===3249}>3249</option>
                                              <option value="4799"selected = {NRIDetails?.days_4_7_offer===4799}>4799</option>
                                              <option value="5499"selected = {NRIDetails?.days_4_7_offer===5499}>5499</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>4</td>
                                          <td>8-15 Day Offer</td>
                                          <td>
                                            0/30/35/40 - Subscriotion done
                                            within 15 days of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0"selected = {NRIDetails?.days_8_15_offer===0}>0</option>
                                              <option value="3499"selected = {NRIDetails?.days_8_15_offer===3499}>3499</option>
                                              <option value="5199"selected = {NRIDetails?.days_8_15_offer===5199}>5199</option>
                                              <option value="5999"selected = {NRIDetails?.days_8_15_offer===5999}>5999</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>5</td>
                                          <td>16-30 Day Offer</td>
                                          <td>
                                            0/25/30/35 - Subscriotion done
                                            within 30 days of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0"selected = {NRIDetails?.days_16_30_offer===0}>0</option>
                                              <option value="3749"selected = {NRIDetails?.days_16_30_offer===3749}>3749</option>
                                              <option value="5599"selected = {NRIDetails?.days_16_30_offer===5599}>5599</option>
                                              <option value="6499"selected = {NRIDetails?.days_16_30_offer===6499}>6499</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    {/* <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                      Save
                    </NavLink> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSubscriptionNRIDetails;
