/*eslint-disable*/
import React, { useEffect} from "react";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { useParams } from "react-router-dom";

const EmailVerifiedPartner = () => {
  const { partner_id } = useParams();
  console.log(partner_id)

  //Api call for cinsumer verification id
  useEffect(() => {
    var data = '';
    var config = {
      method: 'get',
      url: `${BaseAPI.nodeAPI}/users/varification/${partner_id}`,
      headers: {
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])



  return (
    <div className="emailTemWrap w-100">
      <div className="d-flex align-items-center justify-content-center h-100 w-100">
        <div className="account-pages col-md-4 mx-auto w-100">
          <div className="paymentMessages text-center mx-auto">
            <img src="images/we-partner.jpg" alt="" width="130" className="mb-4" />
            <h1 className="fw-bold py-0">
              <i className="fas fa-envelope greenText"></i>
            </h1>
            <h1 className="fw-bold greenText poppins">Your Email is Verified</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerifiedPartner;
