/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import signcheckicon from "../../../../../src/assets/images/sign-check-icon.png";
import { toast } from "react-toastify";

const CreateOffer = () => {
  const navigate = useNavigate();
  const todayMoment = moment();
  // Date Time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // fo
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const [isActiveEarn, setisActiveEarn] = useState();
  const [EarnTitle, setEarnTitle] = useState("");
  const [EarnDescription, setEarnDescription] = useState("");
  const [isSubmit, SetIsSubmit] = useState(false); //for offer api call
  const [isSubmitearn, SetIsSubmitearn] = useState(false); //for earn api call

  const [formErrors, setformError] = useState({});

  const [SubscriptionList, setSubscriptionList] = useState("");
  const [isActive, setisActive] = useState(0);
  const [EW_PlanList, setEW_PlanList] = useState();
  const [OfferName, setOfferName] = useState("");
  const [OfferDesc, setOfferDesc] = useState("");
  const [PageName, setPageName] = useState("");
  const [Sub_Name, setSub_Name] = useState("");
  const [Sub_NameID, setSub_NameID] = useState("");

  const [OfferType_percentage, setOfferType_percentage] = useState("");
  const [amount, setamount] = useState("");
  const [Expiry_date, setExpiry_date] = useState("");
  const [amountEdit, setAmountEdit] = useState(false);
  const [percentEdit, setPercentEdit] = useState(false);

  //validation for earn
  const Validate = (EarnTitle, EarnDescription, isActiveEarn) => {
    const error = {};
    const firstnameregex = /^[a-zA-Z]*$/;

    if (!EarnTitle) {
      error.EarnTitle = "Title name is required!";
    } else if (!firstnameregex.test(EarnTitle)) {
      error.EarnTitle = " Invalid Title Name!";
    }
    if (!EarnDescription) {
      error.EarnDescription = "Please fill description!";
    }
    if (!isActiveEarn) {
      error.isActiveEarn = "Please select recipients type!";
    }
    return error;
  };

  const HandleSubmitEarn = async (e) => {
    setformError(Validate(EarnTitle, EarnDescription, isActiveEarn));
    SetIsSubmitearn(true);
  };

  //api call for earn data
  const handleEarnData = () => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmitearn) {
      try {
        var data = JSON.stringify({
          access_login: isActiveEarn,
          earn_name: EarnTitle,
          description: EarnDescription,
        });

        var config = {
          method: "post",
          url: `${BaseAPI.nodeAPI}earn/add`,
          headers: {
            Authorization: adminauth?.data?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config).then(function (response) {
          console.log(response.data);
          toast.success(response?.data?.message, { position: "top-right" });
          navigate("/offer-data");
        });
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      }
    }
  };

  useEffect(() => {
    handleEarnData();
  }, [formErrors]);

  //api call for subscription list
  const getSubscriptionList = () => {
    var data = JSON.stringify({
      filterData: {
        subscription_for: 1,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}subscription/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data?.data?.rows);
        setSubscriptionList(response.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //api call for ewplan list
  const getEWplanList = (type) => {
    console.log(formErrors);
    var data = JSON.stringify({
      filterData: {
        citizen: "Indian",
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ew_plan/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("getEWplanList", response?.data?.data);
        setEW_PlanList(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getSubscriptionList();
    getEWplanList();
  }, []);

  //offer validation
  const ValidateOffer = () => {
    const error = {};
    const firstnameregex = /^[a-zA-Z ]*$/;
    const numberreges = /^[0-9]*$/;
    const offertype = /^([0-9]{2})$/;

    if (isActive === 0) {
      error.isActive = "Please select recipients type!";
    }

    console.log(OfferName);

    if (!OfferName) {
      error.OfferName = "Title name is required!";
    } else if (!firstnameregex.test(OfferName)) {
      error.OfferName = "Invalid title name!";
    }
    if (!OfferDesc) {
      error.OfferDesc = "Please fill description!";
    }
    if (!PageName) {
      error.PageName = "Please select offer claim path!";
    }
    if (!Sub_Name) {
      error.Sub_Name = "Please select effective on!";
    }

    if (!amount && !OfferType_percentage) {
      error.amount = "Please enter offer type OR amount!";
      // error.OfferType_percentage = "Please enter offer type OR amount!";
    } else if (!numberreges.test(amount)) {
      error.amount = " Invalid offer percentage!";
    }

    if (!Expiry_date) {
      error.Expiry_date = "Please select expiry_date!";
    }
    return error;
  };
  var date = "";
  const getexpiryDate = (value) => {
    // var a = moment(value).format("YYYY-MM-DD");
    // var B = moment().format("hh:mm:ss.ms");
    // date = `${a}T${B}Z`;
    // setExpiry_date(`${a}T${B}Z`);
    // console.log("value", date);
  };

  //API CALL FOR AND VALIDATION CHECK FOR OFFERS
  const HandleSubmitOffer = async () => {
    setformError(ValidateOffer());
    SetIsSubmit(true);
  };

  //USEEFFECT FOR OFFER API CALL
  const handleOfferdata = () => {
    var date = `${moment(Expiry_date).format("YYYY-MM-DD")}T${moment().format(
      "23:59:59.999"
    )}Z`;

    console.log("testresopnce", formErrors, isSubmit);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // var date = new Date(Expiry_date);
      // // console.log("aaa", date.toISOString());
      var data = "";
      try {
        if (PageName === "Subscription") {
          data = JSON.stringify({
            access_login: isActive,
            offer_name: OfferName,
            description: OfferDesc,
            page_name: PageName,
            offer_on_subscription_name: Sub_Name,
            offer_on_subscription_id: Sub_NameID,
            ...(amount && {
              in_amount: parseInt(amount),
            }),
            ...(OfferType_percentage && {
              in_percentage: parseInt(OfferType_percentage),
            }),
            expiry_date: date,
          });
        } else {
          data = JSON.stringify({
            access_login: isActive,
            offer_name: OfferName,
            description: OfferDesc,
            page_name: PageName,
            offer_on_ewplan_name: Sub_Name,
            offer_on_ewplan_id: Sub_NameID,
            ...(amount && {
              in_amount: parseInt(amount),
            }),
            ...(OfferType_percentage && {
              in_percentage: parseInt(OfferType_percentage),
            }),
            expiry_date: date,
          });
        }
        console.log("====================================");
        console.log("data", data);
        console.log("====================================");
        var config = {
          method: "post",
          url: `${BaseAPI.nodeAPI}offer/add`,
          headers: {
            Authorization: adminauth?.data?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config).then(function (response) {
          // //   if (response.data.status === "0) {
          // console.log(response.data);
          // toast.success(response?.data?.message, { position: "top-right" });
          // navigate();
          // // } else {
          // //   toast.error(response?.data?.message, { position: "top-right" });
          // // }

          if (response?.data.status === 0) {
            toast.error(response?.data?.message, { position: "top-right" });
          } else {
            toast.success(response?.data?.message, { position: "top-right" });
            navigate("/offer-data");
          }
        });
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      }
    }
  };

  useEffect(() => {
    console.log("value", Expiry_date);
    handleOfferdata();
  }, [formErrors]);

  const tomorrowMoment = todayMoment.clone().add(1, "days");

  useEffect(() => {
    console.log("tomorrowMoment===>", tomorrowMoment?._d);
  }, []);
  //jsx
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="row">
            <div className="col-6">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="page-title mb-0 font-size-18 fw-normal">
                  Welcome to
                  <span className="fw-bold"> Offer/Benefits</span>
                </h4>
              </div>
            </div>
            <div className="col-6">
              <div className="page-title-box d-flex align-items-center justify-content-end">
                <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                  <span className="fw-normal">
                    {CurrentTime},{CurrentDate}
                  </span>
                </h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card tableCard">
                <div className="card-body">
                  <div className="col-5 pageBack d-flex">
                    <NavLink to={AdminRoutes.OfferData}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">Create Offer/Earn</p>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12">
                      <ul
                        className="nav nav-tabs nav-tabs-custom mt-0 reportsTabs mt-5 mt-md-0 ewPlans"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            data-bs-target="#offertab"
                            role="tab"
                          >
                            <span>OFFER</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link rounded-0"
                            data-bs-toggle="tab"
                            data-bs-target="#earntab"
                            role="tab"
                          >
                            <span>EARNS</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="row mb-2">
                    <div className="col-12">
                      <label className="form-label blueText mt-2" htmlFor="">
                        *Add Recipients
                      </label>
                      <br></br>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                          onClick={() => setisActive(4)}

                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">
                          Consumer
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          onClick={() => setisActive(5)}

                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">
                          Partner
                        </label>

                      </div>
                      <p className='text-danger'>{formErrors.isActive}</p>

                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-12">
                      <div className="tab-content text-muted mt-3">
                        <div
                          className="tab-pane active"
                          id="offertab"
                          role="tabpanel"
                        >
                          <div className="row mb-2">
                            <div className="col-12">
                              <label
                                className="form-label blueText mt-2"
                                htmlFor="inlineRadioOptions"
                              >
                                *Add Offer Recipients
                              </label>
                              <br></br>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio2"
                                  value="option2"
                                  onClick={() => setisActive(4)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Consumer
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio1"
                                  value="option1"
                                  onClick={() => setisActive(5)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Partner
                                </label>
                              </div>
                              <p className="text-danger">
                                {formErrors.isActive}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-sm-12 float-start mb-3">
                              <label className="form-label" htmlFor="">
                                Enter Offer Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="off"
                                name="OfferName"
                                placeholder="Enter Offer Title"
                                onChange={(e) => setOfferName(e.target.value)}
                                maxLength={30}
                              />
                              <div className="d-flex justify-content-between px-1">
                                <p className="text-danger">
                                  {formErrors.OfferName}
                                </p>
                                <p className="text-end">30 characters only</p>
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 float-start mb-3">
                              <label
                                className="form-label"
                                htmlFor="Description"
                              >
                                Enter Offer Description
                              </label>
                              <textarea
                                className="form-control"
                                rows="5"
                                placeholder="Enter Description"
                                maxLength={150}
                                onChange={(e) => setOfferDesc(e.target.value)}
                              ></textarea>
                              <div className="d-flex justify-content-between px-1">
                                <p className="text-danger">
                                  {formErrors.OfferDesc}
                                </p>
                                <p className="text-end">150 characters only</p>
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 float-start mb-5">
                              <label className="form-label" htmlFor="emp_id">
                                Offer Claim Path
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setPageName(e.target.value)}
                              >
                                <option selected>
                                  Select Offer Claim Path
                                </option>
                                <option value="Subscription">
                                  Subscriptions
                                </option>
                                <option value="EW">Extended Waranty</option>
                              </select>
                              <p className="text-danger">
                                {formErrors.PageName}
                              </p>
                            </div>

                            <div className="col-md-12 col-sm-12 float-start mb-5">
                              <label className="form-label" htmlFor="emp_id">
                                Effective On
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  setSub_Name(JSON.parse(e.target.value).title);
                                  setSub_NameID(JSON.parse(e.target.value)._id);
                                  // console.log("a1", JSON.parse(e.target.value).title);
                                  // console.log("a2", JSON.parse(e.target.value)._id)
                                }}
                              >
                                <option selected>Select</option>
                                {PageName == "Subscription" ? (
                                  <>
                                    {SubscriptionList?.length &&
                                      SubscriptionList?.map((val) => (
                                        <option
                                          id={val?._id}
                                          value={JSON.stringify(val)}
                                        >
                                          {val?.title}
                                        </option>
                                      ))}
                                  </>
                                ) : (
                                  <>
                                    {EW_PlanList?.length &&
                                      EW_PlanList?.map((val) => (
                                        <option
                                          id={val?._id}
                                          value={JSON.stringify(val)}
                                        >
                                          {val?.title}
                                        </option>
                                      ))}
                                  </>
                                )}
                              </select>
                              <p className="text-danger">
                                {formErrors.Sub_Name}
                              </p>
                            </div>

                            <div className="col-xl-5 col-md-5 col-sm-12 float-start mb-3">
                              <label className="form-label" htmlFor="emp_id">
                                Offer Type
                              </label>
                              <div className="row gx-2">
                                <div className="col">
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      disabled={percentEdit}
                                      className="form-control input-group-right"
                                      placeholder="Enter 2 digit number"
                                      maxLength={2}
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                      onChange={(e) => {
                                        ValidateOffer();

                                        setOfferType_percentage(e.target.value);
                                        // console.log("e======>", e);
                                        if (e.target.value.length > 0) {
                                          setAmountEdit(true);
                                        } else {
                                          setAmountEdit(false);
                                        }
                                      }}
                                    />
                                    <span
                                      className="input-group-text border-formcontrol-left"
                                      id="basic-addon1"
                                      style={{ backgroundColor: "#fbfbfb" }}
                                    >
                                      %
                                    </span>
                                    <p className="text-danger mt-10 d-block w-100">
                                      {formErrors.amount}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-auto pt-2">
                                  <span>Percentage</span>
                                </div> */}
                              </div>
                            </div>
                            <div className="col-xl-2 col-md-2 col-sm-12 float-start mb-3 d-flex flex-column justify-content-center align-items-center pt-3">
                              OR
                            </div>

                            <div className="col-xl-5 col-md-5 col-sm-12 float-start mb-3">
                              <label className="form-label" htmlFor="emp_id">
                                Amount
                              </label>
                              <div className="row gx-2">
                                <div className="col">
                                  <div className="input-group mb-3">
                                    <span
                                      className="input-group-text border-formcontrol-right"
                                      id="basic-addon1"
                                      style={{ backgroundColor: "#fbfbfb" }}
                                    >
                                      &#8377;
                                    </span>
                                    <input
                                      type="number"
                                      className="form-control input-group-left"
                                      disabled={amountEdit}
                                      placeholder="Enter number only"
                                      aria-label="Username1"
                                      aria-describedby="basic-addon1"
                                      style={{
                                        borderTopRightRadius: "0px !important",
                                        borderBottomRightRadius:
                                          "0px !important",
                                      }}
                                      onChange={(e) => {
                                        setamount(e.target.value);
                                        if (e.target.value.length > 0) {
                                          setPercentEdit(true);
                                        } else {
                                          setPercentEdit(false);
                                        }
                                      }}
                                    />
                                    <p className="text-danger d-block w-100">
                                      {formErrors.amount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-12 float-start mb-3">
                              <label className="form-label" htmlFor="emp_id">
                                Offer Expire Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="emp_id"
                                name="Expiry_date"
                                min={
                                  tomorrowMoment?._d.toISOString().split("T")[0]
                                }
                                placeholder=""
                                onChange={(e) => setExpiry_date(e.target.value)}
                                // onChange={(e) => console.log(e.target.value)}
                              />
                              <p className="text-danger">
                                {formErrors.Expiry_date}
                              </p>
                            </div>
                            <div className="col-md-12 text-end">
                              <NavLink
                                className="btn btn-secondary me-2 rounded-2"
                                to={AdminRoutes.OfferData}
                              >
                                Cancel
                              </NavLink>
                              <button
                                className="btn blueBtns waves-effect waves-light px-5 rounded-2"
                                type="button"
                                onClick={() => HandleSubmitOffer()}
                              >
                                Submit Offer
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane" id="earntab" role="tabpanel">
                          <div className="row mb-2">
                            <div className="col-12">
                              <label
                                className="form-label blueText mt-1"
                                htmlFor="inlineRadioOptions1"
                              >
                                *Add Earn Recipients
                              </label>
                              <br></br>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions1"
                                  id="inlineRadio2"
                                  value="option2"
                                  onClick={() => setisActiveEarn(4)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Consumer
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio1"
                                  value="option1"
                                  onClick={() => setisActiveEarn(5)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Partner
                                </label>
                              </div>
                              <p className="text-danger">
                                {formErrors.isActiveEarn}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-sm-12 float-start mb-3">
                              <label className="form-label" htmlFor="emp_id">
                                Enter Earns Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="earn_id"
                                name="EarnTitle"
                                placeholder="Enter Earn Title"
                                maxLength={30}
                                onChange={(e) => setEarnTitle(e.target.value)}
                              />
                              <div className="d-flex justify-content-between px-1">
                                <p className="text-danger">
                                  {formErrors.EarnTitle}
                                </p>
                                <p className="text-end">30 characters only</p>
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 float-start mb-3">
                              <label className="form-label" htmlFor="emp_id">
                                Enter Earns Description
                              </label>
                              <textarea
                                className="form-control"
                                rows="5"
                                placeholder="Enter Description"
                                maxLength={150}
                                onChange={(e) =>
                                  setEarnDescription(e.target.value)
                                }
                              ></textarea>
                              <div className="d-flex justify-content-between px-1">
                                <p className="text-danger">
                                  {formErrors.EarnDescription}
                                </p>
                                <p className="text-end">150 characters only</p>
                              </div>
                            </div>

                            <div className="col-md-12 text-end">
                              <NavLink
                                to={AdminRoutes.OfferData}
                                type="button"
                                className="btn addBtns waves-effect waves-light px-5 me-3"
                              >
                                Cancel
                              </NavLink>
                              <button
                                className="btn blueBtns waves-effect waves-light px-5 rounded-2"
                                type="button"
                                onClick={(e) => HandleSubmitEarn(e)}
                              >
                                Submit Earn
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="offerModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          id="offerModal-Dialog"
        >
          <div className="modal-content">
            <div className="modal-body p-5">
              <h4 className="fs-3 text-center mb-4 mt-4">
                Your offer has been sent successfully
              </h4>
              <div className="d-flex justify-content-center mb-4">
                <img
                  src={signcheckicon}
                  alt="image"
                  className="check-sign-image"
                />
              </div>

              <p className="text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOffer;
