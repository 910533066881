/*eslint-disable*/
import React from 'react'
import email from '../auth/login/ImagesForMobApp/emailc.png'

const ConsumerEmailFailed = () => {
  return (
    <div className="emailimg">
      <img
              src={email}
              alt=""   
              className='emailImg'   
            />
    </div>
  )
}

export default ConsumerEmailFailed