/*eslint-disable*/
import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";


const AdminAuthLayout = (props) => {
    // Variables
    const navigate = useNavigate();
    const adminObject = localStorage.getItem('adminObject')

    // // Side Effect
    // useEffect(() => {
    //     if (adminObject) {
    //         setTimeout(() =>  navigate("/admin"))
    //     }    
    // }, [adminObject])
    
    // JSX
    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export default AdminAuthLayout