/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from 'moment';

const AdminRMMapping = () => {
  // Date Time
  const CurrentTime = moment().format('LT'); // for current Time
  const CurrentDate = moment().format('L'); // for current Date
  //  auth
  const adminauth = JSON.parse(localStorage.getItem('adminauth'));
  // Variables
  const [rmList, setrmList] = useState([])
  const [Loading, setLoading] = useState()
  const [rmUserCount, setrmUserCount] = useState()
  const [accesslogin, setaccesslogin] = useState()
  const [IsActive, setIsActive] = useState()
  const [CitizenType, setCitizenType] = useState()


  // Useeffect for rm/dirrectconnect api call
  useEffect(() => {
    getRmListData(4)
  }, [])

  // APi call for RM/Directconnetlist api
  const getRmListData = async (value, type) => {
    console.log("type", type)
    setaccesslogin(value)
    setLoading(true)
    setCitizenType(type)
    var data = JSON.stringify({
      "filterData": {
        "assign_for": value,
        "citizen": type
      }
    });

    var config = {
      method: 'post',
      url: `${BaseAPI.nodeAPI}rm_manager/list`,
      headers: {
        'Authorization': adminauth?.data?.token,
        'Content-Type': 'application/json',

      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log("rmlist",response);
          setrmList(response?.data?.data)
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
      });

  }


  // console.log("rmList",rmList);
  //  // function to set the user type
  //  const IndianConsumer = () => {
  //   setIsActive('Indian')
  //   getRmListData("Indian")
  // }

  // const NRIConsumer = () => {
  //   setIsActive('Nri')
  //   getRmListData("Nri")
  // }

  // const ChannelPartner = () => {
  //   setIsActive("Channel Partner",)
  //   getRmListData("Channel Partner",)
  // }
  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">RM Mapping</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>
        {rmList ? (
          <div className="row">
            <div className="col-xl-12">
              <div className="card tableCard">
                <div className="card-body">
                  <div className="row">
                    <div className="mb-3 col-12">
                      {/* <!-- Nav tabs --> */}
                      <div className="d-flex justify-content-between">
                        <ul
                          className="nav nav-tabs nav-tabs-custom mt-0 reportsTabs mt-md-0"
                          role="tablist"
                        >
                          <li className="nav-item" onClick={() => getRmListData(4, "Indian")}>
                            <a
                              className="nav-link active"
                              data-bs-toggle="tab"
                              href="#indian"
                              role="tab"
                            // onClick={() => IndianConsumer("Indian")}
                            >
                              <span>Indian</span>
                            </a>
                          </li>
                          <li className="nav-item" onClick={() => getRmListData(4, "Nri")}>
                            <a
                              className="nav-link rounded-0"
                              data-bs-toggle="tab"
                              href="#nri"
                              role="tab"
                            // onClick={() => NRIConsumer("Nri")}
                            >
                              <span>NRI</span>
                            </a>
                          </li>
                          {/* <li className="nav-item">
                        <a
                          className="nav-link rounded-0"
                          data-bs-toggle="tab"
                          href="#msme"
                          role="tab"
                        >
                          <span>MSME</span>
                        </a>
                      </li> */}
                          <li className="nav-item" onClick={() => getRmListData(5, "Channel Partner")}>
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#partners"
                              role="tab"
                            // onClick={() => ChannelPartner("Channel Partner")}
                            >
                              <span>Partners</span>
                            </a>
                          </li>
                        </ul>
                        <NavLink
                          to={AdminRoutes.AdminAddNewRM}
                          type="button"
                          className="btn addBtns waves-effect waves-light ms-3"
                        >
                          <i className="fas fa-plus"></i> Add New
                        </NavLink>
                      </div>
                      {/* <!-- Tab panes --> */}
                      <div className="tab-content text-muted mt-4">
                        <div
                          className="tab-pane active"
                          id="indian"
                          role="tabpanel"
                        >
                          <div className="table-responsive">
                            <table className="table table-striped mb-0 tablesWrap">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Emp id</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Contact</th>
                                  <th>No. Of Users Assigned</th>
                                  <th>Details</th>
                                </tr>
                              </thead>
                              {Loading ? (
                                <div className="spinner-grow text-info position-absolute tableSpinner" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                                ) : (
                                  <>
                                    {!rmList?.length
                                    
                                      ?
                                      <>
                                        <span className="d-block text-center p-2 border">Data not found</span>
                                      </>
                                      :
                                <tbody>
                                  <>
                                    {rmList && rmList?.map((val, index) =>
                                      <>
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{val?.rm_data?.employee_id}</td>
                                          <td>{val?.rm_data?.name}</td>
                                          <td>{val?.rm_data?.email}</td>
                                          <td>{val?.rm_data?.contact}</td>
                                          <td>{val?.user_count}</td>
                                          <td>
                                            <NavLink
                                              to={"/admin-rm-details/" + val?.rm_data?._id + "/" + val?.rm_data?.assign_for + "/" + val?.rm_data?.citizen
                                            }
                                              type="button"
                                              className="btn btn-sm waves-effect waves-light"
                                            >
                                              View More
                                            </NavLink>
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                  </>
                                </tbody>
                              } </>)}  
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AdminRMMapping;
