/*eslint-disable*/
import React, { useEffect, useState } from "react";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import axios from "axios";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
const BannerAds = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  //partner object
  const [PartnerBanerAdsList, setPartnerBanerAdsList] = useState([
    {
      status: 1,
      _id: "",
      slider_image: "",
      url_type: "",
      redirect_url: "",
      access_login: "",
      image_errorP: "",
      selectplanP: "",
    },
  ]);
  //consumer object
  const [BanerAdsList, setBanerAdsList] = useState([
    {
      status: 1,
      _id: "",
      slider_image: "",
      url_type: "",
      redirect_url: "",
      access_login: "",
      image_error: "",
      radio_error: "",
    },
  ]);
  const [isActive, setisActive] = useState(4);
  const [imgload, setimgeload] = useState(false);
  const [Cimgload, setCimgload] = useState(false);
  const [CImageERror, setCImageERror] = useState("");
  const [PImageERror, setPImageERror] = useState("");
  const [common_error, setcommon_error] = useState("");

  //=========================================================== Consumer banner ads  ====================================================//
  //VALIDATION
  const CBanerUploadValidation = () => {
    console.log("====================================");
    console.log("calling error message function");
    console.log("====================================");

    setBanerAdsList((current) =>
      current.map((obj) => {
        if (!obj.slider_image) {
          console.log("obj.slider_image", obj.slider_image);

          return { ...obj, image_error: "Upload Image" };
        } else {
          return { ...obj, image_error: "" };
        }
      })
    );

    setBanerAdsList((current) =>
      current.map((obj) => {
        console.log("obj.redirect_url", obj.redirect_url);

        if (!obj.redirect_url) {
          return { ...obj, radio_error: "Select Plan" };
        } else {
          return { ...obj, radio_error: "" };
        }
      })
    );
  };

  //add duplicate div function
  const handleAddDuplicateConsumer = () => {
    CBanerUploadValidation();
    console.log(BanerAdsList);
    setBanerAdsList([
      ...BanerAdsList,
      {
        slider_image: "",
        url_type: "",
        redirect_url: "",
        access_login: isActive,
        image_error: "",
        radio_error: "",
      },
    ]);
    // ==========adding error obeject in array
  };

  ///baneeradspatner image upload api call
  const BannerImageUploadConsumer = (e, index) => {
    CBanerUploadValidation();
    setCImageERror();
    console.log(e.target.files[0]);
    if (e.target.files[0].size < 2000000) {
      console.log("true");
      setCimgload(!Cimgload);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", adminauth?.data?.token);
      var formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      console.log(formdata);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${BaseAPI.nodeAPI}fileupload`, requestOptions)
        .then((response) => response.text())
        .then((result) =>
          setImageConsumer(JSON.parse(result).file_path, index)
        );
    } else {
      setCImageERror("File size cannot exceed 2MB");
      console.log(false);
    }
  };

  //function to upload image for consumer
  const setImageConsumer = (url, index) => {
    console.log(url);
    // BanerAdsList [index].slider_image = url
    // setBanerAdsList (BanerAdsList )
    setBanerAdsList((current) =>
      current.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            slider_image: url,
          };
        }
        return obj;
      })
    );
    setCimgload(!Cimgload);
  };

  ////Function to store redio button input aap/browser for consumer
  const HandleredioButtonC = (value, index) => {
    CBanerUploadValidation();

    console.log("HandleredioButtonc", value);
    console.log("HandleredioButtonc", index);
    BanerAdsList[index].url_type = value;

    setBanerAdsList((current) =>
      current.map((obj) => {
        if (obj.index === index) {
          return {
            ...obj,
            url_type: value,
          };
        }
        // console.log(obj);
        return obj;
      })
    );
  };

  //Function to store plan/browserurl input for aap/browser
  const HandleselectplanC = (value, index) => {
    CBanerUploadValidation();
    console.log("HandleselectplanC", value);
    console.log("HandleselectplanC", index);
    BanerAdsList[index].redirect_url = value;
    setBanerAdsList((current) =>
      current.map((obj) => {
        if (obj.index === index) {
          return {
            ...obj,
            redirect_url: value,
          };
        }
        return obj;
      })
    );
  };

  useEffect(() => {
    console.log("isActiveisActiveisActive", isActive);
    BanerAdsList[0].access_login = isActive;
  }, [isActive]);

  // consumer banner ads api call
  const getUploadeConsumerBannerAds = async () => {
    var new_arrayC = BanerAdsList.map((obj) => ({
      slider_image: obj.slider_image,
      url_type: obj.url_type,
      redirect_url: obj.redirect_url,
      access_login: obj.access_login,
    }));
    console.log(new_arrayC);
    var data = JSON.stringify({
      filterData: new_arrayC,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}slider_image/add`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        console.log(response.data);
        toast.success(response.data.message, { position: "top-right" });
      })
      .catch(function (error) {
        console.log(error);
        toast.error(err.response.data.message, { position: "top-right" });
      });
  };

  //function for validation and not call api till the validation complete
  const api_call_Consumer = () => {
    console.log("====================================");
    console.log("AT CONSUMER API CALL");
    console.log("====================================");
    CBanerUploadValidation();
    const error_array = BanerAdsList.filter((value) => {
      return (
        !value.slider_image ||
        !value.redirect_url ||
        !value.access_login ||
        !value.url_type
      );
    });
    if (!error_array.length) {
      getUploadeConsumerBannerAds();
    }
  };

  //delete div image functionality
  const resetConsumer = (value) => {
    const a = BanerAdsList.filter((va, i) => {
      console.log("value", i, value);
      return i !== value;
    });
    // console.log("new array",a);
    setBanerAdsList(a);
  };
  //delete uploaded images function
  const DeletePhotoC = (index) => {
    console.log("id", index);
    var data = JSON.stringify({
      status: 0,
    });

    var config = {
      method: "delete",
      url: `${BaseAPI.nodeAPI}slider_image/${index}`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getBaneerAdsList = (value) => {
    console.log("access_login", value);
    var data = JSON.stringify({
      filterData: {
        access_login: value,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}slider_image/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(
          "BanerAdsListBanerAdsListapiresponce",
          response?.data?.data
        );
        setBanerAdsList(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getBaneerAdsList(4);
  }, []);

  console.log("BanerAdsListBanerAdsList=>>>>>", BanerAdsList);

  /// api call to update the slider images

  const getUpadateSliderImages = () => {
    CBanerUploadValidation();
    var error = false;
    BanerAdsList.map((obj) => {
      if (obj.image_error || obj.radio_error) {
        error = true;
      }
    });

    const UpadateSliderImages_newarray = BanerAdsList.map((obj) => {
      return {
        redirect_url: obj?.redirect_url,
        slider_image: obj?.slider_image,
        access_login: obj?.access_login,
        status: obj?.status,
        url_type: obj?.url_type,
        _id: obj?._id,
      };
    });
    console.log(
      "getUpadateSliderImages_newarray",
      UpadateSliderImages_newarray
    );

    if (!error) {
      var data = JSON.stringify({
        data: UpadateSliderImages_newarray,
      });

      var config = {
        method: "put",
        url: `${BaseAPI.nodeAPI}slider_image/`,
        headers: {
          Authorization: adminauth?.data?.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          toast.success(response.data?.message, { position: "top-right" });
        })
        .catch(function (error) {
          console.log(error);
          toast.error(response?.data?.message, { position: "top-right" });
        });
    }
  };

  //============================================================partner banerads===================================================//
  //VALIDATION
  const PBanerUploadValidation = () => {
    setPartnerBanerAdsList((current) =>
      current.map((obj) => {
        if (!obj.slider_image) {
          return { ...obj, image_errorP: "Upload Image" };
        } else {
          return { ...obj, image_errorP: "" };
        }
      })
    );

    setPartnerBanerAdsList((current) =>
      current.map((obj) => {
        if (!obj.redirect_url) {
          return { ...obj, selectplanP: "Select Plan" };
        } else {
          return { ...obj, selectplanP: "" };
        }
      })
    );
  };

  //function to add  duplicate div for partner
  const handleAddDuplicatePartner = () => {
    console.log(PartnerBanerAdsList);
    setPartnerBanerAdsList([
      ...PartnerBanerAdsList,
      {
        slider_image: "",
        url_type: "",
        redirect_url: "",
        access_login: isActive,
        image_errorP: "",
        selectplanP: "",
      },
    ]);
    // ==========adding error obeject in array
  };

  ///api call banee adspatner image upload
  const BannerImageUploadPartner = (e, index) => {
    PBanerUploadValidation();
    setPImageERror();
    console.log(e.target.files[0]);
    if (e.target.files[0].size < 2000000) {
      console.log("true");
      setimgeload(!imgload);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", adminauth?.data?.token);
      var formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      console.log(formdata);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${BaseAPI.nodeAPI}fileupload`, requestOptions)
        .then((response) => response.text())
        .then((result) => setImagePartner(JSON.parse(result).file_path, index));
      // .then(result=> setPartnerBanerAdsList(PartnerBanerAdsList))
      // .catch(error => setPimgeload(!imgload));
    } else {
      setPImageERror("File size cannot exceed 2MB");
      console.log(false);
    }
  };

  //function to update/upload image state partner
  const setImagePartner = (url, index) => {
    console.log(url);
    // PartnerBanerAdsList[index].slider_image = url
    // setPartnerBanerAdsList(PartnerBanerAdsList)\
    setPartnerBanerAdsList((current) =>
      current.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            slider_image: url,
          };
        }
        return obj;
      })
    );
    setimgeload(!imgload);
  };

  //Function to store redio button input aap/browser for partner
  const HandleredioButton = (value, index) => {
    console.log("HandleredioButton", value);
    console.log("HandleredioButton", index);
    PartnerBanerAdsList[index].url_type = value;

    setPartnerBanerAdsList((current) =>
      current.map((obj) => {
        if (obj.index === index) {
          return { ...obj, url_type: value };
        }
        console.log(obj);

        return obj;
      })
    );
  };

  //Function to store plan/browserurl input for aap/browser
  const Handleselectplan = (value, index) => {
    PBanerUploadValidation();
    console.log("Handleselectplan", value);
    console.log("Handleselectplan", index);
    PartnerBanerAdsList[index].redirect_url = value;
    setPartnerBanerAdsList((current) =>
      current.map((obj) => {
        if (obj.index === index) {
          return { ...obj, redirect_url: value };
        }

        return obj;
      })
    );
  };

  useEffect(() => {
    console.log("isActiveisActiveisActive", isActive);
    PartnerBanerAdsList[0].access_login = isActive;
  }, [isActive]);

  const getUploadePartnerBannerAds = async () => {
    var new_arrayP = PartnerBanerAdsList.map((obj) => ({
      slider_image: obj.slider_image,
      url_type: obj.url_type,
      redirect_url: obj.redirect_url,
      access_login: obj.access_login,
    }));
    var data = JSON.stringify({
      filterData: new_arrayP,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}slider_image/add`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        console.log(response.data);
        toast.success(response.data.message, { position: "top-right" });
      })
      .catch(function (error) {
        console.log(error);
        toast.error(err.response.data.message, { position: "top-right" });
      });
  };

  //Function validation not to call api till error message not fullfill
  const api_call_Partner = () => {
    PBanerUploadValidation();
    const error_arrayp = PartnerBanerAdsList.filter((value) => {
      return (
        !value.slider_image ||
        !value.redirect_url ||
        !value.access_login ||
        !value.url_type
      );
    });
    if (!error_arrayp.length) {
      getUploadePartnerBannerAds();
    }
  };

  //Delete div with all information
  const resetPartner = (value) => {
    const a = PartnerBanerAdsList.filter((va, i) => {
      // console.log("value", i,value);
      return i !== value;
    });
    // console.log("new array",a);
    setPartnerBanerAdsList(a);
  };

  //delete upladed image
  const DeletePhoto = (index) => {
    console.log("id", index);
    var data = JSON.stringify({
      status: 0,
    });

    var config = {
      method: "delete",
      url: `${BaseAPI.nodeAPI}slider_image/${index}`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  ///List api call for partner banner iamges
  const getPartnerBaneerAdsList = () => {
    var data = JSON.stringify({
      filterData: {
        access_login: 5,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}slider_image/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(
          "BanerAdsListBanerAdsListapiresponce",
          response?.data?.data
        );
        setPartnerBanerAdsList(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //update api call for update
  const getUpadatePartnerSliderImages = () => {
    PBanerUploadValidation();
    var errorp = false;
    PartnerBanerAdsList.map((obj) => {
      if (obj.image_errorP || obj.selectplanP) {
        errorp = true;
      }
    });
    const UpadatePartner_newarray = PartnerBanerAdsList.map((obj) => {
      return {
        redirect_url: obj?.redirect_url,
        slider_image: obj?.slider_image,
        access_login: obj?.access_login,
        status: obj?.status,
        url_type: obj?.url_type,
        _id: obj?._id,
      };
    });
    console.log(
      "getUpadatePartnerSliderImagesUpadatePartner_newarray=====>>>>>>>>>",
      UpadatePartner_newarray
    );
    if (!errorp) {
      var data = JSON.stringify({
        data: UpadatePartner_newarray,
      });

      var config = {
        method: "put",
        url: `${BaseAPI.nodeAPI}slider_image/`,
        headers: {
          Authorization: adminauth?.data?.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          toast.success(response.data?.message, { position: "top-right" });
        })
        .catch(function (error) {
          console.log(error);
          toast.error(response?.data?.message, { position: "top-right" });
        });
    }
  };

  //jsx
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Banner Advertisement</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 pageBack d-flex mb-4">
                    {/* <p className="fw-bold font-size-18 h6">
                      Banner Advertisement
                    </p> */}
                    <div className="float-start col-6">
                      <a className="" href="#/">
                        <i
                          className="fas fa-arrow-left text-dark"
                          aria-hidden="true"
                        ></i>
                      </a>
                      <span className="fw-bold h5 ps-3">
                        Banner Advertisement
                      </span>
                    </div>
                    {/* <div className="col-6 d-flex justify-content-end">
                      <NavLink
                        to={AdminRoutes.BannerAdsDetails}
                        type="button"
                        className="btn addBtns waves-effect waves-light ms-3"
                      >
                        Banner Ads Details
                      </NavLink>
                    </div> */}
                  </div>
                  <div className="mb-3 col-12">
                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#consumer"
                          role="tab"
                          onClick={() => [setisActive(4), getBaneerAdsList(4)]}
                        >
                          <span>Consumer</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#partner"
                          role="tab"
                          onClick={() => [
                            setisActive(5),
                            getPartnerBaneerAdsList(),
                          ]}
                        >
                          <span>Partner</span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content p-4 tab-sub text-muted">
                      {/* Consumer Banner ADS div */}
                      {isActive === 4 ? (
                        <div
                          className="tab-pane active"
                          id="consumer"
                          role="tabpanel"
                        >
                          <div className="row">
                            {BanerAdsList &&
                              BanerAdsList.map((val, index) => {
                                return (
                                  <div
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5"
                                    key={index}
                                  >
                                    <div className="p-5 border-button buttonBorderBox">
                                      {/* <div className="d-flex flex-row justify-content-end mb-3">
                                      {index === 0 ?
                                        null :

                                        <button
                                          type="button"
                                          className="btn-close"
                                          aria-label="Close"
                                          onClick={() => {
                                            resetConsumer(index);
                                          }}
                                        ></button>
                                      }
                                    </div> */}
                                      <div className="icon-bg d-flex justify-content-center align-items-center px-5 py-5 bannerAddsBoxWrapper">
                                        <div className="upload-btn-wrapper text-center w-100 overflow-visible bannerAddsBox">
                                          <p className="addUserPic upload-btn text-center w-100 d-flex justify-content-center align-items-center">
                                            {!val.slider_image ? (
                                              <>
                                                <div className="text-center">
                                                  <i className="fa-solid fa-arrow-up-from-bracket font-size-24 mb-3"></i>
                                                  <p>
                                                    Upload image For Consumer
                                                  </p>
                                                </div>
                                                <input
                                                  type="file"
                                                  className="custom-file-input"
                                                  id="customFile"
                                                  name="media"
                                                  multiple="multiple"
                                                  accept=".png,.jpg,.jpeg"
                                                  onChange={(e) =>
                                                    BannerImageUploadConsumer(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <div className="text-center">
                                                  <img
                                                    src={val?.slider_image}
                                                    className="img-fluid boxImage"
                                                    alt=""
                                                  />
                                                </div>
                                                <input
                                                  type="file"
                                                  className="custom-file-input"
                                                  id={val?._id}
                                                  name="media"
                                                  multiple=""
                                                  accept="image/*"
                                                  onChange={(e) =>
                                                    BannerImageUploadConsumer(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                            <label
                                              className="custom-file-label mb-0"
                                              htmlFor={val?._id}
                                            ></label>
                                          </p>

                                          <div
                                            className="modal viewdetails-modal"
                                            id="viewdetails"
                                            tabIndex="-1"
                                            aria-labelledby="viewdetailsLabel"
                                            aria-hidden="true"
                                            data-bs-backdrop="false"
                                          >
                                            <div className="modal-dialog viewdetails-modaldialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                  ></button>
                                                </div>
                                                <div className="modal-body text-start">
                                                  <p className="mb-2">
                                                    <strong>Name :</strong>{" "}
                                                    Elite Discount 25% Thumbnail
                                                  </p>
                                                  <p className="mb-2">
                                                    <strong>File Size :</strong>{" "}
                                                    36Kb
                                                  </p>
                                                  <p className="mb-2">
                                                    <strong>Format :</strong>{" "}
                                                    Png, Jpeg.
                                                  </p>
                                                  <p className="mb-2">
                                                    {" "}
                                                    <strong>
                                                      Uploaded Date :
                                                    </strong>{" "}
                                                    28-11-2022
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="d-flex justify-content-between">
                                           <div className="bottom-btn">
                                            <button
                                              className="btn btn-delete me-4"
                                              onClick={() => DeletePhotoC(val?._id)}
                                            >
                                              <i className="fa-sharp fa-solid fa-trash me-2"></i>
                                              <span>Delete Photo</span>
                                            </button>
                                            <button className="btn btn-delete">
                                              <i className="fa-sharp fa-solid fa-rotate-right me-2"></i>
                                              <span>Update photo</span>
                                            </button>
                                          </div> 
                                           <button type="button" className="btn viewdetails-button" data-bs-toggle="modal" data-bs-target="#viewdetails" id="c1">
                                          View details
                                        </button> 
                                        </div> */}
                                        </div>
                                      </div>
                                      {/* <p className="mb-4 mt-2 text-center text-danger">{CImageERror}</p> */}
                                      <div className="row pt-5">
                                        <div className="col-md-6">
                                          <div className="form-check form-check-inline me-4">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name={`${val?._id}1`}
                                              id={`${val?._id}1`}
                                              value="Publish within app"
                                              //onClick={(e) => {setredioButton(e.target.value) }}
                                              onChange={(e) =>
                                                HandleredioButtonC(
                                                  e.target.value,
                                                  index
                                                )
                                              }
                                              checked={
                                                val?.url_type ===
                                                "Publish within app"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`${val?._id}1`}
                                            >
                                              Publish within app
                                            </label>
                                          </div>

                                          {val?.url_type ==
                                          "Publish within app" ? (
                                            <div className="dropdown w-100 mt-4">
                                              <select
                                                className="form-select planSelect w-25"
                                                aria-label="Default select example"
                                                onChange={(e) =>
                                                  HandleselectplanC(
                                                    e.target.value,
                                                    index
                                                  )
                                                }
                                              >
                                                {/* <option selected>
                                                  Select Plan
                                                </option> */}
                                                <option
                                                  value="C1"
                                                  selected={
                                                    val?.redirect_url === "C1"
                                                  }
                                                >
                                                  C1
                                                </option>
                                                <option
                                                  value="C2"
                                                  selected={
                                                    val?.redirect_url === "C2"
                                                  }
                                                >
                                                  C2
                                                </option>
                                                <option
                                                  value="C3"
                                                  selected={
                                                    val?.redirect_url === "C3"
                                                  }
                                                >
                                                  C3
                                                </option>
                                                <option
                                                  value="C4"
                                                  selected={
                                                    val?.redirect_url === "C4"
                                                  }
                                                >
                                                  C4
                                                </option>
                                              </select>
                                            </div>
                                          ) : null}

                                          {val?.url_type == "Via Browser" ? (
                                            <div className="input-group flex-nowrap mt-4">
                                              <input
                                                type="text"
                                                className="form-control fw-normal py-2"
                                                placeholder="Enter/Paste URL Link"
                                                aria-label="Username"
                                                aria-describedby="addon-wrapping"
                                                onChange={(e) =>
                                                  HandleselectplanC(
                                                    e.target.value,
                                                    index
                                                  )
                                                }
                                                value={val?.redirect_url}
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name={`${val?._id}1`}
                                              id={`${val?._id}2`}
                                              value="Via Browser"
                                              onChange={(e) => {
                                                HandleredioButtonC(
                                                  e.target.value,
                                                  index
                                                );
                                              }}
                                              checked={
                                                val?.url_type === "Via Browser"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`${val?._id}2`}
                                            >
                                              Via Browser
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      {/* ===============CONSUMER ERROR==================== */}
                                      <p className="text-danger">
                                        {val.image_error}
                                      </p>
                                      <p className="text-danger">
                                        {val.radio_error}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            {/* Condition for consumer plus sign not more than 6 */}
                            {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5">
                            {BanerAdsList
                              ?.length === 6
                              ?
                              null :
                              <div className="h-100 border-button1 d-flex align-items-center justify-content-center">
                                <div className="text-center">
                                  <button className="btn"
                                    onClick={() => handleAddDuplicateConsumer()}
                                  ><i className="fa-sharp fa-solid fa-plus plus-icon py-5 px-5"></i><p className="blueText">Create New Advertisement Consumer</p></button>
                                </div>
                              </div>
                            }

                          </div> */}
                          </div>
                          <div className="row mt-3">
                            <p className="mb-4 mt-2 text-center text-danger">
                              {CImageERror}
                            </p>
                            <div className="col-12 text-center">
                              <button
                                className="btn bluebtns waves-effect waves-light px-5"
                                onClick={(e) => getUpadateSliderImages()}
                              >
                                Update Consumer Banner
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {/* partner Banner Ads div */}

                      {isActive == 5 ? (
                        <div
                          className="tab-pane active"
                          id="partner"
                          role="tabpanel"
                        >
                          <div className="row">
                            {PartnerBanerAdsList &&
                              PartnerBanerAdsList.map((val, index) => {
                                return (
                                  <div
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5"
                                    key={index}
                                  >
                                    <div className="p-5 border-button buttonBorderBox">
                                      {/* <div className="d-flex flex-row justify-content-end mb-3">
                                      {index === 0
                                        ?
                                        null :
                                        <button
                                          type="button"
                                          className="btn-close f"
                                          aria-label="Close"
                                          onClick={() => {
                                            resetPartner(index);
                                          }}
                                        ></button>
                                      }
                                    </div> */}
                                      <div className="icon-bg d-flex justify-content-center align-items-center px-5 py-5 bannerAddsBoxWrapper">
                                        <div className="upload-btn-wrapper text-center w-100 overflow-visible bannerAddsBox">
                                          <p className="addUserPic upload-btn text-center w-100 d-flex justify-content-center align-items-center">
                                            {!val.slider_image ? (
                                              <>
                                                <div className="text-center">
                                                  <i className="fa-solid fa-arrow-up-from-bracket font-size-24 mb-3"></i>
                                                  <p>
                                                    Upload image For partner
                                                  </p>
                                                </div>
                                                <input
                                                  type="file"
                                                  className="custom-file-input"
                                                  id="customFile"
                                                  name="media"
                                                  multiple="multiple"
                                                  accept=".png,.jpg,.jpeg"
                                                  onChange={(e) =>
                                                    BannerImageUploadPartner(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <div className="text-center">
                                                  <img
                                                    src={val?.slider_image}
                                                    className="img-fluid boxImage"
                                                    alt=""
                                                  />
                                                </div>
                                                <input
                                                  type="file"
                                                  className="custom-file-input"
                                                  id={val?._id}
                                                  name="media"
                                                  multiple=""
                                                  accept="image/*"
                                                  onChange={(e) =>
                                                    BannerImageUploadPartner(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                            <label
                                              className="custom-file-label mb-0"
                                              htmlFor={val?._id}
                                            ></label>
                                          </p>

                                          {/* <div className="modal viewdetails-modal" id="viewdetails" tabIndex="-1" aria-labelledby="viewdetailsLabel" aria-hidden="true" data-bs-backdrop="false">
                                          <div className="modal-dialog viewdetails-modaldialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                              </div>
                                              <div className="modal-body text-start">
                                                <p className="mb-2"><strong>Name  :</strong> Elite Discount 25% Thumbnail</p>
                                                <p className="mb-2"><strong>File Size :</strong> 36Kb</p>
                                                <p className="mb-2"><strong>Format :</strong> Png, Jpeg.</p>
                                                <p className="mb-2"> <strong>Uploaded Date :</strong> 28-11-2022</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                                          {/* <div className="d-flex justify-content-between">
                                          <div className="bottom-btn">
                                            <button
                                              className="btn btn-delete me-4"
                                              onClick={() => DeletePhoto(val?._id)}
                                            >
                                              <i className="fa-sharp fa-solid fa-trash me-2"></i>
                                              <span>Delete Photo</span>
                                            </button>
                                             <button className="btn btn-delete">
                                              <i className="fa-sharp fa-solid fa-rotate-right me-2"></i>
                                              <span>Update photo</span>
                                            </button> 
                                          </div>
                                          <button type="button" className="btn viewdetails-button" data-bs-toggle="modal" data-bs-target="#viewdetails" id="c1">
                                            View details
                                          </button> 
                                        </div>  */}
                                        </div>
                                      </div>
                                      <p className="mb-4 mt-2 text-center text-danger">
                                        {PImageERror}
                                      </p>
                                      <div className="row pt-5">
                                        <div className="col-md-6">
                                          <div className="form-check form-check-inline me-4">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name={`${val?._id}1`}
                                              id={`${val?._id}1`}
                                              value="Publish within app"
                                              //onClick={(e) => {setredioButton(e.target.value) }}
                                              onChange={(e) =>
                                                HandleredioButton(
                                                  e.target.value,
                                                  index
                                                )
                                              }
                                              checked={
                                                val?.url_type ===
                                                "Publish within app"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`${val?._id}1`}
                                            >
                                              Publish within app
                                            </label>
                                          </div>
                                          {val?.url_type ===
                                          "Publish within app" ? (
                                            <div className="dropdown w-100 mt-4">
                                              <select
                                                className="form-select planSelect w-25"
                                                aria-label="Default select example"
                                                onChange={(e) =>
                                                  Handleselectplan(
                                                    e.target.value,
                                                    index
                                                  )
                                                }
                                              >
                                                {/* <option selected>
                                                  Select Plan
                                                </option> */}
                                                <option
                                                  value="P2"
                                                  selected={
                                                    val?.redirect_url === "P1"
                                                  }
                                                >
                                                  P2
                                                </option>
                                                <option
                                                  value="P1"
                                                  selected={
                                                    val?.redirect_url === "P2"
                                                  }
                                                >
                                                  P1
                                                </option>
                                                <option
                                                  value="P3"
                                                  selected={
                                                    val?.redirect_url === "P3"
                                                  }
                                                >
                                                  P3
                                                </option>
                                                <option
                                                  value="P4"
                                                  selected={
                                                    val?.redirect_url === "P4"
                                                  }
                                                >
                                                  P4
                                                </option>
                                              </select>
                                            </div>
                                          ) : null}
                                          {val?.url_type === "Via Browser" ? (
                                            <div className="input-group flex-nowrap mt-4">
                                              <input
                                                type="text"
                                                className="form-control fw-normal py-2"
                                                placeholder="Enter/Paste URL Link"
                                                aria-label="Username"
                                                aria-describedby="addon-wrapping"
                                                onChange={(e) =>
                                                  Handleselectplan(
                                                    e.target.value,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name={`${val?._id}1`}
                                              id={`${val?._id}2`}
                                              value="Via Browser"
                                              onChange={(e) => {
                                                HandleredioButton(
                                                  e.target.value,
                                                  index
                                                );
                                              }}
                                              checked={
                                                val?.url_type === "Via Browser"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`${val?._id}2`}
                                            >
                                              Via Browser
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      {/* ===============PARTNER ERROR ==================== */}
                                      <p className="text-danger">
                                        {val?.image_errorP}
                                      </p>
                                      <p className="text-danger">
                                        {val?.selectplanP}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            {/* Condition for partner plus sign not more than 6 */}
                            {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5">
                            {PartnerBanerAdsList?.length === 6
                              ?
                              null :
                              <div className="h-100 border-button1 d-flex align-items-center justify-content-center">
                                <div className="text-center">

                                  <button className="btn"
                                    onClick={(e) => handleAddDuplicatePartner(e)}
                                  ><i className="fa-sharp fa-solid fa-plus plus-icon py-5 px-5"></i><p className="blueText">Create New Advertisement Partner</p></button>

                                </div>
                              </div>
                            }
                          </div> */}
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 text-center">
                              <button
                                className="btn bluebtns waves-effect waves-light px-5"
                                onClick={(e) =>
                                  getUpadatePartnerSliderImages(e)
                                }
                              >
                                Update Partner Banner
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerAds;
