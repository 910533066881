/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";

const WeStaffDetails = () => {
  // varibles
  const navigate = useNavigate();
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const [WeStaffDetails, setWeStaffDetails] = useState([]);
  const [isEditEmp_id, setIsEditEmp_id] = useState(true);
  const [isEditFirstName, setIsEditFirstName] = useState(true);
  const [isEditLastName, setIsEditLastName] = useState(true);
  const [isEditContact, setIsEditContact] = useState(true);
  const [isEditEmail, setIsEditEmail] = useState(true);
  const params = useParams();
  const initialValues = {
    firstname: WeStaffDetails?.name,
    lastname: WeStaffDetails?.last_name,
    contactno: WeStaffDetails?.phone,
  };
  const [formValues, setformValues] = useState(initialValues);
  const [roles, setRoles] = useState([]);
  const [activeStatus, setActiveStatus] = useState();
  const [redioStatus, setRedioStatus] = useState(false);
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // Api Call For  WeStaff Details....
  useEffect(() => {
    const WeStaffDetailsData = async () => {
      var config = {
        method: "GET",
        url: `${BaseAPI.nodeAPI}users/${params.westaffuserid}`,
        headers: {
          Authorization: adminauth?.data?.token,
        },
      };
      await axios(config)
        .then(function (response) {
          console.log("object========>", response?.data?.data);
          setWeStaffDetails(response?.data?.data);
          setActiveStatus(response?.data?.data?.user_data);
          setRoles(response?.data?.data?.user_data?.roles);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    WeStaffDetailsData();
  }, []);

  useEffect(() => {
    console.log(activeStatus);
    if (activeStatus?.status) {
      setRedioStatus(true);
    } else {
      setRedioStatus(false);
    }
  }, [activeStatus]);

  // API Call For Update UserDetails.....
  const handleSave = () => {
    var data = JSON.stringify({
      name: formValues.firstname,
      phone: formValues.contactno,
      last_name: formValues.lastname,
      roles: roles,
      status: WeStaffDetails.status,
      address: WeStaffDetails.address,
      image: WeStaffDetails.image,
    });

    var config = {
      method: "put",
      url: `${BaseAPI.nodeAPI}users/update_detail/${params.westaffuserid}`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        toast.success(response.data.message, { position: "top-right" });
        console.log(response.data);
        navigate("/admin-user-management-we-staff");
      })
      .catch(function (err) {
        console.log(err);
        toast.error(err.response.data.message, { position: "top-right" });
      });
  };

  // Function for update values in form
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e);
    setformValues({ ...formValues, [name]: value });
  };

  // function for to show  default checkbox
  const handlecheckbox = (e) => {
    if (e.target.checked === true) {
      roles.push(e.target.name);
    }
    if (e.target.checked === false) {
      setRoles(roles.filter((item) => item !== e.target.name));
    }
  };

  // Edit onclick for emp_id
  const isEditEmp_idHandle = () => {
    setIsEditEmp_id(!isEditEmp_id);
  };
  // Edit onclick for FirstName
  const isEditFirstHandle = () => {
    setIsEditFirstName(!isEditFirstName);
  };
  //  Edit onclick for lastname
  const isEditLasthandle = () => {
    setIsEditLastName(!isEditLastName);
  };
  // / Edit onclick for contact
  const isEditContacthandle = () => {
    setIsEditContact(!isEditContact);
  };
  // / Edit onclick for email
  const isEditEmailhandle = () => {
    setIsEditEmail(!isEditEmail);
  };

  // const handleRedioStatus = (e) => {
  //   if (e.target.checked) {
  //     setRedioStatus(true);
  //   } else {
  //     setRedioStatus(false);
  //   }
  //   setActiveStatus(e.target.checked === true ? 1 : 0);
  // };
  const Refresh = () => {
    window.location.reload(false);
  };
  //delete api call for wendians
  //  const DeleteWendians = (Wendians_id) => {
  //   var data = JSON.stringify({
  //     "_id": params.westaffuserid
  //   });

  //   var config = {
  //     method: 'post',
  //     url: `${BaseAPI.nodeAPI}data_remove/user`,
  //     headers: {
  //       'Authorization': adminauth?.data?.token,
  //       'Content-Type': 'application/json',

  //     },
  //     data: data
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       if(response.data.status === 1){
  //         console.log(response.data);
  //         toast.success(response.data.message, { position: 'top-right' });
  //         navigate('/admin-user-management-we-staff')
  //        Refresh()

  //       }

  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       toast.error(error.response.data.message, { position: 'top-right' });
  //     });

  // }

  //api call for active inactive user/wendians

  const getActiveInactive = (value) => {
    console.log(value);
    var data = JSON.stringify({
      status: value ? 1 : 0,
    });
    var config = {
      method: "put",
      url: `${BaseAPI.nodeAPI}users/update_detail/${params.westaffuserid}`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        navigate("/admin-user-management-we-staff");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("WeStaffDetailsuser_data", WeStaffDetails?.user_data?.status);
  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Wendians</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row pageBack mb-4">
                  <div className="col-lg-6 pb-3">
                    <div className="float-start">
                      <NavLink to={AdminRoutes.AdminUserManagementWeStaff}>
                        <i className="fas fa-arrow-left text-dark"></i>
                      </NavLink>
                      <span className="fw-bold h5 ps-3">Wendians Details</span>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-start justify-content-md-end">
                    {WeStaffDetails?.user_data ? (
                      <>
                        {
                          <div className="float-start text-end">
                            <input
                              type="checkbox"
                              id="switch4"
                              switch="success"
                              // className="progresstoggle"
                              defaultChecked={WeStaffDetails?.user_data?.status}
                              className={
                                WeStaffDetails?.user_data?.status
                                  ? "badge bg-success px-2 py-1"
                                  : "badge rounded bg-warning"
                              }
                              onClick={(e) =>
                                getActiveInactive(e.target.checked)
                              }
                            />
                            <label
                              className="form-label"
                              htmlFor="switch4"
                              data-on-label="Active"
                              data-off-label="Inactive"
                            ></label>
                          </div>
                        }
                      </>
                    ) : null}
                    {/* <span>
                      {console.log(typeof WeStaffDetails?.user_data?.status)}
                    </span> */}
                    {/* <div className="float-start col-6 text-end">
                    <span className={WeStaffDetails?.user_data?.status == 1 ? "badge bg-success px-2 py-1" : 'badge rounded bg-warning'}>{WeStaffDetails?.user_data?.status == 1 ? ' Active' : ' Inactive'}</span>
                  </div> */}
                    {/* <div className="float-start  text-end ps-3">
                <td className="text-center"><i className="fas fa-trash" data-bs-toggle="modal" data-bs-target="#deletModal"></i></td>
                </div> */}
                  </div>
                  {/*  */}

                  {/* Toggele */}
                </div>
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="col-md-4 col-sm-12 col-12 float-start">
                      <div className="col-12 float-start mt-2 mt-md-4 mb-4">
                        <div className="user-wid profilePic text-center pt-2 pb-2">
                          <div className="user-img">
                            <img
                              src={WeStaffDetails?.user_data?.image}
                              alt=""
                              className="mx-auto rounded-circle"
                            />
                          </div>
                        </div>
                        <p className="text-center font14 mb-1">
                          Profile Picture
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8 col-sm-12 col-12 float-start mb-4">
                      <div className="row ps-0 ps-md-4">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="firstname">
                            Employee Id
                          </label>
                          <input
                            name="emp_id"
                            readOnly={isEditEmp_id}
                            type="text"
                            className="form-control"
                            id="emp_id"
                            placeholder="Enter First Name"
                            defaultValue={
                              WeStaffDetails?.user_data?.employee_id
                            }
                            onChange={handleChange}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditEmp_idHandle}
                          ></span>
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="firstname">
                            First Name
                          </label>
                          <input
                            name="firstname"
                            readOnly={isEditFirstName}
                            type="text"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter First Name"
                            defaultValue={WeStaffDetails?.user_data?.name}
                            onChange={handleChange}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditFirstHandle}
                          >
                            <i className="fas fa-pen"></i>
                          </span>
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="lastname">
                            Last Name
                          </label>
                          <input
                            name="lastname"
                            readOnly={isEditLastName}
                            type="text"
                            className="form-control"
                            id="lastname"
                            placeholder="Enter Last Name"
                            defaultValue={WeStaffDetails?.user_data?.last_name}
                            onChange={handleChange}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditLasthandle}
                          >
                            <i className="fas fa-pen"></i>
                          </span>
                        </div>
                        <div className="col-md-6 col-sm-12 px-2 mb-3">
                          <label className="form-label" htmlFor="contact">
                            Contact No.
                          </label>
                          <input
                            name="contactno"
                            readOnly={isEditContact}
                            type="text"
                            className="form-control"
                            id="contact"
                            placeholder="Enter Contact No"
                            defaultValue={WeStaffDetails?.user_data?.phone}
                            onChange={handleChange}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditContacthandle}
                          >
                            <i className="fas fa-pen"></i>
                          </span>
                        </div>
                        <div className="col-md-6 col-sm-12 px-2 mb-3">
                          <label className="form-label" htmlFor="useremail">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="useremail"
                            readOnly={isEditEmail}
                            placeholder="Enter email"
                            defaultValue={WeStaffDetails?.user_data?.email}
                          />
                          <span
                            className="formIcons"
                            onClick={isEditEmailhandle}
                          ></span>
                        </div>
                        <div className="col-md-12 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="useraddress">
                            Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="useraddress"
                            name="address"
                            placeholder="Enter address"
                            required
                            defaultValue={WeStaffDetails?.user_data?.address}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 float-start mb-3">
                      <div className="row">
                        <h6 className="heliosRounded robotofont fw-bold regSecondHead px-2">
                          ROLES
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 float-start">
                      <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="vendorApproval"
                              name="Consumer"
                              required
                              // value={WeStaffDetails?.roles[0]}
                              onChange={(e) => handlecheckbox(e)}
                              checked={roles?.find((items) =>
                                items === "Consumer" ? true : false
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="vendorApproval"
                            >
                              Consumer
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="raiseRequest"
                              name="Partner"
                              required
                              onChange={(e) => handlecheckbox(e)}
                              checked={roles?.find((items) =>
                                items === "Partner" ? true : false
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="raiseRequest"
                            >
                              Partner
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="Subscription"
                              id="raiseRequest"
                              required
                              onChange={(e) => handlecheckbox(e)}
                              checked={roles?.find((items) =>
                                items === "Subscription" ? true : false
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="raiseRequest"
                            >
                              Subscription
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="raiseRequest"
                              name="Product"
                              required
                              onChange={(e) => handlecheckbox(e)}
                              checked={roles?.find((items) =>
                                items === "Product" ? true : false
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="raiseRequest"
                            >
                              Product
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="raiseRequest"
                              name="Ew Plans"
                              onChange={(e) => handlecheckbox(e)}
                              checked={roles?.find((items) =>
                                items === "Ew Plans" ? true : false
                              )}
                              value="Ew Plans"
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="raiseRequest"
                            >
                              Ew Plans
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="chatWithVendor"
                              name="Service Center"
                              onChange={(e) => handlecheckbox(e)}
                              checked={roles?.find((items) =>
                                items === "Service Center" ? true : false
                              )}
                              value="Service Center"
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="chatWithVendor"
                            >
                              Service Center
                            </label>
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-5 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="chatWithCustomer"
                              name="Direct Connect"
                              onChange={(e) => handlecheckbox(e)}
                              required
                              checked={roles?.find((items) =>
                                items === "Direct Connect" ? true : false
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="chatWithCustomer"
                            >
                              Direct Connect
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="chatWithVendor"
                              name="Ask the Expert"
                              onChange={(e) => handlecheckbox(e)}
                              required
                              checked={roles?.find((items) =>
                                items === "Ask the Expert" ? true : false
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="chatWithVendor"
                            >
                              Ask the Expert
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="chatWithRegionalManager"
                              name="Banner Ads"
                              onChange={(e) => handlecheckbox(e)}
                              required
                              checked={roles?.find((items) =>
                                items === "Banner Ads" ? true : false
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="chatWithRegionalManager"
                            >
                              Banner Ads
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="chatWithRegionalManager"
                              name="Notification"
                              onChange={(e) => handlecheckbox(e)}
                              required
                              checked={roles?.find((items) =>
                                items === "Notification" ? true : false
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="chatWithRegionalManager"
                            >
                              Notification
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="chatWithRegionalManager"
                              name="Offer/Benefits"
                              onChange={(e) => handlecheckbox(e)}
                              required
                              checked={roles?.find((items) =>
                                items === "Offer/Benefits" ? true : false
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="chatWithRegionalManager"
                            >
                              Offer/Benefits
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12 float-start px-0  text-end">
                      <button
                        type="submit"
                        className="btn successBtns waves-effect waves-light me-2 px-4"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="deletModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger" id="exampleModalLabel">
                Delete Conformation
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">Are you sure to delete this user?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn addBtns waves-effect waves-light ms-3 px-4"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn blueBtns borderradius10 waves-effect waves-light px-5" onClick={(e)=> DeleteWendians(params.westaffuserid)}>Delete</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeStaffDetails;
