/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";

const AddNewPartnerSubscription = () => {
  const navigate = useNavigate();
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const [SumbitPartnerForm, setSumbitPartnerForm] = useState();

  // Feature
  const [Title, setTitle] = useState("");
  const [Validity, setValidity] = useState();
  // const [ValidTill, setValidTill] = useState("")
  const [MonYer, setMonYer] = useState("");
  const [ProductMargin, setProductMargin] = useState();
  const [AlliacnceFees, setAlliacnceFees] = useState();
  const [MultipleAddressPatt, setMultipleAddressPatt] = useState(null);
  const [WithoutAdvtsPatt, setWithoutAdvtsPatt] = useState(null);
  const [DirectConnectPatt, setDirectConnectPatt] = useState("");
  const [ExpertLead, setExpertLead] = useState("");
  const [BannerAdvertisement, setBannerAdvertisement] = useState(null);
  const [BraeakAdeversisement, setBraeakAdeversisement] = useState(null);
  const [PriorityListing, setPriorityListing] = useState("");
  const [VerifiedListing, setVerifiedListing] = useState(null);
  const [PaymentShedule, setPaymentShedule] = useState();
  // Rewards & Discount
  const [weCoinssubPatt, setweCoinssubPatt] = useState();
  const [weCoinsCustSale, setweCoinsCustSale] = useState();
  const [weCashOnReff, setweCashOnReff] = useState();
  const [weCoinOnReffSub, setweCoinOnReffSub] = useState();
  //  Pricing Plans -Seller or SC
  const [OneTimeFeeSeller, setOneTimeFeeSeller] = useState(0);
  const [AnnualSubSeller, setAnnualSubSeller] = useState(0);
  const [RefundSecuritySeller, setRefundSecuritySeller] = useState(0);
  const [IntroAnnualSubChargeSeller, setIntroAnnualSubChargeSeller] =
    useState(0);
  const [TotalPayablePlanSeller, setTotalPayablePlanSeller] = useState(0);

  //  Pricing Plans -Seller and SC
  const [OneTimeFee, setOneTimeFee] = useState(0);
  const [AnnualSub, setAnnualSub] = useState(0);
  const [RefundSecurity, setRefundSecurity] = useState(0);
  const [IntroAnnualSubCharge, setIntroAnnualSubCharge] = useState(0);
  const [TotalPayablePlan, setTotalPayablePlan] = useState(0);
  // Pricing Plans - Warranty Advisors
  const [AnnualSubChargesFinal, setAnnualSubChargesFinal] = useState();
  // const [TotalPayableFinal, setTotalPayableFinal] = useState("")

  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date

  // API Call For Partner Form Submission

  const handleSubmitPartnerForm = () => {
    try {
      var data = JSON.stringify({
        subscription_for: 3,
        title: Title,
        validity: Validity,
        mon_yr: MonYer,
        multiple_adress: MultipleAddressPatt,
        without_advts: WithoutAdvtsPatt,
        direct_connect: DirectConnectPatt,
        we_coin_subscription: weCoinssubPatt,
        status: 1,
        volt: 0,
        ask_the_expert: 0,
        search: 0,
        warrenty_transfer: 0,
        family_access: 0,
        priority_service: 0,
        subscription_offer: "null",
        offer_6: "null",
        offer_12: "null",
        offer_18: "null",
        offer_24: "null",
        introductory_offer: "null",
        tf_for_we_coin: 0,
        tf_for_we_cash: 0,
        tf_for_we_ew: 0,
        emergency_visit_charges: 0,
        pick_up_and_drop_charges: 0,
        we_cash_subscription: 0,
        we_coin_transaction: 0,
        we_cash_transaction: 0,
        we_coin_ref_subscriber: 0,
        subscription_fees: 0,
        days_0_3_offer: 0,
        days_4_7_offer: 0,
        days_8_15_offer: 0,
        days_16_30_offer: 0,
        product_margin: ProductMargin,
        alliance_fees: AlliacnceFees,
        expert_leads_per_yr: ExpertLead,
        banner_advertisment: BannerAdvertisement,
        break_advertisment: BraeakAdeversisement,
        priority_listing: PriorityListing,
        verified_listing: VerifiedListing,
        payment_schedule: PaymentShedule,
        we_coin_cust_sale: weCoinsCustSale,
        we_cash_ref_seller_sub: weCashOnReff,
        we_coin_ref_seller_sub: weCoinOnReffSub,
        seller_or_sc_one_time_sign_up_free: OneTimeFeeSeller,
        seller_or_sc_annual_subscription_charges: AnnualSubSeller,
        seller_or_sc_refundable_security_deposite: RefundSecuritySeller,
        seller_or_sc_introductory_annual_subscription_Charges:
          IntroAnnualSubChargeSeller,
        seller_or_sc_total_payble: TotalPayablePlanSeller,
        seller_and_sc_one_time_sign_up_free: OneTimeFee,
        seller_and_sc_annual_subscription_charges: AnnualSub,
        seller_and_sc_refundable_security_deposite: RefundSecurity,
        seller_and_sc_introductory_annual_subscription_Charges:
          IntroAnnualSubCharge,
        seller_and_sc_total_payble: TotalPayablePlan,
        WA_annual_sbscription_charges: AnnualSubChargesFinal,
        WA_total_payable: 0,
      });

      var config = {
        method: "post",
        url: `${BaseAPI.nodeAPI}subscription/add`,
        headers: {
          Authorization: adminauth?.data?.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config).then(function (response) {
        setSumbitPartnerForm(response?.data?.data);
        if (response.data.status == 1) {
          toast.success(response.data.message, { position: "top-right" });
          console.log("submitPattnerform", response?.data?.data);
          navigate("/admin-subscription/" + "3");
        }
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, { position: "top-right" });
    }
  };

  useEffect(() => {
    console.log("SumbitPartnerForm---", SumbitPartnerForm);
  }, [SumbitPartnerForm]);

  // Effect for Pricing Plans - Seller or SC  count total payble status
  useEffect(() => {
    setTotalPayablePlanSeller(
      OneTimeFeeSeller +
        // AnnualSubSeller +
        IntroAnnualSubChargeSeller +
        RefundSecuritySeller
    );
  }, [
    OneTimeFeeSeller,
    // AnnualSubSeller,
    IntroAnnualSubChargeSeller,
    RefundSecuritySeller,
  ]);

  // Effect for Pricing Plans - Seller and SC  count total payble status
  useEffect(() => {
    setTotalPayablePlan(
      OneTimeFee + IntroAnnualSubCharge + RefundSecurity
    );
  }, [OneTimeFee, IntroAnnualSubCharge, RefundSecurity]);

  console.log("weCoinssubPatt", weCoinsCustSale);

  console.log("number", typeof AlliacnceFees);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Subscription</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-4 pageBack d-flex">
                    <NavLink to={AdminRoutes.AdminSubscription}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      Add New Plan for Partner
                    </p>
                  </div>

                  <div className="col-md-8 col-sm-12 float-start text-end">
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Duplicate
                    </button>
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Save as Draft
                    </button> */}
                    <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      onClick={handleSubmitPartnerForm}
                    >
                      Submit
                    </NavLink>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="bookTitle">
                            Subscription Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bookTitle"
                            placeholder="Enter Subscription Title"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Validity
                          </label>
                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control me-3"
                              id="noOfPages"
                              placeholder="Enter Validity"
                              onChange={(e) =>
                                setValidity(parseInt(e.target.value))
                              }
                            />
                            <select
                              className="form-select planSelect w-25"
                              aria-label="Default select example"
                              onChange={(e) => setMonYer(e.target.value)}
                            >
                              <option selected>Select</option>
                              <option value="Hrs">Hrs</option>
                              <option value="Days">Days</option>
                              <option value="Months">Months</option>
                              <option value="Years">Years</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start mb-2">
                            <h6 className="fw-bold">Features</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>PRODUCT MARGIN</td>
                                          <td></td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="PRODUCT MARGIN"
                                              onChange={(e) =>
                                                setProductMargin(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="5.00">
                                                5.00%
                                              </option>
                                              <option value="6.00">
                                                6.00%
                                              </option>
                                              <option value="7.50">
                                                7.50%
                                              </option>
                                              <option value="10.00">
                                                10.00%
                                              </option>
                                              <option value="15.00">
                                                15.00%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>ALLIANCE FEE</td>
                                          <td></td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setAlliacnceFees(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="1.50">
                                                1.50%
                                              </option>
                                              <option value="2.50">
                                                2.50%
                                              </option>
                                              <option value="3.00">
                                                3.00%
                                              </option>
                                              <option value="3.50">
                                                3.50%
                                              </option>
                                              <option value="5.00">
                                                5.00%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>MULTIPLE ADDRESS</td>
                                          <td>
                                            Enables users to add more than 1
                                            address in the same app/log-in
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiaddress"
                                                id="multiaddressyes"
                                                value="multiaddressyesoption1"
                                                onChange={(e) =>
                                                  setMultipleAddressPatt(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiaddress"
                                                id="multiaddressno"
                                                value="multiaddressoption2"
                                                onChange={(e) =>
                                                  setMultipleAddressPatt(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>WITHOUT ADVTS</td>
                                          <td>
                                            App without third party break out
                                            advertisements
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadsyes"
                                                value="withoutadsyesoption1"
                                                onChange={(e) =>
                                                  setWithoutAdvtsPatt(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadsno"
                                                value="withoutadsoption2"
                                                onChange={(e) =>
                                                  setWithoutAdvtsPatt(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>DIRECT CONNECT</td>
                                          <td>
                                            Gives direct access to a dedicated
                                            replationship manager for al the
                                            needs
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setDirectConnectPatt(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="No">No</option>
                                              <option value="Self">Self</option>
                                              <option value="Self + Staff">
                                                Self + Staff
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>EXPERT LEADS / yr</td>
                                          <td>
                                            Min Commitment on Consumer leads
                                            passed on the seller
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setExpertLead(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="25">25</option>
                                              <option value="40">40</option>
                                              <option value="75">75</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>7</td>
                                          <td>BANNER ADVERTISEMENTS</td>
                                          <td></td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="banner ads"
                                                id="banneradsyes"
                                                value="banneradsoption1"
                                                onChange={(e) =>
                                                  setBannerAdvertisement(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="banner ads"
                                                id="banneradsno"
                                                value="banneradsoption2"
                                                onChange={(e) =>
                                                  setBannerAdvertisement(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>8</td>
                                          <td>BREAK ADVERTISEMENTS</td>
                                          <td></td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="break ads"
                                                id="breakadsyes"
                                                value="breakadsoption1"
                                                onChange={(e) =>
                                                  setBraeakAdeversisement(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="break ads"
                                                id="breakadsno"
                                                value="breakadsoption2"
                                                onChange={(e) =>
                                                  setBraeakAdeversisement(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>9</td>
                                          <td>PRIORITY LISTING</td>
                                          <td></td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setPriorityListing(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="Last">Last</option>
                                              <option value="Third">
                                                Third
                                              </option>
                                              <option value="Second">
                                                Second
                                              </option>
                                              <option value="First">
                                                First
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>10</td>
                                          <td>VERIFIED LISTING</td>
                                          <td></td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="verified listing"
                                                id="verifiedlistingyes"
                                                value="verifiedlistingoption1"
                                                onChange={(e) =>
                                                  setVerifiedListing(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="verified listing"
                                                id="verifiedlistingno"
                                                value="verifiedlistingoption2"
                                                onChange={(e) =>
                                                  setVerifiedListing(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>11</td>
                                          <td>PAYMENT SCHEDULE</td>
                                          <td></td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setPaymentShedule(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value=" 30 ">
                                                Every 30 days
                                              </option>
                                              <option value=" 21 ">
                                                Every 21 days
                                              </option>
                                              <option value=" 15 ">
                                                Every 15 days
                                              </option>
                                              <option value="7 ">
                                                Every 7 days
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Rewards & Discounts</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>We COINS on Subscription</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCoinssubPatt(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="10"> 10% </option>
                                              <option value="15%">15% </option>
                                              <option value="25%">25%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>WE COINS on Cust Sales</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCoinsCustSale(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="5">5%</option>
                                              <option value="7.5">7.5%</option>
                                              <option value="10">10%</option>
                                              <option value="15">15%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>We CASH on Ref Seller Sub</td>
                                          <td>In Piggy Bank</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCashOnReff(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="1.5">1.5%</option>
                                              <option value="2">2%</option>
                                              <option value="3">3%</option>
                                              <option value="5">5%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>4</td>
                                          <td>We COINS on Ref Seller Sub</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setweCoinOnReffSub(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="2.5">2.5%</option>
                                              <option value="5">5%</option>
                                              <option value="7.5">7.5%</option>
                                              <option value="10">10%</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">
                              Pricing Plans - Seller or SC
                            </h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>One Time Sign Up Fee</td>
                                          <td>Shop Sign Up & Set Up Fee</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setOneTimeFeeSeller(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="1415">1415</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>Annual Subscription Charges</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setAnnualSubSeller(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="3999">3999</option>
                                              <option value="5999">5999</option>
                                              <option value="8999">8999</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>Refundable Security Deposit</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setRefundSecuritySeller(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="3999">3999</option>
                                              <option value="6999">6999</option>
                                              <option value="9999">9999</option>
                                              <option value="12999">
                                                12999
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            Introductory Annual Subscription
                                            Charges
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setIntroAnnualSubChargeSeller(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="3303">3303</option>
                                              <option value="4247">4247</option>
                                              <option value="5309">5309</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>TOTAL PAYABLE</td>
                                          <td>
                                            {/* <input
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e)=>setTotalPayablePlanSeller(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="2998">2998</option>
                                              <option value="5797">5797</option>
                                              <option value="7747">7747</option>
                                              <option value="9998">9998</option>
                                            </input> */}
                                            {TotalPayablePlanSeller}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">
                              Pricing Plans - Seller and SC
                            </h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>One Time Sign Up Fee</td>
                                          <td>Shop Sign Up & Set Up Fee</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setOneTimeFee(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="2359">2359</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>Annual Subscription Charges</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setAnnualSub(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="5499">5499</option>
                                              <option value="6499">6499</option>
                                              <option value="9499">9499</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>Refundable Security Deposit</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setRefundSecurity(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="5999">5999</option>
                                              <option value="11999">
                                                11999
                                              </option>
                                              <option value="16999">
                                                16999
                                              </option>
                                              <option value="19999">
                                                19999
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            Introductory Annual Subscription
                                            Charges
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setIntroAnnualSubCharge(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="4542">4542</option>
                                              <option value="4601">4601</option>
                                              <option value="5604">5604</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>TOTAL PAYABLE</td>
                                          <td>
                                            {/* <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setTotalPayablePlan(e.target.value)}

                                            >
                                              <option selected>Select</option>
                                              <option value="4498">4498</option>
                                              <option value="8497">8497</option>
                                              <option value="10897">
                                                10897
                                              </option>
                                              <option value="13498">
                                                13498
                                              </option>
                                            </select> */}
                                            {TotalPayablePlan}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">
                              Pricing Plans - Warranty Advisors
                            </h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Annual Subscription Charges</td>
                                          <td>Sign Up Fee</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) =>
                                                setAnnualSubChargesFinal(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="499">499</option>
                                              <option value="699">699</option>
                                              <option value="999">999</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          {/* <td>TOTAL PAYABLE</td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setTotalPayableFinal(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="499">499</option>
                                              <option value="699">699</option>
                                              <option value="999">999</option>
                                            </select>
                                          </td> */}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Duplicate
                    </button>
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Save as Draft
                    </button> */}
                    <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <button
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      onClick={handleSubmitPartnerForm}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewPartnerSubscription;
