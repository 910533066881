/*eslint-disable*/
import React, { useState } from 'react'
import logoPartner from "../../../../assets/images/we-partner.jpg"
import imgFooter from "../../../../assets/images/pfooter.png"


const TermsAndConditionpdf = () => {
  //varibale for to show pdf
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // //function for pdf view 
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1)
  // }


  // //function for to change pages
  // function changePage(offSet) {
  //   setPageNumber(prevPageNumber => prevPageNumber + offSet)
  // }

  // //function for to change pages on back  button
  // function ChangePageBack() {
  //   changePage(-1)
  // }

  // //function for to change pdf pages on next buttopn 
  // function ChangePageNext() {
  //   changePage(+1)
  // }
  //JSX
  return (
    // <div className='pdfView'>
    //   <Document file={Terms} onLoadSuccess={onDocumentLoadSuccess}>
    //     <Page height="600" pageNumber={pageNumber}></Page>
    //   </Document>
    //   <p> Page {pageNumber} of {numPages}</p>
    //   <div className='pdfBtns position-absolute'>
    //     {pageNumber > 1 &&
    //       <button className='blueBtns mb-5px' onClick={ChangePageBack}>Previous Page</button>
    //     }
    //     {
    //       pageNumber < numPages &&
    //       <button className='blueBtns mb-5px' onClick={ChangePageNext}>Next</button>
    //     }
    //   </div>
    // </div>
    <div className="container custom-container" id='termsPdf'>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h2 className='Disclaimerheading fst-normal text-center mb-5'> <strong>WARRANTY EXPRESS General Terms and Conditions</strong></h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className='DisclaimerText mb-4'>Please read the Terms carefully before availing/registering for any of Warranty Express Services. By
                    clicking on "Submit" button and/or availing any of the Warranty Express Services and/or access/use the
                    Platform/ Warranty Express Services and/or by downloading the Mobile App, you agree to the Terms
                    set-out hereunder and shall be bound by the same. If you do not wish to avail the Warranty Express
                    Services, please do not click on "Submit" button and/or use any of the Warranty Express Services and/or
                    access/use the Platform and/or download the Mobile App.
                  </p>
                  <p className='DisclaimerText'>The Warranty Express Services shall be made available to the Consumer at the sole discretion of the
                    AUXILL. The Consumer unconditionally confirms that the Consumer shall comply with and be bound
                    by the Terms as in force from time to time. No act, delay or omission by the AUXILL shall affect its
                    rights, powers and remedies under the Terms.</p>
                  <h4 className='DisclaimerHeading fw-bold my-5'>
                    1. Definitions
                  </h4>
                  <p className='DisclaimerText mb-4'>"Applicable Laws" shall mean all laws, statutes, codes, ordinances, decrees, rules, regulations, by-laws,
                    published policies and guidelines, judicial or arbitral or regulatory judgments, orders, decisions, rulings
                    or awards, including RBI Guidelines;</p>
                  <p className='DisclaimerText mb-4'>"Affiliate" shall mean any of the holding, subsidiary, group, affiliate company and shall include any
                    company controlled by or under its common control directly or indirectly;</p>
                  <p className='DisclaimerText mb-4'>"AUXILL" shall mean AUXILL TECHNOLOGIES PRIVATE LIMITED (including its successors
                    and assigns) having its registered office at 1004, Mermaid-1, Sector-11, Plot No. 32/33, CBD Belapur,
                    Navi Mumbai, Pin Code 400614, Maharashtra, India</p>
                  <p className='DisclaimerText mb-4'>“Service Partners” shall mean third party service providers (certified by AUXILL) which includes
                    Channel Partner Retailer Seller (CPRS), Channel Partner Service Centre (CPSC), Channel Partner both
                    Services(CPBS), Channel Development Partner(CDP), Channel Partner Service Technicians(CPST).</p>
                  <p className='DisclaimerText mb-4 bg-yellow1'>"Warranty Express Services/WE" shall mean services approved by the AUXILL for the Consumer from
                    time to time which is including but not limited to ..........................................</p>
                  <p className='DisclaimerText mb-4'>"AUXILL Policy" shall mean any AUXILL policy including policy in relation to this Agreement, rules,
                    additional terms, processes framed and maintained by AUXILL for the Warranty Express Services from
                    time to time and published on <a className='text-decoration-underline' href="https://www.auxill.in/"> www.auxilltechnologies.com </a>and/or Platform:</p>
                  <p className='DisclaimerText mb-4'>"Consumer (s)" or "Customer (s)" shall mean any person who clicks on "Submit" button and/or avail
                    the Warranty Express Services and/or access/use Platform/Warranty Express Services and/or download
                    the Mobile App:</p>
                  <p className='DisclaimerText mb-4'>"Consumer Application"/ "CAF" shall mean the application made by the Customer for availing services
                    under these terms and conditions either online on AUXILLs Platform or through Service Partners or by
                    physically submitting an application along with supporting documents and information subject to
                    AUXILL Policy;</p>
                  <p className='DisclaimerText mb-4'>"KYC Guidelines" shall mean the Know Your Consumer guidelines of RBI and/or any other applicable
                    regulators; "KYC Information" or "KYC" shall include but not limited to name, address, phone/mobile
                    number, email address, Aadhaar details (subject to applicable laws) of the Consumer and documents
                    prescribed by RBI Guidelines/Applicable Laws/AUXILL Policy;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <p className='DisclaimerText mb-4'>"Platform" shall mean any online/offline platform designated by the AUXILL which in including but
                    not limited to the Mobile App, websites through which Warranty Express Services will be made
                    available by the AUXILL to the Consumer;</p>
                  <p className='DisclaimerText mb-4'>"Terms and Conditions" or "Terms" shall mean terms and conditions set-out in this document, any
                    additional terms applicable to the Warranty Express Services and any additions, modifications,
                    amendments made thereto published on Platform and/or ..................... and/or communicated to the
                    Consumer thorough any other mode of communication time to time; and</p>
                  <p className='DisclaimerText mb-4'>"Transaction(s)" shall mean any transaction made by the Consumer using Warranty Express Services</p>
                  <h4 className='DisclaimerHeading fw-bold my-5'>
                    GENERAL TERMS
                  </h4>
                  <p className='DisclaimerText mb-4 negative-indent'>2.1 The Terms and any changes/modifications/amendments made thereto shall be
                    published/communicated by the AUXILL on the Platform and/or <a className='text-decoration-underline' href="https://www.auxill.in/"> www.auxilltechnologies.com </a> a
                    and/or through any other mode of communication. The Consumer shall read and understand the
                    Terms and any changes/modifications/amendments made thereto, AUXILL Policy and Frequently
                    Asked Questions before availing any Warranty Express Services and keep itself abreast about any
                    changes/modifications/amendments to the Terms by visiting the Platform from time to time. Any
                    transaction done subsequent to the amendment/changes/modifications of the Terms will be
                    considered as Consumers acceptance of the amended Terms.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.2 The Consumer shall go through the Frequently Asked Questions (FAQ) section available on
                    Platform and keep itself abreast.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.3 The Consumer shall be bound by the terms and contents of the AUXILL Policy.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.4 Any decision made by the AUXILL in relation to the application made by the Consumer for
                    availing the Warranty Express Services shall be final and binding on the Consumer.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.5 The Consumer understand and agrees that AUXILL shall directly or through any third party
                    appointed by it access the Consumer location and any other data of the Consumer available through
                    Platform and/or through Consumer's mobile phone for the purpose of Warranty Express Services.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.6 The AUXILL may declare its scheme and Consumer offers (Offer") at its own discretion time to
                    time which shall be subject to the terms of the Offer. The AUXILL shall have sole right to deny
                    the Offer benefits to the Consumer if the AUXILL is of the view that the Transaction is fraudulent,
                    dubious or misused and/or the Consumer has not complied with the terms of the Offer.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.7 The incentives and other benefits which may be extended by the AUXILL at its discretion pursuant
                    to any promotional schemes of the AUXILL will be subject to Applicable Law, AUXILL Policy
                    and terms and conditions of such incentive schemes.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.8 The AUXILL may provide third party links to other websites that are maintained by third parties.
                    These links are provided for the convenience of the Consumer only and the provision of these links
                    does not mean that AUXILL endorses these websites or the products and services these third parties
                    provide. The Consumer acknowledges and agrees that the AUXILL shall not be responsible and/or
                    liable for any information/ content or any products/ services available on these third party websites.
                    Consumer is required to ensure its own due-diligence before availing such services/goods.</p>
                  <p className='DisclaimerText negative-indent'> 2.9 The Consumer will not reproduce, sell, resell, duplicate, and copy any part of the Warranty Express
                    Services for any commercial purpose.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <p className='DisclaimerText mb-4 negative-indent'>2.10 The AUXILL reserves the right to discontinue the Warranty Express Services at any time at its
                    sole discretion without giving any reason to the Consumer and/or if there are discrepancies in the
                    information provided by the Consumer and/or the particulars provided by the Consumer in the
                    KYC Information does not match with the details mentioned in the CAF and/or for any other reason
                    whatsoever. In such cases, AUXILL reserves the night to take appropriate action as it may deem
                    fit and proper without incurring any liability in any manner whatsoever.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.11 The Consumer hereby gives express consent to the AUXILL to call the Consumer any time, to
                    record any of its phone conversations and correspondence with the AUXILL and use it for the
                    purpose of defending any claims made by the Consumer or Merchant Establishment or for any
                    other purpose in relation to these Terms and/or as per the applicable laws. The AUXILL shall be
                    entitled to submit the said phone conversations and/or correspondence on the records of the legal
                    and regulatory authorities as an evidence or for any other purpose at its own discretion or as and
                    when demanded by them.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.12 The records maintained by the AUXILL in relation to the Warranty Express Services, Transaction
                    records including but not limited to the traces of the emails/SMS, Consumer phone recordings etc.
                    available with the AUXILL shall be sole and conclusive proofs and shall be final and binding on
                    the Consumer. The AUXILL shall maintain the said records for the period as may deem fit by the
                    AUXILL and shall share the same with any legal and/or regulatory authority as it deems fit or as
                    per the provisions of Applicable Laws and/or for the purpose of defending or taking suitable legal
                    actions.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.13 The AUXILL may change the mode/method of providing the Warranty Express Services or the
                    Warranty Express Services itself and/or Platform any time without giving any notice to the
                    Consumer.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.14 The Consumer shall be sole and exclusive owner of the product related information or Mobile PIN
                    and/or code and/ or password and/or OTP (each hereinafter referred to as "Authentication PIN
                    issued by AUXILL or set by the Consumer on Platform and the Consumer shall keep the same
                    confidential. The Consumer shall accept sole responsibility for use, confidentiality and protection
                    of the Authentication PIN. The Consumer grants express authority to the AUXILL for processing
                    the Transactions and instructions of the Consumer authenticated by the Consumer by providing
                    the Authentication PIN/Fingerprint Biometrics/facial recognition of the Consumer and/or any other
                    authentication method available on the Platform. Consumer shall provide correct mobile number
                    to the AUXILL on which Consumer wish to avail the Warranty Express Services including
                    Authentication PIN etc. The Consumer shall be solely responsible to ensure that the mobile number
                    and other credentials of the Consumer submitted by the Consumer with the AUXILL are correct
                    and belongs to the Consumer.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.15 The Consumer shall not respond to any unauthorized SMS/ e-mail/ phone call in which the
                    Authentication PIN, biometrics or any other personal confidential information are asked for. The
                    AUXILL shall, in no manner whatsoever, be held responsible or liable, if the Consumer incurs any
                    loss as a result of the Authentication PIN, biometrics or any other personal confidential information
                    being disclosed/ shared by the Consumer with any unauthorized persons or in any other manner
                    whereby the security of the Authentication PIN, Accounts, Warranty Express Services, biometrics
                    or any other personal confidential information of the Consumer is compromised.</p>

                  <p className='DisclaimerText mb-4 negative-indent'>2.16 The Consumer shall immediately inform the AUXILL by calling on ...................... or
                    communicating on  <a className='text-decoration-underline'  href="https://www.auxill.in/"> www.auxilltechnologies.com </a> or submitting written application with the
                    AUXILL for the following reasons</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">

                  <p className='DisclaimerText mb-4 ms-5'>
                    <ol type="i">
                      <li className='mb-4'>if his/her WE Account is hacked, misused or if there is any fraudulent transaction; or</li>
                      <li className='mb-4'>in event the customer's mobile having Platform is lost/stolen; or</li>
                      <li className='mb-4'>any issues/grievances. in relation Account/Accounts/Platform/Warranty Express Services; or</li>
                      <li>for any other support in relation to the Express Services and Platform.</li>
                    </ol>
                  </p>
                  <p className='DisclaimerText mb-4 ms-4'>In case of aforesaid scenarios, the AUXILL shall take following actions</p>
                  <p className='DisclaimerText mb-4 ms-5'>
                    <ol type="1.1">
                      <li className='mb-4'>in case of scenario mentioned at point (I) above, the AUXILL shall close/block the Account of
                        the Consumer as per the request of the Consumer or at the sole discretion of the AUXILL. The
                        Consumer shall be solely liable and responsible for the loss due to such hacking, misuse,
                        fraudulent transactions etc. till the AUXILL receives the intimation from the Consumer as
                        mentioned in Clause 2:16. Further action shall be as per the AUXILL Policy,</li>
                      <li className='mb-4'>in case of scenario mentioned at point (II) above, the AUXILL shall close/block the Account
                        of the Consumer as per the request of the Consumer or at the discretion of the AUXILL. The
                        Consumer shall be solely liable and responsible for the loss due to such hacking, misuse,
                        fraudulent transactions etc. till the AUXILL receives the intimation from the Consumer as
                        mentioned in Clause 2.10. Further action shall be as per the AUXILL Policy:</li>
                      <li className='mb-4'>in case of scenario mentioned at point (III) above, the AUXILL will try to resolve the
                        grievances/issues of the Consumer as per the escalation matrix published at
                        <a className='text-decoration-underline' href="https://www.auxill.in/"> www.auxilltechnologies.com </a> and as per AUXILL Policy.</li>
                      <li>in case of scenario mentioned at point (IV) above, the AUXILL will try to resolve the
                        grievances/issues of the Consumer as per the escalation matrix published at
                        <a className='text-decoration-underline'  href="https://www.auxill.in/"> www.auxilltechnologies.com </a>and as per AUXILL Policy.</li>
                    </ol>
                  </p>
                  <p className='DisclaimerText mb-4 ms-4'>Consumer agrees that the resolution of issues/dispute etc. shall be as per the escalation matrix
                    published at <a className='text-decoration-underline' href="https://www.auxill.in/"> www.auxilltechnologies.com </a> and as per the AUXILL Policy. The Consumer
                    understand that the e-mail of the nodal officer shall be ........................................ If the
                    Consumers is not satisfied with the response provided by the AUXILL, the Consumer may approach
                    the appropriate court.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.17 The Consumer hereby gives express consent and authority to the AUXILL to send any messages,
                    notices or any other details including related to the AUXILL and/or its Affiliates product/ services
                    on the mobile number/email address/residential address/official address provided/updated by the
                    Consumer on the records of the AUXILL. The AUXILL shall send any notice and/or
                    communication by push notifications, email, SMS, on Platform or by any other mode of
                    communication including by post, courier at its discretion. However, the Consumer shall send any
                    notice to the AUXILL in relation to the Warranty Express Services on the registered address of the
                    AUXILL published on the Platform.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>
                    <span>2.18 The Consumer hereby agrees and confirms that a) AUXILL shall provide Services directly and/or
                      through authorized representatives or Service Partners under this Agreement b) AUXILL shall
                      appoint/engage authorized representative or Service Partners to collect KYC documents from the
                      Consumer on behalf of the AUXILL and the Consumer shall have no objection on the same c) the
                      AUXILL shall verify KYC documents of the Consumer as per the Applicable Laws and the
                      Consumer shall have no objection on the same d) verification of the Consumer and its KYCdocuments including Aadhaar is mandatory requirement for availing services from AUXILL e)
                      AUXILL shall hold and use such Consumer data as per applicable laws.</span>
                  </p>
                  <p className='DisclaimerText mb-4'></p>
                  <p className='DisclaimerText'></p>
                  <p className='DisclaimerText'></p>
                  <p className='DisclaimerText'></p>
                  <p className='DisclaimerText'></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <p className='DisclaimerText mb-4 ms-5'>If any process, summons, order, injunction, execution, restraint, levy, lien, information, notice,
                    judgment, decree, mandate, attachment order etc. ("Order") is received by the AUXILL from any
                    legal and/or regulatory authority in relation to the WE Account, the AUXILL shall take suitable
                    action as per the Order and as per the AUXILL Policy in relation to such scenarios.</p>
                  <p className='DisclaimerText ms-5 mb-4'>The Consumer agrees that if the Order is passed by the legal and/or regulatory authority as
                    mentioned above in relation to the Accounts, the AUXILL shall take action in relation to the
                    Account as per the AUXILL Policy. The AUXILL shall endeavor to inform the Consumer about
                    the receipt of the Order but shall not be bound to do so.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.19 For provision of the Warranty Express Services the AUXILL may at its sole discretion without
                    any prior intimation to or approval of the Consumer, appoint any authorized person (s)/BC/service
                    providers/agents for providing the Warranty Express Services and/or provide the services through
                    its employees and the Consumer shall hereby agrees that the Consumer shall have no objection on
                    the same and the AUXILL can appoint such authorized persons/BC/service providers/agents or
                    provide the services through the employees as the AUXILL may, in its absolute discretion, deem
                    necessary.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.20 The AUXILL may at its sole discretion, decide not to carry out any instructions of the Consumer
                    in relation to the Warranty Express Services where the AUXILL has reason to believe that the
                    instructions of the Consumer are not genuine or are otherwise improper or unclear or raise a doubt.
                    The AUXILL may rely on the authority of each authorized signatory designated by the Consumer
                    or on a Consumers mandate to send communications until the AUXILL has received written notice
                    or other notice acceptable to it of any change from a duly authorized person and the AUXILL has
                    had a reasonable time to act (after which time it may rely on the change).</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.21 The Consumer agrees that a) only numbers registered on "Do Not Disturb” shall not be considered
                    for telemarketing offers for the products offered by the AUXILL. The AUXILL shall not be
                    responsible or liable for any calls made on a number not registered on Do Not Disturb b)
                    registration on Do Not Disturb shall not limit calls from the AUXILL and any third parties duly
                    authorized by the AUXILL, on account of Account, Warranty Express Services maintenance or
                    any other related issues, for administering or servicing of any Warranty Express Services availed
                    by the Consumer, Alerts on Transactions, Alerts on Warranty Express Services, recovery calls etc.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.22 The Consumer represents and warrants that the Consumer is at least 18 (eighteen) years of age and
                    have not been disqualified for any reason by the AUXILL and/or any legal or regulatory authority
                    for availing the Warranty Express Services at any point in time.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.23 The AUXILL may set limit on the amount of Transaction to be conducted using the Warranty
                    Express Services as per the Applicable Laws and/or the AUXILL Policy. The said limit on the
                    amount of the Transaction shall be binding on the Consumer.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.24 AUXILL may in its discretion deny any split payments towards availing Warranty Express
                    Services</p>
                  <p className='DisclaimerText mb-5 negative-indent'>2.25 The AUXILL reserves the right to reject the Warranty Express Services and/or any applications
                    made by Consumer any time at its sole discretion.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <p className='DisclaimerText mb-4 negative-indent'>2.26 he Customer agrees that the AUXILL shall provide Warranty Express Services directly or through
                    the Service Partners. The Customer agrees that the AUXILL shall have an arrangement with the
                    Service Partners or any other third parties and the Customer shall have no objection on the same
                    The Consumer will get Warranty Express Services and/or any other services under this Agreement
                    through Service Partners if the same is approved by the AUXILL for the Consumer.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.27 The Customer shall be bound by Terms and the Policies of the AUXILL which is including but
                    not limited to Customer compensation policy. The AUXILL shall publish these Terms, Policies,
                    customer compensation policies including any modifications, amendments made thereto. its
                    website is <a className='text-decoration-underline' href="https://www.auxill.in/"> www.auxilltechnologies.com </a> The Customer shall keep itself updated by referring to the
                    same.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.28 The Consumer agrees that the AUXILL shall provide only online statement of WE Account on the
                    Platform if requested by Consumer. Such statement can be downloaded or shared on email of the
                    Consumer by the Consumer for getting the print of the said account statement. If there is any
                    dispute regarding any Transactions listed in the said statement of account, Consumer must inform
                    the AUXILL within 30 days of the Transaction through email and/or raising the complaint through
                    the communication mediums /contact details available on Platform. The AUXILL will conduct
                    investigation and revert on such transactions as per the AUXILL Policy.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.29 If WE Account is not used for the period 1 year by the Consumer, it shall be treated as dormant
                    account/inoperative account. The Consumer shall not be able to use/access/transact in WE Account
                    if the WE Account becomes dormant/inoperative. The Consumer may activate the WE Account
                    by making an application to the AUXILL and the WE Account can be activated by making one
                    transaction subject to these Terms</p>
                  <p className='DisclaimerText mb-4 negative-indent fw-bold'>2.30 FEES AND CHARGES</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.30.1 Usage and operation of the WE Account, Warranty Express Services including Mobile
                    App/Platform is subject to payment of service charges, fees, other charges communicated by
                    the AUXILL on the <a className='text-decoration-underline'  href="https://www.auxill.in/"> www.auxilltechnologies.com </a>and/or Platform and/or other modes of
                    communication from time to time ("Fees"). The Fees shall be exclusive of applicable taxes,
                    GST, duties, cess etc. Any changes made to the Fees shall be communicated by the AUXILL
                    on the <a className='text-decoration-underline'  href="https://www.auxill.in/"> www.auxilltechnologies.com </a> and/or Platform from tune to time. The Consumer
                    unconditionally and irrevocably authorizes the AUXILL to debit WE Account and/or amounts
                    of the Consumers available with the AUXILL from time to time with an equivalent Fee Charges
                    and taxes applicable for the issue and use of the Warranty Express Services The Consumer shall
                    go through the Fees, charges and applicable taxes published/communicated on the
                    <a className='text-decoration-underline'  href="https://www.auxill.in/"> www.auxilltechnologies.com </a>/Platform/other modes of communication before using the
                    Warranty Express Services every time at its sole discretion</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.30.2 While the AUXILL shall exercise due caution, the Consumer agrees that the AUXILL shall
                    have no duty to verify the correctness of any instructions or the identity of the sender of the
                    instructions and the Consumer expressly agrees to be bound by any instructions and
                    communications, whether or not authorized, sent in Consumers name and accepted by the
                    AUXILL.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>2.30.3 The AUXILL shall have lien on all amounts lying in the WE Account towards a) any amounts
                    due to the AUXILL b) amounts payable to the Service Partner/third parties for the Transaction
                    made by Consumer using the Warranty Express Services and c) any fees, loss, charges, legal
                    charges, penalties. any expenses, advocate fees, travelling charges etc, arising out of use of the
                    Warranty Express Services by the Consumer and/or breach of the Terms by the Consumer. TheConsumer hereby give consent and right to AUXILL to mark lien and/or right of set off on the
                    WE Account.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">3. Warranty Express Services through Service Partners</h4>
                  <p className='DisclaimerText mb-4 negative-indent'>3.1 The Consumer will approach only authorised Service Partners appointed by AUXILL for availing
                    Warranty Express Services displaying requisite brands and logos of the AUXILL and the Warranty
                    Express Services and the permission letter/certification issued by the AUXILL.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>3.2 The AUXILL shall decide which Warranty Express Services can be made available for the
                    Consumer through the Service Partner and Consumer shall not be entitled to ask for any Warranty
                    Express Services as of matter of right.</p>

                  <p className='DisclaimerText mb-4 negative-indent'>3.3 For any Transaction or for availing any Warranty Express Services at Service Partners the Consumer
                    shall ensure that a) details provided by the Consumer for the Transaction shall be correct b) the
                    Transaction is complete as desired by the Consumer and the AUXILL shall not be liable and
                    responsible for any disputes/issues in relation to the Transactions made at the Service Partners by
                    using the Warranty Express Services</p>
                  <p className='DisclaimerText mb-4 negative-indent'>3.4 The Consumer agrees to undergo necessary authentication as per the AUXILL Policy for availing
                    the AUXILL Services through any channel including Service Partners.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>3.5 The AUXILL shall not be responsible in any way for the products or services of any Service Partners</p>
                  <p className='DisclaimerText mb-4 negative-indent'>3.6 The Consumer agrees that the AUXILL shall not be party to the contract between the Consumer
                    and Service Partners except for availing Warranty Express Services. Any amount transferred by
                    the Consumer to any Service Partners erroneously shall not be refunded to the Consumer in any
                    circumstances by the AUXILL.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>3.7 The AUXILL does not endorse, promote, guarantee or warrant any goods/products and/or services
                    and/or coupons that might be bought/availed or proposed to be bought/availed by Consumer from
                    Service Partners. Any dispute with or complaint against any Service Partners must be directly be
                    resolved by the Consumer with the Service Partners.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>3.8 The AUXILL may take reasonable steps to investigate the complaint made by the Consumer with
                    the AUXILL in relation to the Service Partners with all the parties involved as per AUXILL Policy
                    and as per the escalation matrix published on the Platform. The Consumer shall be bound by the
                    outcome of such investigation made by the AUXILL. The redressal mechanism for the compliant
                    along with the escalation matrix and contact details shall be available on the Website or Platform
                    <mark className='bg-yellow1'>The Consumer agrees that the AUXILL shall not be made party to any dispute between the
                    Consumer and Service Partners over any issue related to the transaction between the Consumer
                    and the Service Partners.</mark></p>
                  <p className='DisclaimerText mb-4 negative-indent '>3.9 The Consumer gives consent and full authority to the AUXILL to share requisites details once the
                    Consumer has initiated the transaction with the said Service Partners</p>
                  <h4 class="DisclaimerHeading fw-bold my-5">4. PRIVACY POLICY</h4>
                  <p className='DisclaimerText mb-4'>The Consumer agrees that he/she has gone through the privacy policy of the AUXILL available at
                  <a className='text-decoration-underline' href="https://www.auxill.in/"> www.auxilltechnologies.com </a>and shall be bound by the same. The Consumer agrees that he/she shall
                    read the privacy policy time to time and keep himself/herself/itself updated. The Consumer agrees that
                    the policies of the AUXILL shall be published on the Platform, and/or Mobile App and/or Platform or
                    in any other electronic method available for communication and the Consumer shall be bound the said
                    policies of the AUXILL.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 class="DisclaimerHeading fw-bold my-5">5. KNOW YOUR CLIENT (KYC), CONSUMER DATA AND RELATED TERMS</h4>
                  <p className='DisclaimerText mb-4 negative-indent'>5.1 Consumer shall submit all KYC Information as requested by the AUXILL from time to time as per
                    the AUXILL Policy and to comply with various provisions of laws and regulation in India and
                    keep its KYC Information updated in the records of the AUXILL. The Consumer shall immediately
                    inform any changes in its status, address, name, mobile number and any other KYC Information
                    to the AUXILL. The Consumer shall indemnify and keep the AUXILL indemnified from any loss,
                    dispute, actions, issues, compensation, litigation, damages etc, arising out of the same.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>5.2 The Consumer hereby express consent to the AUXILL to share any Consumer personal
                    information/data KYC Information/documents including the Transaction information, with any of
                    its group companies, Service Partners including with third party service providers ("Outsourcing
                    Partners") to provide the AUXILL Services.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>5.3 The Consumer Agrees that the AUXILL can share the information with payment network, NPCI,
                    relevant acquirers, legal and regulatory authority in relation to the Warranty Express Services.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>5.4 The Consumer hereby gives express consent to the AUXILL to share any Consumer
                    information/data, personal information and KYC Information and documents including the
                    Transaction information, AUXILL Services availed by the Consumer without giving any prior
                    intimation to the Consumer with any regulatory or legal authority as and when deems fit by the
                    AUXILL and/or as and when requested by legal or regulatory authority for the purpose of these
                    Terms.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>5.5 The Consumer hereby gives express consent to the AUXILL to obtain its Aadhaar number to get
                    customer details from UIDAI subject to the Applicable Laws for the purpose of providing
                    Warranty Express Services to the Consumer</p>
                  <p className='DisclaimerText mb-4 negative-indent'>5.6. The Consumer agrees that AUXILL shall collect/capture certain details of Consumer from
                    his/her/its registered mobile phone/Platform which is including but not limited to mobile device
                    identification details, geographical location etc. for the purpose of effective and better informed
                    mentoring of Consumer's transactions with the AUXILL.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>5.7 The Consumer acknowledge that unless & until the product details
                    and KYC
                    information/documents are purged permanently by the Consumer, the same may remain on the
                    servers of “WE”.</p>


                  <h4 class="DisclaimerHeading fw-bold my-5">6. COMPLIANCE WITH LAWS AND REGULATIONS AND RELATED TERMS</h4>
                  <p className='DisclaimerText mb-4'>6.1 The Customer agrees that the AUXILL will be entitled at all times, to act in accordance with
                    applicable laws, without requiring to provide prior intimation of such actions to the Consumer. Any
                    and all limits/constraints imposed by the applicable law shall apply to the WE Account held by the
                    Consumer.</p>
                  <p className='DisclaimerText mb-4'>6.2 The Consumer shall not use WE Account or Warranty Express Services for any purpose that might
                    be construed as contrary or repugnant to any Applicable Law, public policy or for any purpose that is
                    contrary to AUXILL Policy or might prejudice the goodwill of AUXILL</p>
                  <p className='DisclaimerText mb-4'>6.3 Consumer shall comply with Applicable Laws for availing the Warranty Express Services. The
                    AUXILL shall have right to repudiate the AUXILL Services to any Consumer if the Consumers name
                    is appearing in the negative list of the AUXILL due to any fraudulent/illegal transactions while availing
                    Warranty Express Services under these Terms.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">7. SUSPENSION, TERMINATION AND CANCELLATION OF SERVICES</h4>
                  <p className='DisclaimerText mb-4'>7.1. The AUXILL may terminate these Terms and/or Account and/or any of the Warranty Express
                    Services any time without reason by giving 30 thirty days' notice in writing to the Consumer</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 class="DisclaimerHeading fw-bold my-5">5. KNOW YOUR CLIENT (KYC), CONSUMER DATA AND RELATED TERMS</h4>
                  <p className='DisclaimerText mb-4'>7.2 The AUXILL reserves the right to suspend and/or discontinues any of the Warranty Express
                    Services and/or Platform or close WE Account, at any time, without giving prior intimation to the
                    Customer, for any reason including but not limited to: (a) any suspected violation of any rules,
                    regulations, orders, directions, notifications issued by legal authority by the Consumer from time to
                    time or for any violation of these Terms or compliance with any Applicable Laws or Policy of the
                    AUXILL. (b) AUXILL is of the view that there are fraudulent transactions in the WE Account and/or
                    the Warranty Express Services are being used for the fraudulent/illegal purposes, (c) any mis-
                    presentation or discrepancy or suspected discrepancy in the particulars of KYC Information or CAF
                    provided by the Consumer being unsatisfactory to AUXILL, (d) Combatting potential frauds, sabotage,
                    willful destruction, threat to national security er for Any other force majeure reasons such as technical
                    failure, modification, up gradation, variation, relocation, repair, maintenance and/ or any transmission
                    deficiencies (e) If the telecom service providers connection of the Consumer on which the Consumer is
                    availing the AUXILL Services ceases to be operational; (f) ineligibility of the Consumer under any
                    criteria as mandated by the AUXILL (g) regulatory/legal authority mandates such closure/suspension
                    or any court has given an order to close/freeze the count (h) if the Consumer breach any terms of the
                    Terms and fails to cure such breach within 15 (fifteen) days (i) if the Consumer breach the applicable
                    laws (j) if AUXILL suspect suspicious or fraudulent or out of pattern Transactions.</p>
                  <p className='DisclaimerText mb-4'>7.3 Prior to such suspension/ discontinuance of the AUXILL Services, the Consumer, at the sole
                    discretion of the AUXILL and subject to Applicable Laws and/or AUXILL Policy, shall be provided
                    with the ability to transfer and/ or utilize the balances lying in the WE Account</p>
                  <p className='DisclaimerText mb-4'>7.4 The AUXILL reserves the right to cancel the Transaction/Warranty Express Services in case of any
                    network failure In such case or for any other reason beyond the control of the AUXILL there could be
                    delay or failure to complete the Transaction/ Warranty Express Services. AUXILL shall not be
                    responsible for any kind of losses that may occur due to such delay or failure,</p>
                  <p className='DisclaimerText mb-4'>7.5 The Consumer may terminate the WE Account and/or other Warranty Express Services by
                    submitting an application with the AUXILL The AUXILL shall close the WE Account/Warranty
                    Express Services after receiving an application as per the AUXILL Policy. Before closing the WE
                    Account, the Consumer shall ensure to utilize the funds available in WE Account through the Platform
                    since Consumer shall not be able to access the WE Account after closing the WE Account The
                    Consumer agrees that the AUXILL shall not be liable for any loss, consequence, litigation etc. arising
                    out of or related to closure/suspension/freezing of the WE Account and/or the Warranty Express
                    Services and Consumer agree to indemnity and keep the AUXILL indemnified from the same.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">8. NO OTHER WARRANTIES</h4>
                  <p className='DisclaimerText mb-4'>AUXILL Services, Account, any other Warranty Express services and the Platform are provided on an
                    "as is" and "as available" "with all faults" basis and does not warrant that the Warranty Express Services,
                    and Platform or any of its software's will be uninterrupted, error free, accessible or operational at all
                    times or that any defects will be corrected Use of the Warranty Express Services, and Platform under
                    these Terms is at the Consumers own risk. To the maximum extent permitted by applicable law, the
                    Warranty Express Services and Platform are provided without warranties of any kind, whether express
                    or implied, including, but not limited to, implied warranties for a particular purpose, or non-
                    infringement. No advice or information, whether oral or written, obtained by the Consumer from the
                    AUXILL or through the Warranty Express Services, Account or Platform will create any warranty not
                    expressly stated herein. Without limiting the foregoing, the AUXILL, its processors, its providers, its
                    licensors (and their respective subsidiaries, affiliates, BCs. directors, and employees) do not warrant
                    that the content is correct, that the Warranty Express Services. Account and Platform will meet the
                    Consumers requirements; that the Warranty Express Services, Account and Platform will be available
                    at any particular time or location, uninterrupted or secure, that any defects or errors will be corrected,
                    or that the Warranty Express Services and Platform is free of viruses or other harmful components. Any</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <p className='DisclaimerText mb-4'>content downloaded or otherwise obtained through the use of the Warranty Express Services, Platform
                    as the case may be is downloaded at the Consumer's own risk and Consumers will be solely responsible
                    for any damage to his property or loss of data that results from such download. AUXILL does not
                    warrant, endorse, guarantee, or assume responsibility for any product or service or any coupons
                    advertised or offered by a third party through the Warranty Express Services or any hyperlinked website
                    or service, or featured in any banner or other advertising and the AUXILL will not be a party to or in
                    any way monitor any Transaction between the Consumer and third-party providers of such products or
                    services or the coupons</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">9. INTELLECTUAL PROPERTY</h4>
                  <p className='DisclaimerText mb-4'>The AUXILL and its Affiliates shall be the owner/licensor/licensee of any of its software's, websites,
                    app, trademarks, trade secrets, trade names, designs, patents, copyrights, intellectual property and any
                    of its copies, derivative work etc. (Intellectual Property The Consumer shall not use any Intellectual
                    Property of the AUXILL its Affiliates without prior written consent from the AUXILL. The Consumer
                    agrees that Consumer shall have no claims/rights of whatsoever nature in the intellectual property rights
                    arising out and in connection with Platform, Account and Warranty Express Services. The Consumer
                    further undertakes that he/she shall not attempt to modify, altar, obscure, translate, disassemble,
                    decompile or reverse engineer the software underlying mobile application or create any derivative
                    product based on the software/Platform or any of the Intellectual Property of the AUXILL and Affiliates
                    The Consumer understands that the user interface, graphics, logos, design, compilation, information,
                    products, services, softwares, and all other elements of the Platform. Account and AUXILL Services
                    are protected by copyright, designs, patent and trademark laws and other intellectual property or
                    proprietary rights of AUXILL and/or Affiliates</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">10. INDEMNITY</h4>
                  <p className='DisclaimerText mb-4'>The Consumer shall be liable to the AUXILL for losses, expenses, compensation, damages etc. and
                    agrees to indemnity and keep the AUXILL and its Affiliates indemnified from any and all claims, losses,
                    damages, liabilities, costs and expenses including without limitation legal fees and expenses arising out
                    of or related to (i) Consumer's use or misuse of the Warranty Express Services, WE Account, Platform
                    or any use of the Warranty Express Services, WE Account of the Consumer by any third party in breach
                    of agreements, representations and warranties and covenants made by the Consumer under the Terms
                    in any action taken by the AUXILL on the instructions of the Consumer in good faith (ii) any issues in
                    relation to the Service Partners (iii) any issues in relation to the discount coupons/ offers issued by the
                    Service Partners (iv) any issues/disputes in relation to the goods, services, coupons, schemes availed by
                    the Consumer using the Warranty Express Services from the Service Partners (v) any fraud or any loss
                    or damage suffered by the AUXILL or Service Partners due to any reason whatsoever which is
                    including but not limited to the failure on the part of the Consumer to communicate correct permanent
                    or communication address, communication number, email address or any other KYC Information.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">11. LIMITATION OF LIABILITY</h4>

                  <p className='DisclaimerText mb-4 negative-indent'>11.1 The AUXILL shall not be liable to the Consumer for any delays, loss of business, profit, revenue
                    or goodwill, anticipated savings, damages, fees, costs, expense, etc. or for any indirect or consequential
                    loss, howsoever arising, on account of unavailability/availability/ usage of Warranty Express Services,
                    WE Account, or otherwise by the Consumer.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <p className='DisclaimerText mb-4 negative-indent'>11.2 The AUXILL and/or Service Provider shall not be liable to the Consumer for a) any indirect
                    consequential, special, unitive, incidental loss or damages arising out of or in relation to use or failure
                    to use of any of the Warranty Express Services including b) loss of profit, loss of business opportunity,
                    loss of revenue, loss of goodwill or any other indirect losses c) loss or damage arising out of or in
                    relation to use or misuse or damage to the mobile phone or any other electronic device used by the
                    Consumer for conducting the Transactions (d) any issues of internet connection, telecommunication or
                    mobile related issues in relations to Service Partner or any other third party service provider e) any
                    issues which are out of control of the AUXILL.</p>
                  <p className='DisclaimerText mb-4 negative-indent'>11.3 The Consumer agrees that in no event the total liability of the AUXILL or its affiliates, employees,
                    directors, Service Partners arising out of or related to these Terms or the Services provided under the
                    Terms shall not be more than <mark className='bg-yellow1'>Rs. 5,000/</mark></p>

                  <h4 class="DisclaimerHeading fw-bold my-5">12. FORCE MAJEURE</h4>
                  <p className='DisclaimerText mb-4'>The AUXILL shall not be liable for any losses caused by its inability or delay in providing all or any
                    AUXILL Services or Account or fulfilling any of the AUXILL's obligations hereunder, either wholly
                    or in part, caused by or in connection with any force majeure events including but not limited to
                    restrictions or convertibility. transferability, requisitions, involuntary transfers, riot, disturbance,
                    strikes, lock-outs, bandhs, war, civil strike, burglary, lockdown, pandemic, theft, dacoity, accidents,
                    technical faults, including problems related to the computers, accessories, software's, printers, fax
                    machines internet, telephone systems and such related hardware and software beyond the control of the
                    AUXILL, breach of the trust by any authorized person, Service Partners, flood, fire, earthquake, acts of
                    God, set of any regulatory or statutory nature or if the performance of such obligation or provision of
                    such service would result in breach of any law, regulation or other requirements of any governmental
                    or other authority or on account of any other restrictions, requisition or other circumstances or events
                    whatsoever beyond the control of the AUXILL and the AUXILL shall not be obliged to provide any
                    Warranty Express Service or perform any obligation hereunder during the period while the AUXILL
                    performance is affected by force majeure conditions. The AUXILL shall not be responsible for any loss
                    or damage caused to the Consumer by reason of any failure to comply with the Consumer instructions
                    when such failure is caused due to reasons beyond the control of the AUXILL the opinion of the
                    AUXILL being final in this regard.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">13. ASSIGNMENT</h4>
                  <p className='DisclaimerText mb-4'>The AUXILL shall have the right to assign/transfer these presents to any third party including its
                    holding company, subsidiaries, affiliates, associates and group companies. Further, the Consumer agree
                    that Terms herein can be novated vis a-viz AUXILL with any of its Affiliate, associates and/ or group
                    companies of the AUXILL and the Consumer hereby irrevocably consents to the same. The Consumer
                    shall have no right to assign these Terms with any third party.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">14 DISPUTE RESOLUTION</h4>

                  <p className='DisclaimerText mb-4'>Any party may refer any disputes under these Terms to the arbitration of single arbitrator to be appointed
                    mutually by the Parties to the dispute. The provisions of the Arbitration and Conciliation Act, 1996 any
                    enactment of statutory modification thereof shall be applicable to such arbitration. The arbitration
                    proceedings shall be in the English language The place of arbitration shall be Mumbai The award of the
                    arbitral tribunal shall be final and binding upon the Parties Courts in Mumbai shall have exclusive
                    Jurisdiction. Consumer may send any notice under the Dispute clause at the registered office address of
                    the AUXILL.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">

                  <h4 class="DisclaimerHeading fw-bold my-5">Warranty Express: Specific Terms and conditions</h4>
                  <h4 class="DisclaimerHeading fw-bold my-5 text-decoration-underline">WHAT IS “WE”</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'><strong>WE</strong> = Warranty Express (it can be called as “WE”)</p>
                  <p className='DisclaimerHeading fw-normal mb-4'><strong>Consumer</strong>= Customer who buy Subscriptions plan of WE either on APP or website or
                    subscribe the said subscription for free. The subscription plan can be opted at the office of
                    Service Partners certified by WE.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>AUXILL provides the 3 plans under WE for all 18 product which falls in 4 subscriptions (Basic,
                    Premium, Privilege & Elite)</p>
                  <ul className='ms-5'>
                    <li>Care (R = Repair)</li>
                    <li>Assure (R-PR = Repair & Partially Replacement)</li>
                    <li>Guard (R-CR = Repair & Complete Replacement)</li>
                  </ul>

                  <table class="table table-bordered my-5">
                    <thead className='bg-warning '>
                      <tr>
                        <th rowspan="2">Sr. No</th>
                        <th rowspan="2">DETAILS</th>
                        <th colspan="1">CARE</th>
                        <th colspan="1">ASSURE</th>
                        <th colspan="1">GUARD</th>
                      </tr>
                      <tr className='bg-secondary'>
                        <th>
                          R
                        </th>
                        <th>
                          R-PR
                        </th>
                        <th>
                          R-CR
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Visit Charges</td>
                        <td>Included</td>
                        <td>
                          Included
                        </td>
                        <td>
                          Included
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Labour & Service Fees</td>
                        <td>Included</td>
                        <td>
                          Included
                        </td>
                        <td>
                          Included
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Cost of Spare Parts due to manufacturing defect</td>
                        <td>Included</td>
                        <td>
                          Included
                        </td>
                        <td>
                          Included
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Number of Service Requests</td>
                        <td>Unlimited</td>
                        <td>
                          Unlimited
                        </td>
                        <td>
                          Unlimited
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Prod. Rep incase beyond repair due to manufacturing defect</td>
                        <td>Ref UDV</td>
                        <td>
                          Ref UDV
                        </td>
                        <td>
                          New UOV
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Damage Repair Services incase of accidental and liquid damage During Extended
                          Warranty Period</td>
                        <td>Excluded</td>
                        <td>
                          Included
                        </td>
                        <td>
                          Included
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Screen Repair Services incase of accidental and liquid damage During Extended
                          Warranty Period</td>
                        <td>Excluded</td>
                        <td>
                          Excluded
                        </td>
                        <td>
                          Included
                        </td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Discount on spare parts incase of damage repair services</td>
                        <td>NA</td>
                        <td>
                          15%
                        </td>
                        <td>
                          35%
                        </td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>Prod. Replacement. incase beyond repair due to any kind of damage</td>
                        <td>Excluded</td>
                        <td>
                          Excluded
                        </td>
                        <td>
                          Excluded
                        </td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>Damage Repair Services arising due to electrical short circuit</td>
                        <td>Excluded</td>
                        <td>
                          Excluded
                        </td>
                        <td>
                          Included
                        </td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>Remote Control Repair</td>
                        <td>Excluded</td>
                        <td>
                          Excluded
                        </td>
                        <td>
                          Included
                        </td>
                      </tr>

                    </tbody>
                  </table>
                  <p className='DisclaimerHeading fw-normal mb-4'><strong>Plan Terms</strong> = from 6 Months up to 36 months (according to product)</p>
                  <p className='DisclaimerHeading fw-normal mb-4'><strong>Manufacturer’s Warranty</strong> = The original warranty issued by <strong>Manufacturer in relation to
                    a product.</strong></p>
                  <p className='DisclaimerHeading fw-normal mb-4'><strong>Extended Warranty</strong> = An extended warranty <strong>covers all or some of the cost of certain
                    repairs after the standard Manufacturer warranty expires.</strong> (Under <strong>“Plan”</strong>)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 class="DisclaimerHeading fw-bold my-4 text-decoration-underline">WHAT IS SERVICE PARTNER</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'><strong>Service Partner</strong> = Service Partners shall mean below Third Party Providers (Certify with WE)</p>
                  <p className='DisclaimerHeading fw-normal mb-4'><strong>Consumer</strong>= Customer who buy Subscriptions plan of WE either on APP or website or
                    subscribe the said subscription for free. The subscription plan can be opted at the office of
                    Service Partners certified by WE.</p>

                  <ul className='ms-5'>
                    <li><strong>CPRS</strong> = Channel Partner Retails Seller</li>
                    <li><strong>CPSC</strong>  </li>
                    <li><strong></strong> = Channel Partner Service Centre</li>
                    <li><strong>CPBS</strong> = Channel Partner both Services  </li>
                    <li><strong>CDP</strong> = Channel Development Partner </li>
                    <li><strong>CPST</strong> = Channel Partner Service Technicians: <strong>CPST</strong> is substitutable terms used for
                      Technicians or Private Repair Professional registered with the “WE” to offer their
                      services to the end customers.</li>
                    <li><strong>Service Delivery Time (TAT):</strong> The time and date specified in the registration <strong>SR</strong> form
                      for the delivery of <strong>Consumer</strong> services. <strong>Turn Around Time</strong>. </li>
                    <li><strong>Service Partner</strong> = Third Party Providers (Certify with <strong>WE</strong>) Technicians and Private
                      Repair Professionals registered with the <strong>“WE”</strong> to offer their services to end customers
                      are referred to as <strong>“Service Partners”</strong>. </li>
                    <li><strong>EW</strong> = Extended Warranty </li>
                    <li><strong>Transfer EW</strong> = Transfer the Extended Warranty </li>
                    <li><strong>R</strong> = Repair </li>
                    <li><strong>R – PR</strong> = Repair & Partially Replacement </li>
                    <li><strong>AC</strong> = Annual Charges </li>
                    <li><strong>UL</strong> = Unlimited </li>
                    <li><strong>OIV</strong> = Original Invoice Value </li>

                    <li><strong>NC</strong> = Not Capped </li>
                    <li><strong>REF UDV</strong> = Old up to depreciated value </li>
                    <li><strong>New UDV</strong> = New up to depreciated value </li>
                  </ul>

                  <h4 class="DisclaimerHeading fw-bold my-4 text-decoration-underline">HOW TO START WE (WARRANTY EXPRESS)</h4>

                  <ul className='ms-5'>
                    <li><strong>Consumer</strong> agree to use the services provided by third-party technicians, Service
                      Partners, or private repair professionals in exchange for the “WE” delivering
                      technology-based home solutions to you in selected <strong>“Pin Codes”</strong> ("the Service
                      Partner"). The term <strong>"Service"</strong> will be used to refer to all services that the <strong>“WE”</strong> offers
                      you as a result of <strong>Consumer</strong> registration of <strong>Service Request</strong> on its website or
                      application.</li>
                    <li><strong>Service Request (for WE Extended Warranty only)</strong> effected by signing up for the
                      website or application in order to reserve a technician or repair professional and use
                      their services. Single services as well as split or multiple repair services can be reserved. </li>
                    <li>In case, the Service Partner accepts the booking request made by Consumer with the
                      <strong>“WE”, Consumer’s</strong> information is sent to the Service partner including Consumer
                      name, contact number etc.</li>
                    <li>The <strong>“WE”</strong>will use reasonable efforts to help consumer contact the Service Partner to
                      provide the requested service subject to the <strong>Service Partner’s</strong> availability at or around
                      <strong>Consumer</strong> location at when Consumer reservation request is sent to the <strong>“WE”</strong>.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <ul className='ms-5'>
                    <li className='mb-4'>It should be mentioned here that the “WE” itself does not own/regulate the service
                      partner nor does any employer/employee relationship exist between them. It is the
                      “Service Partner” that will provide the requested Services to Consumer according to
                      the relevant information submitted to the “WE” upon registration with the “WE”. As a
                      result, the provision of the services to be provided to Consumer by the “Service
                      Partner” is subject to the (soon to be) agreement entered into between Consumer and
                      the “Service Partner” and the “WE” shall never be a party. of such an agreement, in
                      any form. The “WE’s” acceptance of the reservation request and the “Service
                      Partner’s” failure to reach Consumer service location or decision to provide the
                      service, in which case/in such event, the “WE” shall not be liable. to Consumer in any
                      way and the Customer shall not be liable to make any payment in lieu of such payment.</li>
                    <li className='mb-4'>Consumer hereby certify and affirm that the applicable facts Consumer offer to the
                      “WE” whilst registering customer request or advent of account is correct and whole in
                      all admire as can be demanded through the “WE” withinside the utility form. The
                      “WE” is entitled to an all-time affirm the facts which you have provided. The “WE”
                      shall now no longer be dependable if Consumer do now no longer go to the correct
                      internet portal or dial-in the appropriate name centre number. The “WE” reserves the
                      proper to stop or introduce any of the modes of reserving “Service Partner”.</li>
                    <li className='mb-4'>Consumer will treat “Service Partner” introduced to consumer through US with
                      respect and not to cause damage to them or engage in any unlawful, threatening,
                      harassing, abusive behaviour or activity whilst using their service;</li>
                    <li className='mb-4'>When the “WE” transfers products to the “Service Partner” for service, the client is
                      hereby informed and cautioned about the reputation of the “Service Partner”, and any
                      defect in service or theft/damage to the asset, spare parts, accessories, or product shall
                      not be eligible for compensation from the “WE” in any manner whatsoever, and it is
                      clarified that the “WE” shall have no obligation to compensate the “Service Partner” if
                      any harm/damage/loss is caused by such transfer.</li>
                    <li className='mb-4'>You have to confirm & make clear from the provider issuer approximately the time
                      concerned and the spare elements required to render the provider together with the
                      total/combination provider prices concerned therein besides the minimal price earlier
                      than initiation of the Service. In case the identical isn't discussed, its miles implied that
                      the purchaser is aware about the provider prices and has consented to avail the offerings
                      as can be knowledgeable or directed through the provider issuer.</li>
                    <li className='mb-4'>Consumer will compensate and guard the agency absolutely in opposition to any claims
                      or felony court cases added in opposition to US through some other character because
                      of Consumer breach of those Terms.</li>
                    <li className='mb-4'>Please be aware that “WE” aren't accountable for the behaviour, moves or inactions,
                      accuracy, performance of “Service Partner”, great of Service which “Consumer”
                      could use (via “WE” or otherwise). Any Contract for the supply of offerings is solely
                      among Consumer and the Service issuer and now no longer US in any way by any
                      means and “WE” genuinely offer a platform to introduce Service issuer and Consumer
                      looking for the stated service.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 class="DisclaimerHeading fw-bold my-4 text-decoration-underline text-center">PAYMENT AND CANCELLATION CHARGES</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>All services provided to you by any <strong>“Service Partner” </strong>introduced through US will be paid for
                    in full by you. If a spare part is installed or replaced, the costs of the spare part shall be paid to
                    the <strong>“Service Partner” </strong>. A list of the minimum service charge rates can be accessed on the
                    <strong>“WE”</strong>`s website. <strong>“WE”</strong> reserve the right to update or modify our service charges from time to
                    time, and it is Consumer’s responsibility to remain informed of the prevailing minimum
                    charges for our services. The <strong>“Service Partner” </strong> should inform you about the service charge
                    for the repair of the products before commencing the service. <strong>“Consumer”</strong> agrees to pay for
                    all services you receive from our <strong>“Service Partner” </strong> either through cash, cheques, online
                    payments, or any other method introduced by our <strong>“WE”</strong>. All payments are non-refundable.
                    The   <strong>“WE”</strong> will facilitate the receipt of an acknowledgement from the <strong>“Service Partner” </strong> to
                    Consumer registered email address with the <strong>“WE”</strong> following the completion of the Service.</p>
                  <p className='DisclaimerHeading mb-4 fw-bold'>Trademarks and Copyrights</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>The  <strong>“WE”</strong> is the only administrator of all of the rights to the internet web page or some other
                    virtual media and its contents cited at the internet site. The content material method its design,
                    layout, text, images, graphics, sounds, video, etc. the internet site or some other virtual media
                    content material encompass exchange secrets and techniques and highbrow assets rights
                    blanketed beneath Neath relevant laws., its affiliates, agents, legal representatives because the
                    case can also additionally be. All titles, possession and highbrow assets rights withinside the
                    internet site and its content material shall stay with the <strong>“WE”</strong>.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>All rights now no longer in any other case claimed below this Terms and Conditions or via way
                    of means of the <strong>“WE”</strong> are hereby reserved. The records contained on this internet web page is
                    intended, entirely to offer standard records for the non-public use of the reader, who accepts
                    complete duty for its use.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>User Terms and Modification the <strong>“WE”</strong>reserves the right, at its sole discretion, to adjust or
                    update any of those User Terms, or change, suspend, or stop the Application (consisting of
                    without limitation, the provision of any feature, database, or content) at any time via way of
                    means of posting a observe at the Website or via way of means of sending <strong>“Consumer”</strong>
                    observe thru the Service, Application or through email. The <strong>“WE”</strong> may impose limits on sure
                    capabilities and offerings or limitation Consumer get right of entry to elements or all the
                    Service without observe or liability.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>The <strong>“WE”</strong> reserves the right, at its sole discretion, to adjust or update any of those User Terms,
                    or change, suspend, or stop the Application (consisting of without limitation, the provision of
                    any feature, database, or content) at any time via way of means of posting a observe at the
                    Website or via way of means of sending you observe thru the Service, Application or through
                    email. The <strong>“WE”</strong> may impose limits on sure capabilities and offerings or limitation <strong>Consumer</strong> 
                    get right of entry to elements or all the Service without observe or liability.</p>

                  <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>Notice</p>
                  <p className='DisclaimerHeading fw-normal mb-4 '>Notice may be given via a general notice on the Service or Application, or by electronic mail
                    to the email address on Consumer account information, or by regular mail to the address on
                    Consumer account information.</p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>Cookie & Privacy</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>The <strong>“WE”</strong> collects and processes the personal data of the Website visitors/registered
                    Consumers, and the  <strong>“WE”</strong> may send their promotional offers from time to time, unless the
                    same is denied by the <strong>“Consumer”.</strong></p>


                  <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>Excusable Delays (Force Majeure)</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>No party hereto shall be responsible for delays or failures in performance resulting from causes
                    beyond its reasonable control. There are a number of reasons for excusable delays or failures,
                    including strikes, lockouts, riots, rebellions, accidents, floods, storms, acts of God, and similar
                    events.</p>

                  <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>Text Messaging</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>By creating an account, the user agrees that  <strong>“WE”</strong> may send the text (SMS) message as part of
                    the normal operation of the service. The consumer informs <strong>“WE”</strong> that he/she no longer wishes
                    to receive such Text messages (SMS). Consumers acknowledge that opting out of receiving
                    text (SMS)messages may impact their use of the services.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'> <strong>“WE”</strong> Company may, at its sole discretion, create promotional codes that may be redeemed
                    for account credits, or other features or benefits relating to the services and/or third-party
                    providers, subject to any additional terms that  <strong>“WE”</strong> establishes per promotional code ("Promo
                    code").</p>


                  <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>OTP related Terms & Conditions</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>One-time password (OTP) systems provide a mechanism for logging on to a network or service
                    using a unique password that can only be used once, as the name suggests. The OTP feature
                    prevents some forms of identity theft by making sure that a captured user name/password pair
                    cannot be used a second time.</p>


                  <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>Email ID Verification related Terms & Conditions</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>An email address is a valuable piece of data. One of your greatest assets for your entire digital
                    marketing program is a database full of fresh, accurate and active email addresses.</p>


                  <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>User Profile</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>To use the Services, you agree that for below Points. Creating an account with false information
                    is a violation of our terms</p>
                  <ol type='1' className='ms-5'>
                    <li className='DisclaimerHeading fw-normal mb-4'>Consumer must be the "Minimum Age"(18 Years) or older</li>
                    <li className='DisclaimerHeading fw-normal mb-4'>Consumer will only have one self-account, which must be in your real name (as per ID
                      Proof).</li>
                  </ol>

                  <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>User Additional Information Related Terms & Conditions</p>
                  <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>Subscriptions</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>The subscription grants to Consumer the right to use all services which provided in the said
                    plan (type plan : Basic , Premium , Privilege & Elite). The subscription may not be transferable.
                    Subscriptions starts from the date of accepting the terms and subscription amount paid by
                    Consumer. A discount is applicable for annual prepayment. annual prepaid subscriptions may
                    be terminated by email to <a href='https://www.auxill.in/'>contactus@warrantyexpress.in</a> within 3 days of service started after
                    that subscription already initiated and the Consumer will not be entitled to a refund for that
                    subscription period.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">

                  <p className='DisclaimerHeading fw-normal mb-4'>Subscription will start after payment and it will continue if auto renew after 365 days.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>(If auto renew option selected).</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>Consumer can deactivate the auto renewal (Go to Account= Renewal Instructions)</p>
                  <p className='DisclaimerHeading fw-normal mb-4'><strong>“WE”</strong> informs its consumer on the <strong>“WE”</strong> application and Website about system changes,
                    feature updates, and other technical news by e-mail. Therefore, all registered consumer on the
                    <strong>“WE”</strong>app and website accept that they are added to the technical newsletter mailing list (if
                    selected).</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>These Terms together with the agreed Formal Quote contain the entire agreement between the
                    Consumers and <strong>“WE”</strong> all previous correspondence or communications written or on app/Web.
                    <strong>“WE”</strong> may amend these Terms as required from time to time provided that <strong>“WE”</strong> will give
                    Consumer written notice of such amendments and all such amendments will apply to the next
                    renewal of the subscription.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>If consumer want to take advantage/avail the free extended warranty which consumer received
                    with the subscription. consumer have to be the same subscriber next year</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>Subscription Type</p>
                  <ul type="disc" className='ms-5'>
                    <li>Basic</li>
                    <li>Premium</li>
                    <li>Privilege</li>
                    <li>Elite</li>
                  </ul>
                  <p className='DisclaimerHeading fw-normal my-4'>Basic:</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>The Basic model offers access to limited levels of features for free, but only offers additional
                    features or premium features to paying Consumer/Subscriber</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>Consumer can avail all below facilities/features</p>
                  <ul type="disc" className='ms-5'>
                    <li>VAULT</li>
                    <li>ASK THE EXPERT</li>
                    <li>SEARCH</li>
                    <li>WARRANTY TRANSFER (Transfer Fee Applicable as per criteria)</li>
                  </ul>

                  <p className='DisclaimerHeading fw-normal my-4'>Premium:</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>A customer pays a set amount upfront to limited features for nominal fees over a specific period
                    of time. Consumer can avail all below facilities/features</p>
                  <ul type="disc" className='ms-5'>
                    <li>VAULT</li>
                    <li>ASK THE EXPERT</li>
                    <li>SEARCH</li>
                    <li>WARRANTY TRANSFER (Transfer Fee Applicable as per criteria)</li>
                    <li>MULTIPLE ADDRESS</li>
                    <li>FAMILY ACCESS (limited to 1 Member)</li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">



                  <p className='DisclaimerHeading fw-normal my-4'>Privilege:</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>A customer pays a set amount upfront to limited features for nominal fees over a specific period
                    of time. Consumer can avail all below facilities/features</p>
                  <ul type="disc" className='ms-5'>
                    <li>VAULT</li>
                    <li>ASK THE EXPERT</li>
                    <li>SEARCH</li>
                    <li>WARRANTY TRANSFER (Transfer Fee Applicable as per criteria)</li>
                    <li>MULTIPLE ADDRESS</li>
                    <li>FAMILY ACCESS (limited to 1 Member)</li>
                    <li>Direct Connect (for Self)</li>
                  </ul>


                  <p className='DisclaimerHeading fw-normal my-4'>Elite:</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>A customer pays a set amount upfront to unlimited features for nominal fees over a specific
                    period of time. Consumer can avail all below facilities/features</p>
                  <ul type="disc" className='ms-5'>
                    <li>VAULT</li>
                    <li>ASK THE EXPERT</li>
                    <li>SEARCH</li>
                    <li>WARRANTY TRANSFER (Transfer Fee Applicable as per criteria)</li>
                    <li>MULTIPLE ADDRESS</li>
                    <li>FAMILY ACCESS (Self and Family Access)</li>
                    <li>
                      <strong>Transfer</strong>
                      <br></br>
                      <p className='DisclaimerHeading fw-normal mb-4'>“Consumer” may not assign or transfer Extended Warranty terms in whole or in part without
                        “WE” Company’s prior written approval. “Consumer” can initiate the transfer request through
                        App or Web with accepting the terms and charges of “WE” for it to transfer any Extended
                        Warranty terms as whole or in part.</p>
                    </li>
                    <li>
                      <strong>Transfer the Extended Warranty
                        Consumer can transfer the Extended Warranty</strong>
                      <br></br>
                      <p className='DisclaimerHeading fw-normal mb-4'>“Consumer can transfer the Extended Warranty to a new owner of the particular equipment by
                        “Transfer Extended Warranty” functions with necessary details of the change. Consumer’s
                        Extended Warranty cannot be transferred to any other equipment.</p>
                    </li>
                    <li>
                      <strong>Transfer WE Coins</strong>
                      <br></br>
                      <p className='DisclaimerHeading fw-normal mb-4'>“Consumer can transfer the WE Coins to a new/Existing Consumer of WE by “Transfer WE
                        Coins” functions with necessary details of the change and Transfer Charges as applicable to
                        that subscriber. Consumer’s WE Coins cannot be transferred to actual Bank Accounts or any
                        other wallet.</p>
                    </li>
                    <li>
                      <strong>Transfer WE Cash</strong>
                      <br></br>
                      <p className='DisclaimerHeading fw-normal mb-4'>Consumer can transfer the WE Cash to a new/Existing Consumer of WE by “Transfer WE
                        Cash” functions with necessary details of the change and Transfer Charges as applicable to that
                        subscriber. Consumer’s WE Cash cannot be transferred to actual Bank accounts or any other
                        wallet.</p>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <ul type="disc" className='ms-5'>

                    <li>
                      <strong>Transfer WE Both</strong>
                      <br></br>
                      <p className='DisclaimerHeading fw-normal mb-4'>Consumer can transfer the WE Cash & Coins to a new/Existing Consumer of WE by “Transfer
                        WE Both” functions with necessary details of the change and Transfer Charges as applicable
                        to that subscriber. Consumer’s WE Both cannot be transferred to actual Bank accounts or any
                        other wallet.</p>
                      <p className='DisclaimerHeading fw-normal mb-4 fw-bold'>Multiple Address</p>
                      <p className='DisclaimerHeading fw-normal mb-4'>Consumers can add multiple addresses depending on subscription eligibility and retain product
                        details according to address.</p>
                    </li>
                    <li>
                      <strong>Family Access / Local Access & Admin Access</strong>
                      <br></br>
                      <p className='DisclaimerHeading fw-normal mb-4'>Consumers can give the family access / local access & Admin Access against multiple
                        addresses as per the requirements (Selective product or full access) depending on subscription
                        eligibility. Given access person can raise request for “WE” Extended Warranty product if any
                        and add product, add documents, download documents (Invoice, EW etc.) for particular
                        address only. Given access person can view vault also</p>
                      <p className='DisclaimerHeading fw-normal mb-4'>Given access person can use below facilities provided by “WE” *Depending on subscription
                        eligibility</p>
                      <ol type='disc' className='ms-5 mb-4'>
                        <li>Search Service Centre</li>
                        <li>Buy EW Plan</li>
                        <li>Ask the expert</li>
                        <li>Subscriptions</li>
                        <li>Ask US</li>
                      </ol>
                    </li>
                  </ul>

                  <h4 class="DisclaimerHeading fw-bold my-5">Advertisement Free / No Advertisement</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>This advertising freedom applies to mobile devices.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>*Depending on subscription eligibility.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">Direct Connect (Relationship Manager)</h4>
                  <h4 class="DisclaimerHeading fw-bold my-5">Relationship Manager call as Direct Connect.</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>Direct Connect will act on its behalf “WE” with regard to matters arising in “WE Extended
                    Warranty” or any other issues shall address by the Direct Connect in writing of the name of its
                    Direct Connect; however, the Direct Connect shall have no authority to alter or amend any
                    term, condition, or provision of terms and conditions. Consumer can’t change its Direct
                    Connect(s).</p>


                  <h4 class="DisclaimerHeading fw-bold my-5">Gift “an” Extended Warranty</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>Consumers can purchase an extended warranty on behalf of friends and family who want to
                    give an extended warranty to the products listed in our application. Each gifted Extended
                    Warranty has a 90-day activation period. Extension of the expiration date from the issue date
                    is strictly prohibited. We reserve the all right to refuse or cancel the Gift "an" Extended
                    Warranty for gifts that have not been activated within the specified time period. The Gift “an”extended warranty provided to particular product only is not transferable to other products or
                    devices.</p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3" id="productabclist">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 class="DisclaimerHeading fw-bold my-5">Add Products/Documents</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'> <strong>“WE”</strong> Providing facility to add and save details of all the products in various category that
                    Consumer have or Consumer have purchased.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>Product will define as</p>

                  <p className='DisclaimerHeading fw-normal mb-4'><strong>Old Product</strong> (Consumers have products that have out of warranty and purchased date for more
                    than 90 days)</p>

                  <p className='DisclaimerHeading fw-normal mb-4'><strong>Existing product</strong> (The consumer has purchased the product covered by the OEM warranty and
                    the purchase date has exceeded 90 days.)</p>

                  <p className='DisclaimerHeading fw-normal mb-4'><strong>New product</strong> (The consumer purchases the product covered by the OEM warranty and the
                    purchase date is within 90 days.)</p>

                  <p className='DisclaimerHeading fw-normal mb-4'>Add product has 4 Categories with define product list</p>


                  <ul type="disc" className='ms-5'>
                    <li>
                      Electronics
                      <ul className='ms-5'>
                        <li>Air Conditioner</li>
                        <li>Home Theaters</li>
                        <li>Music System</li>
                        <li>Projector</li>
                        <li>Television</li>
                      </ul>
                    </li>
                    <li>
                      Appliances
                      <ul className='ms-5'>
                        <li>Dishwashers</li>
                        <li>Microwaves</li>
                        <li>OTGs</li>
                        <li>Refrigerators</li>
                        <li>Washing Machines</li>
                      </ul>
                    </li>
                    <li>
                      Gadgets
                      <ul className='ms-5'>
                        <li>Gaming Console</li>
                        <li>Smart phones</li>
                        <li>Tablets</li>
                        <li>Wearable</li>
                      </ul>
                    </li>
                    <li>
                      Computers
                      <ul className='ms-5'>
                        <li>2-in-1 Convertible Laptop</li>
                        <li>AIO(All in one Desktop)</li>
                        <li>Desktop</li>
                        <li>Laptop</li>
                      </ul>
                    </li>
                  </ul>

                  <h4 class="DisclaimerHeading fw-bold my-5">Document Saved, Upload & Download</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>Invoice of all product at one place keep important documents electronically</p>
                  <p className='DisclaimerHeading fw-normal'>If Consumer Device is lost or broken, you can still access your files from other devices after
                    login again with WE app (Mobile login with OTP).
                  </p>
                  <p className='DisclaimerHeading fw-normal'>
                    Consumer retain ownership of any document (invoice, warranty card, etc..) that consumer hold
                    in that content. In short, what belongs to consumer its stays Consumer only.</p>
                  <p className='DisclaimerHeading fw-normal'>
                    WE do not claim any ownership in any of your invoices, including warranties in your account.</p>

                  <p className='DisclaimerHeading fw-normal'>Consumer control who can access & upload invoices, warranty certificates in Documents. WE
                    will not share consumer data with others except as described in Family, Local & Admin
                    access.</p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 class="DisclaimerHeading fw-bold my-5">Vault</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>Vault is compartment for the safekeeping of all products list with invoice and EW at one place
                    The Vault Service is a set of tools, applications, web services and widgets that allows you to
                    capture, control and manage products on your electronic devices. You can use the vault storage
                    location on your device with multiple access storage location (add address).</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">Piggy Bank</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>Whatever a Consumer earns with an App / Net login, they can actually put it in the piggy bank
                    Consumer can earn the WE Coins or WE Cash on Under any ongoing provide with a view to
                    replicate in consumer`s piggy bank.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>WE coins can be redeemed for price lists and consumers can transfer them to another WE
                    consumer for a small transfer fee.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>WE Cash can be used for future purchases and consumers can transfer money to another WE
                    consumer with only a small transfer fee.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">My Warranty</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>Basically, my warranty is part of a vault defined in Detailed description category wise.</p>
                  <h4 class="DisclaimerHeading fw-bold my-5">Vault</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>The category of the product will be change as per the criteria mentioned below.</p>


                  <p className='DisclaimerHeading fw-normal mb-4'>My Warranty falls under 6 categories, which are defined below:</p>
                  <ul type="disc" className='ms-5'>
                    <li>Expired</li>
                    <li>About to Expired</li>
                    <li>Yet to Activate</li>
                    <li>OEM</li>
                    <li>Third Party</li>
                    <li>Warranty Express</li>
                  </ul>

                  <h4 class="DisclaimerHeading fw-bold my-5">Expired:</h4>

                  <p className='DisclaimerHeading fw-normal mb-4'>This category displays a list of the product which OEM Warranty got over and no other
                    Extended Warranty has been taken. Basically, its call “OLD Product” too.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">About to Expired:</h4>

                  <p className='DisclaimerHeading fw-normal mb-4'>This category displays a list of the product which in OEM Warranty going to expired in Few
                    Days (60 Days) and no other Extended Warranty has been taken. Basically, its call “Existing
                    Product” too.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3 footerCustomHeight" >
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 class="DisclaimerHeading fw-bold my-5">OEM</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>This category displays a list of the product which is in OEM Warranty and no other Extended
                    Warranty has been taken. It can be within 90 days or above 90 days from the date of purchase
                    (Invoice date). Product can be called as “New Product” or “Existing Product” basis on the
                    conditions mentioned above.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">Third Party</h4>
                  <p className='DisclaimerHeading fw-normal'>This category displays a list of the product which is in OEM Warranty and third-party warranty
                    taken against the product.</p>
                  <p className='DisclaimerHeading fw-normal mb-4'>Product can be called as “New Product” or “Existing Product” basis on the conditions
                    mentioned above.</p>


                  <h4 class="DisclaimerHeading fw-bold my-5">Warranty Express</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>This category displays a list of the product which is in OEM Warranty and taken WE Extended
                    warranty against the product It can be within 90 days or above 90 days from the date of
                    purchase (Invoice date). Product can be called as “New Product” or “Existing Product” basis
                    on the conditions mentioned above.</p>

                  <h4 class="DisclaimerHeading fw-bold my-5">Ask the expert</h4>
                  <p className='DisclaimerHeading fw-normal mb-4'>“WE” having, involvement and displaying a special skill and knowledge derived from
                    experience. WE only give the suggestions from where you can buy with more features.</p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default TermsAndConditionpdf