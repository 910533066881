/*eslint-disable*/
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {

  // date time 
const CurrentTime = moment().format('LT'); // htmlFor current Time
const CurrentDate = moment().format('L'); // htmlFor current Date
  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                Welcome to <span className="fw-bold">Dashboard</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
            <div className="row employeeCards d-flex">
              <div className="col-md-4 d-flex">
                <div className="col-12 d-flex">
                  <div className="card w-100">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <p className="mb-2 fw-bold blueText">
                            Consumer Overview- Reports Due
                          </p>

                          <div className="col-9 float-start d-flex mt-2 align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="43.576"
                              height="49.333"
                              viewBox="0 0 43.576 49.333"
                            >
                              <g
                                id="_4_Businessman"
                                data-name="4_Businessman"
                                transform="translate(-10.222 -7.33)"
                              >
                                <path
                                  id="Path_4822"
                                  data-name="Path 4822"
                                  d="M53.37,51.661A24.762,24.762,0,0,0,35.362,39.585V37.014c3-1.791,5.533-5.743,6.893-9.745a3.252,3.252,0,0,0,.45.08,3.332,3.332,0,0,0,3.312-3.342,3.282,3.282,0,0,0-1.451-2.681,3.191,3.191,0,0,0,1.451-2.651V16A3.342,3.342,0,0,0,43.3,12.723a3.342,3.342,0,0,0-2.671-2.661A3.352,3.352,0,0,0,37.343,7.33H21.335A3.342,3.342,0,0,0,18,10.672v8a3.182,3.182,0,0,0,1.461,2.651A3.272,3.272,0,0,0,18,24.008a3.342,3.342,0,0,0,3.332,3.332,3.691,3.691,0,0,0,.46-.08c1.351,4,3.862,8,6.883,9.745v2.581a24.8,24.8,0,0,0-7.474,2.321h0A24.682,24.682,0,0,0,10.65,51.661a3.362,3.362,0,0,0,2.891,5H50.479a3.362,3.362,0,0,0,2.891-5ZM29.009,40.886l2.2,4.272a2.782,2.782,0,0,0-.64.19l-2.421,1.211a2,2,0,0,1-2.311-.37l-3.432-3.382a23.111,23.111,0,0,1,6.6-1.921Zm1.451-.1c.53,0,1.061-.1,1.6-.1s1,.07,1.541.1L32.01,43.877Zm1.6,8.6,1,6H31.01Zm3-8.5a23.4,23.4,0,0,1,6.633,1.921L38.3,46.188a2,2,0,0,1-2.311.37l-2.421-1.211a2.861,2.861,0,0,0-.65-.19Zm9.625-16.878a2,2,0,0,1-2.051,1.941,18.309,18.309,0,0,0,.67-3.812A2,2,0,0,1,44.686,24.008Zm-25.352,0a2,2,0,0,1,1.391-1.871,18.269,18.269,0,0,0,.66,3.812A2,2,0,0,1,19.334,24.008ZM21.4,20.676h-.06a2,2,0,0,1-2-2v-8a2,2,0,0,1,2-2H37.343a2,2,0,0,1,2,2,.66.66,0,0,0,.67.66,2,2,0,0,1,2,2,.67.67,0,0,0,.67.67,2,2,0,0,1,2,2v2.671a2,2,0,0,1-1.841,2,9.875,9.875,0,0,1-5.072-3.182.66.66,0,0,0-.51-.23.85.85,0,0,0-.26,0,12.836,12.836,0,0,1-9.925,0,.69.69,0,0,0-.78.18,10,10,0,0,1-4.9,3.232Zm.61,1.151a11.135,11.135,0,0,0,5-3.081,14.267,14.267,0,0,0,10.095,0,11.225,11.225,0,0,0,4.912,3c-.22,5.813-4.782,14.9-10,14.9S22.276,27.64,22.006,21.827Zm8,15.787a5.2,5.2,0,0,0,4,0v1.871c-.66-.06-1.331-.14-2-.14s-1.331.08-2,.14ZM11.8,54.352a2,2,0,0,1,0-2,23.371,23.371,0,0,1,9.324-8.894l3.712,3.7a3.322,3.322,0,0,0,3.842.63l2.431-1.211h0l-1.481,8.814H13.542A2,2,0,0,1,11.8,54.352Zm40.419,0a2,2,0,0,1-1.741,1H34.361l-1.471-8.814h0l2.431,1.211a3.392,3.392,0,0,0,1.491.35,3.332,3.332,0,0,0,2.351-1l3.712-3.7a23.451,23.451,0,0,1,9.344,8.924,2,2,0,0,1,0,2.031Z"
                                  transform="translate(0 0)"
                                  fill="#00395d"
                                />
                              </g>
                            </svg>

                            <p className="mb-0 ps-4">
                              Total no. of Verified  Consumer
                            </p>
                          </div>
                          <div className="col-3 float-start">
                            <h1 className="mb-0 fw-bold text-center">00</h1>
                          </div>
                        </div>

                        <hr />

                        <div className="col-6">
                          <h3 className="pt-2 fw-bold">00</h3>
                          <p className="mb-0">Consumer</p>
                        </div>
                        <div className="col-6">
                          <h3 className="pt-2 fw-bold">00</h3>
                          <p className="mb-0">Partner</p>
                        </div>
                        {/* <div className="col-4 pl-0 mb-2">
                          <h3 className="pt-2 fw-bold">00</h3>
                          <p className="mb-0">Interim Report</p>
                        </div> */}

                        <hr />

                        <div className="col-12 pl-0 text-end mt-3 viewMore">
                          <a href="#">View More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex">
                <div className="col-12 d-flex">
                  <div className="card w-100">
                    <div className="card-body pb-2">
                      <div className="col-12 d-flex">
                        <p className="mb-2 fw-bold blueText col-9">
                          Partner Overview- Performance
                        </p>
                        <p className="mb-2 col-3 text-end h6">
                          <a href="#" className="blackText">
                            View More
                          </a>
                        </p>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-sm-12">
                          <div id="bar-chart" className="apex-charts"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex">
                <div className="col-12 d-flex">
                  <div className="card w-100">
                    <div className="card-body p-0">
                      <div className="p-3">
                        <div className="row">
                          <div className="col">
                            <h6 className="m-0">Notifications</h6>
                          </div>
                          <div className="col-4 text-end h6 dropdown">
                            <button
                              type="button"
                              className="btn dropdown-toggle p-0 dayFilter"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fas fa-filter"></i>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                <a className="dropdown-item" href="#">
                                  7 Day
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  15 Days
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Month
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Year
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div data-simplebar>
                        <a href="" className="text-reset notification-item">
                          <div className="d-flex align-items-start">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title bg-primary rounded-circle font-size-16">
                                <i className="bx bx-cart"></i>
                              </span>
                            </div>
                            <div className="flex-1">
                              <h6 className="mt-0 mb-1">
                                Your order is placed
                              </h6>
                              <div className="font-size-12 text-muted">
                                <p className="mb-1">
                                  If several languages coalesce the grammar
                                </p>
                                <p className="mb-0">
                                  <i className="mdi mdi-clock-outline"></i> 3
                                  min ago
                                </p>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a href="" className="text-reset notification-item">
                          <div className="d-flex align-items-start">
                            <img
                              src="assets/images/users/avatar-3.jpg"
                              className="me-3 rounded-circle avatar-xs"
                              alt="user-pic"
                            />
                            <div className="flex-1">
                              <h6 className="mt-0 mb-1">James Lemire</h6>
                              <div className="font-size-12 text-muted">
                                <p className="mb-1">
                                  It will seem like simplified English.
                                </p>
                                <p className="mb-0">
                                  <i className="mdi mdi-clock-outline"></i> 1
                                  hours ago
                                </p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row employeeCards d-flex">
              <div className="col-md-4">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-12 d-flex">
                          <p className="mb-2 fw-bold blueText col-12">
                            Consumer- Reports Analytics
                          </p>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-sm-6">
                            <div
                              id="radial_chart"
                              className="apex-charts"
                              dir="ltr"
                            ></div>
                          </div>
                          <div className="col-sm-6">
                            <div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="py-0">
                                    <p className="mb-1 text-truncate">
                                      <i className="mdi mdi-circle text-primary me-1"></i>
                                      Not Submitted
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="py-0">
                                    <p className="mb-1 text-truncate">
                                      <i className="mdi mdi-circle text-success me-1"></i>
                                      Late Submission
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="py-0">
                                    <p className="mb-1 text-truncate">
                                      <i className="mdi mdi-circle text-warning me-1"></i>
                                      Completed
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 pl-0 text-end mt-2 viewMore">
                          <a href="#">View More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex">
                <div className="col-12 d-flex">
                  <div className="card w-100">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-12 d-flex">
                          <p className="mb-2 fw-bold blueText col-12">
                            Partner
                          </p>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-sm-6">
                            <div
                              id="pie_chart"
                              className="apex-charts"
                              dir="ltr"
                            ></div>
                          </div>
                          <div className="col-sm-6">
                            <div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="py-0">
                                    <p className="mb-1 text-truncate">
                                      <i className="mdi mdi-circle text-primary me-1"></i>
                                      Not active more than 30 days
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="py-0">
                                    <p className="mb-1 text-truncate">
                                      <i className="mdi mdi-circle text-success me-1"></i>
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 pl-0 text-end mt-2 viewMore">
                          <a href="#">View More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex">
                <div className="col-12 d-flex">
                  <div className="card w-100">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-12 d-flex align-items-center">
                          <div className="col-9 float-start mt-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="44"
                              viewBox="0 0 44 44"
                            >
                              <g
                                id="Icon_feather-edit"
                                data-name="Icon feather-edit"
                                transform="translate(-1.5 -1.318)"
                              >
                                <path
                                  id="Path_4855"
                                  data-name="Path 4855"
                                  d="M21.339,6H7.075A4.075,4.075,0,0,0,3,10.075V38.6a4.075,4.075,0,0,0,4.075,4.075H35.6A4.075,4.075,0,0,0,39.678,38.6V24.339"
                                  transform="translate(0 1.141)"
                                  fill="none"
                                  stroke="#00395d"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Path_4856"
                                  data-name="Path 4856"
                                  d="M33.4,4.084A4.322,4.322,0,0,1,39.508,10.2L20.151,29.555,12,31.592l2.038-8.151Z"
                                  transform="translate(3.226 0)"
                                  fill="none"
                                  stroke="#00395d"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                              </g>
                            </svg>
                            <p className="mb-0 mt-2 fw-bold blueText">
                              Total no. of reports due by partner
                            </p>
                          </div>
                          <div className="col-3 float-start">
                            <h1 className="mb-0 fw-bold">00</h1>
                          </div>
                        </div>
                        <hr />
                        <p className="mt-2 mb-0 blueText">
                          Report Configuration Insights
                        </p>
                        <div className="col-4">
                          <h3 className="pt-2 fw-bold">00</h3>
                          <p className="mb-0">Seller</p>
                        </div>
                        <div className="col-4">
                          <h3 className="pt-2 fw-bold">00</h3>
                          <p className="mb-0">Service</p>
                        </div>
                        <div className="col-4 pl-0 mb-2">
                          <h3 className="pt-2 fw-bold">00</h3>
                          <p className="mb-0">Retailer</p>
                        </div>
                        <hr />
                        <div className="col-12 pl-0 text-end mt-3 viewMore">
                          <a href="#">View More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
