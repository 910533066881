/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import qs from "qs";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";


const AdminLogin = () => {
  // Extra Stuffs
  const navigate = useNavigate();

  // Admin Login Values, Errors & Validate
  const initialValues = {
    email: "",
    password: "",
  };
  const [adminLoginFormValues, setAdminLoginFormValues] = useState(initialValues);
  const [adminLoginFormErrors, setAdminLoginFormErrors] = useState({});
  const [adminLoginFormIsValid, setAdminLoginFormIsValid] = useState(false);
  const isUserCheck = JSON.parse(localStorage.getItem('isChecked'));
  const [rememberMe, setRememberMe] = useState(isUserCheck)
  const [passwordShown, setPasswordShown] = useState(false);

  // Handle Input
  const handleInput = (event) => {
    event.preventDefault();
    // Not Image
    if (event.target.type !== "file") {
      const { name, value } = event.target;
      setAdminLoginFormValues({ ...adminLoginFormValues, [name]: value });
    }
  };

  // Validate Values
  const validateValues = (valuesObject) => {
    const errors = {};
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    // email
    if (!valuesObject.email) {
      errors.email = "Email is required!";
    } else if (!emailRegrex.test(valuesObject.email)) {
      errors.email = "Enter valid email";
    }
    // password
    if (!valuesObject.password) {
      errors.password = "Password is required!";
    } else if (
      valuesObject.password.length < 8 ||
      valuesObject.password.length > 30
    ) {
      errors.password = "Should be between 8 to 30";
    }

    return errors;
  };


  // Handle Submit
  const handleSubmit = (event) => {
    event.preventDefault();
    setAdminLoginFormErrors(validateValues(adminLoginFormValues));
    setAdminLoginFormIsValid(true);
  };

  // Handle Submit API
  useEffect(() => {
    if (
      Object.keys(adminLoginFormErrors).length === 0 &&
      adminLoginFormIsValid
    ) {
      // Login API Call
      adminLoginAPI();
    }
  }, [adminLoginFormErrors]);

  // APIs
  // Login API
  const adminLoginAPI = async () => {
    try {
      var data = JSON.stringify({
        email: adminLoginFormValues.email,
        password: adminLoginFormValues.password
      });

      var config = {
        method: 'post',
        url: `${BaseAPI.nodeAPI}users/login`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data
      };

      await axios(config)
        .then(function (response) {
          console.log(response.status);
          if (rememberMe) {
            localStorage.setItem('isChecked', JSON.stringify("true"))
            localStorage.setItem("adminauth", JSON.stringify(response.data))
            navigate('/')
            toast.success(response.data.message, { position: 'top-right' });
          }
          if (!rememberMe) {
            localStorage.removeItem('isChecked');
            localStorage.setItem("adminauth", JSON.stringify(response.data))
            navigate('/')
            // alert(response.data.message)         
            toast.success(response.data.message, { position: 'top-right' });
          }

          if (response?.data.status === 0) {
            // alert(response.data.message)
            toast.error(response.message, { position: 'top-right' });
          }
        })
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response.data.message, { position: 'top-right' });
    };
  }

 
  //function for handle&store user data  
  const handleRemember = (e) => {
    console.log(e.target.checked);
    setRememberMe(e.target.checked);
  }

  //function for showpassword
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  // JSX
  return (
    <div className="d-flex align-items-center h-100 w-100">
      <div className="account-pages col-md-4 mx-auto form-signin">
        <div className="loginForm">
          <div className="card overflow-hidden mb-0">
            <div className="card-body p-0">
              <form
                onSubmit={handleSubmit}
                className="form-horizontal"
                noValidate
              >
                <div className="mb-1 w-100">
                  <div className="loginBackground text-center">
                    <img
                      src="./assets/images/logo-dark.png"
                      width="100px"
                      alt=""
                      className="img-fluid py-4"
                    />
                  </div>
                </div>

                {/* email */}
                <div className="mb-4 float-start w-100 px-4">
                  <label className="form-label fw-normal" htmlFor="email">
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    autoComplete="off"
                    onChange={handleInput}
                  />
                  <p style={{ color: "red" }}>{adminLoginFormErrors.email}</p>
                </div>

                {/* password */}
                <div className="mb-4 float-start w-100 px-4 position-relative">
                  <label className="form-label fw-normal" htmlFor="userpassword">Password</label>

                  <input
                    type={!passwordShown ? "password" : "text"}
                    className="form-control"
                    id="userpassword"
                    name="password"
                    autoComplete="off"
                    onChange={handleInput}
                  />
                  <span className="formIcons">
                    <i toggle="#password-field"
                      className="fa fa-fw field-icon toggle-password fa-eye"
                      onClick={togglePassword}
                    ></i>
                  </span>
                  <p style={{ color: "red" }}>
                    {adminLoginFormErrors.password}
                  </p>
                </div>
                <div className="mb-4 float-start w-100 px-4">
                  <div className="form-check float-start">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customControlInline"
                      onClick={(e) => handleRemember(e)}
                      defaultChecked={rememberMe}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customControlInline"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="mb-4 float-start w-100 px-4">
                  <button
                    type="submit"
                    className="btn blueBtns borderradius10 waves-effect waves-light px-5 mt-3 w-100"
                  >
                    Admin Login
                  </button>
                </div>
                <div className="mb-4 float-start w-100 px-4">
                  <NavLink
                    to={AdminRoutes.AdminWendiansLogin}
                    className="btn blueBtns borderradius10 waves-effect waves-light px-5 mt-3 w-100"
                  >
                    Login as a Wendians
                  </NavLink>
                </div>
                <div className="text-center float-start w-100 px-3 pb-4">
                  <a >
                    <i className="fa fa-solid fa-lock"></i>
                    <span className="ms-2" onClick={(e) => navigate("/forgot-password")}>Forgot your password?</span>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
