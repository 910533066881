/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const AddNewIndianPlan = () => {
  // date time 
  const CurrentTime = moment().format('LT'); // htmlFor current Time
  const CurrentDate = moment().format('L'); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem('adminauth'));
  // Features Variables
  const [Titlewe, setTitlewe] = useState("")
  const [Validitywe, setValiditywe] = useState("")
  const [MonYerwe, setMonYearwe] = useState("")
  const [VisitingChrg, setVisitingChrg] = useState("")
  const [LabourFees, setLabourFees] = useState("")
  const [CostOfSpare, setCostOfSpare] = useState("")
  const [ServiceRequest, setServiceRequest] = useState("")
  const [ManifacturingDefect, setManifacturingDefect] = useState("")
  const [LiduidDamage, setLiduidDamage] = useState("")
  const [ScreenRepair, setScreenRepair] = useState("")
  const [DiscountonSpare, setDiscountonSpare] = useState("")
  const [KindOfDamage, setKindOfDamage] = useState("")
  const [ShortCircuit, setShortCircuit] = useState("")
  const [RemoteControlRepair, setRemoteControlRepair] = useState("")
  // usestate for api calls
  const [SubmitIndianForm, setSubmitIndianForm] = useState([]);
  const [SubmitPricingPlans, setSubmitPricingPlans] = useState()
  // usestate for Duplicate row child/parentchild
  const [DuplicateChildRow, setDuplicateChildRow] = useState([{ subcategory_id: "", ew_plan_month: "", discount_percentage: "", ew_plan_id: "" }])
  const [SelectedPlanID, setSelectedPlanID] = useState('')
  // usestate for subcategory listing api
  const [SubcategoryList, setSubcategoryList] = useState()


  // fuction for to add the duplicate child row
  const handleSubmitDuplicaterow = (index, event) => {
    const values = [...DuplicateChildRow]
    values[index][event.target.name] = event.target.value;
    setDuplicateChildRow(values)
  }

  useEffect(() => {
    console.log(DuplicateChildRow)
  }, [DuplicateChildRow])

  const handleAddDuplicate = (index) => {
    setDuplicateChildRow([...DuplicateChildRow, { subcategory_id: "", ew_plan_month: "", discount_percentage: "", ew_plan_id: '' }])
  }

  // API call for to add the feature indian plans
  const HandleSubmitIndianFeatureForm = async () => {
    setDuplicateChildRow(DuplicateChildRow)
    console.log("objectcreated for pricing plan", DuplicateChildRow)

    var data = JSON.stringify({
      "ew_plan_data": {
        "title": Titlewe,
        "validity": Validitywe,
        "mon_yr": MonYerwe,
        "visit_charges": VisitingChrg,
        "labour_fees": LabourFees,
        "cost_of_spare_part": CostOfSpare,
        "no_of_service_req": ServiceRequest,
        "manufacturing_defect": ManifacturingDefect,
        "liquid_damage": LiduidDamage,
        "screen_repair": ScreenRepair,
        "discount_of_spare_part": DiscountonSpare,
        "any_kind_of_damage": KindOfDamage,
        "electrical_short_circuit": ShortCircuit,
        "remote_control_repair": RemoteControlRepair,
        "citizen": "Indian",
        "status": 1
      }
    });

    var config = {
      method: 'post',
      url: `${BaseAPI.nodeAPI}ew_plan/add`,
      headers: {
        'Authorization': adminauth?.data?.token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios(config)
      .then(function (response) {
        console.log("submitindian form", response?.data?.ew_plan_data._id);
        // if (response?.data?.ew_plan_data) {
        //   for (let i = 0; i <= DuplicateChildRow.length; i++) {
        //      DuplicateChildRow[i].ew_plan_id = response?.data?.ew_plan_data._id
        //   }
        // }
        setSubmitIndianForm(response?.data)
        handleSubmitPricingPlan(response?.data?.ew_plan_data._id)
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    console.log("SubmitIndianForm", DuplicateChildRow)
  }, [DuplicateChildRow])




  // Api call for to submit pricing palns 
  const handleSubmitPricingPlan = async (myID) => {
    // for (let i = 0; i <= DuplicateChildRow.length; i++) {
    //   DuplicateChildRow[i].ew_plan_id = myID
    // }
    const discountData = DuplicateChildRow.map((val) => {
      val['ew_plan_id'] = myID
      return val;
    })
    console.log("DuplicateChildRow", discountData)
    var data = JSON.stringify({
      "ew_product_discount_data": discountData
    });

    var config = {
      method: 'post',
      url: `${BaseAPI.nodeAPI}ew_discount/add`,
      headers: {
        'Authorization': adminauth?.data?.token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data);
        setSubmitPricingPlans(response?.data)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  useEffect(() => {
    getSubcategoryList()
  }, [])

  // APi call for subcategory Listing in pricing plans
  const getSubcategoryList = () => {
    var data = JSON.stringify({
      "filterData": {
        "status": 1
      }
    });

    var config = {
      method: 'post',
      url: `${BaseAPI.nodeAPI}subcategory/list`,
      headers: {
        'Authorization': adminauth?.data?.token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log("subcategorylisting", response?.data?.data);
        setSubcategoryList(response?.data?.data?.rows)

      })
      .catch(function (error) {
        console.log(error);
      });

  }
  // Function to store the Subcategoryid in object
  const Handlesubcategory_id = (value, index) => {
    console.log("Handlesubcategory_value", value)
    console.log("Handlesubcategory_index", index)
    DuplicateChildRow[index].subcategory_id = value
  }

  // Function to store the ew_plan_month in object
  const Handlesubcategory_Months = (value, index) => {
    console.log("Handlesubcategory_value",typeof parseInt(value))
    console.log("Handlesubcategory_index", index)
    DuplicateChildRow[index].ew_plan_month = parseInt(value)
  }

  // Function to store the discount_percentage in object
  const Handlesubcategory_Percentage = (value, index,) => {
    console.log("Handlesubcategory_value", typeof parseInt(value))
    console.log("Handlesubcategory_index", index)
    DuplicateChildRow[index].discount_percentage = parseFloat(value)
  }

  // const Handlesubcategory_ew_plan_id = (value, index) => {
  //   console.log("Handlesubcategory_value", value)
  //   console.log("Handlesubcategory_index", index)
  //   DuplicateChildRow[index]._id = value
  // }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Extended Warranty Plans</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-4 pageBack d-flex">
                    <NavLink to={AdminRoutes.EwPlans}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      Add New Plan for Indian
                    </p>
                  </div>

                  <div className="col-8 d-flex justify-content-end">
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Save as Draft
                    </button> */}
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      onClick={() => HandleSubmitIndianFeatureForm()}
                    >
                      Submit
                    </NavLink>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="bookTitle">
                            EW Plan Title :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bookTitle"
                            placeholder="Enter EW Plan Title :"
                            onChange={(e) => setTitlewe(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Validity :
                          </label>
                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control"
                              id="noOfPages"
                              placeholder="Enter Validity"
                              onChange={(e) => setValiditywe(parseInt(e.target.value))}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Duration :
                          </label>
                          <div className="d-flex">
                            <select
                              className="form-select planSelect w-25"
                              aria-label="Default select example"
                              onChange={(e) => setMonYearwe(e.target.value)}
                            >
                              <option selected>Select</option>
                              <option value="Hrs">Hrs</option>
                              <option value="Days">Days</option>
                              <option value="Months"> Months</option>
                              <option value="Years">Years</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start mb-2">
                            <h6 className="fw-bold">Features</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0 text-end">
                                            <i className=" h5 text-white"></i> 
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Visit Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setVisitingChrg(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="Included">
                                                Included
                                              </option>
                                              <option value="Excluded">
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>Labour & Service Fees</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setLabourFees(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="Included">
                                                Included
                                              </option>
                                              <option value="Excluded">
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>
                                            Cost of Spare Parts due to
                                            manufacturing defect
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setCostOfSpare(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="Included">
                                                Included
                                              </option>
                                              <option value="Excluded">
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>Number of Service Requests</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setServiceRequest(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="Unlimited">
                                                Unlimited
                                              </option>
                                              <option value="Limited">
                                                Limited
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>
                                            Prod. Rep incase beyond repair due
                                            to manufacturing defect
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setManifacturingDefect(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="Ref UDV">
                                                Ref UDV
                                              </option>
                                              <option value="New UOV">
                                                New UOV
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>
                                            Damage Repair Services incase of
                                            accidental and liquid damage
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setLiduidDamage(e.target.value)}

                                            >
                                              <option selected>Select</option>
                                              <option value="Included">
                                                Included
                                              </option>
                                              <option value="Excluded">
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>7</td>
                                          <td>
                                            Screen Repair Services incase of
                                            accidental and liquid damage
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setScreenRepair(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="Included">
                                                Included
                                              </option>
                                              <option value="Excluded">
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>8</td>
                                          <td>
                                            Discount on spare parts incase of
                                            damage repair services
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setDiscountonSpare(parseInt(e.target.value))}
                                            >
                                              <option selected>Select</option>
                                              <option value="0">NA</option>
                                              <option value="15%">15%</option>
                                              <option value="35%">35%</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>9</td>
                                          <td>
                                            Prod. Rep. incase beyond repair due
                                            to any kind of damage
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setKindOfDamage(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="Included">
                                                Included
                                              </option>
                                              <option value="Excluded">
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>10</td>
                                          <td>
                                            Damage Repair Services arising due
                                            to electrical short circuit
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setShortCircuit(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="Included">
                                                Included
                                              </option>
                                              <option value="Excluded">
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>11</td>
                                          <td>Remote Control Repair</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              onChange={(e) => setRemoteControlRepair(e.target.value)}
                                            >
                                              <option selected>Select</option>
                                              <option value="Included">
                                                Included
                                              </option>
                                              <option value="Excluded">
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Pricing Plans</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    {/* {DuplicateParentRow?.map((val,index)=>
                                      <> */}
                                    <table className="table table-striped mb-0 tablesWrap">

                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Products
                                          </th>
                                          <th className="border-top-0">
                                            Months
                                          </th>
                                          <th className="border-top-0">
                                            Percentage
                                          </th>
                                          <th>Add </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <>
                                          {DuplicateChildRow?.map((val, index) =>
                                            <>
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  <select
                                                    className="form-select planSelect"
                                                    aria-label="Default select example"
                                                    value={val?.name}
                                                    id={"check" + index}
                                                    onChange={(e) => Handlesubcategory_id(e.target.value, index)}
                                                    onClick={() => getSubcategoryList()}

                                                  >
                                                    <option selected>Select</option>
                                                    {SubcategoryList?.map((val) =>
                                                      <>
                                                        <option id={val?._id} value={val?._id}>
                                                          {val?.name}
                                                        </option>
                                                      </>
                                                    )}


                                                  </select>
                                                </td>

                                                <td>
                                                  <select
                                                    className="form-select planSelect"
                                                    aria-label="Default select example"
                                                    onChange={(e) => Handlesubcategory_Months(e.target.value, index)}
                                                  >
                                                    <option selected>Select</option>
                                                    <option value="6">
                                                      6 Months
                                                    </option>
                                                    <option value="12">
                                                      12 Months
                                                    </option>
                                                    <option value="18">
                                                      18 Months
                                                    </option>
                                                    <option value="24">
                                                      24 Months
                                                    </option>
                                                    <option value="30">
                                                      30 Months
                                                    </option>
                                                    <option value="36">
                                                      36 Months
                                                    </option>
                                                  </select>
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    className="form-control me-3"
                                                    id="noOfPages"
                                                    placeholder="Enter Percentage"
                                                    onChange={(e) => Handlesubcategory_Percentage(e.target.value, index)}
                                                  />
                                                </td>

                                                <td className="align-middle">
                                                  <i className="fas fa-plus-circle blueText h4 mb-0" onClick={() => handleAddDuplicate(index)}></i>
                                                </td>
                                              </tr>
                                            </>
                                          )}
                                        </>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Save as Draft
                    </button> */}
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      onClick={() => HandleSubmitIndianFeatureForm()}
                    >
                      Submit
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewIndianPlan;
