/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';


const ResetPassword = () => {

  // Varibles //
  const params = useParams();
  const initialValues = { NewPass: "", ConfirmPass: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  //Password Validation//

  const validate = (values) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/i;
    const errors = {};
    console.log(values)
    if (!values?.NewPass) {
      errors.NewPass = "Field is Required!";
    } else if (!regex.test(values.NewPass)) {
      errors.NewPass = "Password must contain at least 8 characters, 1 number, 1 upper and 1 lowercase!";
    }
    if (!values?.ConfirmPass) {
      errors.ConfirmPass = "Field is Required!";
    } else if (formValues.NewPass !== formValues.ConfirmPass) {
      errors.ConfirmPass = "Password does not match!";
    }
    return errors;
  };
console.log("userid",params.userid)
  // Reset Password Api Call
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      var data = JSON.stringify({
        "id": localStorage.getItem("myMail"),
        "token": localStorage.getItem("token"),
        "password": formValues?.ConfirmPass
      });

      var config = {
        method: 'POST',
        url: `${BaseAPI.nodeAPI}users/reset-password`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log((response.data));
          toast.success(response?.data?.message, { position: 'top-right' });
          navigate('/');
          localStorage.removeItem('myMail');
        })
        .catch(function (error) {
          console.log(error);
          toast.error(error?.response?.data.message, { position: 'top-right' });

        });
    }

  }, [formErrors])

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setFormErrors(validate(formValues));
  };


  // JSX //
  return (
    <div className="d-flex align-items-center h-100 w-100">
      <div className="account-pages col-md-4 mx-auto form-signin">
        <div className="loginForm">
          <div className="card overflow-hidden mb-0">
            <div className="card-body p-0">
              <form className="form-horizontal" noValidate>
                <div className="mb-1 w-100">
                  <div className="loginBackground text-center">
                    <img
                      src="./assets/images/logo-dark.png"
                      width="100px"
                      alt=""
                      className="img-fluid py-4"
                    />
                  </div>
                </div>
                <h5 className="px-4 fw-bold mb-4 text-center">
                  Reset Your Password
                </h5>
                  {/* OTP */}
                  {/* <div className="mb-4 float-start w-100 px-4 position-relative">
                  <label className="form-label fw-normal" htmlFor="otp">
                    OTP
                  </label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control"
                    id="otp"
                    name="otp"
                    // onChange={handleChange}
                    placeholder='Please enter your OTP.'
                  />
                  <span className="formIcons">
                    <i toggle="#password-field" className="fa fa-fw field-icon toggle-password fa-eye"></i>
                  </span>
                  <p className="mb-0" style={{ color: "red" }}>
                    {/* {formErrors?.NewPass} */}

                  {/* </p>
                </div> */} 

                {/* {/ New password /} */}
                <div className="mb-4 float-start w-100 px-4 position-relative">
                  <label className="form-label fw-normal" htmlFor="newpassword">
                    New Password
                  </label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control"
                    id="newpassword"
                    name="NewPass"
                    onChange={handleChange}
                    placeholder='Please enter your new password.'
                  />
                  <span className="formIcons">
                    <i toggle="#password-field" className="fa fa-fw field-icon toggle-password fa-eye" onClick={() => setPasswordShown(!passwordShown)} ></i>
                  </span>
                  <p className="mb-0" style={{ color: "red" }}>
                    {formErrors?.NewPass}

                  </p>
                </div>

                {/* {/ Conform password /} */}
                <div className="mb-2 float-start w-100 px-4 position-relative">
                  <label
                    className="form-label fw-normal"
                    htmlFor="conformpassword"
                  >
                    Confirm Password
                  </label>
                  <input
                    type={passwordShown2 ? "text" : "password"}
                    className="form-control"
                    id="conformpassword"
                    name="ConfirmPass"
                    onChange={handleChange}
                    placeholder='Reenter your password'
                  />
                  <span className="formIcons">
                    <i toggle="#password-field" className="fa fa-fw field-icon toggle-password fa-eye" onClick={() => setPasswordShown2(!passwordShown2)} ></i>
                  </span>
                  <p className="mb-0" style={{ color: "red" }}>
                    {formErrors?.ConfirmPass}
                  </p>
                </div>
                <div className="mb-4 float-start w-100 px-4">
                  <NavLink
                    to={AdminRoutes.AdminLogin}
                    type="submit"
                    className="btn blueBtns borderradius10 waves-effect waves-light px-5 mt-3 w-100"
                    onClick={(e) => handleSubmit(e)}

                  >
                    Submit
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;