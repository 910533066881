/*eslint-disable*/
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from "react-router-dom";
import { BaseAPI } from '../../../../api/admin/BaseAPI';
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
const AddNRIPlansDetails = () => {
  // date time 
  const CurrentTime = moment().format('LT'); // htmlFor current Time
  const CurrentDate = moment().format('L'); // htmlFor current Date 
  // auth
  const adminauth = JSON.parse(localStorage.getItem('adminauth'));
  const params = useParams();
  const [NriewplanDetails, setNriewplanDetails] = useState()
  const [PrisingPlanDetails, setPrisingPlanDetails] = useState()

  // Api call for to get only feature indian ewplans details
  useEffect(() => {
    var data = '';

    var config = {
      method: 'get',
      url: `${BaseAPI.nodeAPI}ew_plan/${params.ewplannriid}`,
      headers: {
        'Authorization': adminauth?.data?.token
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log("IndianewplanDetails", response?.data?.data);
        setNriewplanDetails(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [])

  //  Api call for only pricing plan details as per the id
  useEffect(() => {
    var data = {
      "filterData": {
        "ew_plan_id": params.ewplannriid
      }
    }
    var config = {
      method: 'post',
      url: `${BaseAPI.nodeAPI}ew_discount/list_admin`,
      headers: {
        'Authorization': adminauth?.data?.token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log("pricingplan details", response?.data?.data);
        setPrisingPlanDetails(response?.data?.data)
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [])

  console.log("ewplanid", params.ewplanindianid)

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Extended Warranty Plans</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-3 pageBack d-flex">
                    <NavLink to={AdminRoutes.EwPlans}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      NRI EW Plan Details
                    </p>
                  </div>

                  <div className="col-9 d-flex justify-content-end">
                    {/* <button
                          type="button"
                          className="btn addBtns waves-effect waves-light px-4 me-3"
                        >
                          Save as Draft
                        </button> */}
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                      Submit
                    </NavLink>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" for="bookTitle">
                            EW Plan Title :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bookTitle"
                            placeholder="Enter EW Plan Title :"
                            defaultValue={NriewplanDetails?.title}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" for="noOfPages">
                            Validity :
                          </label>

                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control me-3"
                              id="noOfPages"
                              placeholder="Enter Validity"
                              defaultValue={NriewplanDetails?.validity}
                            />
                            <select
                              class="form-select planSelect w-25"
                              aria-label="Default select example"
                            >
                              <option value="Hrs" selected={NriewplanDetails?.mon_yr === 'Hrs'}>Hrs</option>
                              <option value="Days" selected={NriewplanDetails?.mon_yr === 'Days'}>Days</option>
                              <option value="Months" selected={NriewplanDetails?.mon_yr === 'Months'}>Months </option>
                              <option value="Years" selected={NriewplanDetails?.mon_yr === 'Years'}>Years</option>
                            </select>
                          </div>

                        </div>
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start mb-2">
                            <h6 className="fw-bold">Features</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div class="table-responsive">
                                    <table class="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0"></th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Visit Charges</td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Included" selected={NriewplanDetails?.visit_charges === "Included"}> Included</option>
                                              <option value="Excluded" selected={NriewplanDetails?.visit_charges === "Excluded"}> Excluded </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>Labour & Service Fees</td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Included" selected={NriewplanDetails?.labour_fees === "Included"}>Included </option>
                                              <option value="Excluded" selected={NriewplanDetails?.labour_fees === "Excluded"}> Excluded</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>
                                            Cost of Spare Parts due to
                                            manufacturing defect
                                          </td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Included" selected={NriewplanDetails?.cost_of_spare_part === "Included"}>Included </option>
                                              <option value="Excluded" selected={NriewplanDetails?.cost_of_spare_part === "Excluded"}> Excluded</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>Number of Service Requests</td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Unlimited" selected={NriewplanDetails?.no_of_service_req === "Unlimited"}>Unlimited </option>
                                              <option value="Limited" selected={NriewplanDetails?.no_of_service_req === "Limited"}> Limited </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>
                                            Prod. Rep incase beyond repair due
                                            to manufacturing defect
                                          </td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Ref UDV" selected={NriewplanDetails?.manufacturing_defect ==="Ref UDV"}>Ref UDV</option>
                                              <option value="New UOV" selected={NriewplanDetails?.manufacturing_defect ==="New UOV"}> New UOV </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>
                                            Damage Repair Services incase of
                                            accidental and liquid damage
                                          </td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Included" selected={NriewplanDetails?.liquid_damage === "Included"}>Included</option>
                                              <option value="Excluded" selected={NriewplanDetails?.liquid_damage === "Excluded"}> Excluded</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>7</td>
                                          <td>
                                            Screen Repair Services incase of
                                            accidental and liquid damage
                                          </td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Included" selected={NriewplanDetails?.screen_repair === "Included"}>Included</option>
                                              <option value="Excluded" selected={NriewplanDetails?.screen_repair === "Excluded"}>Excluded </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>8</td>
                                          <td>
                                            Discount on spare parts incase of
                                            damage repair services
                                          </td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0" selected={NriewplanDetails?.discount_of_spare_part === 0} >NA</option>
                                              <option value="15%" selected={NriewplanDetails?.discount_of_spare_part === 15}>15%</option>
                                              <option value="35%" selected={NriewplanDetails?.discount_of_spare_part === 35}>35%</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>9</td>
                                          <td>
                                            Prod. Rep. incase beyond repair due
                                            to any kind of damage
                                          </td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Included" selected={NriewplanDetails?.any_kind_of_damage === "Included"}>
                                                Included
                                              </option>
                                              <option value="Excluded" selected={NriewplanDetails?.any_kind_of_damage === "Excluded"}>
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>10</td>
                                          <td>
                                            Damage Repair Services arising due
                                            to electrical short circuit
                                          </td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Included" selected={NriewplanDetails?.electrical_short_circuit === "Included"}>
                                                Included
                                              </option>
                                              <option value="Excluded" selected={NriewplanDetails?.electrical_short_circuit === "Excluded"}>
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>11</td>
                                          <td>Remote Control Repair</td>
                                          <td>
                                            <select
                                              class="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Included" selected={NriewplanDetails?.remote_control_repair === "Included"}>
                                                Included
                                              </option>
                                              <option value="Excluded" selected={NriewplanDetails?.remote_control_repair === "Excluded"}>
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Pricing Plans</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div class="table-responsive">
                                    <table class="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Products
                                          </th>
                                          <th className="border-top-0">
                                            Months
                                          </th>
                                          <th className="border-top-0">
                                            Percentage
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>

                                      <>
                                       {PrisingPlanDetails?.length && PrisingPlanDetails?.map((val,index)=>{
                                            return(
                                              <>
                                                       <tr>
                                          <td>{index+1}</td>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control me-3"
                                              id="noOfPages"
                                              placeholder="Enter Product"
                                              defaultValue={val?.subcategory_id?.name}

                                            />
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="6" selected ={val?.ew_plan_month ===6}>6 Months</option>
                                              <option value="12" selected ={val?.ew_plan_month ===12}>12 Months</option>
                                              <option value="18"selected ={val?.ew_plan_month ===18}> 18 Months </option>
                                              <option value="24"selected ={val?.ew_plan_month ===24}>24 Months</option>
                                              <option value="30"selected ={val?.ew_plan_month ===30}>30 Months </option>
                                              <option value="36"selected ={val?.ew_plan_month ===36}> 36 Months</option>
                                            </select>
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              min="0" 
                                              step="any"
                                              className="form-control me-3"
                                              id="noOfPages"
                                              placeholder="Enter Percentage"
                                              defaultValue={val?.discount_percentage}
                                            />
                                          </td>
                                          <td>
                                            <i className="fas fa-plus-circle blueText h3"></i>
                                          </td>
                                        </tr>
                                              </>
                                            )
                                       })}
                                       </>

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    {/* <button
                          type="button"
                          className="btn addBtns waves-effect waves-light px-4 me-3"
                        >
                          Save as Draft
                        </button> */}
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                      Submit
                    </NavLink>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNRIPlansDetails