/*eslint-disable*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";
import moment from "moment";

const AddNewWeStaff = () => {
  const navigate = useNavigate();
  const initialValues = {
    emp_id: "",
    firstname: "",
    lastname: "",
    contactno: "",
    email: "",
    address: "",
  };
  const [formValues, setformValues] = useState(initialValues);
  const [roles, setRoles] = useState([]);
  const [formErrors, setformError] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  const [ImageUpload, setImageUpload] = useState();
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
    // console.log(formValues)
  };
  // Form Validationregex
  const Validate = (values) => {
    const error = {};
    const firstnameregex = /^[a-zA-Z]*$/;
    const emailregex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    const mobileregex = /^[6-9]\d{9}$/gi;
    if (!values?.emp_id) {
      error.emp_id = " Employee Id is required!";
    }

    if (!values.firstname) {
      error.firstname = "First name is required!";
    } else if (!firstnameregex.test(values.firstname)) {
      error.firstname = " Invalid First Name!";
    }
    if (!values.lastname) {
      error.lastname = " Last name is required!";
    } else if (!firstnameregex.test(values.lastname)) {
      error.lastname = " Invalid Last Name!";
    }
    if (!values.contactno) {
      error.contactno = " Contact No. is required!";
    } else if (!mobileregex.test(values.contactno)) {
      error.contactno = " Invalid Contact Number!";
    }

    if (!values.email) {
      error.email = " E-mail is required!";
    } else if (!emailregex.test(values.email)) {
      error.email = " This is not valid email!";
    }
    if (!values.address) {
      error.address = " Address is required!";
    }
    return error;
  };

  const handlecheckbox = (e) => {
    if (e.target.checked === true) {
      roles.push(e.target.name);
    }
    if (e.target.checked === false) {
      setRoles(roles.filter((item) => item !== e.target.name));
    }
  };

  // add new WEstaff from submistion
  const handleSubmit = async (e) => {
    e.preventDefault();
    setformError(Validate(formValues));
    SetIsSubmit(true);
  };

  useEffect(async () => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      try {
        var data = JSON.stringify({
          employee_id: formValues.emp_id,
          name: formValues.firstname,
          phone: formValues.contactno,
          last_name: formValues.lastname,
          email: formValues.email,
          address: formValues.address,
          image: ImageUpload,
          roles: ["1", "2"],
          roles: roles,
          organization_name: "Qodequay",
          category: [],
          subcategory: [],
          brand: [],
          access_login: 1,
          status: 1,
        });

        var config = {
          method: "POST",
          url: `${BaseAPI.nodeAPI}users/create`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config).then(function (response) {
          console.log(response.data);
          toast.success(response.data.message, { position: "top-right" });
          navigate("/admin-user-management-we-staff");
        });
      } catch (err) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message, { position: "top-right" });
      }
    }
  }, [formErrors]);

  ///wendians image upload api call
  const ImageUploadWendians = (e) => {
    console.log(e);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", adminauth?.data?.token);

    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    console.log(formdata);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BaseAPI.nodeAPI}fileupload`, requestOptions)
      .then((response) => response.text())
      .then((result) => setImageUpload(JSON.parse(result).file_path))
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Wendians</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row pageBack">
                  <div className="float-start col-12">
                    <NavLink to={AdminRoutes.AdminUserManagementWeStaff}>
                      <i className="fas fa-arrow-left text-dark"></i>
                    </NavLink>
                    <span className="fw-bold h5 ps-3">Add New Wendians</span>
                  </div>
                  <div className="col-md-12 col-sm-12 float-start px-0  text-end">
                    {/* <button
                      type="submit"
                      className="btn addBtns waves-effect waves-light me-2 px-4"
                    >
                      Save as Draft
                    </button> */}
                    <button
                      type="submit"
                      className="btn successBtns waves-effect waves-light me-2 px-4"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <form className="needs-validation" noValidate>
                  <div className="row">
                    <div className="mb-3 col-12">
                      <div className="col-md-4 col-sm-12 col-12 float-start">
                        <div className="col-12 float-start mt-2 mt-md-4 mb-4">
                          <p className="addUserPic mx-auto mt-1 mb-1">
                            <span className="addPicIcon">
                              <i className="fas fa-pen"></i>
                            </span>
                            {!ImageUpload ? (
                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                name="media"
                                multiple="multiple"
                                accept="image/*"
                                onChange={(e) => ImageUploadWendians(e)}
                              />
                            ) : (
                              <div className="uplodedImg d-flex align-items-center rounded-circle">
                                <img
                                  src={ImageUpload}
                                  //src={`${BaseAPI.nodeAPI}/images/${ImageUpload.filename}`}
                                  className="img-fluid h-100"
                                  alt="das"
                                />
                                <br />
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="customFile"
                                  name="media"
                                  multiple="multiple"
                                  accept="image/*"
                                  onChange={(e) => ImageUploadWendians(e)}
                                />
                              </div>
                            )}
                            <label
                              className="custom-file-label mb-0"
                              htmlFor="customFile"
                            ></label>
                          </p>
                          <p className="text-center font14 mb-1 pt-3">
                            Profile Picture
                          </p>
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12 col-12 float-start">
                        <div className="row ps-0 ps-md-4 mt-2 mt-md-4">
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="emp_id">
                              Employee Id
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="emp_id"
                              name="emp_id"
                              placeholder="Enter Employee Id"
                              value={formValues?.emp_id}
                              required
                              onChange={handleChange}
                            />
                            <p className="text-danger">{formErrors?.emp_id}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="firstname">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstname"
                              name="firstname"
                              placeholder="Enter First Name"
                              value={formValues.firstname}
                              required
                              onChange={handleChange}
                            />
                            <p className="text-danger">
                              {formErrors.firstname}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="lastname">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              placeholder="Enter Last Name"
                              required
                              name="lastname"
                              value={formValues.lastname}
                              onChange={handleChange}
                            />
                            <p className="text-danger">{formErrors.lastname}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="contact">
                              Contact No.
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="contact"
                              name="contactno"
                              placeholder="Enter Contact No"
                              required
                              value={formValues.contactno}
                              onChange={handleChange}
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Contact No.
                            </div>
                            <p className="text-danger">
                              {formErrors.contactno}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="useremail">
                              E- Mail Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="useremail"
                              name="email"
                              placeholder="Enter email"
                              required
                              value={formValues.email}
                              onChange={handleChange}
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Email
                            </div>
                            <p className="text-danger">{formErrors.email}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="useraddress">
                              Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="useraddress"
                              name="address"
                              placeholder="Enter address"
                              required
                              value={formValues.address}
                              onChange={handleChange}
                            />
                            <p className="text-danger">{formErrors.address}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 my-4">
                        <div className="row">
                          <h6 className="heliosRounded robotofont fw-bold regSecondHead px-2">
                            ROLES
                          </h6>
                        </div>
                      </div>
                      <div className="col-12 float-start">
                        <div className="row">
                          <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="vendorApproval"
                                name="Consumer"
                                onChange={(e) => handlecheckbox(e)}
                                value="Consumer"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="vendorApproval"
                              >
                                Consumer
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="raiseRequest"
                                required
                                name="Partner"
                                onChange={(e) => handlecheckbox(e)}
                                value="Partner"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="raiseRequest"
                              >
                                Partner
                              </label>
                            </div>

                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="raiseRequest"
                                name="Subscription"
                                onChange={(e) => handlecheckbox(e)}
                                value="Subscription"
                                required
                              />
                              <label
                                className="form-check-label"
                                fohtmlForr="raiseRequest"
                              >
                                Subscription
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="chatWithCustomer"
                                name="Product"
                                onChange={(e) => handlecheckbox(e)}
                                value="Product"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chatWithCustomer"
                              >
                                Product
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="raiseRequest"
                                name="Ew Plans"
                                onChange={(e) => handlecheckbox(e)}
                                value="Ew Plans"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="raiseRequest"
                              >
                                Ew Plans
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="chatWithVendor"
                                name="Service Center"
                                onChange={(e) => handlecheckbox(e)}
                                value="Service Center"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chatWithVendor"
                              >
                                Service Center
                              </label>
                            </div>
                          </div>
                          <div className="col-xl-5 col-lg-6 col-md-5 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="chatWithRegionalManager"
                                name="Direct Connect"
                                onChange={(e) => handlecheckbox(e)}
                                value="Direct Connect"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chatWithRegionalManager"
                              >
                                Direct Connect
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="chatWithRegionalManager"
                                name="Ask the Expert"
                                onChange={(e) => handlecheckbox(e)}
                                value="Ask the Expert"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chatWithRegionalManager"
                              >
                                Ask the Expert
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="chatWithRegionalManager"
                                name="Banner Ads"
                                onChange={(e) => handlecheckbox(e)}
                                value="Banner Ads"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chatWithRegionalManager"
                              >
                                Banner Ads
                              </label>
                            </div>{" "}
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="chatWithRegionalManager"
                                name="Notification"
                                onChange={(e) => handlecheckbox(e)}
                                value="Notification"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chatWithRegionalManager"
                              >
                                Notification
                              </label>
                            </div>{" "}
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="chatWithRegionalManager"
                                name="Offer/Benefits"
                                onChange={(e) => handlecheckbox(e)}
                                // onChange={(e) => console.log(e.target.value)}
                                value="Offer/Benefits"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chatWithRegionalManager"
                              >
                                Offer/Benefits
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewWeStaff;
