/*eslint-disable*/
import React, { useState } from 'react'
import { Document, Page } from 'react-pdf';
import logoPartner from "../../../../assets/images/we-partner.jpg"
import imgFooter from "../../../../assets/images/pfooter.png"


const FaqChannelPaartnerpdf = () => {
  // //varibale for to show pdf
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // //function for pdf view 
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1)
  // }

  // //function for to change pages
  // function changePage(offSet) {
  //   setPageNumber(prevPageNumber => prevPageNumber + offSet)
  // }

  // //function for to change pages on back  button
  // function ChangePageBack() {
  //   changePage(-1)
  // }

  // //function for to change pdf pages on next buttopn 
  // function ChangePageNext() {
  //   changePage(+1)
  // }


  //JSX
  return (
    // <div className='pdfView'>
    //   <Document file={Faq} onLoadSuccess={onDocumentLoadSuccess}>
    //     <Page height="600" pageNumber={pageNumber}></Page>
    //   </Document>
    //   <p> Page {pageNumber} of {numPages}</p>
    //   <div className='pdfBtns position-absolute'>
    //     {pageNumber > 1 &&
    //       <button className='blueBtns' onClick={ChangePageBack}>Previous Page</button>
    //     }
    //     {
    //       pageNumber < numPages &&
    //       <button className='blueBtns' onClick={ChangePageNext}>Next</button>
    //     }
    //   </div>
    // </div>

    <div className="container custom-container">
      <div className="card p-5 mt-3">
        <div className="card-body">
          <div className="pdfContent p-xxl-5">
            <div className='pdfHeader'>
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img src={logoPartner} alt="" className="img-fluid logoPartner" />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h2 className='pdfheading text-center'>(FAQ) FREQUENTLY ASKED QUESTIONS</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                   <strong>1. What is “Warranty Express for Partners?</strong> 
                  </h4>
                  <p className='queText'>Warranty Express for partners is a unified, innovative mobile-based application-based solution for channel
                    partners to manage their business engagement with us. With the ease and comfort of a simplified mobile
                    application, our partners can carry out the following activities.
                  </p>
                  <div className="ms-xl-5 ms-3 mt-5">
                    <ul className='pdfList ms-3'>
                      <li><strong>Register</strong> as our valued “Channel Partner” (Seller, Service Center, or both)</li>
                      <li><strong>Link –</strong> Send links to the customer to register & view the completion status. This will link the customer
                        with the channel partner for engagement with WE. Earn extra by sending a link to the consumer as all
                        future transactions benefit the channel partner as Loyalty points (any transaction done by Consumers
                        like a subscription or Extended Warranty) you will receive points thereby enhancing your revenue
                        earnings.</li>
                      <li><strong>Sell –</strong> Sell Subscription packages and extended warranties to customers from our list of customized</li>
                      <li><strong>Income –</strong> View income earned via customer subscriptions, Extended Warranties, and loyalty coins, cash,
                        subscription packages, and Extended Warranty Plans all in one place. The income option also gives a
                        detailed consolidated income summary on earnings from Extended Warranties, Subscriptions, and
                        Alliance Fees under a single view.</li>
                      <li><strong>Benefits & Features -</strong> Avail exclusive discounted offers and plans, claim exclusive Rewards, and view
                        Rewards benefits earned on every single transaction.</li>
                      <li><strong>Vault –</strong> Monitor the links sent & conversion statuses of registration links, and subscriptions Extended
                        warranties product-wise. It displays all the transactions carried out by a customer and the channel
                        partner.</li>
                      <li><strong>Leads –</strong> View sales and service leads assigned to the channel partners based on customer inquiries
                        received from customers nearby. This feature adds value to our channel partners. Received services &
                        purchase queries are easily convertible sales.</li>
                      <li><strong>Direct Connect –</strong> A dedicated Relationship Manager for assistance at your service as per the subscription
                        plan.</li>
                      <li><strong>Piggy Bank -</strong> Exclusive Loyalty Programs -Earn WECoins and redeem against goodies and use cash for
                        future purchases. Also, view monthly earnings via sales, services, and loyalty earnings. Partners can also
                        view the transactions carried out by the customers and the monthly payout details. Monthly earned
                        income will be visible as WeCash and will be credited on a month-on-month basis to the partners’
                        designated bank account!</li>
                      <li><strong>Account</strong> Can view a personal profile, view a list of multiple location addresses, and renewal
                        Instructions, Upload testimonies, and view the list of registered cards with us.</li>
                    </ul>
                  </div>
                </div>

                <div className="col-12">
                  <h4 className='queHeading my-5'>
                   <strong> 2. What is the WE Partner App?</strong>
                  </h4>
                  <p className='queText'>WE Partner App is a mobile app designed for Warranty Express channel partners to register & subscribe to our
                    service offerings, manage customer onboarding, and transaction summary, and view monthly business
                    generated, and payout-related information. In short, the WE Partner app helps our business partners to
                    manage their entire business with us in a simplified mobile application.
                  </p>
                </div>
              </div>
            </div>
            <div className='pdfFooter mt-5'>
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className='queHeading fst-normal'>
                    Ver 1.1
                  </h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card p-5 mt-3">
        <div className="card-body">
          <div className="pdfContent p-xxl-5">
            <div className='pdfHeader'>
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img src={logoPartner} alt="" className="img-fluid logoPartner" />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong>3. Why partner app required for Seller / Service Centre?</strong>
                  </h4>
                  <p className='queText'>WE Partner App is a useful tool that helps our channel partners easily manage their business association with
                    Warranty Express! Partners can manage their subscriptions, renew subscriptions, and send an invitation link
                    to customers to purchase subscriptions and extended warranty plans that meet a customer post-purchase
                    assistance-related requirement.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 4. What is different between Seller, Service center & Both?</strong>
                  </h4>
                  <p className='queText'>A seller is a Warranty Express registered outlet or a business that only sells electronic products whereas a
                    service partner only provides post-purchase service assistance to our customers. Partners who sell electronic
                    products as well as provide post-purchase assistance for electronic products.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong>  5. Why Channel Partner KYC & Contact/Owner KYC?</strong>
                  </h4>
                  <p className='queText'><strong>KYC means</strong> to “Know Your Customer”. It’s an effective way for an institution to confirm and thereby verify the
                    authenticity of a business entity & the owner. For this, the partner is required to submit all KYC documentation
                    before investing in various instruments. All financial institutions are mandated by the RBI to do the KYC process
                    for all customers before giving them the right to carry out any financial transactions. Whether the customer
                    uses KYC online verification or opts for offline KYC, this is a simple one-time process. KYC secures the business
                    relationship and felicitates genuine and authentic business transactions.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 6. Why Channel partners are not able to see the screen after the Registration?</strong>
                  </h4>
                  <p className='queText'>Once the Channel Partner completes the registration process, an action process is carried out in the backend
                    post verification. This activity is done once the registration process is completed. Post the registration process,
                    the account is activated by our Partner On-boarding team from the backend. This process might take a few
                    minutes to an hour in exceptional cases for activation. Your designated Relations Managers will assist to
                    complete the account activation.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 7. How to select the subscription and activate the subscription?</strong>
                  </h4>
                  <p className='queText'>Once the channel partner is registered an account is activated, login to the WE Channel partner Mobile app.
                    On the landing page, select “Subscriptions”, choose the subscription package that suits your business
                    requirement, continue to make the payment, and complete the payment. Once the payment is completed,
                    your subscription is activated. Your Relationship Manager will assist you in subscription activation in case of
                    any challenges.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 8. What are the types of subscription packages offered to Channel Partners?</strong>
                  </h4>
                  <p className='queText'>Warrant Express offers exclusive subscription packages that perfectly meet every channel partner depending
                    on the business volumes and customer footfall.
                    <ol className="numberStyle ms-xl-5 ms-3 my-4">
                      <li>Beginner</li>
                      <li>Associate</li>
                      <li>Expert</li>
                      <li>Preferred</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
            <div className='pdfFooter mt-5'>
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className='queHeading fst-normal'>
                    Ver 1.1
                  </h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card p-5 mt-3">
        <div className="card-body">
          <div className="pdfContent p-xxl-5">
            <div className='pdfHeader'>
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img src={logoPartner} alt="" className="img-fluid logoPartner" />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 9. Does the subscription gets renewed automatically?</strong>
                  </h4>
                  <p className='queText'>Yes. If the channel partner had enabled “Auto Renewal” at the time of initial subscription, the Subscription
                    gets auto-renewed at the time of renewal. Else, the Partner will receive a friendly notification alert 7 days
                    before subscription renewals.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 10. What are the charges applicable for Channel Partners?</strong>
                  </h4>
                  <p className='queText'>There is a one-time set-up fee & a refundable security deposit as a part of the onboarding process. Depending
                    on the subscription package opted by the merchant, an annual subscription fee is applicable.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 11. What is one time set fee?</strong>
                  </h4>
                  <p className='queText'><strong>KYC means</strong> A one-time setup fee is a non-refundable nominal fee applied while onboarding a partner. This includes the
                    cost of setting up an account and providing a mobile application to manage the overall WE-related business
                    operations for the partner. The one-time setup fee is applicable only at the time of the onboarding fee and
                    does not re-occur throughout our business engagement. A one-time setup fee for Software-as-a-Service is
                    identified in the applicable Order Document or Pricing Summary for each Software application. This setup
                    charge is non-refundable, non-transferable
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 12. What is a security deposit?</strong>
                  </h4>
                  <p className='queText'>A security deposit is a refundable amount paid by the partner to WE at the time of onboarding and is
                    refundable at the time when the business partner opts out or ends the business contact with, WE. A security
                    deposit is kept as refundable collateral for the services and mobile platform provided by, WE. A refundable
                    Security Deposit is the sum payable by the channel Partner to cover any breakages, damages, or minor repairs
                    to the consumer side which may be incurred while servicing or sale of the product.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 14. What is Product Margin?</strong>
                  </h4>
                  <p className='queText'>Product margin refers to the profit margin of a specific product. It is used to determine the markup of a
                    particular product, which can help businesses identify which of the products churns maximum revenue versus
                    the actual cost of the product. Our Partners can enjoy excellent product margins across our portfolio of
                    products and services. Please reach out to your relationship manager for more information.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong>15. What is loyalty?</strong>
                  </h4>
                  <p className='queText'>Loyalty is an additional earning in the form of coins and cash that can be redeemed against the purchase of
                    gifts or to renew, or upgrade subscriptions packages
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong>16. What is the difference between Product Margin & Loyalty?</strong>
                  </h4>
                  <p className='queText'>A product margin is a profit that a business earns when involved in selling a product or a service. This is the
                    difference in profit when calculated on revenue earned after-sales versus the actual cost of the product.
                    Whereas Loyalty is earned over and above the product margin. Loyalty is earned in WECash & WECoins which
                    has revenue value however can be used to redeem gift items or purchase subscription plans or extended
                    warranty. Product margin is the actual revenue generated when a product is sold whereas loyalty is an
                    additional earning in the form of a digital earning which can be used exclusively & only on the Warranty
                    Express platform.
                  </p>
                </div>
              </div>
            </div>
            <div className='pdfFooter mt-5'>
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className='queHeading fst-normal'>
                    Ver 1.1
                  </h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card p-5 mt-3">
        <div className="card-body">
          <div className="pdfContent p-xxl-5">
            <div className='pdfHeader'>
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img src={logoPartner} alt="" className="img-fluid logoPartner" />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 17. What is a service agreement?</strong>
                  </h4>
                  <p className='queText'>A written Service Agreement is a contract that defines the terms and conditions of a working arrangement
                    between a contractor and their client. This type of contract outlines a service that a contractor provides to a
                    client in exchange for compensation
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong>18. Is the business agreement the same for all the 3-channel partner categories?</strong>
                  </h4>
                  <p className='queText'>Yes. The business agreement is different for all the 3 channel partner categories. An agreement is defined
                    based on the type of business engagement that the channel partner is engaged with us. A selling partner
                    will have a different agreement as compared to a service partner. Whereas the agreement for a partner
                    who is engaged in both sales & Service will have a specifically defined business agreement. Agreement
                    differs based on the type of business engagement e: 1) Only Sales 2) Only Service 3) Both Sales & Services.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong>19. Does the partner agreement gets renewed?</strong>
                  </h4>
                  <p className='queText'>Yes. Channel Partner Agreements are signed for a period of 1 year from the date of commencement.
                    Business agreements are reviewed, updated, and or amended based on the business requirements and as
                    per the Subscription memberships.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong>20. Is Subscription important?</strong>
                  </h4>
                  <p className='queText'>Yes. Subscription defined the membership type and avail exclusive value additions and privileges that our
                    partners can enjoy throughout your association with us. Subscriptions entitle you to a higher percentage in
                    earnings for every transaction that a customer carries out on the WE Platform. Eg: An Elite Partner
                    subscription entitles larger returns on sales, and services and avail priority services and better loyalty
                    programs,
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 22. What if a Channel Partner chooses to downgrade the plan at the time of renewal?</strong>
                  </h4>
                  <p className='queText'>In case a channel partner chooses to downgrade the plan at the time of renewal, he will not be entitled to
                    avail of the benefits offered along with the previous subscription package.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong>  23. If a channel Partner does not renew the business agreement, can he redeem the loyalty earned on his registered
                    number?</strong>
                  </h4>
                  <p className='queText'>No. To redeem the Loyalty earned (WECash & coins), it’s mandatory to have an active subscription. In case the
                    agreement is not renewed, all the benefits will get lapsed and the partner will not be able to redeem the loyalty
                    points earned.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 24. What type of training support can a channel partner get from “Warranty Express”? </strong>
                  </h4>
                  <p className='queText'>We provide Training support for all Channel Partners and assist them to optimize their productivity.</p>
                  <p className='queText my-3'>
                    <strong>1) Standard Training -</strong> Face-to-face training and e-learning training materials will be provided by WE for
                    Channel Partners post onboarding & activation.
                  </p>
                  <p className='queText'>
                    <strong>2) Customized Training programs -</strong> Custom training engagements can also be contracted through “WE” in the
                    form of any new offer launch or for any new segment introduced by the “WE”. CP may not allow anyone else to
                    access the e-training courses via your log-in details
                  </p>
                </div>
              </div>
            </div>
            <div className='pdfFooter mt-5'>
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className='queHeading fst-normal'>
                    Ver 1.1
                  </h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card p-5 mt-3">
        <div className="card-body">
          <div className="pdfContent p-xxl-5">
            <div className='pdfHeader'>
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img src={logoPartner} alt="" className="img-fluid logoPartner " />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className='queHeading my-5'>
                  <strong> 25. Is Training mandatory? </strong>
                  </h4>
                  <p className='queText'>Yes. Training is mandatory. It gives a better insight into how channel partners can benefit from our business
                    engagement with a detailed idea of how our products work, understanding earning potential, and how to
                    manage and support leads and sales by incorporating Warranty Express as a value proposition to customers
                    and enhancing overall profitability!
                  </p>
                </div>
              </div>
            </div>
            <div className='pdfFooter footerCustomHeight mt-5'>
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className='queHeading fst-normal'>
                    Ver 1.1
                  </h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqChannelPaartnerpdf