/*eslint-disable*/
import React, { useEffect, useState } from "react";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import axios from "axios";
import { toast } from "react-toastify";
const AddIndianPlansDetails = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const params = useParams();
  const [IndianewplanDetails, setIndianewplanDetails] = useState();
  const [PrisingPlanDetails, setPrisingPlanDetails] = useState([]);
  const [modified_array, setmodified_array] = useState([]);
  const [Loading, setLoading] = useState(false)
  

  // usestate for subcategory listing api
  const [SubcategoryList, setSubcategoryList] = useState();
  // Api call for to get only feature indian ewplans details
  useEffect(() => {
    var data = "";

    var config = {
      method: "get",
      url: `${BaseAPI.nodeAPI}ew_plan/${params.ewplanindianid}`,
      headers: {
        Authorization: adminauth?.data?.token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("IndianewplanDetails", response?.data?.data);
        setIndianewplanDetails(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const getPricingPlanDetails = () => {
    setLoading(true)
    var data = {
      filterData: {
        ew_plan_id: params.ewplanindianid,
      },
    };
    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ew_discount/list_admin`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("pricingplan details", response?.data?.data);
        setPrisingPlanDetails(response?.data?.data);
        setLoading(false)
        const modify = response?.data?.data.map((obj) => ({
          subcategory_id: obj.subcategory_id._id,
          ew_plan_month: obj.ew_plan_month,
          discount_percentage: obj.discount_percentage,
          ew_plan_id: params.ewplanindianid,
          _id: obj?._id
        }));

        setmodified_array(modify);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
      });
  };
  //  Api call for only pricing plan details as per the id
  useEffect(() => {
    getPricingPlanDetails();
    getSubcategoryList();
  }, []);

  console.log("modified array", modified_array);
  //console.log("ewplanid", params.ewplanindianid)

  // APi call for subcategory Listing in pricing plans
  const getSubcategoryList = () => {
    var data = JSON.stringify({
      filterData: {
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}subcategory/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("subcategorylisting", response?.data?.data);
        setSubcategoryList(response?.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //================adding new object in modified array=================//
  const handleAddDuplicate = () => {
    console.log("row added >>>>>>");

    setmodified_array([
      ...modified_array,
      {
        subcategory_id: "",
        ew_plan_month: "",
        discount_percentage: "",
        ew_plan_id: "",

      },
    ]);
    setPrisingPlanDetails([
      ...PrisingPlanDetails,
      {
        subcategory_id: "",
        ew_plan_month: "",
        discount_percentage: "",
        ew_plan_id: "",

      },
    ]);
  };

  //api call to update pricing plan
  const handleUpdatePricingPlan = async () => {
    // console.log("paramsid",params.ewplanindianid)
    var final_list = modified_array.map((obj) => ({
      _id: obj?._id,
      subcategory_id: obj.subcategory_id,
      ew_plan_month: obj.ew_plan_month,
      discount_percentage: obj.discount_percentage,
      ew_plan_id: params.ewplanindianid,
    }));
    console.log("final_list", final_list);
    var data = JSON.stringify({
      ew_product_discount_data: final_list,
    });

    var config = {
      method: "put",
      url: `${BaseAPI.nodeAPI}ew_discount/update`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        getPricingPlanDetails();
        toast.success(response.data.message, { position: 'top-right' });
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: 'top-right' });
      });
  };

  // =====================updating values======================//

  const first_value = (value, i) => {
    console.log("first", value, i);
    console.log("modified array", modified_array);

    modified_array[i].subcategory_id = value;
  };
  const second_value = (value, i) => {
    console.log("second", value, i);
    console.log("modified array", modified_array);

    modified_array[i].ew_plan_month = parseInt(value);
  };
  const third_value = (value, i) => {
    console.log("third", value, i);
    console.log("modified array", modified_array);

    modified_array[i].discount_percentage = parseFloat(value);
  };

  //===========================jsx=============================
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Extended Warranty Plans</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-4 pageBack d-flex">
                    <NavLink to={AdminRoutes.EwPlans}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      Indian EW Plan Details
                    </p>
                  </div>

                  <div className="col-8 d-flex justify-content-end">
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Save as Draft
                    </button> */}
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    {/* <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                      Submit
                    </NavLink> */}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="bookTitle">
                            EW Plan Title :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bookTitle"
                            placeholder="Enter EW Plan Title :"
                            defaultValue={IndianewplanDetails?.title}
                          />
                        </div>
                        <div className="col-md-3 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Validity :
                          </label>
                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control"
                              id="noOfPages"
                              placeholder="Enter Validity"
                              defaultValue={IndianewplanDetails?.validity}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Duration :
                          </label>
                          <div className="d-flex">
                            <select
                              className="form-select planSelect w-25"
                              aria-label="Default select example"
                            >
                              <option
                                value="Hrs"
                                selected={IndianewplanDetails?.mon_yr === "Hrs"}
                              >
                                Hrs
                              </option>
                              <option
                                value="Days"
                                selected={
                                  IndianewplanDetails?.mon_yr === "Days"
                                }
                              >
                                Days
                              </option>
                              <option
                                value="Months"
                                selected={
                                  IndianewplanDetails?.mon_yr === "Months"
                                }
                              >
                                Months{" "}
                              </option>
                              <option
                                value="Years"
                                selected={
                                  IndianewplanDetails?.mon_yr === "Years"
                                }
                              >
                                Years
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start mb-2">
                            <h6 className="fw-bold">Features</h6>
                          </div>

                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0"></th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Visit Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Included"
                                                selected={
                                                  IndianewplanDetails?.visit_charges ===
                                                  "Included"
                                                }
                                              >
                                                {" "}
                                                Included
                                              </option>
                                              <option
                                                value="Excluded"
                                                selected={
                                                  IndianewplanDetails?.visit_charges ===
                                                  "Excluded"
                                                }
                                              >
                                                {" "}
                                                Excluded{" "}
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>Labour & Service Fees</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Included"
                                                selected={
                                                  IndianewplanDetails?.labour_fees ===
                                                  "Included"
                                                }
                                              >
                                                Included
                                              </option>
                                              <option
                                                value="Excluded"
                                                selected={
                                                  IndianewplanDetails?.labour_fees ===
                                                  "Excluded"
                                                }
                                              >
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>
                                            Cost of Spare Parts due to
                                            manufacturing defect
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Included"
                                                selected={
                                                  IndianewplanDetails?.cost_of_spare_part ===
                                                  "Included"
                                                }
                                              >
                                                Included
                                              </option>
                                              <option
                                                value="Excluded"
                                                selected={
                                                  IndianewplanDetails?.cost_of_spare_part ===
                                                  "Excluded"
                                                }
                                              >
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>Number of Service Requests</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Unlimited"
                                                selected={
                                                  IndianewplanDetails?.no_of_service_req ===
                                                  "Unlimited"
                                                }
                                              >
                                                Unlimited
                                              </option>
                                              <option
                                                value="Limited"
                                                selected={
                                                  IndianewplanDetails?.no_of_service_req ===
                                                  "Limited"
                                                }
                                              >
                                                Limited
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>
                                            Prod. Rep incase beyond repair due
                                            to manufacturing defect
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Ref UDV"
                                                selected={
                                                  IndianewplanDetails?.manufacturing_defect ===
                                                  "Ref UDV"
                                                }
                                              >
                                                Ref UDV
                                              </option>
                                              <option
                                                value="New UOV"
                                                selected={
                                                  IndianewplanDetails?.manufacturing_defect ===
                                                  "New UOV"
                                                }
                                              >
                                                New UOV
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>
                                            Damage Repair Services incase of
                                            accidental and liquid damage
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Included"
                                                selected={
                                                  IndianewplanDetails?.liquid_damage ===
                                                  "Included"
                                                }
                                              >
                                                Included
                                              </option>
                                              <option
                                                value="Excluded"
                                                selected={
                                                  IndianewplanDetails?.liquid_damage ===
                                                  "Excluded"
                                                }
                                              >
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>7</td>
                                          <td>
                                            Screen Repair Services incase of
                                            accidental and liquid damage
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Included"
                                                selected={
                                                  IndianewplanDetails?.screen_repair ===
                                                  "Included"
                                                }
                                              >
                                                Included
                                              </option>
                                              <option
                                                value="Excluded"
                                                selected={
                                                  IndianewplanDetails?.screen_repair ===
                                                  "Excluded"
                                                }
                                              >
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>8</td>
                                          <td>
                                            Discount on spare parts incase of
                                            damage repair services
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="0"
                                                selected={
                                                  IndianewplanDetails?.discount_of_spare_part
                                                  === 0                                                }
                                              >
                                                NA
                                              </option>
                                              <option
                                                value="15%"
                                                selected={
                                                  IndianewplanDetails?.discount_of_spare_part ===
                                                  15
                                                }
                                              >
                                                15%
                                              </option>
                                              <option
                                                value="35%"
                                                selected={
                                                  IndianewplanDetails?.discount_of_spare_part ===
                                                  35
                                                }
                                              >
                                                35%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>9</td>
                                          <td>
                                            Prod. Rep. incase beyond repair due
                                            to any kind of damage
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Included"
                                                selected={
                                                  IndianewplanDetails?.any_kind_of_damage ===
                                                  "Included"
                                                }
                                              >
                                                Included
                                              </option>
                                              <option
                                                value="Excluded"
                                                selected={
                                                  IndianewplanDetails?.any_kind_of_damage ===
                                                  "Excluded"
                                                }
                                              >
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>10</td>
                                          <td>
                                            Damage Repair Services arising due
                                            to electrical short circuit
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Included"
                                                selected={
                                                  IndianewplanDetails?.electrical_short_circuit ===
                                                  "Included"
                                                }
                                              >
                                                Included
                                              </option>
                                              <option
                                                value="Excluded"
                                                selected={
                                                  IndianewplanDetails?.electrical_short_circuit ===
                                                  "Excluded"
                                                }
                                              >
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>11</td>
                                          <td>Remote Control Repair</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="Included"
                                                selected={
                                                  IndianewplanDetails?.remote_control_repair ===
                                                  "Included"
                                                }
                                              >
                                                Included
                                              </option>
                                              <option
                                                value="Excluded"
                                                selected={
                                                  IndianewplanDetails?.remote_control_repair ===
                                                  "Excluded"
                                                }
                                              >
                                                Excluded
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* ======================edit update============================ */}
                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Pricing Plans</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Products
                                          </th>
                                          <th className="border-top-0">
                                            Months
                                          </th>
                                          <th className="border-top-0">
                                            Percentage
                                          </th>
                                          <th className="border-top-0"></th>

                                          <th className="border-top-0">
                                            <i
                                              className="fas fa-plus-circle text-white h3"
                                              onClick={() =>
                                                handleAddDuplicate()
                                              }
                                            ></i>
                                          </th>
                                        </tr>
                                      </thead>
                                      {/* =================================     inputs   ========================================= */}
                                      {Loading ?
                                        <div className="spinner-grow text-info position-absolute tableSpinner" role="status">
                                          <span className="visually-hidden">Loading...</span>
                                        </div>
                                        :

                                        <tbody>
                                          <>
                                            {PrisingPlanDetails?.length &&
                                              PrisingPlanDetails?.map(
                                                (val, index) => {
                                                  return (
                                                    <>
                                                      <tr>
                                                        <td>{index + 1}</td>

                                                        {/* ===============first input================= */}
                                                        {val?.subcategory_id?.name
                                                          ?
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control me-3"
                                                              id="noOfPages"
                                                              placeholder="Enter Product"
                                                              defaultValue={val?.subcategory_id?.name}

                                                            />
                                                          </td>
                                                          :
                                                          <td>
                                                            <select
                                                              className="form-select planSelect w-100"
                                                              aria-label="Default select example"
                                                              defaultValue={
                                                                val?.subcategory_id
                                                                  ?.name
                                                              }
                                                              onChange={(e) =>
                                                                first_value(
                                                                  e.target.value,
                                                                  index
                                                                )
                                                              }
                                                            // onClick={() =>
                                                            //   getSubcategoryList()
                                                            // }
                                                            >
                                                              <option
                                                                selected
                                                                value={
                                                                  val
                                                                    ?.subcategory_id
                                                                    ?.name
                                                                }
                                                              >
                                                                {
                                                                  val
                                                                    ?.subcategory_id
                                                                    ?.name
                                                                }
                                                              </option>
                                                              <option selected>
                                                                Select
                                                              </option>
                                                              {SubcategoryList?.map(
                                                                (val) => (
                                                                  <>
                                                                    <option
                                                                      id={val?._id}
                                                                      value={
                                                                        val?._id
                                                                      }
                                                                    >
                                                                      {val?.name}
                                                                    </option>
                                                                  </>
                                                                )
                                                              )}
                                                            </select>
                                                          </td>
                                                        }
                                                        {/* ===============second input================= */}
                                                        {val?.ew_plan_month
                                                          ?
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control me-3"
                                                              id="noOfPages"
                                                              placeholder="Enter Product"
                                                              defaultValue={val?.ew_plan_month}

                                                            />
                                                          </td>
                                                          :

                                                          <td>
                                                            <select
                                                              className="form-select planSelect pricingPlan float-start"
                                                              aria-label="Default select example"
                                                              name="months"
                                                              onChange={(e) =>
                                                                second_value(
                                                                  e.target.value,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <option selected>
                                                                Select
                                                              </option>
                                                              <option
                                                                value="6"
                                                                selected={
                                                                  val?.ew_plan_month ===
                                                                  6
                                                                }
                                                              >
                                                                6 Months
                                                              </option>
                                                              <option
                                                                value="12"
                                                                selected={
                                                                  val?.ew_plan_month ===
                                                                  12
                                                                }
                                                              >
                                                                12 Months
                                                              </option>
                                                              <option
                                                                value="18"
                                                                selected={
                                                                  val?.ew_plan_month ===
                                                                  18
                                                                }
                                                              >
                                                                {" "}
                                                                18 Months{" "}
                                                              </option>
                                                              <option
                                                                value="24"
                                                                selected={
                                                                  val?.ew_plan_month ===
                                                                  24
                                                                }
                                                              >
                                                                24 Months
                                                              </option>
                                                              <option
                                                                value="30"
                                                                selected={
                                                                  val?.ew_plan_month ===
                                                                  30
                                                                }
                                                              >
                                                                30 Months{" "}
                                                              </option>
                                                              <option
                                                                value="36"
                                                                selected={
                                                                  val?.ew_plan_month ===
                                                                  36
                                                                }
                                                              >
                                                                {" "}
                                                                36 Months
                                                              </option>
                                                            </select>
                                                          </td>

                                                        }
                                                        {/* ===============third input================= */}

                                                        <td>
                                                          <input
                                                            type="number"
                                                            min="0"
                                                            step="any"
                                                            className="form-control"
                                                            id="noOfPages"
                                                            name="Percentage"
                                                            placeholder="Enter Percentage"
                                                            defaultValue={
                                                              val?.discount_percentage
                                                            }
                                                            onChange={(e) =>
                                                              third_value(
                                                                e.target.value,
                                                                index
                                                              )
                                                            }
                                                          />
                                                        </td>
                                                      </tr>
                                                    </>
                                                  );
                                                }
                                              )}
                                          </>
                                        </tbody>

                                      }
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ====================== buttons  ============================== */}
                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                      onClick={(e) => handleUpdatePricingPlan()}
                    >
                      Update & Submit
                    </button>
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    {/* <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                    </NavLink>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddIndianPlansDetails;
