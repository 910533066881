/*eslint-disable*/
import React from "react";
import { useNavigate } from "react-router-dom";

const AdminLogout = () => {
  // Variables
  const navigate = useNavigate();

  // Handle Submit
  const handleSubmit = (event) => {
    localStorage.removeItem("adminauth");
    localStorage.removeItem('isChecked');
    setTimeout(() => navigate("/admin-login"));
  };

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Admin</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="row pageHeading">
              <div className="mb-3 col-12">
                <h4 className="text-uppercase fw-bold">Logout</h4>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="row pageBack">
                  <a href="/#">
                    <i className="fas fa-arrow-left"></i>
                  </a>
                </div>

                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="col-12 float-start mt-3">
                        <h3 className="blueText">
                          Are you sure you want to logout?
                        </h3>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="col-12 float-start">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 px-2 mb-3">
                          <button
                            type="submit"
                            className="btn blueBtns borderradius10 waves-effect waves-light px-5 float-end"
                            onClick={handleSubmit}
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogout;
