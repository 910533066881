/*eslint-disable*/

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import axios from "axios";

const Notifications = () => {
  // date time 
  const CurrentTime = moment().format('LT'); // htmlFor current Time
  const CurrentDate = moment().format('L'); // htmlFor current Date
  //auth
  const adminauth = JSON.parse(localStorage.getItem('adminauth'));
  const [ListData, setListData] = useState("");
  const [Loading, setLoading] = useState(false)

  useEffect(() => {
    getNotificationListData(4)

  }, []);

  const getNotificationListData = (va) => {
    setLoading(true)
    try {
      var data = JSON.stringify({
        "access_login": va
      });

      var config = {
        method: 'post',
        url: `${BaseAPI.nodeAPI}notification/list`,
        headers: {
          'Authorization': adminauth?.data?.token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log(response?.data?.data);
          setListData(response?.data?.data);
          setLoading(false)
        })
    }
    catch (error) {
      console.log(error);
      setLoading(false)
    };

  }

  // console.log("DATAEAND TIME", {moment(ListData[0]?.createdAt).format('LLLL')})


  //jsx  
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Notifications</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 pageBack d-flex mb-4">
                    <div className="float-start col-6">
                      <a className="" href="#/">
                        <i className="fas fa-arrow-left text-dark" aria-hidden="true"></i>
                      </a>
                      <span className="fw-bold h5 ps-3">All Notifications</span>
                    </div>
                    <div className="col-6">
                      <NavLink
                        to="/CreateNewNotification"
                        className="btn addBtns waves-effect waves-light ms-3 float-end">
                        <i className="fas fa-plus me-2"></i>
                        Create New Notification
                      </NavLink>
                    </div>
                  </div>

                  {/* <!-- Nav tabs --> */}
                  <ul
                    className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#consumerNote"
                        role="tab"
                        onClick={(e) => getNotificationListData(4)}
                      >
                        <span>Consumer Notification</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#partnerNote"
                        role="tab"
                        onClick={(e) => getNotificationListData(5)}
                      >
                        <span>Partner Notification</span>
                      </a>
                    </li>
                  </ul>

                  {/* <!-- Tab panes --> */}
                  <div className="tab-content text-muted mt-3">
                    <div
                      className="tab-pane active"
                      id="consumerNote"
                      role="tabpanel"
                    >
                      {Loading ? (
                        <div className="spinner-grow text-info position-absolute tableSpinner" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <>
                          {!ListData?.length

                            ?
                            <>
                              <span className="d-block text-center p-2 border">Data not found</span>
                            </>
                            :

                            <>

                              {ListData?.length && ListData?.map((val, index) => {
                                return (
                                  <div className="mb-3 col-12">
                                    <div className="row mt-5 notification_title p-4">
                                      <div className="col-md-2">
                                        <p>Date</p>
                                        <p className="fw-bold blueText">{moment(val?.createdAt).format('LLLL')}</p>
                                      </div>
                                      <div className="col-md-6">
                                        <p>Title</p>
                                        <p className="fw-bold blueText notification_text">{val?.title}</p>
                                      </div>
                                      <div className="col-md-2">
                                        <p>Notification Type</p>
                                        <p className="fw-bold blueText notification_text">{val?.notif_type}</p>
                                      </div>
                                      <div className="col-md-2">
                                        <p>Recipient Group</p>
                                        {val.group_name
                                        ?
                                        <p className="fw-bold blueText notification_text">{val?.group_name}</p>
                                        :
                                        <p className="fw-bold blueText notification_text ml-3">-----------</p>
                                        }
                                      </div>
                                    </div>
                                    <div className="row notification_details p-5">
                                      <div className="col-md-2">

                                        {val?.file ?
                                          <div className="img_box">
                                            <img
                                              src={val?.file}
                                              className="img-fluid h-100"
                                              alt="das"
                                            />
                                          </div>
                                          :
                                          <div className="img_box">
                                            <i className="fa-regular fa-images"></i>
                                          </div>
                                        }


                                      </div>
                                      <div className="col-md-10">
                                        <p>Notification Details</p>
                                        <p className="fw-bold blueText notification_text">{val?.description}</p>
                                      </div>
                                    </div>

                                  </div>
                                )
                              })}
                            </>
                          }
                        </>
                      )

                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
