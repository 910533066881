/*eslint-disable*/
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import FaqC from "../auth/login/SharedConsumerPDF/FAQC.pdf";
import PDFViewer from "pdf-viewer-reactjs";
import logoPartner from "../../../assets/images/we-logo-consumer.jpg";
import imgFooter from "../../../assets/images/pfooter.png";
const ConsumerFAQPDF = () => {
  // //varibale for to show pdf
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // //function for pdf view
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1)
  // }

  // //function for to change pages
  // function changePage(offSet) {
  //   setPageNumber(prevPageNumber => prevPageNumber + offSet)
  // }

  // //function for to change pages on back  button
  // function ChangePageBack() {
  //   changePage(-1)
  // }

  // //function for to change pdf pages on next buttopn
  // function ChangePageNext() {
  //   changePage(+1)
  // }

  //jsx
  return (
    // <div className='pdfView'>
    //   <Document file={FaqC} onLoadSuccess={onDocumentLoadSuccess}>
    //     <Page height="600" pageNumber={pageNumber}></Page>
    //   </Document>
    //   <p> Page {pageNumber} of {numPages}</p>
    //   <div className='pdfBtns position-absolute'>
    //     {pageNumber > 1 &&
    //       <button className='blueBtns' onClick={ChangePageBack}>Previous Page</button>
    //     }
    //     {
    //       pageNumber < numPages &&
    //       <button className='blueBtns' onClick={ChangePageNext}>Next</button>
    //     }
    //   </div>
    // </div>

    <div className="container custom-container">
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h2 className="pdfheading text-center">
                    (FAQ) FREQUENTLY ASKED QUESTIONS
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    1. What is Warranty Express?
                  </h4>
                  <p className="queText">
                    Warranty Express is a unified, innovative mobile based
                    application-based solution for consumers to manage
                    hassle-free post purchase care of their priceless devices &
                    appliances. A user once registered with Warranty Express can
                    carry the following activities.
                  </p>
                  <div className="ms-xl-5 ms-3 mt-5">
                    <ul className="pdfList ms-3">
                      <li>
                        <strong>Register</strong> as “Indian Resident user” as
                        well as “NRI User” and mange device post purchase care
                        for devices under a single platform.
                      </li>
                      <li>
                        <strong>Vault -</strong> Securely upload & preserve all
                        device and home appliances related purchase invoices,
                        warrant certificates documents at a single location for
                        easy access and future references.
                      </li>
                      <li>
                        <strong>Add Products Documents -</strong> Provision to
                        upload & save all devices & home appliancesrelated
                        important documents online onto the vault. Users can
                        upload copies of all device related purchase invoices,
                        warranty certificates, OEM Warranties, any other
                        warranties at a single place and access anytime,
                        anywhere.
                      </li>
                      <li>
                        <strong>Ask The Expert -</strong> Value proposition to
                        check for best offers near you. Enables the users to
                      </li>
                      <li>
                        <strong>Buy Product Plans -</strong> Opti-in for
                        exclusive Subscription plans & extended warranties, Gift
                        an Extended Warranty.
                      </li>
                      <li>
                        <strong>Gift an Extended Warranty -</strong> Gift your
                        loved ones a memorable experience of a life time! - Gift
                        a device / appliance along with extended warranty from
                        us.
                      </li>
                      <li>
                        <strong>Raise Service Requests -</strong> Track and view
                        historic requests
                      </li>
                      <li>
                        <strong>Search Service Centers -</strong> Locate and
                        connect with nearest service centers
                      </li>
                      <li>
                        <strong>My Addresses -</strong> Flexibility{" "}
                        <strong>to</strong> Manage multiple locations & multiple
                        devices at these locations on a unified platform.
                      </li>
                      <li>
                        <strong>Add Access -</strong> Grant access to family
                        &Local access to members to manage registered devices
                        with full access or product wise access at a specific
                        address.
                      </li>
                      <li>
                        <strong>Transfer Extended Warranty -</strong> Enhance
                        resale value of your registered devices as the warrant
                        can be transferred to the purchaser.
                      </li>
                      <li>
                        <strong>Direct Connect -</strong> A dedicate
                        Relationship Managers & Expert Technicians for
                        assistance as per subscription plan.
                      </li>
                      <li>
                        <strong>Transaction History -</strong> Provision to view
                        all transactions carried out like purchases, gifting,
                        transfers, and also reflects all active & inactive
                        extended warranties related transactions
                      </li>
                      <li>
                        <strong>Piggy Bank -</strong> Exclusive Loyalty Programs
                        -Earn <strong>WECoins / WECash</strong> and redeem
                        against goodies and use WEcash for future purchases.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    2. What do I do if I receive an error while registration?
                  </h4>
                  <p className="queText">
                    WE can assist you to complete your registration process.
                    Please reach our Toll free number{" "}
                    <strong>+91 022 4605 3324 </strong>
                    for assistance or simply email us to{" "}
                    <a className="text-decoration-underline" href="mailto:https://www.auxill.in/">
                      contactus@warrantyexpress.in
                    </a>{" "}
                    along with your contact number . Our experts will reach you
                    within 24hrs with a resolution.
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    3. Why is "User Registration" important?
                  </h4>
                  <p className="queText">
                    User registration systems are screens, forms, or profile
                    pages that request information from a user to create a
                    web-based account or profile. A user registration system
                    generally asks a user to create a username and login with
                    OTP to avail any services online. “User Registration" plays
                    a vital role in managing your subscription with us.
                    Registering as a unique user helps you to access your own
                    devices details, warranty details, plans & Ver 1.0
                    subscriptions opted in a secure manner. User Registration"
                    allows users to personalise your personal details and login
                    with a unique personalised access to mobile app and avail
                    our services. It also provides an enhanced level of
                    protection and peace of mind that the data had been
                    independently verified.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    4. Why should I use my mobile number & email ID to
                    register"?
                  </h4>
                  <p className="queText">
                    Registering your mobile numbers and email addresses enhances
                    both the security and the convenience of your Online /
                    Mobile usage experience. If you are logging in from a new
                    device or have forgotten your password, a one-time OTP can
                    be sent to your registered phone number, allowing you to log
                    in. Your mobile number serves as a unique identifier for us
                    to serve you better. Your email address is used to send
                    important information and updates about your account that
                    you choose to receive, transaction alerts, & exclusive
                    offers. If you wish to change your mobile number in the
                    future, your email ID serves as an alternate mode of
                    communication.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    5. What is a "Registered User"?
                  </h4>
                  <p className="queText">
                    A "Registered User" is a unique individual user of a website
                    / mobile application who had previously registered and has
                    an existing business relationship with us. “Registered
                    Users" normally provides some sort of credentials & certain
                    personal details (such as a username or e-mail address, at
                    the time of registration in order to prove their identity. A
                    user can register with multiple mobile numbers however;
                    every number will be treated as a unique account with us.
                    User can register with multiple mobile numbers for their
                    ease of managing multiple locations or addresses.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    6. Why is "User Profile" important?
                  </h4>
                  <p className="queText">
                    A user profile is a collection of settings and information
                    associated with a user. It contains critical information
                    that is used to identify an individual, such as their name,
                    age, individual preferences etc. It also helps us to provide
                    customised solutions and offering to meet your unique needs.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    7. What are the benefits of having "Additional Information
                    “of user profile?
                  </h4>
                  <p className="queText">
                    "Additional Information of user profile has so many benefits
                    from a servicing perspective. User profiling gives us a
                    better understanding of preference & choices of every user,
                    with all the additional information that has been gathered
                    while creating a profile, we discover how, when, where and
                    what to communicate basis on customers’ Preferences.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    8. What does "Additional Details" mean?
                  </h4>
                  <p className="queText">
                    A users "Additional Details" contains key information about
                    customers like educational, professional, Marital Status and
                    alternate contact details. This information helps us to
                    build a user profile in order to helps us to provide a
                    customised solution that perfectly meets your requirements
                    when it comes to post purchase care for your valuable. Also,
                    it entitles the user to get free 100 "WE" Coins, which can
                    be redeemed against discounts towards future purchase.
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    9. What are the benefits of registering as a"Non-Resident
                    Indian"?
                  </h4>
                  <p className="queText">
                    NRI or Non-Resident Indian are users of Indian origin
                    however, resides outside the country. In most cases, such
                    users either lease out their apartments / homes or their
                    immediate family members reside at their home. NRI
                    registration comes really handy in managing all their house
                    hold appliances, devices and gadgets from any part of the
                    world and can buy "Extended Warranties" for ease and
                    convenience of their parents or their rented house in India.
                    NRI users can also give access to their dependent parents &
                    any other family members
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">10. What is "OTP"?</h4>
                  <p className="queText">
                    "OTP" is One Time Password. A one-time password (OTP) is an
                    automatically generated numeric or alphanumeric string of
                    characters that authenticates a user for a single
                    transaction or login session.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">11. Why do we need "OTP"?</h4>
                  <p className="queText">
                    An "OTP" is a secure method of accessing any application /
                    system & to prevent unauthorised access and evade fraud. OTP
                    enabled login access alerts the user as and when someone
                    tries to access their account
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    12. What is the significance of “OTP”?
                  </h4>
                  <p className="queText">
                    "OTP" is required in order to authenticate the respective
                    email or phone number used at the time of registration. This
                    ensures that the authorised users get alerted every time a
                    login attempt is made. Since OTP is an auto generated unique
                    secure code that authenticates a user for a single
                    transaction / login session, it serves as an additional
                    layer of protection for any transaction and grants secure
                    access only to the registered user
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    13. What is "Email Verification" and why is it important?
                  </h4>
                  <p className="queText">
                    Email verification serves as an effective way to user at the
                    time of registration It’s an alternate mode of verifying or
                    validating a use for online products & services, ensuring
                    that the information provided on the website is correct.
                    Furthermore, email verification also serves as an alternate
                    mode to reach out to consumers with latest offers and
                    exclusive schemes.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    14. What are the reasons to perform "Email Verification"?
                  </h4>
                  <p className="queText">
                    "Email Verification" verified identities, Reduce Fraud and
                    Improve Trust & Safety of a customer.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    15. Why are "Location" based services important?
                  </h4>
                  <p className="queText">
                    This solution enables accurate and quick determination of
                    geographical coordinates of mobile phone numbers by
                    providing operator-independent location data. This further
                    helps us to personalise our service offerings that is more
                    relevant and location specific. Location Services “allow
                    users aid in serving personalized and relevant content.
                    “Location sharing" can be useful in locating and identifying
                    the nearest service providers in your area thereby saving
                    time and reach out to the nearest service provider for
                    services.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    16. What are the advantages of "Location Aware
                    Applications"?
                  </h4>
                  <p className="queText">
                    "Location Aware Applications" Offers location awareness
                    within buildings or areas where GPS cannot be used.
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    17. What is the benefit of "Subscribing"?
                  </h4>
                  <p className="queText">
                    "Subscription" payments lower the cost of post purchase care
                    and allow users to opt-in tailor made, cost effective plans
                    for your valuable products. Annual "Subscriptions" secures
                    you with adequate coverage for your devices and appliances
                    ensuring timely support & care and avoid last moment rush
                    and stress searching for assistance. It also gives you
                    benefits of saving time and money since a subscription plan
                    leaves you with peace of mind and a well-planned approach
                    towards post purchase care.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    18. What is a "Yearly Subscription "service?
                  </h4>
                  <p className="queText">
                    "Yearly Subscription" services allows customers to avail
                    world class post purchase care for all your valuable devices
                    & appliances. It’s a pre-paid subscription with a varied
                    list of features & benefits that aids consumers to avail
                    expert professional & technical support to manage any
                    unexpected breakdown, device malfunctions, save valuable
                    time in calls & follow up for assistance. Our Subscription
                    plan also entitles users with additional advantage like a
                    free care packages depending on your subscription. It also,
                    Saves Time & Cost, Flexibility to opt-in tailor made, world
                    class plans, & serves as a memorable Gifting solution.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    19. How does Warranty Express Benefits consumer?
                  </h4>
                  <p className="queText">
                    Unlike a traditional warranty cover for your device,
                    Warranty Express enriches your post purchase device
                    experience at your finger tip! Bundled up with features that
                    serves as single point of contact for all your devices &
                    appliances, WE provides access to all your device
                    information right from device invoices, and other related
                    details, with access to a dedicated Service Manager,
                    customized subscription plans & extended warranty covers
                    give our offering a cutting edge over any traditional post
                    purchase services available in the market.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    20. What all services are provided under “Warranty Express”?
                  </h4>
                  <p className="queText">
                    We offer world class Service Subscription and Extended
                    Warranty plans tailored to meet your post purchase
                    requirements with flexible packages that meets every
                    individual need.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    21. What all devices can be covered under “Warranty Express”
                    Extended Warranty?
                  </h4>
                  <p className="queText">
                    With Warranty Express, most of the major brands are covered
                    leaving you with peace of mind and convenience to manage
                    “Any Brand”, “Any product” at the convenience of a
                    simplified & user-friendly mobile app.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    22. When Should I buy the "Extended Warranty"?
                  </h4>
                  <p className="queText">
                    The “Extended Warranty" should be purchased & activated on
                    all products within 90-days of purchase. The warranty stands
                    void if not activated within 90-days of purchasing the
                    “Extended Warranty Plan. Our customer care team will send
                    you timely friendly reminders to ensure that you do not miss
                    out on activating the extended warranty.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    23. When does the "Extended Warranty Plan “starts?
                  </h4>
                  <p className="queText">
                    Our Warranty Plans starts the very next day after the
                    manufacturer’s warranty expires provided that extended
                    warranty had been activated within 90 days of the purchase.
                  </p>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    24. How does Warranty Express work and how can I avail the
                    services?
                  </h4>
                  <p className="queText">
                    With years of research in consumer post purchase experience
                    & pain areas of following up with multiple service
                    providers, brands, local service centers and vendor eats up
                    tremendous amount of time and efforts. Hence, WE provide a
                    unified platform that manage all your post purchase
                    requirements via a simplified mobile based application that
                    saves your valuable time, avail skilled services from expert
                    technician thereby saving a lot of time and efforts when it
                    comes to your device care. Simply download our mobile app
                    “Warranty Express” from Google play store / Apple Store and
                    register to start availing the services. Once registered,
                    you can start availing our service immediately depending on
                    the subscription & Extended warranty opted.
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    25. How different is Warranty Express as compared to a
                    traditional Insurance cover for my devices & appliances?
                  </h4>
                  <p className="queText">
                    “Warranty Express” eases the pain of connecting with
                    multiple service provider as our mobile application is
                    bundled with features covering all the post purchase areas
                    of support, get expert assistance to fix the issues
                    throughout the entire life journey of your devices &
                    appliances till the time its disposed!
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    26. What is the difference between Insurance, warranty,
                    extended warranty, and Subscription?
                  </h4>
                  <p className="queText ms-5">
                    - <strong>Warranty</strong> is an assurance given by the
                    manufacturer covering Manufacturing Defects for a certain
                    time period.
                  </p>
                  <br />
                  <p className="queText ms-5">
                    - <strong>Insurance</strong> is an assurance from damage
                    caused to your appliances or gadgets due to external forces
                    on specific conditions.
                  </p>
                  <br />
                  <p className="queText">
                    - An <strong>extended warranty</strong> is a feature which
                    you can opted to safeguard your products from manufacturing
                    defect from a third-party service period once your OEM
                    warranty time period expires. A subscription is feature
                    which you can opt for to enjoy a bunch of benefits from WE
                    at a given amount.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    27. What is the validity of a subscription & extended
                    warranty?
                  </h4>
                  <p className="queText">
                    The subscription comes for a tenure of 1 year in 4 different
                    categories and the extended warranty plan ranges from 6
                    months to 36 months.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    28. I had purchased my device from outside India. Does
                    Warranty Express cover my devices?
                  </h4>
                  <p className="queText">
                    Yes, you would be required to submit proper purchase
                    documents when adding your devices onto WE platform.
                    Depending on the extend warranty opted-in, your device will
                    be covered.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    29. Can I extend my subscription?
                  </h4>
                  <p className="queText">
                    Yes, you can. Please login to the mobile app, Select My
                    Warranty to check your warranty status for upgrade.
                    Alternatively, a friendly reminder is sent to your
                    registered mobile number and email ID 7 days before your
                    subscription is due for renewal.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    30. What If I wish to Upgrade / Downgrade "My Subscription"?
                  </h4>
                  <p className="queText">
                    You can upgrade your subscription at any point in time by
                    paying the difference amount of the upgraded subscription.
                    However, the upgraded plan will be valid for 1 year from the
                    date of initial subscription. Also, if you are lucky, you
                    can also upgrade via our special offers as and when we
                    introduce it.
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    31. Will I get a refund if I wish to cancel my subscription?
                  </h4>
                  <p className="queText">
                    Yes! IF you wish to cancel your subscription within 7 days
                    of purchase, we will reimburse the subscription value
                    without additional deduction. However, refund cannot be
                    processed if the request is raised after 7 days from the
                    date of purchase.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    32. Can I Downgrade an Annual Subscription Plan?
                  </h4>
                  <p className="queText">
                    No, the plan will automatically get cancelled once the
                    "Annual Subscription Period" gets over.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    33. Will my device / appliance gets replaced if its beyond
                    repair?
                  </h4>
                  <p className="queText">
                    WE will evaluate the best possible options to check if we
                    can repair your device / appliances. However, if its beyond
                    repair, we can recommend few exclusive offers at discounted
                    price so that you can take an informed decision. Use “Ask
                    Expert” option in our mobile app to know more.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    34. Can I buy an "Extended Warranty" that is gifted by me or
                    can they just use the warranty under my name?
                  </h4>
                  <p className="queText">
                    Yes, you can buy an "Extended Warranty" on all the
                    appliances & gadgets to the new owner. They just need to
                    download the "WE" application to activate the same.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    35. What is the type of Subscriptions that I can avail from
                    Warranty Express?
                  </h4>
                  <p className="queText mb-5">
                    “WE” offers exclusive and customized Subscriptions from a
                    basic to a comprehensive cover to meet every consumer need.
                  </p>
                  <table className="table table-responsivetable-responsive table-bordered table-pdf queText">
                    <tr className="bg-lightblue1">
                      <th>Sr.No</th>
                      <th>FEATURES</th>
                      <th>DESCRIPTION</th>
                      <th>BASIC</th>
                      <th>PREMIUM</th>
                      <th>PRIVILEGE</th>
                      <th>ELITE</th>
                    </tr>
                    <tr>
                      <td>1.</td>
                      <td>VAULT</td>
                      <td>
                        Securely store & access all product purchase Invoices &
                        warranty certificate at a single place.
                      </td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>

                    <tr>
                      <td>2.</td>
                      <td>ASK THE EXPERT</td>
                      <td>
                        Access to product purchase assistance & avail exclusive
                        offers available nearby.
                      </td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>

                    <tr>
                      <td>3.</td>
                      <td>SEARCH</td>
                      <td>
                        Enables to search & directly connect with local service
                        providers if the product is not covered under warranty.
                      </td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>

                    <tr>
                      <td>4.</td>
                      <td>WARRANTY TRANSFER</td>
                      <td>
                        Empowers the user to transfer extended warranties to 3rd
                        Party.
                      </td>
                      <td>*Fee Applicable</td>
                      <td>*Fee Applicable</td>
                      <td>*Fee Applicable</td>
                      <td>Free</td>
                    </tr>

                    <tr>
                      <td>5.</td>
                      <td>MULTIPLE ADDRESS</td>
                      <td>
                        Add & manage multiple addresses under a single
                        registration.
                      </td>
                      <td>No</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>

                    <tr>
                      <td>6.</td>
                      <td>ADV. FREE</td>
                      <td>
                        Enjoy uninterrupted mobile app usage without 3rd party
                        advertisements
                      </td>
                      <td>No</td>
                      <td>No</td>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>

                    <tr>
                      <td>7.</td>
                      <td>FAMILY ACCESS</td>
                      <td>
                        Enables the user to grant access to family members
                      </td>
                      <td>No</td>
                      <td>Yes - 1 family Member</td>
                      <td>Yes - 3 family Members</td>
                      <td>Yes - 5 Family Members</td>
                    </tr>

                    <tr>
                      <td>8.</td>
                      <td>DIRECT CONNECT</td>
                      <td>
                        Enjoy the privilege of a dedicates Relationship Mangers
                        to assist you with all your post purchase device care.
                      </td>
                      <td>No</td>
                      <td>No</td>
                      <td>Yes - Only for the primary account holder.</td>
                      <td>Yes - Primary account holder & Family.</td>
                    </tr>

                    <tr>
                      <td>9.</td>
                      <td>PRIOIRITY SERVICES</td>
                      <td>Response to service requests</td>
                      <td>Within 48 Working Hrs.</td>
                      <td>Within 36 Working Hrs.</td>
                      <td>Within 24 Working Hrs.</td>
                      <td>Within 12 Working Hrs.</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    36. What are the Extended Warranty plans that I can avail
                    from Warranty Express?
                  </h4>
                  <p className="queText">
                    An extended warranty covers all or some of the cost of
                    certain repairs after the standard Manufacturer warranty
                    expires. “WE” offers exclusive and customized Extended
                    Warranty from 6 months to 36 months that covers every
                    consumer need and doesn't come heavy on your pocket. We
                    offer extended warranties that provides a basic cover to a
                    comprehensive cover for all your valuable gadgets, devices
                    and home appliances. We provide extended warranties under 3
                    customized plans to meet every individual post purchase
                    care.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    37. I don't have any Invoice or relevant document for my
                    devices / appliance. Can I still subscribe for WE plans?
                  </h4>
                  <p className="queText">
                    Yes. You can subscribe to WE plans. Once you plan to opt for
                    our extended warranty Plans. our expert technician will
                    visit your location and conducts a device health check and
                    ascertain the scope of work - Coming soon!
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    38. Which all brands are covered under the subscription
                    plans and extended warranty?
                  </h4>
                  <p className="queText">
                    Warranty Express covers{" "}
                    <strong>“Any brand, any product”</strong>approach as long as
                    there is a valid purchase invoice.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    39. How do I determine the Extended Warranty coverage of my
                    Devices & appliances?
                  </h4>
                  <p className="queText">
                    You can view all your device/appliance extended warranties
                    on WE mobile app along with its documents under Vault
                    feature & further segregated and presented under MY
                    Warranty.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    40. How many devices & appliances can I add under “Warranty
                    Express” (Vault)?
                  </h4>
                  <p className="queText">
                    You can add as many devices and appliances as you wish.
                    There are no limitations in terms of the number of devices
                    that can be registered with us covering any and all of your
                    devices.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    41. How secure is my personal information & appliance
                    purchase invoice and other details with Warranty Express?
                  </h4>
                  <p className="queText">
                    We believe in data security & user privacy. All personal and
                    product related details are secured and stored with 128 /
                    256-bit encryption technology ensuring data security. Access
                    restricted only to the registered primary account holder
                    ensuring that your information is safe and secure with us
                    and will be used only for the specific purpose of only
                    providing post purchase device care. Refer to our Privacy
                    Policy for more details.
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    42. Whom do I reach for support and assistance if my device
                    / appliance malfunction?
                  </h4>
                  <div className="ms-xl-5 ms-3 mt-5">
                    <ul className="pdfList ms-3">
                      <li>
                        There are multiple ways to reach us for assistance
                        <ul className="ms-3">
                          <li>
                            i. If you are a Privilege or Elite customer, simply
                            reach out to you dedicated Relationship Manager via
                            Direct Connect feature from the mobile app.
                          </li>
                          <li>
                            ii. Alternatively, Call our customer care number at{" "}
                            <strong>022 4605 3324</strong>
                          </li>
                          <li>
                            iii. Email:
                            <a className="text-decoration-underline" href="mailto: https://www.auxill.in/">
                              {" "}
                              contactus@warrantyexpress.in
                            </a>
                          </li>
                          <li>
                            iv. Raise a service request via our mobile app.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    43. Do I get any discounts if I register more devices?
                  </h4>
                  <p className="queText">
                    For every transaction that you carry out on our unified
                    platform earns WE loyalty coins and WE Cash back. These can
                    be redeemed to upgrade or add our subscription plans and
                    extended warranties.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    44. Does Warranty Express provide repairs incase my device
                    is not working?
                  </h4>
                  <p className="queText">
                    Coming soon - <strong>Repair & Maintenance.</strong>
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    45. How much does it cost for a repair?
                  </h4>
                  <p className="queText">
                    Coming soon - <strong>Repair & Maintenance.</strong>
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    46. I am an NRI, does WE cover all my devices at my India
                    home?
                  </h4>
                  <p className="queText">
                    Yes! With Warranty Express, you can enjoy an exclusive
                    flexibility to cover almost all your devices & home
                    appliances if they are covered under extended warranty.
                    Manage virtually from any part of the world or by giving
                    access to your family members / Tenants residing in India.
                    To know more about the brands and the devices covered under
                    extended warranty.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    47. I travel a lot outside India, will I be able to avail WE
                    services abroad in case my device malfunctions or gets
                    Damaged?
                  </h4>
                  <p className="queText">
                    All your devices & home appliances registered with us are
                    covered basis the subscription plans and extended warranty
                    opted in. However, currently we are only providing this
                    service in India and plan to extend the service on foreign
                    land in near future.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    48. I have more than 1 house and in India, will I be able to
                    avail WE services for all my devices at both these
                    locations?
                  </h4>
                  <p className="queText">
                    Yes. Only with Warranty Express, you can manage multiple
                    locations & multiple devices, gadgets. & appliances with
                    ease via “Add Addresses “feature of the mobile app. Further
                    you can also take advantage of adding your family members to
                    manage day-to-day post purchase care for any brand, any
                    device irrespective of the location.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    49. Wil I be able to avail WE services for my parent /
                    relatives staying at other locations?
                  </h4>
                  <p className="queText">
                    Yes, you can. Once you are registered as the primary account
                    holder, you can easily add / modify multiple access rights
                    to your parents or relatives to manage post purchase care
                    depending on the subscription plan.
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    50. I am an Administrator of a company managing multiple
                    locations in India. Can we cover all our office devices and
                    appliances through “Warranty Express”?
                  </h4>
                  <p className="queText">
                    Yes, you can. Subscribe with our Elite Subscription plan to
                    cover multiple location and multiple devices with ease. You
                    can cover all devices, gadgets and appliances at these
                    locations and can further assign up to 5 dedicated local
                    SPOC who can manage all post purchase care required across
                    laptops, desktops, printers, ACs, mobile phones etc.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    51. Does Warranty Express provide services PAN India?
                  </h4>
                  <p className="queText">
                    Yes. Currently WE cover almost all brands across major metro
                    cities in India.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    52. What should I do if I had to report a query / concern
                    regarding quality of workmanship?
                  </h4>
                  <div className="ms-xl-5 ms-3 mt-5">
                    <ul className="pdfList ms-3">
                      <li>
                        There are multiple ways to reach us for assistance
                        <ul className="ms-3">
                          <li>
                            i. If you are a Privilege or Elite customer, simply
                            reach out to you dedicated Relationship Manager via
                            Direct Connect feature from the mobile app.
                          </li>
                          <li>
                            ii. Alternatively, Call our customer care number at{" "}
                            <strong>022 4605 3324</strong>
                          </li>
                          <li>
                            iii. Email:
                            <a className="text-decoration-underline" href="mailto: https://www.auxill.in/">
                              {" "}
                              contactus@warrantyexpress.in
                            </a>
                          </li>
                          <li>
                            iv. Raise a service request via our mobile app.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    53. Depreciation value of device?
                  </h4>
                  <table className="table table-responsivetable-bordered table-pdf queText">
                    <tr>
                      <th colSpan={3} className="text-center">
                        Depriciated Value
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>Depriciation</th>
                      <th>Dep. Value</th>
                    </tr>
                    <tr>
                      <td>Upto 3 months</td>
                      <td>15%</td>
                      <td>85</td>
                    </tr>
                    <tr>
                      <td>3 months to 6 months</td>
                      <td>20%</td>
                      <td>80</td>
                    </tr>
                    <tr>
                      <td>6 months to 9 months</td>
                      <td>25%</td>
                      <td>75</td>
                    </tr>
                    <tr>
                      <td>9 months to 12 months</td>
                      <td>30%</td>
                      <td>70</td>
                    </tr>
                    <tr>
                      <td>12 months to 18 months</td>
                      <td>40%</td>
                      <td>60</td>
                    </tr>
                    <tr>
                      <td>18 months to 24 months</td>
                      <td>50%</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>24 months to 30 months</td>
                      <td>60%</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>30 months to 36 months</td>
                      <td>70%</td>
                      <td>30</td>
                    </tr>
                    <tr>
                      <td>30 months to 36 months</td>
                      <td>80%</td>
                      <td>20</td>
                    </tr>
                  </table>
                </div>
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    54. Is my warranty transferrable?
                  </h4>
                  <p className="queText">
                    Yes. You can transfer the warranty of your devices /
                    appliances to anyone. Use “Transfer Extended warranty”
                    option in our mobile app to complete the transfer
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">55. Additional Charge</h4>
                  <p className="queText mb-4">
                    Depending on your subscription, there will be a minimal
                    administration fee applicable.
                  </p>
                  <table className="table table-responsivetable-bordered table-pdf queText">
                    <tr className="bg-lightblue1">
                      <th colspan="2">TRANSFER</th>
                      <th colspan="4">SUBSCRIPTIONS</th>
                    </tr>
                    <tr>
                      <th>Sr.No</th>
                      <th>TYPES</th>
                      <th>BASIC</th>
                      <th>PREMIUM</th>
                      <th>PRIVILEGE</th>
                      <th>ELITE</th>
                    </tr>
                    <tr>
                      <td>1.</td>
                      <td>Transfer Fee for WECoins</td>
                      <td>INR 49</td>
                      <td>INR 39</td>
                      <td>INR 29</td>
                      <td>INR 0</td>
                    </tr>

                    <tr>
                      <td>2.</td>
                      <td>Transfer Fee for WECash</td>
                      <td>INR 99</td>
                      <td>INR 69</td>
                      <td>INR 49</td>
                      <td>INR 0</td>
                    </tr>

                    <tr>
                      <td>3.</td>
                      <td>Transfer Fee for Extended Warranty</td>
                      <td>INR 249</td>
                      <td>INR 199</td>
                      <td>INR 149</td>
                      <td>INR 0</td>
                    </tr>

                    <tr>
                      <td>4.</td>
                      <td>Emergency Visit Charges</td>
                      <td>INR 799</td>
                      <td>INR 499</td>
                      <td>INR 249</td>
                      <td>INR 0</td>
                    </tr>

                    <tr>
                      <td>5.</td>
                      <td>Pick up & Drop Charges</td>
                      <td>INR 999</td>
                      <td>INR 699</td>
                      <td>INR 499</td>
                      <td>INR 0</td>
                    </tr>
                  </table>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    56. Can I transfer my extended warranty to someone else when
                    I resale my devices & appliances?
                  </h4>
                  <p className="queText">
                    Yes. You can transfer the extended warranty of your devices
                    / appliances to anyone at nominal fee. User “Transfer
                    Extended Warranty” option in our mobile app to complete the
                    transfer. To know more about the transfer fees.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    57. What happens if the company doesn't cancel my
                    "Subscription"?
                  </h4>
                  <p className="queText">
                    Please email us with your customer ID and the registered
                    mobile number to{" "}
                    <a className="text-decoration-underline" href="mailto:https://www.auxill.in/">
                      contactus@warrantyexpress.in
                    </a>
                    along with a request to cancel your payments.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    58. What can be the maximum age for the appliance to be
                    eligible for "Warranty Extension"?
                  </h4>
                  <p className="queText">
                    "Extended Warranty" and "Services" covers products that are
                    up to 90 days
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    59. How does "Ask The Expert" work?
                  </h4>
                  <p className="queText">
                    The primary objective of "Ask The Expert" is to help users
                    in purchase assistance. This feature will help the user to
                    avail product details & exclusive offers, comparative
                    analysis on options available nearby as well extended
                    warranty wise. This empowering you to take an informed
                    decision in devices & appliances purchases.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    60. Why is "Search" option important?
                  </h4>
                  <p className="queText">
                    If you have multiple options to choose from, a "Search" bar
                    allows user to find what you are looking for quickly. This
                    will also help you to narrow down the search and directly
                    connect with local service providers in case of products
                    outside warranty.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    61. What is "Direct Connect"?
                  </h4>
                  <p className="queText">
                    "Direct Connect" provides "Warranty Express (WE)" users to
                    directly connect to a Dedicated Relationship Manager for all
                    the needs.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    62. What if user wants to register a "Service Request" in
                    "Non-Working Hours"?
                  </h4>
                  <p className="queText">
                    You can register a service request through "WE“mobile app at
                    any point time. Depending on your subscription and the
                    nature of query, our experts will reach you within 24
                    working hrs from the time a request had been logged.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    63. How can I get exciting pricing "Offers" while buying
                    subscriptions?
                  </h4>
                  <p className="queText">
                    As a part of subscription, a user is entitled to get free
                    extended warranty depending on the subscription opted in at
                    the time of availing the free extended warranty. Eg: If a
                    user had subscribed for “Elite” which covers Ver 1.0
                    extended warranty for 1 any product. Since the extended
                    warranty will only commence once the OEM warranty expires,
                    to avail the free extended warranty, the user should have an
                    active subscription in order to benefit the free extended
                    warranty cover provided with the subscription plan.
                  </p>
                  <br />
                  <table className="table table-responsivetable-bordered table-pdf queText">
                    <tr className="bg-lightblue1">
                      <th>BASIC</th>
                      <th>PREMIUM</th>
                      <th>PRIVILEGE</th>
                      <th>ELITE</th>
                    </tr>
                    <tr>
                      <td>NA</td>
                      <td>
                        Any 1 Product covered for<strong>6 months</strong>
                      </td>
                      <td>
                        Any 1 Product covered for<strong>12 months</strong>
                      </td>
                      <td>
                        Any 1 Product covered for<strong>18 months</strong>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    64. How do I claim a free "EW " on "WE”?
                  </h4>
                  <p className="queText">
                    To claim a free extended warranty, offer:
                    <br />
                    - Add the free product to your Vault.
                    <br />- Enter the claim code exactly as it appears in the
                    promotion details, or copy and paste the claim code while
                    purchasing.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    65. Can I avail "2 Offers" at the same time?
                  </h4>
                  <p className="queText">
                    No. You cannot club multiple offers at once.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    66. What is “WE Coins” & “WE Cash”?
                  </h4>
                  <p className="queText">
                    <strong>WECoins & WE</strong> Cash are an exclusive loyalty
                    programs to honour your association with us. We value your
                    business with us hence all our subscription plan entitles
                    our users with Loyalty points in the form of WE Coins &
                    WECash. However, the value differs from plan to plan.{" "}
                    <strong>WECoins</strong> can be redeemed against goodies and
                    gifts while <strong>WECash</strong> is a digital money that
                    can used to purchase further subscriptions & extended
                    warranties. Every transaction carried out via the mobile app
                    earns both WECoins & WECash. In order to earn "WE COINS" you
                    must Register and opt-in for a subscription plan
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    67. How and when can I start "Earning" "WE COINS”?
                  </h4>
                  <p className="queText">
                    Start "Earning" as soon as you register with us. Also, for
                    every transaction that you carry out via the mobile app
                    entitles to earn WE Coins & WECash. Our users earn loyalty
                    coins for every single transaction throughout their journey
                    with us.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    68. Do I earn any benefits if I refer “WE” to my friends and
                    loved one?
                  </h4>
                  <p className="queText">
                    You can earn "WE COINS" on certain opportunities by inviting
                    others to "WE" application with a referral code or a link.
                    You earn "WE COINS" only for referred individuals who had
                    completed registration and - <br /> <br />
                    i. Enter through your referral link and accept your invite.{" "}
                    <br />
                    ii. Download “WE" application through your referral code{" "}
                    <br />
                    iii. Are not residents in locations where the "WE" is not
                    listed <br />
                    iv. Complete the registration process.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    69. Additional Terms of "Earn" "WE COINS"?
                  </h4>
                  <p className="queText">
                    "Earn offers" are limited. "WE" reserves the right to cancel
                    any offer at our sole discretion any time without giving any
                    prior intimation. Once this offer is terminated or
                    cancelled, an invitee may still be able to view the
                    associated content but will not "Earn" "WE COINS" for
                    registration.
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    70. How do I receive my "Rewards”?
                  </h4>
                  <p className="queText">
                    Rewards will be added to your “Piggy Bank”. Your will
                    receive a notification with further instructions on how to
                    redeem the same.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    71. What do I do if I am unable to "Redeem" the points?
                  </h4>
                  <p className="queText">
                    Please reach out to Customer Care for assistance in
                    "Redeeming" your WECoin or WECash. If you are a “Privilege”
                    or “Elite” subscriber, please reach out to your relationship
                    manager via “Direct Connect” feature in our mobile app.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    72. How does "Extended Warranty Transfer" work?
                  </h4>
                  <p className="queText">
                    If you purchase a new product and later sell it to someone
                    else, the warranty will still remain valid for the new owner
                    until the end of the original warranty period. Once logged
                    in, select “Transfer Extended Warranty”, Select the
                    “Device”. Enter the new owners details along with a mobile
                    number and click “Transfer EW”.
                    <br />A bitley with a link will be send as an SMS & WhatsApp
                    to the new owner. Once the new owner downloads the app and
                    registers with the same mobile number used for invite, check
                    under “My Warranty” to view the EW
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    73. What is "Multiple Address"?
                  </h4>
                  <p className="queText">
                    "Multiple Addresses”, enables the user to manage multiple
                    locations simultaneously. With this revolutionary feature of
                    "WE" users can add multiple address & multiple devices under
                    a single user registration making it easy to manage all your
                    devices and appliances on a simplified unified platform.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    74. What does "No Ads" mean?
                  </h4>
                  <p className="queText">
                    “No Ads” means that the user can use our mobile app without
                    any promotional ads. "WE" users have the option of
                    subscribing to " Privilege or Elite" plans to enjoy an
                    ad-free experience. The subscription-only costs a low amount
                    yearly and it does not put any limits.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    75. What does "Family Access" mean?
                  </h4>
                  <p className="queText">
                    "Family Access" allows users to add up to five members to
                    access amazing "WE “services. This also enables user to
                    share uploaded details with family members and assign a
                    designated person to manage device & appliances care at
                    multiple location with ease.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    76. What are the benefits of adding "Product Details"?
                  </h4>
                  <p className="queText">
                    It is a feature that presents the description of a specific
                    product in view. The details displayed often include
                    Category of product, Brand and other relevant information.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    77. What are the benefits of using "Add Documents"?
                  </h4>
                  <p className="queText">
                    Reduced Storage Space
                    <br />
                    Enhanced Security
                    <br />
                    Easier Documents Retrieval
                    <br />
                    Backup of your documents
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    78. What is "Gift 'An' Extended Warranty"?
                  </h4>
                  <p className="queText">
                    A "Gift 'An' Extended Warranty" benefit increases the
                    standard manufacturer's warranty & extended warranty that
                    comes with an item you purchase as a gift. Now with WE, you
                    can gift an extended warranty when you gift a device, gadget
                    or any home appliances to your loved ones making the gifting
                    experience memorable!
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    79. Can I buy an "Extended Warranty" for a product that has
                    been gifted to me?
                  </h4>
                  <p className="queText">
                    Yes. If the name on the Product's invoice is not the same as
                    the name of the person buying the "Extended Warranty" with
                    the best key feature of "WE" you can buy an "Extended
                    Warranty" on your own with the help of "VAULT" where all
                    your product data's details has been stored.Redemption of
                    "WE CASH" or "WE COINS" against your future purchase will be
                    an additional advantage for you
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    80. Comparison between "Authorized Service Centres" &
                    "Nearby Service Centres"?
                  </h4>
                  <p className="queText">
                    i) Service Centres <br />
                    a) Authorized Service Centres <br />
                    b) Nearby Service Centres
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    81."Authorized Service Centre" or "Nearby Service Centre"
                    which one to opt for?
                  </h4>
                  <p className="queText">
                    With the special feature of "Search Service Centre"
                    comparison of products are easy and can save your money.
                    It's especially beneficial when buying warranties where the
                    product quality or price varies greatly Most customers
                    remain in a state of confusion whether to go or not to go
                    for "Authorized Service Centres" or, simply the "Nearby
                    Service Centre" Local technician will be able to fix their
                    problem. <br />
                    Going for "Authorized Service Centre” will certainly give
                    you peace of mind. While the "Nearby Service Centre “will be
                    quiet nice on your pocket.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    82. How does "Ask The Expert" work?
                  </h4>
                  <p className="queText">
                    The primary job of a "Ask The Expert" is to help users in
                    purchase assistance this will help them to get product
                    details & available offers. Expert Advice will be given by
                    our skilled & experience team of experts which will help you
                    to take an informed decision when it comes to your post
                    purchase device care.
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    83. What if "Ask The Expert" puts user on hold for long
                    time?
                  </h4>
                  <p className="queText">
                    During peak hours when the experts are busy taking other
                    Customers call, please try after sometime or Alternatively,
                    you can register service request through WhatsApp or Email
                  </p>
                </div>

                <div className="col-12">
                  <h4 className="queHeading my-5">
                    84. Is there any "Call Back Facility"?
                  </h4>
                  <p className="queText">
                    Yes, this facility is available via "Mobile Applications
                    Only"
                  </p>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className="queHeading my-5">
                    85. How do I raise a service request?
                  </h4>
                  <table className="table table-responsivetable-bordered table-pdf queText">
                    <tr>
                      <th colSpan={3} className="text-center">
                        CARE
                      </th>
                    </tr>
                    <tr>
                      <th>PRODUCT</th>
                      <th>INCLUSIONS</th>
                      <th>EXCLUSIONS</th>
                    </tr>

                    <tr>
                      <td>GENERAL</td>
                      <td>
                        Visit Charges | Labour & Service Fee | Unlimited
                        Services | Discount of spare parts incase of damage
                        repair | No Hidden Charges
                      </td>
                      <td>
                        Damage Repair Services incase of accidental and liquid
                        damage |Screen Repair Services incase of accidental and
                        liquid damage|Product Replacement incase beyond repair
                        due to any kind of damage |Damage Repair Services
                        arising due to electrical short circuit
                      </td>
                    </tr>

                    <tr>
                      <td>AIR CONDITIONERS</td>
                      <td>Gas Charging| Cooling Coil.</td>
                      <td>
                        Black Patches| LCD Leakage| LED Breakage| Power Adaptor|
                        External Camera|Condenser Coil| Compressor| Fan|
                        Thermostat| Blower| Controler board| main board and
                        Power Board|Remote| Swing Flap| Hose Pipes| Installation
                        and Re-Installation| Air Filter| Honey Comb mesh. Gas
                        charging not covered where coil is phycially damaged.
                      </td>
                    </tr>

                    <tr>
                      <td>TELEVISION</td>
                      <td> Main Board| Power Board| Soft Touch Board|</td>
                      <td>
                        Belts| Inner Tub| Outer Tub| Inlet/Outlet Valves Or
                        Connections| Hose Pipes| Gasket| Water Filter| Lid|
                        Debris Filter| Covers Legs| Agitator| Valves| Display
                        Panel| Main Board| Power Board| LVD's Cable| Back Light|
                        Sensor Assembly| TCON Board| Inverter Board| LED Driver
                        Supply board| SMPS| Soft Touch Board| Speaker| 3D
                        Emmiter board| IR Board| Logic Board| WIFI Module|
                        Bluetooth Module| Built-In- Camera
                      </td>
                    </tr>

                    <tr>
                      <td>WASHING MACHINES</td>
                      <td>Pulsator| Timer Board</td>
                      <td>
                        Gasket| Bulb| Crispers| Trays| Fuse| Strainer| Filter|
                        Drain Tray| Lock| Door Switch|Water Indicator Board|
                        Power Board| Motor| Water Pump| Control Panel BoardWater
                        Indicator Board| Power Board| Motor| Water Pump| Control
                        Panel Board
                      </td>
                    </tr>

                    <tr>
                      <td>REFRIGERATORS</td>
                      <td>Evaporator Pan| Condensor Coil</td>
                      <td>
                        Fuse| Door| Condenser Fan| Motor| Thermostat| Sensor
                        Board| Compressor| Time Board| Overload Protector Board|
                        Front Display Panel
                      </td>
                    </tr>

                    <tr>
                      <td>MICROWAVES</td>
                      <td>Magnectron| HT Transformer|</td>
                      <td>
                        Battries| Power Adaptor| Covers| Battary Assembly|
                        Softwares| Cables | Furse | Door.
                      </td>
                    </tr>

                    <tr>
                      <td>SMART PHONES</td>
                      <td>Main Board| Display </td>
                      <td>
                        | Mic| Speaker| WIFI| IR| Touch Button| Sensors| LED|
                        Camera| Flash| Internal Anttena| Vibrator|Software|
                        Hinges| Battery| Plastic Assembly| Cables|Battries|
                        Power Adaptor| Covers| Battary Assembly| Softwares|
                        Cables
                      </td>
                    </tr>

                    <tr>
                      <td>LAPTOPS</td>
                      <td>Display </td>
                      <td>
                        Touch Panel| Main Board| Power Board| Touch Panels|
                        Keyboard| WIFI| Bluetooth| Speakers| Camera| Mic
                      </td>
                    </tr>

                    <tr>
                      <td>DISHWASHERS</td>
                      <td>Heating Element| Detergent dispenser </td>
                      <td>
                        Control Pad| Racks| Silverware Basket| Spray Arms| Door
                        latch| Water Inlet Valve| Circulation Pump/Motor|
                        Filtration System
                      </td>
                    </tr>

                    <tr>
                      <td>DESKTOPS</td>
                      <td>Desktop Screen </td>
                      <td>CPU Unit| Mother Board| RAM| Fan| Hardisk|</td>
                    </tr>

                    <tr>
                      <td>OTGs</td>
                      <td>Control Board| Indicator Display </td>
                      <td>Thermostat| Convection Fan| Timer| Ressistor</td>
                    </tr>

                    <tr>
                      <td>PROJECTORS</td>
                      <td>Filter Cover| Front IR Receiver| Lens| Filter</td>
                      <td>
                        Remote| Power Adaptor|Kensigton Lock| Control Panel|
                        Rear IR Receiver| Exhaust| Lamp| Remote
                      </td>
                    </tr>

                    <tr>
                      <td>HOME THEATERS</td>
                      <td>Speaker| Power Board|</td>
                      <td>
                        Main Board| Display Board| IR Board| Loader Assembly| IO
                        Board| MIC|Remote| Power Adaptor
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <table className="table table-responsivetable-bordered table-pdf queText">
                    <tr>
                      <th colSpan={3} className="text-center">
                        ASSURE
                      </th>
                    </tr>
                    <tr>
                      <th>PRODUCT</th>
                      <th>INCLUSIONS</th>
                      <th>EXCLUSIONS</th>
                    </tr>

                    <tr>
                      <td>GENERAL</td>
                      <td>
                        Visit Charges | Labour & Service Fees | Cost of Spare
                        Parts due to manufacturing defect | Number of Service
                        Requests - Unlimited | Ref UDV ( Refurb Under
                        Depreciated Value) | Prod. Rep incase beyond repair due
                        to manufacturing defect | Damage Repair Services incase
                        of accidental and liquid damage |15 % Discount on spare
                        parts incase of damage repair services |Gas Charging |
                        Cooling Coil | Condenser Coil | Compressor | Fan |
                        Thermostat| Blower | Controler board | main board and
                        Power Board |
                      </td>
                      <td>
                        Screen Repair Services incase of accidental and liquid
                        damage | Prod. Rep. incase beyond repair due to any kind
                        of damage |Damage Repair Services arising due to
                        electrical short circuit | Remote |Swing Flap | Hose
                        Pipes | Installation and ReInstallation | Air Filter |
                        Honey Comb mesh | Gas charging not covered where coil is
                        phycially damadged.
                      </td>
                    </tr>

                    <tr>
                      <td>AIR CONDITIONERS</td>
                      <td>
                        Gas Charging| Cooling Coil| Condenser Coil| Compressor|
                        Fan| Thermostat| Blower
                      </td>
                      <td>
                        Black Patches| LCD Leakage| LED Breakage| Power Adaptor|
                        External Camera| Controler board| main board and Power
                        Board| Remote| Swing Flap| Hose Pipes| Installation and
                        ReInstallation| Air Filter| Honey Comb mesh. Gas
                        charging not covered where coil is phycially damaged.
                      </td>
                    </tr>

                    <tr>
                      <td>TELEVISION</td>
                      <td>
                        Display Panel| Main Board| Power Board| LVD's Cable|
                        Back Light| Sensor Assembly| TCON Board| Inverter Board|
                        LED Driver Supply board| SMPS| Soft Touch Board|
                        Speaker| 3D Emmiter board| IR Board| Logic Board| WIFI
                        Module| Bluetooth Module| Built-In- Camera
                      </td>
                      <td>
                        Belts| Inner Tub| Outer Tub| Inlet/Outlet Valves Or
                        Connections| Hose Pipes| Gasket| Water Filter| Lid|
                        Debris Filter| Covers Legs| Agitator| Valves| Belt
                      </td>
                    </tr>

                    <tr>
                      <td>WASHING MACHINES</td>
                      <td>Pulsator| Timer Board</td>
                      <td>
                        Gasket| Bulb| Crispers| Trays| Fuse| Strainer| Filter|
                        Drain Tray| Lock| Door Switch|Water Indicator Board|
                        Power Board| Motor| Water Pump| Control Panel BoardWater
                        Indicator Board| Power Board| Motor| Water Pump| Control
                        Panel Board
                      </td>
                    </tr>

                    <tr>
                      <td>REFRIGERATORS</td>
                      <td>
                        Evaporator Pan| Condenser Coil| Condenser Fan| Motor|
                        Thermostat
                      </td>
                      <td>
                        Fuse| Door|Evaporator Pan| Condenser Coil| Condenser
                        Fan| Motor| Thermostat| Sensor Board| Compressor| Time
                        Board| Overload Protector Board| Front Display Panel
                      </td>
                    </tr>

                    <tr>
                      <td>MICROWAVES</td>
                      <td>Magnectron| HT Transformer| Batteries</td>
                      <td>
                        Power Adaptor| Covers| Battary Assembly| Softwares|
                        Cables|Capacitor Motor| Control Board | Fuse| Door.
                      </td>
                    </tr>

                    <tr>
                      <td>SMART PHONES</td>
                      <td>Main Board| Display| Mic| Speaker </td>
                      <td>
                        Software| Hinges| Battery| Plastic Assembly|
                        Cables|WIFI| IR| Touch Button| Sensors| LED| Camera|
                        Flash| Internal Anttena| Vibrator|Battries| Power
                        Adaptor| Covers| Battary Assembly| Softwares| Cables
                      </td>
                    </tr>

                    <tr>
                      <td>LAPTOPS</td>
                      <td>Display| Touch Panels</td>
                      <td>
                        Main Board| Power Board| Keyboard| WIFI| Bluetooth|
                        Speakers| Camera| Mic|Software| Hinges| Battery| Plastic
                        Assembly| Cables.
                      </td>
                    </tr>

                    <tr>
                      <td>DISHWASHERS</td>
                      <td>
                        Heating Element| Detergent dispenser| Control Pad| Racks
                      </td>
                      <td>
                        Silverware Basket| Spray Arms| Door latch| Water Inlet
                        Valve| Circulation Pump/Motor| Filtration System
                      </td>
                    </tr>

                    <tr>
                      <td>DESKTOPS</td>
                      <td>Desktop Screen| CPU Unit</td>
                      <td>Mother Board| RAM| Fan| Hardisk| </td>
                    </tr>

                    <tr>
                      <td>OTGs</td>
                      <td>
                        Control Board| Indicators Display| Thermostat|
                        Convection
                      </td>
                      <td>Fan| Timer| Ressistor </td>
                    </tr>

                    <tr>
                      <td>PROJECTORS</td>
                      <td>
                        Filter Cover| Front IR Receiver| Lens| Filter| Kensigton
                        Lock|
                      </td>
                      <td>
                        Remote| Power Adaptor| Control Panel| Rear IR Receiver|
                        Exhaust| Lamp| Remotes
                      </td>
                    </tr>

                    <tr>
                      <td>HOME THEATERS</td>
                      <td>
                        Speaker| Power Board| Main Board| Display Board| Power
                        Adaptors.
                      </td>
                      <td>IR Board| Loader Assembly| IO Board| MIC|Remote.</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfHeader">
              <div className="row mb-4">
                <div className="col-12 text-end">
                  <img
                    src={logoPartner}
                    alt=""
                    className="img-fluid logoPartner"
                  />
                </div>
              </div>
            </div>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <table className="table table-responsivetable-bordered table-pdf queText">
                    <tr>
                      <th colSpan={3} className="text-center">
                        GUARD
                      </th>
                    </tr>
                    <tr>
                      <th>PRODUCT</th>
                      <th>INCLUSIONS</th>
                      <th>EXCLUSIONS</th>
                    </tr>

                    <tr>
                      <td>GENERAL</td>
                      <td>
                        Visit Charges |Labour & Service Fees | Cost of Spare
                        Parts due to manufacturing defect | Number of Service
                        Requests - Unlimited | New UDV (New Under Depreciated
                        Value)Prod. Rep incase beyond repair due to
                        manufacturing defect | 35% Discount on spare parts
                        incase of damage repair services | Damage Repair
                        Services incase of accidental and liquid damage | Screen
                        Repair Services incase of accidental and liquid damage |
                        Prod. Rep. incase beyond repair due to any kind of
                        damage | Damage Repair Services arising due to
                        electrical short circuit | Remote Control Repair
                      </td>
                      <td>
                        Product Replacement incase beyond repair due to any kind
                        of damage
                      </td>
                    </tr>

                    <tr>
                      <td>AIR CONDITIONERS</td>
                      <td>
                        Gas Charging| Cooling Coil| Condenser Coil| Compressor|
                        Fan| Thermostat| Blower| Controler board| main board and
                        Power Board|Remote| Swing Flap| Hose Pipes| Installation
                        and Re-Installation| Air Filter| Honey Comb mesh. Gas
                        charging even if the coil is phycially damaged.
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>TELEVISION</td>
                      <td>
                        Display Panel| Main Board| Power Board| LVD's Cable|
                        Back Light| Sensor Assembly| TCON Board| Inverter Board|
                        LED Driver Supply board| SMPS| Soft Touch Board|
                        Speaker| 3D Emmiter board| IR Board| Logic Board| WIFI
                        Module| Bluetooth Module| Built-In- Camera| Belts| Inner
                        Tub| Outer Tub| Inlet/Outlet Valves Or Connections| Hose
                        Pipes| Gasket| Water Filter| Lid| Debris Filter| Covers
                        Legs| Agitator| Valves| Belt
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>WASHING MACHINES</td>
                      <td>
                        Pulsator| Timer Board| Water Indicator Board| Power
                        Board| Motor| Water Pump| Control Panel Board
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>REFRIGERATORS</td>
                      <td>
                        Evaporator Pan| Condenser Coil| Condenser Fan| Motor|
                        Thermostat| Sensor Board| Compressor| Time Board|
                        Overload Protector Board| Front Display Panel
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>MICROWAVES</td>
                      <td>
                        Magnectron| HT Transformer| Capacitor Motor| Control
                        Board | Fuse | Door.
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>SMART PHONES</td>
                      <td>
                        Main Board| Display| Mic| Speaker| WIFI| IR| Touch
                        Button| Sensors| LED| Camera| Flash| Internal Anttena|
                        Vibrator|Battries| Power Adaptor| Covers| Battary
                        Assembly| Softwares| Cables
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>LAPTOPS</td>
                      <td>
                        Display| Main Board| Power Board| Touch Panels|
                        Keyboard| WIFI| Bluetooth| Speakers| Camera| Mic
                        |Software| Hinges| Battery| Plastic Assembly| Cables
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>DISHWASHERS</td>
                      <td>
                        Heating Element| Detergent dispenser| Control Pad|
                        Racks| Silverware Basket| Spray Arms| Door latch| Water
                        Inlet Valve| Circulation Pump/Motor| Filtration System
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>DESKTOPS</td>
                      <td>
                        Dekstop Screen| CPU Unit| Mother Board| RAM| Fan|
                        Hardisk|
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>OTGs</td>
                      <td>
                        Control Board| Indicators Display| Thermostat|
                        Convection Fan| Timer| Ressistor
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>PROJECTORS</td>
                      <td>
                        Filter Cover| Front IR Receiver| Lens| Filter| Kensigton
                        Lock| Control Panel| Rear IR Receiver| Exhaust| Lamp|
                        Remotes
                      </td>
                      <td>NA</td>
                    </tr>

                    <tr>
                      <td>HOME THEATERS</td>
                      <td>
                        Speaker| Power Board| Main Board| Display Board| IR
                        Board| Loader Assembly| IO Board| MIC|Remote| Power
                        Adaptor|IR Board| Loader Assembly| IO Board| MIC|Remote.
                      </td>
                      <td>NA</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="pdfFooter mt-5">
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className="queHeading fst-normal">Ver 1.1</h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerFAQPDF;
