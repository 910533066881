/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import axios from "axios";

const AdminProductsCategoryDetails = (props) => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const params = useParams();
  const navigate = useNavigate();
  const [formErrors, SetformError] = useState({});
  const [SubCategory, setSubCategory] = useState([]);
  const [InputBrandFields, setInputBrandFieldSub] = useState("");
  const [Items, setItems] = useState([]);
  const initialValues = { Product: "" };
  const [formProductValues, setformProductValues] = useState(initialValues);
  const [addMultipleBrand, setaddMultipleBrand] = useState();
  const [isSubmitForm, SetIsSubmitForm] = useState(false);

  console.log("object", params);
  const Validate = (values, Items) => {
    const error = {};
    const Nameregex = /^[a-zA-Z ]*$/;

    if (!values.Product) {
      error.Product = "Product name is required!";
    }
    // if (!Nameregex.test(values.Product)) {
    //   error.Product = " Invalid Product name!";
    // }
    if (!Items.length) {
      error.Items = "Field should not be blank";
    }
    return error;
  };

  // Api call htmlFor Product/subcategory list by id
  useEffect(() => {
    var data = "";
    var config = {
      method: "get",
      url: `${BaseAPI.nodeAPI}subcategory/categorywise/${params.Productid}`,
      headers: {
        Authorization: adminauth?.data?.token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("productlist", response?.data);
        setSubCategory(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // TO create the list of brands on plus sign
  const itemEvent = (e) => {
    setInputBrandFieldSub(e.target.value);
  };
  const AddSubBrand = () => {
    setItems((oldItems) => {
      return [...oldItems, InputBrandFields];
    });
    setInputBrandFieldSub("");
  };

  // function htmlFor to target value by name in add new category
  const handleChange = (e) => {
    const { name, value } = e.target;
    setformProductValues({ ...formProductValues, [name]: value });
    console.log("formProductValues", formProductValues);
  };

  // Api call htmlFor to add more brand in product/subcategory
  const handleSubmitProduct = (e) => {
    e.preventDefault();
    SetformError(Validate(formProductValues, Items));
    SetIsSubmitForm(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitForm) {
      try {
        var data = JSON.stringify({
          category_id: params.Productid,
          subcategory_name: formProductValues.Product,
          brand_name: Items,
        });

        var config = {
          method: "post",
          url: `${BaseAPI.nodeAPI}brand/add_multiple_brand`,
          headers: {
            Authorization: adminauth?.data?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config).then(function (response) {
          console.log(response.data);
          window.$("#addNewCategory").modal("hide");
          setaddMultipleBrand(response?.data?.data);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Service Center</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 pageBack d-flex">
                    <NavLink to={AdminRoutes.AdminProducts}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">Category Details</p>
                  </div>
                  <div className="col-7 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light me-3 "
                      data-bs-toggle="modal"
                      data-bs-target="#addNewCategory"
                    >
                      <i className="fas fa-plus" aria-hidden="true"></i> Add New
                      Product
                    </button>
                    <a
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      href="#/admin-products"
                    >
                      Save
                    </a>
                  </div>

                  {/* <div className="col-7 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light me-3 "
                      data-bs-toggle="modal"
                      data-bs-target="#addNewCategory"
                    >
                      <i className="fas fa-plus"></i> Add New Product
                    </button>
                    <NavLink
                      to={AdminRoutes.AdminProducts}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                      Save
                    </NavLink>
                  </div> */}
                </div>

                <form className="needs-validation mt-4">
                  <div className="row">
                    {SubCategory &&
                      SubCategory?.map((val) => {
                        return (
                          <>
                            <div className="col-xl-3 col-lg-6 col-md-6">
                              <div className="computerDetails p-5 mb-5">
                                {/* <i className="fa fa-sharp fa-solid fa-laptop blueText"></i> */}
                                <div className="text-center">
                                  <img
                                    src={val?.image}
                                    className="img-fluid"
                                    alt=""
                                  />
                                  {/* <svg width="130" height="130" viewBox="0 0 150 150">
                                  <g id="Group_10198" data-name="Group 10198" transform="translate(0.001 25)">
                                    <path id="Path_10272" data-name="Path 10272" d="M0,86.987c2.1-.034,4.2-.153,6.3-.074,1.384.053,1.857-.341,1.843-1.791-.069-7.383-.032-14.766-.032-22.15,0-17.6.123-35.207-.063-52.808C7.979,3.533,12.893-.01,18.185,0Q75.027.111,131.869.043c5.181,0,8.751,2.521,9.988,6.952a11.676,11.676,0,0,1,.244,3.2q.008,37.259,0,74.518c0,2.165,0,2.165,2.137,2.168,1.565,0,3.13.027,4.694,0,1-.018,1.353.427,1.395,1.413a11.292,11.292,0,0,1-11,11.691q-64.25.03-128.5,0a10.817,10.817,0,0,1-10.3-7.534C.37,92,.178,91.548,0,91.094V86.987M75.219,10.807H17.274c-1.91,0-1.914,0-1.914,1.95q0,33.74,0,67.48c0,1.946,0,1.95,1.913,1.95h115.6c1.985,0,1.987,0,1.987-2.023q0-33.667,0-67.334c0-2.02,0-2.024-1.987-2.024H75.219M75,7.454a1.935,1.935,0,0,0,2.1-1.947,2.027,2.027,0,0,0-2.048-2.189A2.208,2.208,0,0,0,72.886,5.45a2.113,2.113,0,0,0,2.112,2" transform="translate(0 0)" fill="#138d8e" />
                                  </g>
                                </svg> */}
                                </div>

                                <p className="mb-2 fw-bold blueText col-12 fs-4">
                                  {val?.name}
                                </p>
                                {/* <a href="#/admin-products-details" className="btn blueText waves-effect waves-light p-0 mt-5"><u>View Details</u></a> */}

                                <NavLink
                                  to={
                                    "/admin-products-details/" +
                                    val._id +
                                    "/" +
                                    val.name +
                                    "/" +
                                    params?.Productid +
                                    "/" +
                                    params?.Productname
                                  }
                                  className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                                >
                                  View Details
                                </NavLink>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade w-100 h-100"
        tabIndex="-1"
        role="dialog"
        id="addNewCategory"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title mt-0">Add New Category</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="categoryname">
                    Category Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder="Enter Category Name"
                    required
                    name="Category"
                    value={formProductValues.Category}
                    onChange={handleChange}
                    defaultValue={params.Productname}
                  />
                </div>

                <div className="col">
                  <label className="form-label" htmlFor="productname">
                    Product Name
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder="Enter Product Name"
                    required
                    name="Product"
                    value={formProductValues.Product}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{formErrors.Product}</p>
                </div>
                <div className="col">
                  <label className="form-label" htmlFor="brandname">
                    Brand Name
                  </label>

                  <input
                    type="text"
                    className="form-control mb-2"
                    id="brandName"
                    placeholder="Enter Brand Name"
                    required
                    value={InputBrandFields}
                    onChange={itemEvent}
                  />
                  <p className="text-danger">{formErrors.Items}</p>
                  <span className="newCategoryAdd formIcons">
                    <i
                      className="fas fa-plus-circle blueText h4 cursorPointer"
                      onClick={AddSubBrand}
                    ></i>
                  </span>
                  <ol>
                    {Items?.map((itemval) => {
                      return <li>{itemval}</li>;
                    })}
                  </ol>
                </div>
              </div>
            </div>
            <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
              <button
                type="button"
                className="btn addBtns waves-effect waves-light px-4"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn filledaddBtns waves-effect waves-light px-4"
                onClick={(e) => handleSubmitProduct(e)}
              >
                {" "}
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductsCategoryDetails;
