/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";

const AdminProducts = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // state for brand list
  const [InputBrandFields, setInputBrandFieldSub] = useState("");
  const [Items, setItems] = useState([]);
  const [AddProduct, setAddProduct] = useState();
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const initialValues = { Category: "", Product: "" };
  const [formProductValues, setformProductValues] = useState(initialValues);
  const [formErrors, SetformError] = useState({});
  const [ProductList, setProductList] = useState();
  const [Loading, setLoading] = useState(false);
  const [isSubmitForm, SetIsSubmitForm] = useState(false);

  //validation
  // Form Validationregex
  console.log("formErrorsformErrors==>", formErrors);
  const Validate = (values, Items) => {
    const error = {};
    const Nameregex = /^[a-zA-Z ]*$/;
    if (!values.Category) {
      error.Category = "Category name is required!";
    } else if (!Nameregex.test(values.Category)) {
      error.Category = " Invalid Category name!";
    }
    if (!values.Product) {
      error.Product = "Product name is required!";
    }
    // if (!Nameregex.test(values.Product)) {
    //   error.Product = " Invalid Product name!";
    // }
    if (!Items.length) {
      error.Items = "Field should not be blank";
    }
    return error;
  };
  // TO craete the list of brands on plus sign
  const itemEvent = (e) => {
    setInputBrandFieldSub(e.target.value);
  };
  const AddSubBrand = () => {
    setItems((oldItems) => {
      return [...oldItems, InputBrandFields];
    });
    setInputBrandFieldSub("");
  };
  console.log("object", Items);

  // function for to target value by name in add new category
  const handleChange = (e) => {
    const { name, value } = e.target;
    setformProductValues({ ...formProductValues, [name]: value });
    console.log("formProductValues", formProductValues);
  };

  // API CALL FOR ADD new PRODUCT
  const handleSubmitProduct = (e) => {
    e.preventDefault();
    SetformError(Validate(formProductValues, Items));
    SetIsSubmitForm(true);
  };

  useEffect(async () => {
    if (Object.keys(formErrors).length === 0 && isSubmitForm) {
      try {
        var data = JSON.stringify({
          category_name: formProductValues.Category,
          subcategory_name: formProductValues.Product,
          brand_name: Items,
        });

        var config = {
          method: "post",
          url: `${BaseAPI.nodeAPI}brand/add_multiple_brand`,
          headers: {
            Authorization: adminauth?.data?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config).then(function (response) {
          console.log(response.data);
          if (response?.data?.status === 1) {
            setAddProduct(response?.data?.data);
            toast.success(response.data?.message, { position: "top-right" });
            window.$("#addNewCategory").modal("hide");
            Refresh();
          }
          // else{
          //   toast.error(response?.data?.message, { position: 'top-right' });
          // }
        });
      } catch (error) {
        console.log(error);
        toast.error(response?.data?.message, { position: "top-right" });
      }
    }
  }, [formErrors]);

  // API Call for listing product
  useEffect(() => {
    setLoading(true);
    var data = JSON.stringify({
      filterData: {},
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}category/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setProductList(response.data.data.rows);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log("ProductList", ProductList);
  }, [ProductList]);

  const reset = (e) => {
    setInputBrandFieldSub([]);
    setformProductValues({
      ...initialValues,
      Category: "",
      Product: "",
    });
  };

  //function to refresh the page
  const Refresh = () => {
    window.location.reload(false);
  };
  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Products</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 pageBack d-flex">
                    <p className="fw-bold font-size-18 h6">All Categories</p>
                  </div>

                  <div className="col-7 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light ms-3 "
                      data-bs-toggle="modal"
                      data-bs-target="#addNewCategory"
                    >
                      <i className="fas fa-plus"></i> Add New Category
                    </button>
                  </div>
                </div>
                {Loading ? (
                  <div
                    className="spinner-grow text-info position-absolute tableSpinner"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="row">
                    <div className="mb-3 col-12">
                      <div className="mb-3 col-12 position-relative">
                        <div className="row mt-4">
                          {ProductList?.map((val) => {
                            return (
                              <>
                                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                  <div className="card">
                                    <div className="card-body box-shadow">
                                      <div className="row">
                                        <div className="mb-2 col-12 pageHeading">
                                          <h5 className="fw-bold text-center text-dark">
                                            {val?.name}
                                          </h5>
                                        </div>
                                        <div className="col-12">
                                          <div className="col-6 float-start px-2">
                                            <p className="text-end">
                                              Products :
                                            </p>
                                          </div>
                                          <div className="col-6 float-start px-2">
                                            <p className="fw-bold text-start">
                                              {val?.subcategory_count}
                                            </p>
                                          </div>

                                          <div className="col-6 float-start px-2">
                                            <p className="text-end">Brands :</p>
                                          </div>
                                          <div className="col-6 float-start px-2">
                                            <p className="fw-bold text-start">
                                              {val?.brand_count}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                          <NavLink
                                            to={
                                              "/admin-products-category-details/" +
                                              val._id +
                                              "/" +
                                              val.name
                                            }
                                            className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                                          >
                                            View Details
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        id="addNewCategory"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style={{ height: "auto", width: "auto" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title mt-0">Add New Category</h5>
              <button
                type="button"
                id="modal"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="categoryname">
                    Category Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder="Enter Category Name"
                    required
                    name="Category"
                    value={formProductValues.Category}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{formErrors.Category}</p>
                </div>

                <div className="col">
                  <label className="form-label" htmlFor="productname">
                    Product Name
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder="Enter Product Name"
                    required
                    name="Product"
                    value={formProductValues.Product}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{formErrors.Product}</p>
                </div>
                <div className="col">
                  <label className="form-label" htmlFor="brandname">
                    Brand Name
                  </label>

                  <input
                    type="text"
                    className="form-control mb-2"
                    id="brandName"
                    placeholder="Enter Brand Name"
                    required
                    name="Brand"
                    value={InputBrandFields}
                    onChange={itemEvent}
                  />
                  <p className="text-danger">{formErrors.Items}</p>
                  <span className="newCategoryAdd formIcons">
                    <i
                      className="fas fa-plus-circle blueText h4 cursorPointer"
                      onClick={(e) => AddSubBrand(e)}
                    ></i>
                  </span>
                  <ol>
                    {Items?.map((itemval) => {
                      return <li>{itemval}</li>;
                    })}
                  </ol>
                </div>
              </div>
            </div>
            <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
              <button
                type="button"
                className="btn addBtns waves-effect waves-light px-4"
                data-bs-dismiss="modal"
                onClick={() => {
                  reset();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn filledaddBtns waves-effect waves-light px-4"
                onClick={(e) => handleSubmitProduct(e)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProducts;
