import React from 'react'

const FileNotFound = () => {
  return (
    <div>
       <h1>404 File Not Found</h1>
    </div>
  )
}

export default FileNotFound
