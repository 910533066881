/*eslint-disable*/
import React from "react";

const Profile = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Profile</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">Time,Date</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 pageBack d-flex">
                    <p className="fw-bold font-size-18 h6">
                      My Profile 
                    </p>
                  </div>
                  <div className="col-7 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                      Save
                    </button>
                  </div>
                </div>

                <form className="needs-validation" noValidate>
                  <div className="row">
                    <div className="mb-3 col-12">
                      <div className="col-md-4 col-sm-12 col-12 float-start">
                        <div className="col-12 float-start mt-2 mt-md-4 mb-4">
                          <p className="addUserPic mx-auto mt-1 mb-1">
                            <span className="addPicIcon">
                              <i className="fas fa-pen"></i>
                            </span>
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFile"
                              name="media"
                              multiple="multiple"
                              accept="image/*"
                            />
                            <div className="uplodedImg d-flex align-items-center rounded-circle">
                              {/* <img
                                src={ImageUpload}
                                src={`${BaseAPI.nodeAPI}/images/${ImageUpload.filename}`}
                                className="img-fluid h-100"
                                alt="das"
                              />
                              <br /> */}
                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                name="media"
                                multiple="multiple"
                                accept="image/*"
                              />
                            </div>
                            <label
                              className="custom-file-label mb-0"
                              htmlFor="customFile"
                            ></label>
                          </p>
                          <p className="text-center font14 mb-1 pt-3">
                            Profile Picture
                          </p>
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12 col-12 float-start mb-4">
                        <div className="row ps-0 ps-md-4 mt-2 mt-md-4 mb-4">
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="name">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Enter Name"
                              required
                              name="name"
                            />
                            <p className="text-danger">Errors</p>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="copmanyname">
                              Company Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="companyname"
                              placeholder="Enter Company Name"
                              required
                              name="copmanyname"
                            />
                            <p className="text-danger">Errors</p>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="contact">
                              Contact No.
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contact"
                              placeholder="Enter Contact No"
                              required
                              name="contactno"
                            />
                            <p className="text-danger">Errors</p>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="useremail">
                              E- Mail Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              required
                              name="email"
                            />
                            <p className="text-danger">Errors</p>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="useraddress">
                              Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="useraddress"
                              placeholder="Enter Address"
                              required
                              name="address"
                            />
                            <p className="text-danger">Errors</p>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="useraddress">
                              Pincode
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="useraddress"
                              placeholder="Enter pincode"
                              required
                              name="pincode"
                            />
                            <p className="text-danger">Errors</p>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="usercity">
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="usercity"
                              placeholder="Enter pincode"
                              required
                              name="city"
                            />
                            <p className="text-danger">Errors</p>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="usercity">
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="userState"
                              placeholder="Enter State"
                              required
                              name="state"
                            />
                            <p className="text-danger">Errors</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
