/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const AdminRequestDetails = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">RM Mapping</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">00:00, 06/06/2022</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div class="row pageBack mb-4">
                  <div className="float-start col-6">
                    <NavLink to={AdminRoutes.AdminRMDetails}>
                      <i class="fas fa-arrow-left text-dark"></i>
                    </NavLink>
                    <span className="fw-bold h5 ps-3">Request Details</span>
                  </div>
                  <div className="float-start col-6 text-end">
                    <span class="badge bg-warning text-dark px-2 py-1">
                      Pending
                    </span>
                    <span class="badge bg-secondary px-2 py-1">Completed</span>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="col-12 float-start">
                      <div className="row px-1 mt-2 mt-md-4">
                        <h6 className="fw-bold px-2">Personal Details</h6>
                        <div className="col-md-4 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label fw-bold" for="name">
                            Name
                          </label>
                          <p className="mb-0">Girish Karnad</p>
                        </div>
                        <div className="col-md-4 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label fw-bold" for="email">
                            Email
                          </label>
                          <p className="mb-0">girish.karnad@gmail.com</p>
                        </div>
                        <div className="col-md-4 col-sm-12 float-start px-2">
                          <label className="form-label fw-bold" for="contact">
                            Contact No.
                          </label>
                          <p className="mb-0">+91 8899225566</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 float-start pe-5 mt-4">
                      <h6 className="fw-bold">Subject Here</h6>
                      <p className="">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting.
                      </p>
                    </div>
                    <div className="col-md-12 col-sm-12 float-start px-0 text-end mt-3">
                      <NavLink
                        to={AdminRoutes.AdminRMDetails}
                        type="submit"
                        className="btn addBtns waves-effect waves-light px-3 me-3"
                      >
                        Close Request
                      </NavLink>
                      <button
                        type="submit"
                        className="btn successBtns waves-effect waves-light me-2 px-4"
                        data-bs-toggle="modal"
                        data-bs-target=".requestReply"
                      >
                        Add Reply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade requestReply"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title mt-0">Add Reply</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Upload the CSV file, in correct format</p>
              <div class="form-floating">
                <textarea
                  class="form-control"
                  id="floatingTextarea2"
                  style={{ height: "150px" }}
                ></textarea>
                <label for="floatingTextarea2">Comments</label>
              </div>
            </div>
            <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
              <button
                type="button"
                className="btn addBtns waves-effect waves-light px-4"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn filledaddBtns waves-effect waves-light px-5"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRequestDetails;
