/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";

const AskExpertDetails = () => {
  const navigate = useNavigate();
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const params = useParams();
  const [AskDetails, setAskDetails] = useState([]);
  const [Conumser_id, setConumser_id] = useState("");
  const [AssignTo, setAssignTo] = useState("");
  const [PartnerList, setPartnerList] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Selectone_partner, setSelectone_partner] = useState("");
  const [selected_list, setselected_list] = useState([]);
  const [PartnerResultdropdown, setPartnerResultdropdown] = useState([]);
  const [additionalOffer, setadditionalOffer] = useState("");
  const [StoreValue, setStoreValue] = useState([]);
  const [IsVisibal, setIsVisibal] = useState("");

  useEffect(() => {
    var data = JSON.stringify({
      filterData: {
        _id: params.AskID,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ask_expert/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(
          "consumer_idconsumer_id===>",
          response?.data?.data[0]?.consumer_id
        );
        setAskDetails(response?.data?.data);
        setConumser_id(response?.data?.data[0]?.consumer_id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // console.log(Query_id);
  //api call for update ask the expert status
  const AskExpertUpdate = (value) => {
    var data = JSON.stringify({
      query_status: value ? "Completed" : "In Progress",
    });

    var config = {
      method: "put",
      url: `${BaseAPI.nodeAPI}ask_expert/${params.AskID}`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response.data.message, { position: "top-right" });
        navigate("/ask-expert");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(err.response.data.message, { position: "top-right" });
      });
  };

  useEffect(() => {
    getpartnerList();
  }, []);
  //api call for all partner assign
  const getpartnerList = () => {
    setselected_list([]);
    setLoading(true);

    var data = JSON.stringify({
      filterData: {
        access_login: 5,
        citizen: "Channel Partner",
        is_deleted: false,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ask_expert/partner_list_dropdown`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("partnerlist", response.data.data);
        setPartnerList(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const HandleSubmitAllpartner = () => {
    // console.log("Query_id", Query_id);
    var data = JSON.stringify({
      sending_data: {
        query_id: params.AskID,
        partner_id: "",
      },
      query_send_type: "All",
      response_msg: {
        status: 1,
        message: "Success",
      },
      error_msg: {
        status: 0,
        message: "Errors",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ask_expert_quotation/query_send_to_partner`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response?.data?.message, { position: "top-right" });
        navigate("/ask-expert");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  const handleSubmitOnePartner = () => {
    var data = JSON.stringify({
      sending_data: [
        {
          query_id: params.AskID,
          partner_id: Selectone_partner,
        },
      ],
      query_send_type: "One",
      response_msg: {
        status: 1,
        message: "Success",
      },
      error_msg: {
        status: 0,
        message: "Errors",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ask_expert_quotation/query_send_to_partner`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response?.data?.message, { position: "top-right" });
        navigate("/ask-expert");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  //function to add selected list
  const add_selected_list = (value) => {
    PartnerList.filter((va) => {
      if (va?._id === value) {
        setselected_list([
          ...selected_list,
          { query_id: params.AskID, partner_id: va?._id, title: va?.title },
        ]);
      }
    });
  };

  // console.log("====================================");
  // console.log("selected_list", selected_list);
  // console.log("====================================");

  //function for to remove selected name
  const remove_selected_list = (value) => {
    setselected_list(
      selected_list.filter((va) => {
        return va?.partner_id !== value?.partner_id;
      })
    );
  };

  const handleSubmitMultiplePartner = () => {
    var data = JSON.stringify({
      sending_data: selected_list,
      query_send_type: "Multiple",
      response_msg: {
        status: 1,
        message: "Success",
      },
      error_msg: {
        status: 0,
        message: "Errors",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ask_expert_quotation/query_send_to_partner`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        toast.success(response?.data?.message, { position: "top-right" });
        navigate("/ask-expert");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  useEffect(() => {
    // console.log("params.AskIDparams.AskID============>", params.AskID);
    var data = JSON.stringify({
      filterData: {
        query_id: params.AskID,
        partner_res_status: 1,
      },
      sort_cond: {
        "partner_data.id": -1,
      },
      success_msg: {
        status: 1,
        message: "Success",
      },
      error_msg: {
        data: [],
        status: 0,
        message: "Failed",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ask_expert_quotation/query_list_for_admin`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(
          "ask_expert_quotation/query_list_for_admin",
          response?.data?.data
        );
        setPartnerResultdropdown(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handlecheckbox = (e) => {
    if (e.target.checked === true) {
      setStoreValue([
        ...StoreValue,
        {
          _id: e.target.id,
          consumer_id: Conumser_id,
        },
      ]);
    }
    if (e.target.checked === false) {
      setStoreValue(StoreValue.filter((item) => item !== e.target.id));
    }
  };

  console.log("====================================");
  console.log("SubProduct", StoreValue);
  console.log("====================================");

  const handleSubmitAdditionalOffer = () => {
    var Final_Result = StoreValue.map((obj) => ({
      _id: obj._id,
      consumer_id: obj.consumer_id,
      extra_offer: additionalOffer,
    }));

    console.log("====================================");
    console.log("sending data", Final_Result);
    console.log("====================================");

    var data = JSON.stringify({
      sending_data: Final_Result,
      success_msg: {
        status: 1,
        message: "Success",
      },
      error_msg: {
        status: 0,
        message: "Error",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ask_expert_quotation/result_send_to_consumer`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        toast.success(response?.data?.message, { position: "top-right" });
        navigate("/ask-expert");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  useEffect(() => {
    var data = JSON.stringify({
      data: {
        query_id: params.AskID,
      },
      success_msg: {
        status: 1,
        message: "Success",
      },
      error_msg: {
        status: 0,
        message: "Failed",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ask_expert_quotation/list_api_for_hide_form`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("===================>>>>>>>>>>>>>>>", response.data);
        setIsVisibal(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Welcome to Ask The Expert</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal"></span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="h-100" id="ExpertDetails">
              <div className="card-body bg-white">
                <div className="row pageBack mb-5">
                  <div className="float-start col-12">
                    <NavLink to={"/ask-expert"}>
                      <i className="fas fa-arrow-left text-dark"></i>
                    </NavLink>
                    <span className="fw-bold h5 ps-3">
                      Ask The Expert Details
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="col-md-3 col-sm-12 col-12 float-start">
                      <div className="col-12 float-start mt-2 mt-md-4 mb-4">
                        <div className="user-wid profilePic text-center pt-2 pb-2">
                          <div className="user-img">
                            <img
                              src={AskDetails[0]?.consumer_data[0]?.image}
                              alt=""
                              className="mx-auto rounded-circle"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 col-12 float-start mb-4 h-100">
                      <div className="row h-100 align-items-start">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <p className="fs-5 mb-2">
                            Name.{" "}
                            <span className="blueText fw-bold">
                              {AskDetails[0]?.consumer_data[0]?.name}{" "}
                              {AskDetails[0]?.consumer_data[0]?.last_name}
                            </span>
                          </p>
                          <p className="fs-5 mb-2">
                            Mobile No.{" "}
                            <span className="blueText fw-bold">
                              {AskDetails[0]?.consumer_data[0]?.phone}
                            </span>
                          </p>
                          <p className="fs-5 mb-2">
                            Subscription details:{" "}
                            <span className="blueText fw-bold">
                              {AskDetails[0]?.subsc_data[0]?.title}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <p className="fs-5 mb-2">
                            Purchase Date:{" "}
                            <span className="blueText fw-bold">
                              {moment(
                                AskDetails[0]?.consumer_data[0]?.createdAt
                              ).format("LLLL")}
                            </span>
                          </p>
                          <p className="fs-5 mb-2">
                            Profile Status:{" "}
                            <span
                              className={
                                AskDetails?.status === 1
                                  ? "badge bg-success"
                                  : "badge rounded bg-warning"
                              }
                            >
                              {AskDetails?.status === 1
                                ? "Verified"
                                : "Not-Verified"}
                            </span>
                          </p>
                        </div>
                        {/* <div className="col-md-4 col-sm-12 float-start px-2 mb-3">
                          <p className="fs-5">Profile Status:</p>
                          <span
                            className={
                              AskDetails?.status === 1
                                ? "badge bg-success"
                                : "badge rounded bg-warning"
                            }
                          >
                            {AskDetails?.status === 1
                              ? "Verified"
                              : "Not-Verified"}
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pt-5">
                  {/* <div className="col">
                                        <p className="fs-5 fw-bold">Query No.</p>
                                        <p className="text-muted mt-3"></p>
                                    </div> */}
                  <div className="col">
                    <p className="fs-5 fw-bold">Sub_ID</p>
                    <p className="text-muted mt-3">
                      {AskDetails[0]?.consumer_data[0]?.code}
                    </p>
                  </div>
                  <div className="col">
                    <p className="fs-5 fw-bold">Date/Time</p>
                    <p className="text-muted mt-3">
                      {moment(AskDetails[0]?.createdAt).format("LLLL")}
                    </p>
                  </div>
                  <div className="col">
                    <p className="fs-5 fw-bold">Product Details</p>
                    <p className="text-muted mt-3">
                      {AskDetails[0]?.category_name} -{" "}
                      {AskDetails[0]?.subcategory_name} -{" "}
                      {AskDetails[0]?.brand_name}
                    </p>
                  </div>
                  <div className="col">
                    <p className="fs-5 fw-bold">Budget Range</p>
                    <p className="text-muted mt-3">
                      INR {AskDetails[0]?.min_range} -{" "}
                      {AskDetails[0]?.max_range}
                    </p>
                  </div>
                  <div className="col">
                    <p className="fs-5 fw-bold mb-3">Query Status {} </p>
                    {/* {AskDetails.length ? (
                      <>
                        {
                          <div className="float-start text-end">
                            <input
                              type="checkbox"
                              id="switch4"
                              switch="success"
                              className="progresstoggle"
                              defaultChecked={
                                AskDetails[0]?.query_status === "Completed"
                                  ? true
                                  : false
                              }
                              onClick={(e) => AskExpertUpdate(e.target.checked)}
                            />
                            <label
                              className="form-label"
                              htmlFor="switch4"
                              data-on-label="Completed"
                              data-off-label="In Process"
                            ></label>
                          </div>
                        }
                      </>
                    ) : null} */}
                    <span
                      className={
                        AskDetails[0]?.query_status === "In Progress"
                          ? "badge bg-warning"
                          : "badge rounded bg-success"
                      }
                    >
                      {AskDetails[0]?.query_status === "In Progress"
                        ? "In Process"
                        : "Completed"}
                    </span>
                  </div>
                </div>
                {/* <div className="row justify-centent-center mt-5">
                                    <div className="col-11">
                                        <p className="fs-5 fw-bold">Query Details</p>
                                        <p className="text-muted mt-3">{AskDetails[0]?.query_remark}</p>
                                    </div>
                                </div> */}

                <hr />
                {!IsVisibal ? (
                  <>
                    {AskDetails[0]?.query_status === "In Progress" ? (
                      <>
                        <div className="row mb-5">
                          <div className="col-12">
                            <p className="fs-5 fw-bold blueText">Assign to</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-check form-check-inline me-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="All"
                                onChange={(e) => setAssignTo(e.target.value)}
                                // onChange={(e) => console.log(e.target.value)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                All
                              </label>
                            </div>

                            <div className="form-check form-check-inline me-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="One"
                                onChange={(e) => setAssignTo(e.target.value)}
                                // onChange={(e) => console.log(e.target.value)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                One
                              </label>
                            </div>

                            <div className="form-check form-check-inline me-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio3"
                                value="Multiple"
                                onChange={(e) => setAssignTo(e.target.value)}
                                // onChange={(e) => console.log(e.target.value)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio3"
                              >
                                Multiple
                              </label>
                            </div>
                          </div>

                          {AssignTo === "One" ? (
                            <div className="col-md-12 col-sm-12 float-start mb-5 mt-5">
                              <label
                                className="form-label"
                                htmlFor="inlineRadio2"
                              >
                                Select One Partner
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) =>
                                  setSelectone_partner(e.target.value)
                                }
                                // onChange={(e) => console.log(e.target.value)}
                              >
                                {Loading ? (
                                  <option>Loading...</option>
                                ) : (
                                  <>
                                    <option selected>Select</option>
                                    <>
                                      {PartnerList.length &&
                                        PartnerList?.map((val) => (
                                          <>
                                            <option value={val?._id}>
                                              {val?.title}
                                            </option>
                                          </>
                                        ))}
                                    </>
                                  </>
                                )}
                              </select>
                              <p className="text-danger">
                                {/* {formErrors.PageName} */}
                              </p>
                            </div>
                          ) : null}

                          {AssignTo === "Multiple" ? (
                            <div className="add_recipients_name mt-3 p-4">
                              <div className="d-flex">
                                <label
                                  className="form-label"
                                  htmlFor="inlineRadio3"
                                >
                                  Select Multiple Partner
                                </label>
                                <select
                                  className="form-select planSelect w-100"
                                  aria-label="Default select example"
                                  value="name"
                                  id="name"
                                  onChange={(e) =>
                                    add_selected_list(e.target.value)
                                  }
                                >
                                  {Loading ? (
                                    <option>Loading...</option>
                                  ) : (
                                    <>
                                      <option selected>Select</option>
                                      <>
                                        {PartnerList.length &&
                                          PartnerList?.map((val) => (
                                            <>
                                              <option value={val?._id}>
                                                {val?.title}
                                              </option>
                                            </>
                                          ))}
                                      </>
                                    </>
                                  )}
                                </select>
                              </div>
                              {selected_list.map((value) => {
                                return (
                                  <p className="p-3 add_recipients_text me-3">
                                    {value.title}
                                    <span
                                      onClick={() =>
                                        remove_selected_list(value)
                                      }
                                    >
                                      <i className="fa-sharp fa-solid fa-xmark ms-3"></i>
                                    </span>
                                  </p>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>

                        {AssignTo === "All" ? (
                          <div className="row mt-5">
                            <div className="col-12">
                              <div className="float-end">
                                <NavLink
                                  to={AdminRoutes.AskExpert}
                                  type="button"
                                  className="btn greyBtns borderradius10 waves-effect waves-light px-5 me-3"
                                >
                                  Cancel
                                </NavLink>
                                <button
                                  className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                                  onClick={(e) => HandleSubmitAllpartner()}
                                >
                                  Submit to All
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {AssignTo === "One" ? (
                          <div className="row mt-5">
                            <div className="col-12">
                              <div className="float-end">
                                <NavLink
                                  to={AdminRoutes.AskExpert}
                                  type="button"
                                  className="btn greyBtns borderradius10 waves-effect waves-light px-5 me-3"
                                >
                                  Cancel
                                </NavLink>
                                <button
                                  className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                                  onClick={(e) => handleSubmitOnePartner()}
                                >
                                  Submit One Partner
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {AssignTo === "Multiple" ? (
                          <div className="row mt-5">
                            <div className="col-12">
                              <div className="float-end">
                                <NavLink
                                  to={AdminRoutes.AskExpert}
                                  type="button"
                                  className="btn greyBtns borderradius10 waves-effect waves-light px-5 me-3"
                                >
                                  Cancel
                                </NavLink>
                                <button
                                  className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                                  onClick={(e) => handleSubmitMultiplePartner()}
                                >
                                  Submit Multiple Partner
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}

                {PartnerResultdropdown?.length &&
                AskDetails[0]?.query_status === "In Progress" ? (
                  <>
                    <div className="row mt-5">
                      <div className="col-12">
                        <p className="fs-5 fw-bold blueText">Results</p>
                      </div>
                      <div className="col-12 mt-3">
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>Select</th>
                                <th>Company Name</th>
                                <th>Brand Name</th>
                                <th>Warranty Details</th>
                                <th>Partner Offer</th>
                                <th>Mint price</th>
                                <th>Online price</th>
                                <th>Offline price</th>
                              </tr>
                            </thead>
                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                                {!PartnerResultdropdown?.length ? (
                                  <>
                                    <span className="d-block text-center p-2 border">
                                      Data not found
                                    </span>
                                  </>
                                ) : (
                                  <tbody>
                                    <>
                                      {PartnerResultdropdown?.length
                                        ? PartnerResultdropdown?.map((val) => (
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id={val._id}
                                                  value="option1"
                                                  onChange={(e) =>
                                                    handlecheckbox(e)
                                                  }
                                                />
                                              </td>
                                              <td>
                                                {
                                                  val?.partner_company_data[0]
                                                    ?.company_name
                                                }
                                              </td>
                                              <td>{val?.brand_name}</td>
                                              <td>{val?.warranty_details}</td>
                                              {val?.additional_offer ? (
                                                <td>{val?.additional_offer}</td>
                                              ) : (
                                                <td>NA</td>
                                              )}
                                              <td>{val?.mint_store}</td>
                                              <td>{val?.online_price}</td>
                                              <td>{val?.retail_price}</td>
                                            </tr>
                                          ))
                                        : null}
                                    </>
                                  </tbody>
                                )}{" "}
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                      <div className="col-12 mt-5">
                        <p className="fs-5 fw-bold blueText">
                          Additional Offer(Optional)
                        </p>
                        <textarea
                          className="form-control mt-2"
                          id="floatingTextarea2"
                          rows="5"
                          onChange={(e) => setadditionalOffer(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12">
                        <div className="float-end">
                          <NavLink
                            to={AdminRoutes.AskExpert}
                            type="button"
                            className="btn greyBtns borderradius10 waves-effect waves-light px-5 me-3"
                          >
                            Cancel
                          </NavLink>
                          <button
                            className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                            onClick={(e) => [
                              handleSubmitAdditionalOffer(),
                              AskExpertUpdate("Completed"),
                            ]}
                          >
                            Submit Result
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskExpertDetails;
