/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";

const ConsumerDetails = () => {
  const navigate = useNavigate();
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const params = useParams();
  const [ConsumerDetails, setConsumerDetails] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Orderid, setOrderid] = useState("");
  const [Trackingid, setTrackingid] = useState("");
  const [Bankref, setBankref] = useState("");
  const [OrderStatus, setOrderStatus] = useState("");
  const [PaymentMode, setPaymentMode] = useState("");
  const [CardName, setCardName] = useState("");
  const [Statusmessage, setStatusmessage] = useState("");
  const [Currency, setCurrency] = useState("");
  const [Amount, setAmount] = useState("");
  //const [MerAmount, setMerAmount] = useState("")
  const [Retry, setRetry] = useState("");
  const [TransDate, setTransDate] = useState("");
  const [BillingName, setBillingName] = useState("");
  const [BillingAddress, setBillingAddress] = useState("");
  const [BillingCity, setBillingCity] = useState("");
  const [BillingState, setBillingState] = useState("");
  const [Billingzip, setBillingzip] = useState("");
  const [BillingCountry, setBillingCountry] = useState("");
  const [BillingTel, setBillingTel] = useState("");
  const [BillingEmail, setBillingEmail] = useState("");
  const [DilivaryName, setDilivaryName] = useState("");
  const [DilivaryAddress, setDilivaryAddress] = useState("");
  const [DilivaryCity, setDilivaryCity] = useState("");
  const [DilivaryState, setDilivaryState] = useState("");
  const [DilivaryZip, setDilivaryZip] = useState("");
  const [DilivaryCountry, setDilivaryCountry] = useState("");
  const [DilivaryTel, setDilivaryTel] = useState("");
  const [SubscriptionList, setSubscriptionList] = useState("");
  const [Subscriptionid, setSubscriptionid] = useState("");
  const [Partnerid, setPartnerid] = useState("");

  const [Partnerlist, setPartnerlist] = useState([]);
  const [EW_PlanList, setEW_PlanList] = useState([]);
  const [EWProductList, setEWProductList] = useState([]);
  const [EWProductID, setEWProductID] = useState([]);
  const [ProductID, setProductID] = useState();
  const [getTimePeriod, setgetTimePeriod] = useState([]);
  const [ew_month, setew_month] = useState([]);
  const [ew_discountID, setew_discontID] = useState();
  const [ewProduct_purchase_prise, setewProduct_purchase_prise] = useState([]);
  const [formErrors, setformError] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);

  // API CALL FOR CONSUMER dETAILS
  useEffect(() => {
    const ConsumerDetailsData = () => {
      setLoading(true);
      var data = "";
      var config = {
        method: "get",
        url: `${BaseAPI.nodeAPI}users/${params.consumerid}`,
        headers: {
          Authorization: adminauth?.data?.token,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response?.data?.data);
          setConsumerDetails(response?.data?.data);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    };
    ConsumerDetailsData();
  }, []);

  //api call for subscription list for ccavenue data

  useEffect(() => {
    getSubscriptionList();
  }, []);

  //api call for subscription list
  const getSubscriptionList = () => {
    var data = JSON.stringify({
      filterData: {
        subscription_for: 1,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}subscription/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data?.data?.rows);
        setSubscriptionList(response.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Handlesubcategory_id = (value, index) => {
    console.log(value);
  };

  useEffect(() => {
    console.log("Subscriptionid", Subscriptionid);
  }, [Subscriptionid]);

  //api call for to fill ccavenue data by conumer id from admin side manually
  const Submitconsumerccavenuedetails = () => {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();
    var expiry_date = new Date(year + 1, month, day, hours, minutes, seconds);
    let date_1 = expiry_date.getDate();
    let month_1 = expiry_date.getMonth() + 1;
    if (date_1 < 10) {
      date_1 = `0${expiry_date.getDate()}`;
    }
    if (month_1 < 10) {
      month_1 = `0${expiry_date.getMonth() + 1}`;
    }
    var da_te = `${date_1}/${month_1}/${expiry_date.getFullYear()} ${expiry_date.getHours()}:${expiry_date.getMinutes()}:${expiry_date.getSeconds()}`;
    console.log("expiry date", da_te);
    var data = JSON.stringify({
      data: {
        order_id: parseInt(Orderid),
        tracking_id: parseInt(Trackingid),
        bank_ref_no: parseInt(Bankref),
        order_status: OrderStatus,
        payment_mode: PaymentMode,
        card_name: CardName,
        currency: Currency,
        amount: parseInt(Amount),
        mer_amount: parseInt(Amount),
        trans_date: TransDate,
        billing_name: BillingName,
        billing_address: BillingAddress,
        billing_city: BillingCity,
        billing_state: BillingState,
        billing_zip: Billingzip,
        billing_country: BillingCountry,
        billing_tel: BillingTel,
        billing_email: BillingEmail,
        delivery_name: DilivaryName,
        delivery_address: DilivaryAddress,
        delivery_city: DilivaryCity,
        delivery_state: DilivaryState,
        delivery_zip: DilivaryZip,
        delivery_country: DilivaryCountry,
        delivery_tel: DilivaryTel,
        user_id: params.consumerid,
        subscription_id: Subscriptionid,
        access_login: 4,
        expire_date: da_te,
      },
      partner_volt: {
        status: 1,
        title: "Subscription",
        partner_id: Partnerid,
        consumer_id: params.consumerid,
        subsc_payment_id: "",
        subscription_id: Subscriptionid,
      },
      response_msg: {
        status: 1,
        message: "Subscription Added successfully",
      },
      error_msg: {
        status: 0,
        message: "Error",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}buysubscription/add_payment`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("Timeperiod", response.data);
        setgetTimePeriod(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //api call for product list
  const getProductList = () => {
    var data = JSON.stringify({
      filterData: {
        consumer_id: params.consumerid,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ewproduct/list_for_admin`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("product====?", response?.data?.data);
        setEWProductList(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("Purchase_purchase_prise", ewProduct_purchase_prise);

  //api call for get partner list
  const getEWpartnerlist = () => {
    var data = JSON.stringify({
      filterData: {
        access_login: 5,
        citizen: "Channel Partner",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}partner_product/user_list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("partnerlist", response?.data?.data);
        setPartnerlist(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //api call for ew plan list
  const getEWplanList = () => {
    var data = JSON.stringify({
      filterData: {
        citizen: "Indian",
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ew_plan/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("ewplanlist", response?.data?.data);
        setEW_PlanList(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //api call for time period
  const getTimeperiodData = () => {
    var data = JSON.stringify({
      filterData: {
        ew_plan_id: Subscriptionid,
        subcategory_id: EWProductID,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}ew_discount/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data?.data);
        setgetTimePeriod(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //api call for partner list
  useEffect(() => {
    getEWplanList();
    getProductList();
    getEWpartnerlist();
  }, []);

  useEffect(() => {
    getTimeperiodData();
  }, [EWProductID, Subscriptionid]);

  //validation for ewplan
  const ewplanValidation = () => {
    const error = {};
    const firstnameregex = /^[a-zA-Z]*$/;
    const numberreges = /^[0-9]*$/;

    if (!Orderid) {
      error.Orderid = "Order id is required!";
    } else if (!numberreges.test(Orderid)) {
      error.Orderid = " Invalid Order id!";
    }
    if (!Trackingid) {
      error.Trackingid = "Tracking id is required!";
    } else if (!numberreges.test(Trackingid)) {
      error.Trackingid = " Invalid Tracking id!";
    }
    if (!Bankref) {
      error.Bankref = "Bank ref no is required!";
    } else if (!numberreges.test(Bankref)) {
      error.Bankref = " Invalid Bank ref no!";
    }
    if (!OrderStatus) {
      error.OrderStatus = "Order status is required!";
    } else if (!firstnameregex.test(OrderStatus)) {
      error.OrderStatus = " Invalid order status!";
    }
    if (!PaymentMode) {
      error.PaymentMode = "Payment mode is required!";
    } else if (!firstnameregex.test(PaymentMode)) {
      error.PaymentMode = " Invalid payment mode!";
    }
    if (!CardName) {
      error.CardName = "Card name is required!";
    } else if (!firstnameregex.test(CardName)) {
      error.CardName = " Invalid Card Name!";
    }
    if (!Currency) {
      error.Currency = "Please select Currency!";
    } else if (!firstnameregex.test(Currency)) {
      error.Currency = " Invalid Currency!";
    }
    if (!Amount) {
      error.Amount = "Amount is required!";
    } else if (!numberreges.test(Amount)) {
      error.Bankref = " Invalid Amount!";
    }
    if (!TransDate) {
      error.TransDate = "Trans date is required!";
    }
    if (!ProductID) {
      error.ProductID = "Product list is required!";
    }

    if (!Subscriptionid) {
      error.Subscriptionid = "EW plan id is required!";
    }
    if (!ew_discountID) {
      error.ew_discountID = "Time Period is required!";
    }
    if (!Partnerid) {
      error.Partnerid = "Partnerid is required!";
    }

    return error;
  };

  //submit api call for ewplan
  const SubmitEW_plandata = async (e) => {
    setformError(ewplanValidation());
    SetIsSubmit(true);
    HandleSubmitEW_Plan();
  };

  //api call for ewplan form
  const HandleSubmitEW_Plan = () => {
    if (Object.keys(formErrors).length === 0) {
      // console.log("setew_monthsetew_month==>",ew_month)
      // console.log("ew_discountID",ew_discountID)
      var futureMonth = moment().add(ew_month, "M");
      var Expiry_date = moment(futureMonth).format("DD/MM/YYYY h:mm:ss");
      console.log("aaa", Expiry_date);

      // console.log("setew_monthsetew_month==>",A)
      // console.log("ew_discountID",ew_discountID)
      try {
        var data = JSON.stringify({
          data: {
            order_id: parseInt(Orderid),
            tracking_id: parseInt(Trackingid),
            bank_ref_no: parseInt(Bankref),
            order_status: OrderStatus,
            payment_mode: PaymentMode,
            card_name: CardName,
            currency: Currency,
            amount: parseInt(Amount),
            mer_amount: parseInt(Amount),
            trans_date: TransDate,
            billing_name: BillingName,
            billing_address: BillingAddress,
            billing_city: BillingCity,
            billing_state: BillingState,
            billing_zip: Billingzip,
            billing_country: BillingCountry,
            billing_tel: BillingTel,
            billing_email: BillingEmail,
            delivery_name: DilivaryName,
            delivery_address: DilivaryAddress,
            delivery_city: DilivaryCity,
            delivery_state: DilivaryState,
            delivery_zip: DilivaryZip,
            delivery_country: DilivaryCountry,
            delivery_tel: DilivaryTel,
            purchase_price: ewProduct_purchase_prise,
            expire_date: Expiry_date,
            ewproduct_id: ProductID,
            ew_discount_id: ew_discountID,
            user_id: params.consumerid,
            access_login: 4,
          },
          partner_volt: {
            title: "EW",
            partner_id: Partnerid,
            consumer_id: params.consumerid,
            ew_payment_id: "",
            ewproduct_id: ProductID,
            ew_discount_id: ew_discountID,
          },
          response_msg: {
            status: 1,
            message: "Added successfully",
          },
          error_msg: {
            status: 0,
            message: "Error",
          },
        });

        var config = {
          method: "post",
          url: `${BaseAPI.nodeAPI}buy_ew_plan/add`,
          headers: {
            Authorization: adminauth?.data?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config).then(function (response) {
          console.log(response.data);
          toast.success(response?.data?.message, { position: "top-right" });
          Refresh();
        });
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      }
    }
  };

  //function to refresh the page
  const Refresh = () => {
    window.location.reload(false);
  };
  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Consumer</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row pageBack mb-4">
                  <div className="float-start col-6">
                    <NavLink to={AdminRoutes.AdminUserManagementConsumer}>
                      <i className="fas fa-arrow-left text-dark"></i>
                    </NavLink>
                    <span className="fw-bold h5 ps-3">Consumer Details</span>
                  </div>
                  <div className="float-start col-6 text-end">
                    <span
                      className={
                        ConsumerDetails?.user_data?.status == 1
                          ? "badge bg-success px-2 py-1"
                          : "badge rounded bg-warning"
                      }
                    >
                      {ConsumerDetails?.user_data?.status == 1
                        ? " Verified"
                        : " Not-Verified"}
                    </span>
                  </div>
                </div>
                {Loading ? (
                  <div
                    className="spinner-grow text-info position-absolute tableSpinner"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="row">
                    <div className="mb-3 col-12">
                      <div className="col-md-4 col-sm-12 col-12 float-start">
                        <div className="col-12 float-start mt-2 mt-md-4 mb-4">
                          <div className="user-wid profilePic text-center pt-2 pb-2">
                            <div className="user-img">
                              <img
                                src={ConsumerDetails?.user_data?.image}
                                alt=""
                                className="mx-auto rounded-circle"
                              />
                            </div>
                          </div>
                          <p className="text-center font14 mb-1">
                            Profile Picture
                          </p>
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12 col-12 float-start mb-4">
                        <div className="row ps-0 ps-md-2">
                          <div className="col-12 float-start mb-3">
                            <div className="row">
                              <h6 className="heliosRounded robotofont fw-bold regSecondHead px-2">
                                PERSONAL DETAILS
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">First Name</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">Last Name</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.last_name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">Mobile</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.phone}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">City</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.city}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">State</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.state}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">Pincode</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.pincode}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">Area</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.area}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">Landmark</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.landmark}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">Address</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.flat_no}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <div className="row">
                            <div className="col-4">Occupation</div>
                            <div className="col-8 fw-bold">Businessman</div>
                          </div>
                        </div> */}
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">Education</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.education}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">Date Of Birth</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.dob}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-4">Marital Status</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.user_data?.marital_status}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 float-start mb-3">
                        <div className="row">
                          <h6
                            className="heliosRounded robotofont fw-bold regSecondHead px-2"
                            style={{ color: "#208d8e" }}
                          >
                            SUBSCRIPTION DETAILS
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 col-12 float-start mb-4">
                        <div className="row">
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                            <div className="row">
                              <div className="col-4">Subscription Plan :</div>
                              <div className="col-8 fw-bold">
                                {ConsumerDetails?.subscription_data?.title}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                            <div className="row">
                              <div className="col-4">Purchase Date :</div>
                              {ConsumerDetails?.subscription_data?.title ===
                              "BASIC" ? (
                                <div className="col-8 fw-bold">
                                  {moment(
                                    ConsumerDetails?.user_data?.createdAt
                                  ).format("LLLL")}
                                </div>
                              ) : (
                                <div className="col-8 fw-bold">
                                  {ConsumerDetails?.buysubsc_data?.trans_date}
                                </div>
                              )}
                            </div>
                          </div>
                          {ConsumerDetails ? (
                            <div className="col-12 float-start px-2">
                              <div className="row">
                                <div className="table-responsive">
                                  <table className="table table-striped mb-0 tablesWrap">
                                    <thead>
                                      <tr>
                                        <th>Product</th>
                                        <th>Extended Warranty</th>
                                        <th>Time Period</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ConsumerDetails &&
                                        ConsumerDetails?.ewproduct_data?.map(
                                          (val, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  {val?.ewproduct_id?.name}
                                                </td>
                                                <td>
                                                  {
                                                    val?.ew_discount_id
                                                      ?.ew_plan_id?.title
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    val?.ew_discount_id
                                                      ?.ew_plan_month
                                                  }{" "}
                                                  Months
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <ul
                            className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#formnav"
                                role="tab"
                              >
                                <span>EW Form</span>
                              </a>
                            </li>

                            {ConsumerDetails?.subscription_data?.title ===
                            "BASIC" ? (
                              <>
                                <li className="nav-item">
                                  <a
                                    className="nav-link "
                                    data-bs-toggle="tab"
                                    href="#subsformnav"
                                    role="tab"
                                  >
                                    <span>Subcription Plan Form</span>
                                  </a>
                                </li>
                              </>
                            ) : null}
                          </ul>

                          {/* subscription plan cavenue form*/}
                          <div className="tab-content text-muted mt-3">
                            <div
                              className="tab-pane active"
                              id="formnav"
                              role="tabpanel"
                            >
                              <div className="col-12 float-start mb-3 mt-5">
                                <div className="row">
                                  <h6 className="heliosRounded robotofont fw-bold regSecondHead px-2">
                                    EW Form
                                  </h6>
                                </div>
                              </div>

                              <div className="col-md-12 col-sm-12 col-12 float-start mb-4">
                                <div className="row mt-2 mt-md-4 mb-4">
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="order_id"
                                    >
                                      Order Id
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="order_id"
                                      placeholder="Enter Order Id"
                                      required
                                      name="Orderid"
                                      onChange={(e) =>
                                        setOrderid(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.Orderid}
                                    </p>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="tracking_id"
                                    >
                                      Tracking Id
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="tracking_id"
                                      placeholder="Enter Tracking Id"
                                      required
                                      name="Trackingid"
                                      onChange={(e) =>
                                        setTrackingid(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.Trackingid}
                                    </p>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="bank_ref_no"
                                    >
                                      Bank Ref No.
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="bank_ref_no"
                                      placeholder="Enter Bank Ref No"
                                      required
                                      name="Bankref"
                                      onChange={(e) =>
                                        setBankref(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.Bankref}
                                    </p>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="order_status"
                                    >
                                      Order Status
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="order_status"
                                      placeholder="Enter Order Status"
                                      required
                                      name="OrderStatus"
                                      onChange={(e) =>
                                        setOrderStatus(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.OrderStatus}
                                    </p>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="payment_mode"
                                    >
                                      Payment Mode
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="payment_mode"
                                      placeholder="Enter Payment Mode"
                                      required
                                      name="PaymentMode"
                                      onChange={(e) =>
                                        setPaymentMode(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.PaymentMode}
                                    </p>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="card_name"
                                    >
                                      Card Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="card_name"
                                      placeholder="Enter Card Name"
                                      required
                                      name="CardName"
                                      onChange={(e) =>
                                        setCardName(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.CardName}
                                    </p>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="currency"
                                    >
                                      Currency
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="currency"
                                      placeholder="Enter Currency"
                                      required
                                      name="currency"
                                      onChange={(e) =>
                                        setCurrency(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.Currency}
                                    </p>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="amount"
                                    >
                                      Amount
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="amount"
                                      placeholder="Enter Amount"
                                      required
                                      name="amount"
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.Amount}
                                    </p>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="mer_amount"
                                    >
                                      Mer Amount
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="mer_amount"
                                      placeholder="Enter Mer Amount"
                                      required
                                      name="mer_amount"
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.Amount}
                                    </p>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="trans_date"
                                    >
                                      Trans Date
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="trans_date"
                                      placeholder="Enter Trans Date"
                                      required
                                      name="TransDate"
                                      onChange={(e) =>
                                        setTransDate(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.TransDate}
                                    </p>
                                  </div>
                                </div>
                                <div className="row mt-2 mt-md-4 mb-4">
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_name"
                                    >
                                      Billing Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_name"
                                      placeholder="Enter Billing Name"
                                      required
                                      name="billing_name"
                                      onChange={(e) =>
                                        setBillingName(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_address"
                                    >
                                      Billing Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_address"
                                      placeholder="Enter Billing Address"
                                      required
                                      name="billing_address"
                                      onChange={(e) =>
                                        setBillingAddress(e.target.value)
                                      }
                                    />
                                    {/* {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_city"
                                    >
                                      Billing City
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_city"
                                      placeholder="Enter Billing City"
                                      required
                                      name="billing_city"
                                      onChange={(e) =>
                                        setBillingCity(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_state"
                                    >
                                      Billing State
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_state"
                                      placeholder="Enter Billing State"
                                      required
                                      name="billing_state"
                                      onChange={(e) =>
                                        setBillingState(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_zip"
                                    >
                                      Billing Zip
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_zip"
                                      placeholder="Enter Billing Zip"
                                      required
                                      name="billing_zip"
                                      onChange={(e) =>
                                        setBillingzip(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_country"
                                    >
                                      Billing Country
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_country"
                                      placeholder="Enter Billing Country"
                                      required
                                      name="billing_country"
                                      onChange={(e) =>
                                        setBillingCountry(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_tel"
                                    >
                                      Billing Tel
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_tel"
                                      placeholder="Enter Billing Tel"
                                      required
                                      name="billing_tel"
                                      onChange={(e) =>
                                        setBillingTel(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_email"
                                    >
                                      Billing Email
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="billing_email"
                                      placeholder="Enter Billing Email"
                                      required
                                      name="billing_email"
                                      onChange={(e) =>
                                        setBillingEmail(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                </div>
                                <div className="row mt-2 mt-md-4 mb-4">
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_name"
                                    >
                                      Delivery Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_name"
                                      placeholder="Enter Delivery Name"
                                      required
                                      name="delivery_name"
                                      onChange={(e) =>
                                        setDilivaryName(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_address"
                                    >
                                      Delivery Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_address"
                                      placeholder="Enter Delivery Address"
                                      required
                                      name="delivery_address"
                                      onChange={(e) =>
                                        setDilivaryAddress(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_city"
                                    >
                                      Delivery City
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_city"
                                      placeholder="Enter Delivery City"
                                      required
                                      name="delivery_city"
                                      onChange={(e) =>
                                        setDilivaryCity(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_state"
                                    >
                                      Delivery State
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_state"
                                      placeholder="Enter Delivery State"
                                      required
                                      name="delivery_state"
                                      onChange={(e) =>
                                        setDilivaryState(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_zip"
                                    >
                                      Delivery Zip
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_zip"
                                      placeholder="Enter Delivery Zip"
                                      required
                                      name="delivery_zip"
                                      onChange={(e) =>
                                        setDilivaryZip(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_country"
                                    >
                                      Delivery Country
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_country"
                                      placeholder="Enter Delivery Country"
                                      required
                                      name="delivery_country"
                                      onChange={(e) =>
                                        setDilivaryCountry(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_tel"
                                    >
                                      Delivery Tel
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_tel"
                                      placeholder="Enter Delivery Tel"
                                      required
                                      name="delivery_tel"
                                      onChange={(e) =>
                                        setDilivaryTel(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                </div>
                                <div className="row mt-2 mt-md-4 mb-4">
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="noOfPages"
                                    >
                                      Product List
                                    </label>
                                    <div className="d-flex">
                                      <select
                                        className="form-select planSelect w-100"
                                        aria-label="Default select example"
                                        name="ProductID"
                                        onChange={(e) => {
                                          setEWProductID(
                                            JSON.parse(e.target.value)
                                              .subcategory_id
                                          );
                                          setProductID(
                                            JSON.parse(e.target.value)._id
                                          );
                                          setewProduct_purchase_prise(
                                            JSON.parse(e.target.value)
                                              .purchase_price
                                          );
                                          // console.log("a1", JSON.parse(e.target.value).subcategory_id);
                                          // console.log("a2", JSON.parse(e.target.value).purchase_price)
                                        }}
                                      >
                                        <option selected>Select</option>
                                        {EWProductList?.length &&
                                          EWProductList?.map((val) => (
                                            <>
                                              <option
                                                id={val?._id}
                                                value={JSON.stringify(val)}
                                              >
                                                {val?.name}
                                              </option>
                                            </>
                                          ))}
                                      </select>
                                    </div>
                                    <p className="text-danger">
                                      {formErrors.ProductID}
                                    </p>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="noOfPages"
                                    >
                                      EW Plan Id
                                    </label>
                                    <div className="d-flex">
                                      <select
                                        className="form-select planSelect w-100"
                                        aria-label="Default select example"
                                        name="Subscriptionid"
                                        onChange={(e) => {
                                          setSubscriptionid(e.target.value);
                                        }}
                                      >
                                        <option selected>Select</option>
                                        {EW_PlanList?.length &&
                                          EW_PlanList?.map((val) => (
                                            <>
                                              <option
                                                id={val?._id}
                                                value={val?._id}
                                              >
                                                {val?.title}
                                              </option>
                                            </>
                                          ))}
                                      </select>
                                    </div>
                                    <p className="text-danger">
                                      {formErrors.Subscriptionid}
                                    </p>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="noOfPages"
                                    >
                                      Time Period
                                    </label>
                                    <div className="d-flex">
                                      <select
                                        className="form-select planSelect w-100"
                                        aria-label="Default select example"
                                        name="ew_discountID"
                                        onChange={(e) => {
                                          setew_month(
                                            JSON.parse(e.target.value)
                                              .ew_plan_month
                                          );
                                          setew_discontID(
                                            JSON.parse(e.target.value)._id
                                          );
                                          console.log(
                                            "a1",
                                            JSON.parse(e.target.value)
                                              .ew_plan_month
                                          );
                                          console.log(
                                            "a2",
                                            JSON.parse(e.target.value)._id
                                          );
                                        }}
                                      >
                                        <option selected>Select</option>
                                        {getTimePeriod?.length &&
                                          getTimePeriod?.map((val) => (
                                            <>
                                              <option
                                                id={val?._id}
                                                value={JSON.stringify(val)}
                                              >
                                                {val?.ew_plan_month}Months
                                              </option>
                                            </>
                                          ))}
                                      </select>
                                    </div>
                                    <p className="text-danger">
                                      {formErrors.ew_discountID}
                                    </p>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="partner_id"
                                    >
                                      Partner Id
                                    </label>
                                    <select
                                      className="form-select planSelect w-100"
                                      aria-label="Default select example"
                                      name=""
                                      onChange={(e) => {
                                        setPartnerid(e.target.value);
                                      }}
                                    >
                                      <option selected>Select</option>
                                      {Partnerlist?.length &&
                                        Partnerlist?.map((val) => (
                                          <>
                                            <option
                                              id={val?._id}
                                              value={val?._id}
                                            >
                                              {val?.title}
                                            </option>
                                          </>
                                        ))}
                                    </select>
                                    <p className="text-danger">
                                      {formErrors.Partnerid}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 col-sm-12 float-start px-0  text-end">
                                <button
                                  type="submit"
                                  className="btn successBtns waves-effect waves-light me-2 px-5"
                                  onClick={(e) => SubmitEW_plandata(e)}
                                >
                                  Submit EW plan
                                </button>
                              </div>
                            </div>
                            <div
                              className="tab-pane "
                              id="subsformnav"
                              role="tabpanel"
                            >
                              <div className="col-12 float-start mb-3 mt-5">
                                <div className="row">
                                  <h6 className="heliosRounded robotofont fw-bold regSecondHead px-2">
                                    Subcription Form
                                  </h6>
                                </div>
                              </div>

                              <div className="col-md-12 col-sm-12 col-12 float-start mb-4">
                                <div className="row mt-2 mt-md-4 mb-4">
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="order_id"
                                    >
                                      Order Id
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="order_id"
                                      placeholder="Enter Order Id"
                                      required
                                      name="order_id"
                                      onChange={(e) =>
                                        setOrderid(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">
                                      {formErrors.Orderid}
                                    </p>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="tracking_id"
                                    >
                                      Tracking Id
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="tracking_id"
                                      placeholder="Enter Tracking Id"
                                      required
                                      name="tracking_id"
                                      onChange={(e) =>
                                        setTrackingid(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="bank_ref_no"
                                    >
                                      Bank Ref No.
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="bank_ref_no"
                                      placeholder="Enter Bank Ref No"
                                      required
                                      name="bank_ref_no"
                                      onChange={(e) =>
                                        setBankref(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="order_status"
                                    >
                                      Order Status
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="order_status"
                                      placeholder="Enter Order Status"
                                      required
                                      name="order_status"
                                      onChange={(e) =>
                                        setOrderStatus(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="payment_mode"
                                    >
                                      Payment Mode
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="payment_mode"
                                      placeholder="Enter Payment Mode"
                                      required
                                      name="payment_mode"
                                      onChange={(e) =>
                                        setPaymentMode(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="card_name"
                                    >
                                      Card Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="card_name"
                                      placeholder="Enter Card Name"
                                      required
                                      name="card_name"
                                      onChange={(e) =>
                                        setCardName(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  {/* <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="status_message"
                                    >
                                      Status Message
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="status_message"
                                      placeholder="Enter Status Message"
                                      required
                                      name="status_message"
                                      onChange={(e) =>
                                        setStatusmessage(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">Errors</p> 
                                  </div> */}
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="currency"
                                    >
                                      Currency
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="currency"
                                      placeholder="Enter Currency"
                                      required
                                      name="currency"
                                      onChange={(e) =>
                                        setCurrency(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="amount"
                                    >
                                      Amount
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="amount"
                                      placeholder="Enter Amount"
                                      required
                                      name="amount"
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  {/* <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="vault"
                                    >
                                      Vault
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="vault"
                                      placeholder="Enter Vault"
                                      required
                                      name="vault"
                                      onChange={(e) => setVault(e.target.value)}
                                    />
                                     <p className="text-danger">Errors</p> 
                                  </div> */}
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="mer_amount"
                                    >
                                      Mer Amount
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="mer_amount"
                                      placeholder="Enter Mer Amount"
                                      required
                                      name="mer_amount"
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  {/* <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="retry"
                                    >
                                      Retry
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="retry"
                                      placeholder="Enter Retry"
                                      required
                                      name="retry"
                                      onChange={(e) => setRetry(e.target.value)}
                                    />
                                     <p className="text-danger">Errors</p> 
                                  </div> */}
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="trans_date"
                                    >
                                      Trans Date
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="trans_date"
                                      placeholder="Enter Trans Date"
                                      required
                                      name="trans_date"
                                      onChange={(e) =>
                                        setTransDate(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="noOfPages"
                                    >
                                      Partner Id
                                    </label>
                                    <select
                                      className="form-select planSelect w-100"
                                      aria-label="Default select example"
                                      name=""
                                      onChange={(e) => {
                                        setPartnerid(e.target.value);
                                      }}
                                    >
                                      <option selected>Select</option>
                                      {Partnerlist?.length &&
                                        Partnerlist?.map((val) => (
                                          <>
                                            <option
                                              id={val?._id}
                                              value={val?._id}
                                            >
                                              {val?.title}
                                            </option>
                                          </>
                                        ))}
                                    </select>
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                </div>

                                <div className="row mt-2 mt-md-4 mb-4">
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_name"
                                    >
                                      Billing Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_name"
                                      placeholder="Enter Billing Name"
                                      required
                                      name="billing_name"
                                      onChange={(e) =>
                                        setBillingName(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_address"
                                    >
                                      Billing Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_address"
                                      placeholder="Enter Billing Address"
                                      required
                                      name="billing_address"
                                      onChange={(e) =>
                                        setBillingAddress(e.target.value)
                                      }
                                    />
                                    {/* {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_city"
                                    >
                                      Billing City
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_city"
                                      placeholder="Enter Billing City"
                                      required
                                      name="billing_city"
                                      onChange={(e) =>
                                        setBillingCity(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_state"
                                    >
                                      Billing State
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_state"
                                      placeholder="Enter Billing State"
                                      required
                                      name="billing_state"
                                      onChange={(e) =>
                                        setBillingState(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_zip"
                                    >
                                      Billing Zip
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_zip"
                                      placeholder="Enter Billing Zip"
                                      required
                                      name="billing_zip"
                                      onChange={(e) =>
                                        setBillingzip(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_country"
                                    >
                                      Billing Country
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_country"
                                      placeholder="Enter Billing Country"
                                      required
                                      name="billing_country"
                                      onChange={(e) =>
                                        setBillingCountry(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_tel"
                                    >
                                      Billing Tel
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="billing_tel"
                                      placeholder="Enter Billing Tel"
                                      required
                                      name="billing_tel"
                                      onChange={(e) =>
                                        setBillingTel(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="billing_email"
                                    >
                                      Billing Email
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="billing_email"
                                      placeholder="Enter Billing Email"
                                      required
                                      name="billing_email"
                                      onChange={(e) =>
                                        setBillingEmail(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                </div>

                                <div className="row mt-2 mt-md-4 mb-4">
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_name"
                                    >
                                      Delivery Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_name"
                                      placeholder="Enter Delivery Name"
                                      required
                                      name="delivery_name"
                                      onChange={(e) =>
                                        setDilivaryName(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_address"
                                    >
                                      Delivery Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_address"
                                      placeholder="Enter Delivery Address"
                                      required
                                      name="delivery_address"
                                      onChange={(e) =>
                                        setDilivaryAddress(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_city"
                                    >
                                      Delivery City
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_city"
                                      placeholder="Enter Delivery City"
                                      required
                                      name="delivery_city"
                                      onChange={(e) =>
                                        setDilivaryCity(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_state"
                                    >
                                      Delivery State
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_state"
                                      placeholder="Enter Delivery State"
                                      required
                                      name="delivery_state"
                                      onChange={(e) =>
                                        setDilivaryState(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_zip"
                                    >
                                      Delivery Zip
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_zip"
                                      placeholder="Enter Delivery Zip"
                                      required
                                      name="delivery_zip"
                                      onChange={(e) =>
                                        setDilivaryZip(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_country"
                                    >
                                      Delivery Country
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_country"
                                      placeholder="Enter Delivery Country"
                                      required
                                      name="delivery_country"
                                      onChange={(e) =>
                                        setDilivaryCountry(e.target.value)
                                      }
                                    />
                                    {/* <p className="text-danger">Errors</p> */}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="delivery_tel"
                                    >
                                      Delivery Tel
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="delivery_tel"
                                      placeholder="Enter Delivery Tel"
                                      required
                                      name="delivery_tel"
                                      onChange={(e) =>
                                        setDilivaryTel(e.target.value)
                                      }
                                    />
                                    <p className="text-danger">Errors</p>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="noOfPages"
                                    >
                                      Subscription Id
                                    </label>
                                    <div className="d-flex">
                                      <select
                                        className="form-select planSelect w-25"
                                        aria-label="Default select example"
                                        name=""
                                        onChange={(e) => {
                                          setSubscriptionid(e.target.value);
                                        }}
                                      >
                                        <option selected>Select</option>
                                        {SubscriptionList?.length &&
                                          SubscriptionList?.map((val) => (
                                            <>
                                              <option
                                                id={val?._id}
                                                value={val?._id}
                                              >
                                                {val?.title}
                                              </option>
                                            </>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 col-sm-12 float-start px-0  text-end">
                                <button
                                  type="submit"
                                  className="btn successBtns waves-effect waves-light me-2 px-5"
                                  onClick={(e) =>
                                    Submitconsumerccavenuedetails(e)
                                  }
                                >
                                  Submit Subscription Plan
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerDetails;
