/*eslint-disable*/
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Routes, Route, HashRouter } from "react-router-dom";

import AdminRoutes from "./routes/admin/AdminRoutes";

import AdminAuthLayout from "./layout/admin/AdminAuthLayout";
import AdminDetailsLayout from "./layout/admin/AdminDetailsLayout";

import AdminLogin from "./components/admin/auth/login/AdminLogin";
import ForgotPassword from "./components/admin/auth/login/ForgotPassword";
import ResetPassword from "./components/admin/auth/login/ResetPassword";

import Profile from "./components/admin/details/profile/Profile";

import EmailVerifiedConsumer from "./components/admin/auth/login/EmailVerifiedConsumer";
import EmailVerifiedPartner from "./components/admin/auth/login/EmailVerifiedPartner";

import AdminDashboard from "./components/admin/details/dashboard/AdminDashboard";

import AdminUserManagementConsumer from "./components/admin/details/usermanagement/AdminUserManagementConsumer";
import ConsumerDetails from "./components/admin/details/usermanagement/ConsumerDetails";
import AdminUserManagementMsme from "./components/admin/details/usermanagement/AdminUserManagementMsme";
import AdminUserManagementPartners from "./components/admin/details/usermanagement/AdminUserManagementPartners";
import PartnerDetails from "./components/admin/details/usermanagement/PartnerDetails";
import AdminUserManagementWeStaff from "./components/admin/details/usermanagement/AdminUserManagementWeStaff";
import AddNewWeStaff from "./components/admin/details/usermanagement/AddNewWeStaff";
import WeStaffDetails from "./components/admin/details/usermanagement/WeStaffDetails";

import AdminConfigaration from "./components/admin/details/configaration/AdminConfigaration";

import AdminProducts from "./components/admin/details/products/AdminProducts";
import AdminProductsDetails from "./components/admin/details/products/AdminProductsDetails";
import AdminProductsCategoryDetails from "./components/admin/details/products/AdminProductsCategoryDetails";


import PartnerData from "./components/admin/details/partnerData/PartnerData";

import AdminOrderManagement from "./components/admin/details/orderManagement/AdminOrderManagement";

import AdminWeProducts from "./components/admin/details/weProducts/AdminWeProducts";

import AdminServiceProvider from "./components/admin/details/serviceProvider/AdminServiceProvider";
import AddNewServiceProvider from "./components/admin/details/serviceProvider/AddNewServiceProvider";
import AdminServiceProviderDetails from "./components/admin/details/serviceProvider/AdminServiceProviderDetails";

import AdminSubscription from "./components/admin/details/subscription/AdminSubscription";
import AddNewSubscription from "./components/admin/details/subscription/AddNewSubscription";
import AddNewNRISubscription from "./components/admin/details/subscription/AddNewNRISubscription";
import AddNewPartnerSubscription from "./components/admin/details/subscription/AddNewPartnerSubscription";
import AdminSubscriptionDetails from "./components/admin/details/subscription/AdminSubscriptionDetails";
import AdminSubscriptionNRIDetails from "./components/admin/details/subscription/AdminSubscriptionNRIDetails";
import AdminSubscriptionPartnerDetails from "./components/admin/details/subscription/AdminSubscriptionPartnerDetails";

import AdminRMMapping from "./components/admin/details/directConnect/AdminRMMapping";
import AdminRMDetails from "./components/admin/details/directConnect/AdminRMDetails";
import AdminRequestDetails from "./components/admin/details/directConnect/AdminRequestDetails";

import AdminSupport from "./components/admin/details/support/AdminSupport";

import AdminLogout from "./components/admin/auth/login/AdminLogout";

import AdminFileNotFound from "./routes/admin/AdminFileNotFound";
import AddNewIndianPlan from "./components/admin/details/EW Plans/AddNewIndianPlans";
import EwPlans from "./components/admin/details/EW Plans/EwPlans";
import AddNewNRIPlans from "./components/admin/details/EW Plans/AddNewNRIPlans";
import AddIndianPlansDetails from "./components/admin/details/EW Plans/AddIndianPlansDetails";
import AddNRIPlansDetails from "./components/admin/details/EW Plans/AddNRIPlansDetails";
import AdminWendiansLogin from "./components/admin/auth/login/AdminWendiansLogin";
import ReffralConsumner from "./components/admin/auth/login/ReffralConsumner";
import ReffralPartner from "./components/admin/auth/login/ReffralPartner";
//mobile app routes for pdf viewer
import FaqChannelPaartnerpdf from "./components/admin/auth/login/FaqChannelPaartnerpdf";
import TermsAndConditionpdf from "./components/admin/auth/login/TermsAndConditionpdf";
import PartnerMobileApppdf from "./components/admin/auth/login/PartnerMobileApppdf";
import PrivacyPolicypdf from "./components/admin/auth/login/PrivacyPolicypdf";
import DisclaimerApplicationpdf from "./components/admin/auth/login/DisclaimerApplicationpdf";
import ConsumerFAQPDF from "./components/admin/auth/ConsumerFAQPDF";
import ConsumerDisclaimerPDF from "./components/admin/auth/ConsumerDisclaimerPDF";
import ConsumerAppPrivacyPolicyPDF from "./components/admin/auth/ConsumerAppPrivacyPolicyPDF";
import ConsumerEmailFailed from "./components/admin/auth/ConsumerEmailFailed";
import ConsumerOverallCondpdf from "./components/admin/auth/ConsumerOverallCondpdf";
import ConsumerMobileapppdf from "./components/admin/auth/ConsumerMobileapppdf";
import PartnerLinkDetails from "./components/admin/details/partnerData/PartnerLinkDetails";
import PartnerLinkdata from "./components/admin/details/usermanagement/PartnerLinkdata";
import BannerAds from "./components/admin/details/bannerAds/BannerAds";
import AdminAddNewRM from "./components/admin/details/directConnect/AdminAddNewRM";
import Notifications from "./components/admin/details/notifications/Notifications";
import CreateNewNotification from "./components/admin/details/notifications/CreateNewNotification";
import AskExpert from "./components/admin/details/AskExpert/AskExpert";
import AskExpertDetails from "./components/admin/details/AskExpert/AskExpertDetails";

import OfferData from "./components/admin/details/Offer/OfferData";
import CreateOffer from "./components/admin/details/Offer/CreateOffer";

import ServicePackList from "./components/admin/details/ServicePack/ServicePackList";
import ServicePackDetails from "./components/admin/details/ServicePack/ServicePackDetails";
import CreateServicePack from "./components/admin/details/ServicePack/CreateServicePack";

function App() {
  return (
    <HashRouter>
      <React.Fragment>
        <ToastContainer />
        <Routes>
          {/* Admin Login */}
          <Route
            exact
            path={AdminRoutes.AdminLogin}
            element={
              <AdminAuthLayout>
                <AdminLogin />
              </AdminAuthLayout>
            }
          />
          {/* Admin Wendians Login */}
          <Route
            exact
            path={AdminRoutes.AdminWendiansLogin}
            element={
              <AdminAuthLayout>
                <AdminWendiansLogin />
              </AdminAuthLayout>
            }
          />
          {/* Admin Forgot Password */}
          <Route
            exact
            path={AdminRoutes.ForgotPassword}
            element={
              <AdminAuthLayout>
                <ForgotPassword />
              </AdminAuthLayout>
            }
          />
          {/* Admin Reset Password */}
          <Route
            exact
            path={AdminRoutes.ResetPassword}
            element={
              <AdminAuthLayout>
                <ResetPassword />
              </AdminAuthLayout>
            }
          />
          {/* Profile */}
          <Route
            exact
            path={AdminRoutes.Profile}
            element={
              <AdminDetailsLayout>
                <Profile />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Dashboard */}
          <Route
            exact
            path={AdminRoutes.AdminDashboard}
            element={
              <AdminDetailsLayout>
                <AdminDashboard />
              </AdminDetailsLayout>
            }
          />
          {/* Email Verified Consumer */}
          <Route
            path={AdminRoutes.EmailVerifiedConsumer}
            element={
              <AdminAuthLayout>
                <EmailVerifiedConsumer />
              </AdminAuthLayout>
            }
          />
          {/* Email Verified Partner */}
          <Route
            path={AdminRoutes.EmailVerifiedPartner}
            element={
              <AdminAuthLayout>
                <EmailVerifiedPartner />
              </AdminAuthLayout>
            }
          />
          {/* Reffral Consumer Verification*/}
          <Route
            path={AdminRoutes.ReffralConsumner}
            element={
              <AdminAuthLayout>
                <ReffralConsumner />
              </AdminAuthLayout>
            }
          />
          {/* Reffral Consumer Verification*/}
          <Route
            path={AdminRoutes.ReffralPartner}
            element={
              <AdminAuthLayout>
                <ReffralPartner />
              </AdminAuthLayout>
            }
          />

          {/* //=============================mobile apppdf viewer route ==================// */}

          {/* FAQ CHANNEL PARTNER PDF VIEWER FOR MOBILE APP*/}
          <Route
            path={AdminRoutes.FaqChannelPaartnerpdf}
            element={
              <AdminAuthLayout>
                <FaqChannelPaartnerpdf />
              </AdminAuthLayout>
            }
          />

          {/* TERMS AND CONDITION PDF VIEWER FOR MOBILE APP*/}
          <Route
            path={AdminRoutes.DisclaimerApplicationpdf}
            element={
              <AdminAuthLayout>
                <DisclaimerApplicationpdf />
              </AdminAuthLayout>
            }
          />



          {/* CHANNEL PARTNER MOBILE APP PDF VIEWER FOR MOBILE APP*/}
          <Route
            path={AdminRoutes.PartnerMobileApppdf}
            element={
              <AdminAuthLayout>
                <PartnerMobileApppdf />
              </AdminAuthLayout>
            }
          />

          {/* PRIVACY POLICY PDF VIEWER FOR MOBILE APP*/}
          <Route
            path={AdminRoutes.PrivacyPolicypdf}
            element={
              <AdminAuthLayout>
                <PrivacyPolicypdf />
              </AdminAuthLayout>
            }
          />

          {/* TERMS AND CONDITION  PDF VIEWER FOR MOBILE APP*/}
          <Route
            path={AdminRoutes.TermsAndConditionpdf}
            element={
              <AdminAuthLayout>
                <TermsAndConditionpdf />
              </AdminAuthLayout>
            }
          />

          {/* //=============================mobile apppdf viewer route FOR CONSUMER ==================// */}


          {/*  FAQ  CONSUMER PDF VIEWER FOR MOBILE APP* */}
          <Route
            exact
            path={AdminRoutes.ConsumerFAQPDF}
            element={
              <AdminAuthLayout>
                <ConsumerFAQPDF />
              </AdminAuthLayout>
            }
          />

          {/*  Disclaimer  CONSUMER PDF VIEWER FOR MOBILE APP* */}
          <Route
            exact
            path={AdminRoutes.ConsumerDisclaimerPDF}
            element={
              <AdminAuthLayout>
                <ConsumerDisclaimerPDF />
              </AdminAuthLayout>
            }
          />

          {/* AppPrivacyPolicyPDF  CONSUMER PDF VIEWER FOR MOBILE APP* */}
          <Route
            exact
            path={AdminRoutes.ConsumerAppPrivacyPolicyPDF}
            element={
              <AdminAuthLayout>
                <ConsumerAppPrivacyPolicyPDF />
              </AdminAuthLayout>
            }
          />

          {/*ConsumerEmailFailed CONSUMER PDF VIEWER FOR MOBILE APP* */}
          <Route
            exact
            path={AdminRoutes.ConsumerEmailFailed}
            element={
              <AdminAuthLayout>
                <ConsumerEmailFailed />
              </AdminAuthLayout>
            }
          />

          {/*ConsumerOverallCondpdf CONSUMER PDF VIEWER FOR MOBILE APP* */}
          <Route
            exact
            path={AdminRoutes.ConsumerOverallCondpdf}
            element={
              <AdminAuthLayout>
                <ConsumerOverallCondpdf />
              </AdminAuthLayout>
            }
          />
          {/*ConsumerOverallCondpdf CONSUMER PDF VIEWER FOR MOBILE APP* */}
          <Route
            exact
            path={AdminRoutes.ConsumerMobileapppdf}
            element={
              <AdminAuthLayout>
                <ConsumerMobileapppdf />
              </AdminAuthLayout>
            }
          />
          {/*  ==========================Auth routs for mobile and consumer /partner end==================================== */}

          {/* Admin UserManagement Consumer */}
          <Route
            exact
            path={AdminRoutes.AdminUserManagementConsumer}
            element={
              <AdminDetailsLayout>
                <AdminUserManagementConsumer />
              </AdminDetailsLayout>
            }
          />
          {/* Admin UserManagement Msme */}
          <Route
            exact
            path={AdminRoutes.AdminUserManagementMsme}
            element={
              <AdminDetailsLayout>
                <AdminUserManagementMsme />
              </AdminDetailsLayout>
            }
          />
          {/* Admin UserManagement Consumer Details */}
          <Route
            exact
            path={AdminRoutes.ConsumerDetails}
            element={
              <AdminDetailsLayout>
                <ConsumerDetails />
              </AdminDetailsLayout>
            }
          />
          {/* Admin UserManagement Partners */}
          <Route
            exact
            path={AdminRoutes.AdminUserManagementPartners}
            element={
              <AdminDetailsLayout>
                <AdminUserManagementPartners />
              </AdminDetailsLayout>
            }
          />
          {/* Admin UserManagement Partners Details */}
          <Route
            exact
            path={AdminRoutes.PartnerDetails}
            element={
              <AdminDetailsLayout>
                <PartnerDetails />
              </AdminDetailsLayout>
            }
          />
          {/* Admin UserManagement WeStaff */}
          <Route
            exact
            path={AdminRoutes.AdminUserManagementWeStaff}
            element={
              <AdminDetailsLayout>
                <AdminUserManagementWeStaff />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Add New We Staff */}
          <Route
            exact
            path={AdminRoutes.AddNewWeStaff}
            element={
              <AdminDetailsLayout>
                <AddNewWeStaff />
              </AdminDetailsLayout>
            }
          />
          {/* Admin We Staff Details */}
          <Route
            exact
            path={AdminRoutes.WeStaffDetails}
            element={
              <AdminDetailsLayout>
                <WeStaffDetails />
              </AdminDetailsLayout>
            }
          />
          Admin Configaration
          <Route
            exact
            path={AdminRoutes.AdminConfigaration}
            element={
              <AdminDetailsLayout>
                <AdminConfigaration />
              </AdminDetailsLayout>
            }
          />


          {/* Admin partner link data */}
          <Route
            exact
            path={AdminRoutes.PartnerLinkdata}
            element={
              <AdminDetailsLayout>
                <PartnerLinkdata />
              </AdminDetailsLayout>
            }
          />


         

          {/* Admin Subscription */}
          <Route
            exact
            path={AdminRoutes.AdminSubscription}
            element={
              <AdminDetailsLayout>
                <AdminSubscription />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Add New Subscription */}
          <Route
            exact
            path={AdminRoutes.AddNewSubscription}
            element={
              <AdminDetailsLayout>
                <AddNewSubscription />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Add New NRI Subscription */}
          <Route
            exact
            path={AdminRoutes.AddNewNRISubscription}
            element={
              <AdminDetailsLayout>
                <AddNewNRISubscription />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Add New Partner Subscription */}
          <Route
            exact
            path={AdminRoutes.AddNewPartnerSubscription}
            element={
              <AdminDetailsLayout>
                <AddNewPartnerSubscription />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Subscription Details */}
          <Route
            exact
            path={AdminRoutes.AdminSubscriptionDetails}
            element={
              <AdminDetailsLayout>
                <AdminSubscriptionDetails />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Subscription NRI Details */}
          <Route
            exact
            path={AdminRoutes.AdminSubscriptionNRIDetails}
            element={
              <AdminDetailsLayout>
                <AdminSubscriptionNRIDetails />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Subscription Partner Details */}
          <Route
            exact
            path={AdminRoutes.AdminSubscriptionPartnerDetails}
            element={
              <AdminDetailsLayout>
                <AdminSubscriptionPartnerDetails />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Products */}
          <Route
            exact
            path={AdminRoutes.AdminProducts}
            element={
              <AdminDetailsLayout>
                <AdminProducts />
              </AdminDetailsLayout>
            }
          />

          <Route
            exact
            path={AdminRoutes.AdminProductsCategoryDetails}
            element={
              <AdminDetailsLayout>
                <AdminProductsCategoryDetails />
              </AdminDetailsLayout>
            }
          />

<Route
            exact
            path={AdminRoutes.AdminProductsDetails}
            element={
              <AdminDetailsLayout>
                <AdminProductsDetails />
              </AdminDetailsLayout>
            }
          />

          {/* Partner Data */}
          <Route
            exact
            path={AdminRoutes.PartnerData}
            element={
              <AdminDetailsLayout>
                <PartnerData />
              </AdminDetailsLayout>
            }
          />

          <Route
            exact
            path={AdminRoutes.PartnerLinkDetails}
            element={
              <AdminDetailsLayout>
                <PartnerLinkDetails />
              </AdminDetailsLayout>
            }
          />

          {/* Admin Order Management */}
          <Route
            exact
            path={AdminRoutes.AdminOrderManagement}
            element={
              <AdminDetailsLayout>
                <AdminOrderManagement />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Ew Plans */}
          <Route
            exact
            path={AdminRoutes.EwPlans}
            element={
              <AdminDetailsLayout>
                <EwPlans />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Ew Plans */}
          <Route
            exact
            path={AdminRoutes.AddNewIndianPlan}
            element={
              <AdminDetailsLayout>
                <AddNewIndianPlan />
              </AdminDetailsLayout>
            }
          />
          <Route
            exact
            path={AdminRoutes.AddIndianPlansDetails}
            element={
              <AdminDetailsLayout>
                <AddIndianPlansDetails />
              </AdminDetailsLayout>
            }
          />
          <Route
            exact
            path={AdminRoutes.AddNewNRIPlans}
            element={
              <AdminDetailsLayout>
                <AddNewNRIPlans />
              </AdminDetailsLayout>
            }
          />
          <Route
            exact
            path={AdminRoutes.AddNRIPlansDetails}
            element={
              <AdminDetailsLayout>
                <AddNRIPlansDetails />
              </AdminDetailsLayout>
            }
          />
          {/* Admin We Products */}
          <Route
            exact
            path={AdminRoutes.AdminWeProducts}
            element={
              <AdminDetailsLayout>
                <AdminWeProducts />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Service Provider */}
          <Route
            exact
            path={AdminRoutes.AdminServiceProvider}
            element={
              <AdminDetailsLayout>
                <AdminServiceProvider />
              </AdminDetailsLayout>
            }
          />
          {/* Add New Admin Service Provider */}
          <Route
            exact
            path={AdminRoutes.AddNewServiceProvider}
            element={
              <AdminDetailsLayout>
                <AddNewServiceProvider />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Service Provider Details*/}
          <Route
            exact
            path={AdminRoutes.AdminServiceProviderDetails}
            element={
              <AdminDetailsLayout>
                <AdminServiceProviderDetails />
              </AdminDetailsLayout>
            }
          />
          {/* Admin directConnect*/}
          <Route
            exact
            path={AdminRoutes.AdminAddNewRM}
            element={
              <AdminDetailsLayout>
                <AdminAddNewRM/>
              </AdminDetailsLayout>
            }
          />
         
          <Route
            exact
            path={AdminRoutes.AdminRMMapping}
            element={
              <AdminDetailsLayout>
                <AdminRMMapping />
              </AdminDetailsLayout>
            }
          />
          {/* Admin RM Mapping */}
          <Route
            exact
            path={AdminRoutes.AdminRMDetails}
            element={
              <AdminDetailsLayout>
                <AdminRMDetails />
              </AdminDetailsLayout>
            }
          />
          {/* Admin RM Mapping */}
          <Route
            exact
            path={AdminRoutes.AdminRequestDetails}
            element={
              <AdminDetailsLayout>
                <AdminRequestDetails />
              </AdminDetailsLayout>
            }
          />
          {/* Admin Support*/}
          <Route
            exact
            path={AdminRoutes.AdminSupport}
            element={
              <AdminDetailsLayout>
                <AdminSupport />
              </AdminDetailsLayout>
            }
          />

           {/* Banner Ads*/}
           <Route
            exact
            path={AdminRoutes.BannerAds}
            element={
              <AdminDetailsLayout>
                <BannerAds />
              </AdminDetailsLayout>
            }
          />

               {/* Notifications Ads*/}
          <Route
            exact
            path={AdminRoutes.Notifications}
            element={
              <AdminDetailsLayout>
                <Notifications/>
              </AdminDetailsLayout>
            }
          />
          {/* CreateNewNotification Ads*/}
          <Route
            exact
            path={AdminRoutes.CreateNewNotification}
            element={
              <AdminDetailsLayout>
                <CreateNewNotification />
              </AdminDetailsLayout>
            }
          />

             {/* Ask Expert*/}
             <Route
            exact
            path={AdminRoutes.AskExpert}
            element={
              <AdminDetailsLayout>
                <AskExpert />
              </AdminDetailsLayout>
            }
          />

           {/* Ask Expert Details*/}
           <Route
            exact
            path={AdminRoutes.AskExpertDetails}
            element={
              <AdminDetailsLayout>
                <AskExpertDetails />
              </AdminDetailsLayout>
            }
          />

            {/* Offer Data*/}
            <Route
            exact
            path={AdminRoutes.OfferData}
            element={
              <AdminDetailsLayout>
                <OfferData />
              </AdminDetailsLayout>
            }
          />

          {/* Offer Data*/}
          <Route
            exact
            path={AdminRoutes.CreateOffer}
            element={
              <AdminDetailsLayout>
                <CreateOffer />
              </AdminDetailsLayout>
            }
          />

          {/*Service Pack*/}
          <Route
            exact
            path={AdminRoutes.CreateServicePack}
            element={
              <AdminDetailsLayout>
                <CreateServicePack />
              </AdminDetailsLayout>
            }
          />

          <Route
            exact
            path={AdminRoutes.ServicePackDetails}
            element={
              <AdminDetailsLayout>
                <ServicePackDetails />
              </AdminDetailsLayout>
            }
          />

          <Route
            exact
            path={AdminRoutes.ServicePackList}
            element={
              <AdminDetailsLayout>
                <ServicePackList />
              </AdminDetailsLayout>
            }
          />

          {/* Admin Logout*/}
          <Route
            exact
            path={AdminRoutes.AdminLogout}
            element={
              <AdminDetailsLayout>
                <AdminLogout />
              </AdminDetailsLayout>
            }
          />
          {/* Admin FileNotFound*/}
          <Route
            exact
            path={AdminRoutes.AdminFileNotFound}
            element={
              <AdminDetailsLayout>
                <AdminFileNotFound />
              </AdminDetailsLayout>
            }
          />
        </Routes>
      </React.Fragment>
    </HashRouter>
  );
}

export default App;
