/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import axios from "axios";
import { toast } from "react-toastify";

const AddNewSubscription = () => {
  const navigate = useNavigate();
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  // Features Variables
  const [Title, setTitle] = useState();
  const [Validity, setValidity] = useState();
  const [MonYer, setMonYer] = useState("");
  const [submitIndianForm, setsubmitIndianForm] = useState();
  const [vault, setvault] = useState(null);
  const [AsktheExpert, setAsktheExpert] = useState(null);
  const [Search, setSearch] = useState(null);
  const [WarrantyTransfer, setWarrantyTransfer] = useState(null);
  const [MultipleAddress, setMultipleAddress] = useState(null);
  const [WithoutAdvts, setWithoutAdvts] = useState(null);
  const [FamilyAccess, setFamilyAccess] = useState();
  const [DirectConnect, setDirectConnect] = useState();
  const [PriorityServices, setPriorityServices] = useState();
  // Free EW Offers Variables
  const [warrPlan, setwarrPlan] = useState();
  const [offer6, setoffer6] = useState();
  const [offer12, setoffer12] = useState();
  const [offer18, setoffer18] = useState();
  const [offer24, setoffer24] = useState();
  const [IntroductoryOffer, setIntroductoryOffer] = useState();
  // Charges Variables
  const [weCoins, setweCoins] = useState();
  const [weCash, setweCash] = useState();
  const [EW, setEW] = useState();
  const [EmgVisitCharges, setEmgVisitCharges] = useState();
  const [PickupDrop, setPickupDrop] = useState();
  // Rewards & Discounts Variables
  const [weCoinssub, setweCoinssub] = useState();
  const [weCashsub, setweCashsub] = useState();
  const [weCoinstran, setweCoinstran] = useState();
  const [weCashtran, setweCashtran] = useState();
  const [weCoinsRefsub, setweCoinsRefsub] = useState();
  // Pricing Plans Variables
  const [SubscriptionFees, setSubscriptionFees] = useState();
  const [day03offer, setday03offer] = useState();
  const [day47offer, setday47offer] = useState();
  const [day15offer, setday15offer] = useState();
  const [day30offer, setday30offer] = useState();

  // API call for Add New Plan for Indian
  const handleSubmitIndianForm = (type) => {
    try {
      var data = JSON.stringify({
        subscription_for: 1,
        title: Title,
        validity: Validity,
        mon_yr: MonYer,
        multiple_adress: MultipleAddress,
        without_advts: WithoutAdvts,
        direct_connect: DirectConnect,
        we_coin_subscription: weCoinssub,
        status: 1,
        volt: vault,
        ask_the_expert: AsktheExpert,
        search: Search,
        warrenty_transfer: WarrantyTransfer,
        family_access: FamilyAccess,
        priority_service: PriorityServices,
        subscription_offer: warrPlan,
        offer_6: offer6,
        offer_12: offer12,
        offer_18: offer18,
        offer_24: offer24,
        introductory_offer: IntroductoryOffer,
        tf_for_we_coin: weCoins,
        tf_for_we_cash: weCash,
        tf_for_we_ew: EW,
        emergency_visit_charges: EmgVisitCharges,
        pick_up_and_drop_charges: PickupDrop,
        we_cash_subscription: weCashsub,
        we_coin_transaction: weCoinstran,
        we_cash_transaction: weCashtran,
        we_coin_ref_subscriber: weCoinsRefsub,
        subscription_fees: SubscriptionFees,
        days_0_3_offer: day03offer,
        days_4_7_offer: day47offer,
        days_8_15_offer: day15offer,
        days_16_30_offer: day30offer,
        product_margin: 0,
        alliance_fees: 0,
        expert_leads_per_yr: 0,
        banner_advertisment: 0,
        break_advertisment: 0,
        priority_listing: "Null",
        verified_listing: 0,
        payment_schedule: 0,
        we_coin_cust_sale: 0,
        we_cash_ref_seller_sub: 0,
        we_coin_ref_seller_sub: 0,
        seller_or_sc_one_time_sign_up_free: 0,
        seller_or_sc_annual_subscription_charges: 0,
        seller_or_sc_refundable_security_deposite: 0,
        seller_or_sc_introductory_annual_subscription_Charges: 0,
        seller_or_sc_total_payble: 0,
        seller_and_sc_one_time_sign_up_free: 0,
        seller_and_sc_annual_subscription_charges: 0,
        seller_and_sc_refundable_security_deposite: 0,
        seller_and_sc_introductory_annual_subscription_Charges: 0,
        seller_and_sc_total_payble: 0,
        WA_annual_sbscription_charges: 0,
        WA_total_payable: 0,
      });

      var config = {
        method: "post",
        url: `${BaseAPI.nodeAPI}subscription/add`,
        headers: {
          Authorization: adminauth?.data?.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config).then(function (response) {
        setsubmitIndianForm(response.data.data);
        toast.success(response.data.message, { position: "top-right" });
        console.log("indianform", response.data);
        navigate("/admin-subscription/" + "1");
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, { position: "top-right" });
    }
  };
  // useffect for indian form
  useEffect(() => {
    console.log("submitIndianForm---", submitIndianForm);
  }, []);

  // const BasicSubsicption = () => {

  //   handleSubmitIndianForm(1)
  // }

  // const PrimiumSubsicption = () => {

  //   handleSubmitIndianForm(2)
  // }
  // const PrivilageSubsicption = () => {

  //   handleSubmitIndianForm(3)
  // }
  // const EliteSubsicption = () => {

  //   handleSubmitIndianForm(4)
  // }

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Subscription</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-4 pageBack d-flex">
                    <NavLink to={AdminRoutes.AdminSubscription}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      Add New Plan for Indian
                    </p>
                  </div>

                  <div className="col-8 d-flex justify-content-end">
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Duplicate
                    </button>
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Save as Draft
                    </button> */}
                    <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      onClick={handleSubmitIndianForm}
                    >
                      Submit
                    </NavLink>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="bookTitle">
                            Subscription Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bookTitle"
                            placeholder="Enter Subscription Title"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Validity
                          </label>
                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control"
                              id="noOfPages"
                              placeholder="Enter Validity"
                              onChange={(e) =>
                                setValidity(parseInt(e.target.value))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" htmlFor="noOfPages">
                            Duration
                          </label>
                          <div className="d-flex">
                            <select
                              className="form-select planSelect w-25"
                              aria-label="Default select example"
                              onChange={(e) => setMonYer(e.target.value)}
                            >
                              <option selected>Select</option>
                              <option value="Hrs">Hrs</option>
                              <option value="Days">Days</option>
                              <option value="Months">Months</option>
                              <option value="Years">Years</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start mb-2">
                            <h6 className="fw-bold">Features</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 no_wrap text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>VAULT</td>
                                          <td>
                                            This enables you to save all Product
                                            and Warranty details along with
                                            saving original invoice and warranty
                                            card at one place
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="vault"
                                                id="vaultyes"
                                                value="vaultyesoption1"
                                                onChange={() => setvault(1)}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="vault"
                                                id="vaultno"
                                                defaultValue="vaultoption2"
                                                onChange={() => setvault(0)}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>ASK THE EXPERT</td>
                                          <td>
                                            Access to product purchase
                                            assistance feature than enables the
                                            user to get product details and
                                            offers available locally
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ask expert"
                                                id="askExpertyes"
                                                defaultValue="askExpertyesoption1"
                                                onChange={() =>
                                                  setAsktheExpert(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ask expert"
                                                id="askExpertno"
                                                defaultValue="askExpertoption2"
                                                onChange={() =>
                                                  setAsktheExpert(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>SEARCH</td>
                                          <td>
                                            Enables the user to search and
                                            directly connect with local service
                                            providers incase of prducts outside
                                            warranty
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="search"
                                                id="searchyes"
                                                defaultValue="searchyesoption1"
                                                onChange={() => setSearch(1)}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="search"
                                                id="searchno"
                                                defaultValue="searchoption2"
                                                onChange={() => setSearch(0)}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>WARRANTY TRANSFER</td>
                                          <td>
                                            Gives the right of transferring EW
                                            plans to a third party
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="warranty transfer"
                                                id="warrantytransferyes"
                                                defaultValue="warrantytransferyesoption1"
                                                onChange={() =>
                                                  setWarrantyTransfer(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Paid
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="warranty transfer"
                                                id="warrantytransferno"
                                                defaultValue="warrantytransferoption2"
                                                onChange={() =>
                                                  setWarrantyTransfer(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Free
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>MULTIPLE ADDRESS</td>
                                          <td>
                                            Enables users to add more than 1
                                            address in the same app/log-in
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiple address"
                                                id="multipleaddressyes"
                                                defaultValue="multipleaddressyesoption1"
                                                onChange={() =>
                                                  setMultipleAddress(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiple address"
                                                id="multipleaddressno"
                                                defaultValue="multipleaddressoption2"
                                                onChange={() =>
                                                  setMultipleAddress(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>WITHOUT ADVTS</td>
                                          <td>
                                            App without third party break out
                                            advertisements
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadsyes"
                                                defaultValue="withoutadsoption1"
                                                onChange={() =>
                                                  setWithoutAdvts(1)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadssno"
                                                defaultValue="withoutadsoption2"
                                                onChange={() =>
                                                  setWithoutAdvts(0)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>7</td>
                                          <td>FAMILY ACCESS</td>
                                          <td>
                                            Enables user to share uploaded
                                            details with family members
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="FAMILY ACCESS"
                                              onChange={(e) =>
                                                setFamilyAccess(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">Self Access</option>
                                              <option value="1">
                                                {" "}
                                                1 member{" "}
                                              </option>
                                              <option value="3">
                                                3 member
                                              </option>
                                              <option value="5">
                                                {" "}
                                                5 member
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>8</td>
                                          <td>DIRECT CONNECT</td>
                                          <td>
                                            Gives direct access to a dedicated
                                            replationship manager for al the
                                            needs
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="DIRECT CONNECT"
                                              onChange={(e) =>
                                                setDirectConnect(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="No">No</option>
                                              <option value="Self">Self</option>
                                              <option value="Self + Family">
                                                Self + Family
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>9</td>
                                          <td>PRIORITY SERVICE</td>
                                          <td>Time taken to attend a call</td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="PRIORITY SERVICE"
                                              onChange={(e) =>
                                                setPriorityServices(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="48">
                                                Within 48 working hours
                                              </option>
                                              <option value="36">
                                                Within 36 working hours
                                              </option>
                                              <option value="24">
                                                Within 24 working hours
                                              </option>
                                              <option value="12">
                                                Within 12 working hours
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Free EW Offers</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 no_wrap text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Subscription Offer</td>
                                          <td>Free Extended Warranty Plan</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="	Subscription Offer"
                                              onChange={(e) =>
                                                setwarrPlan(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="NA">NA</option>
                                              <option value="1 prod / 3 months - CARE">
                                                1 prod / 3 months - CARE
                                              </option>
                                              <option value="1 prod / 6 months - CARE">
                                                1 prod / 6 months - CARE
                                              </option>
                                              <option value="1 prod / 12 months - CARE">
                                                1 prod / 12 months - CARE
                                              </option>
                                              <option value="1 prod / 18 months - CARE">
                                                1 prod / 18 months - CARE
                                              </option>
                                              <option value="1 prod / 24 months - CARE">
                                                1 prod / 24 months - CARE
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>Offer - 6</td>
                                          <td>
                                            Valid for 15 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Offer - 6"
                                              onChange={(e) =>
                                                setoffer6(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="300 We COINS + 30 WE CASH">
                                                300 We COINS + 30 WE CASH
                                              </option>
                                              <option value="400 We COINS + 40 WE CASH">
                                                400 We COINS + 40 WE CASH
                                              </option>
                                              <option value="500 We COINS + 50 WE CASH">
                                                500 We COINS + 50 WE CASH
                                              </option>
                                              <option value="600 We COINS + 60 WE CASH">
                                                600 We COINS + 60 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>Offer - 12</td>
                                          <td>
                                            Valid for 30 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Offer - 12"
                                              onChange={(e) =>
                                                setoffer12(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="700 WE COINS + 70 WE CASH">
                                                700 WE COINS + 70 WE CASH
                                              </option>
                                              <option value="900 WE COINS + 90 WE CASH">
                                                900 WE COINS + 90 WE CASH
                                              </option>
                                              <option value="1000 WE COINS + 100 WE CASH">
                                                1000 WE COINS + 100 WE CASH
                                              </option>
                                              <option value="1200 WE COINS + 120 WE CASH">
                                                1200 WE COINS + 120 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>Offer - 18</td>
                                          <td>
                                            Valid for 45 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Offer - 18"
                                              onChange={(e) =>
                                                setoffer18(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="1000 WE COINS + 100 WE CASH">
                                                1000 WE COINS + 100 WE CASH
                                              </option>
                                              <option value="1250 WE COINS + 125 WE CASH">
                                                1250 WE COINS + 125 WE CASH
                                              </option>
                                              <option value="1500 WE COINS + 150 WE CASH">
                                                1500 WE COINS + 150 WE CASH
                                              </option>
                                              <option value="1800 WE COINS + 180 WE CASH">
                                                1800 WE COINS + 180 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>Offer - 24</td>
                                          <td>
                                            Valid for 90 days from the date of
                                            download
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Offer - 24"
                                              onChange={(e) =>
                                                setoffer24(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="1500 WE COINS + 150 WE CASH">
                                                1500 WE COINS + 150 WE CASH
                                              </option>
                                              <option value=" 1750 WE COINS + 175 WE CASH">
                                               1750 WE COINS + 175 WE CASH
                                              </option>
                                              <option value="2000 WE COINS + 200 WE CASH">
                                                2000 WE COINS + 200 WE CASH
                                              </option>
                                              <option value="2400 WE COINS + 240 WE CASH">
                                                2400 WE COINS + 240 WE CASH
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>6</td>
                                          <td>Introductory Offer</td>
                                          <td>
                                            BUY 1 year WE GUARD within 30 days
                                            of the product purchase and get WE
                                            CARE free for another product
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Introductory Offer"
                                              onChange={(e) =>
                                                setIntroductoryOffer(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="For 6 months - CARE">
                                                For 6 months - CARE
                                              </option>
                                              <option value="For 12 months - CARE">
                                                For 12 months - CARE
                                              </option>
                                              <option value="For 6 months - ASSURE">
                                                For 6 months - ASSURE
                                              </option>
                                              <option value="For 12 months - ASSURE">
                                                For 12 months - ASSURE
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Charges</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 no_wrap text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Transfer Fees for We Coins</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Transfer Fees for We Coins"
                                              onChange={(e) =>
                                                setweCoins(e.target.value)
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="49">INR 49</option>
                                              <option value="39">INR 39</option>
                                              <option value="29">INR 29</option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>Transfer Fees for We Cash</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Transfer Fees for We Cash"
                                              onChange={(e) =>
                                                setweCash(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="99">INR 99</option>
                                              <option value="69">INR 69</option>
                                              <option value="49">INR 49</option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>Transfer Fee for EW</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Transfer Fee for EW"
                                              onChange={(e) =>
                                                setEW(parseInt(e.target.value))
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="249">
                                                INR 249
                                              </option>
                                              <option value="199">
                                                INR 199
                                              </option>
                                              <option value="149">
                                                INR 149
                                              </option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>Emergency Visit Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Emergency Visit Charges"
                                              onChange={(e) =>
                                                setEmgVisitCharges(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="799">
                                                INR 799
                                              </option>
                                              <option value="499">
                                                INR 499
                                              </option>
                                              <option value="249">
                                                INR 249
                                              </option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>Pick Up & Drop Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name="Pick Up & Drop Charges"
                                              onChange={(e) =>
                                                setPickupDrop(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="999">
                                                INR 999
                                              </option>
                                              <option value="699">
                                                INR 699
                                              </option>
                                              <option value="499">
                                                INR 499
                                              </option>
                                              <option value="0">INR 0</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Rewards & Discounts</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 no_wrap text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>We COINS on Subscription</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setweCoinssub(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="10%">10%</option>
                                              <option value="15%">15%</option>
                                              <option value="25%">25%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>We CASH on Subscription</td>
                                          <td>Cash Back</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setweCashsub(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="2%">2%</option>
                                              <option value="3%">3%</option>
                                              <option value="5%">5%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>We COINS on transactions</td>
                                          <td>
                                            Net amount after discount. Does not
                                            include taxes
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setweCoinstran(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="5%">5%</option>
                                              <option value="7.50%"> 7.50%</option>
                                              <option value="10%">10%</option>
                                              <option value="12.50%">12.50% </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>4</td>
                                          <td>We CASH on transactions</td>
                                          <td>
                                            Cash Back on every transaction
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setweCashtran(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="1.5%">1.5%</option>
                                              <option value="2%">2%</option>
                                              <option value="3%">3%</option>
                                              <option value="5%">5%</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>5</td>
                                          <td>We COINS on Ref Subscriber</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setweCoinsRefsub(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="2.50%">2.50%</option>
                                              <option value="5%">5%</option>
                                              <option value="7.50%">7.50%</option>
                                              <option value="10%">10%</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Pricing Plans</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 no_wrap text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Subscription Fees</td>
                                          <td>Annual Subscription Charges</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setSubscriptionFees(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="4499">4499</option>
                                              <option value="5999">5999</option>
                                              <option value="7499">7499</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>0-3 Day Offer</td>
                                          <td>
                                            0/40/45/50 - Subscriotion done
                                            within 72 hours of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setday03offer(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="2699">2699</option>
                                              <option value="3299">3299</option>
                                              <option value="3750">3750</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>4-7 Day Offer</td>
                                          <td>
                                            0/35/40/45 - Subscriotion done
                                            within 7 days of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setday47offer(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="2924">2924</option>
                                              <option value="3599">3599</option>
                                              <option value="4124">4124</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>4</td>
                                          <td>8-15 Day Offer</td>
                                          <td>
                                            0/30/35/40 - Subscriotion done
                                            within 15 days of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setday15offer(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="3149">3149</option>
                                              <option value="3899">3899</option>
                                              <option value="4499">4499</option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>5</td>
                                          <td>16-30 Day Offer</td>
                                          <td>
                                            0/25/30/35 - Subscriotion done
                                            within 30 days of reg/n
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                              name=""
                                              onChange={(e) =>
                                                setday30offer(
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option value="3374">3374</option>
                                              <option value="4199">4199</option>
                                              <option value="4874">4874</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    {/* <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Duplicate
                    </button>
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light px-4 me-3"
                    >
                      Save as Draft
                    </button> */}
                    <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <button
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      onClick={handleSubmitIndianForm}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewSubscription;
