/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";

const AdminSubscriptionPartnerDetails = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  //  auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const params = useParams();
  const [PartnerDetails, setPartnerDetails] = useState();
  console.log(params.Partnerid);
  // API CALL FOR PARTNER SUBSCRIPTION VIEW MORE
  useEffect(() => {
    var data = "";
    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}subscription/${params.Partnerid}`,
      headers: {
        Authorization: adminauth?.data?.token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setPartnerDetails(response?.data?.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  console.log("ViewMorePartner", PartnerDetails);

  //  JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Subscription</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 pageBack d-flex">
                    <NavLink to={AdminRoutes.AdminSubscription}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      Partner Subscription Details
                    </p>
                  </div>

                  <div className="col-6 d-flex justify-content-end">
                    {/* <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                      Save
                    </NavLink> */}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" for="bookTitle">
                            Subscription Title :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bookTitle"
                            placeholder="Enter Subscription Title :"
                            defaultValue={PartnerDetails?.title}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                          <label className="form-label" for="noOfPages">
                            Validity :
                          </label>
                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control me-3"
                              id="noOfPages"
                              placeholder="Enter Validity"
                              defaultValue={PartnerDetails?.validity}
                            />
                            <select
                              className="form-select planSelect w-25"
                              aria-label="Default select example"
                            >
                              <option
                                value="Hrs"
                                selected={PartnerDetails?.mon_yr === "Hrs"}
                              >
                                Hrs
                              </option>
                              <option
                                value="Days"
                                selected={PartnerDetails?.mon_yr === "Days"}
                              >
                                Days
                              </option>
                              <option
                                value="Months"
                                selected={PartnerDetails?.mon_yr === "Months"}
                              >
                                Months{" "}
                              </option>
                              <option
                                value="Years"
                                selected={PartnerDetails?.mon_yr === "Months"}
                              >
                                Years
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start mb-2">
                            <h6 className="fw-bold">Features</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>PRODUCT MARGIN</td>
                                          <td></td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="5.00"
                                                selected={
                                                  PartnerDetails?.product_margin ===
                                                  5
                                                }
                                              >
                                                5.00%
                                              </option>
                                              
                                              <option
                                                value="6.00"
                                                selected={
                                                  PartnerDetails?.product_margin ===
                                                  6
                                                }
                                              >
                                                6.00%
                                              </option>
                                              <option
                                                value="7.5"
                                                selected={
                                                  PartnerDetails?.product_margin ===
                                                  7.5
                                                }
                                              >
                                                7.50%
                                              </option>
                                              <option
                                                value="10.00"
                                                selected={
                                                  PartnerDetails?.product_margin ===
                                                  10
                                                }
                                              >
                                                10.00%
                                              </option>
                                              <option
                                                value="15.00"
                                                selected={
                                                  PartnerDetails?.product_margin ===
                                                  15
                                                }
                                              >
                                                15.00%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>ALLIANCE FEE</td>
                                          <td></td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="1.50"
                                              selected={
                                                PartnerDetails?.alliance_fees ===
                                                1.5
                                              }
                                              >
                                                1.50%
                                              </option>
                                              <option
                                                value="2.50"
                                                selected={
                                                  PartnerDetails?.alliance_fees ===
                                                  2.5
                                                }
                                              >
                                                2.50%
                                              </option>
                                              <option
                                                value="3.00"
                                                selected={
                                                  PartnerDetails?.alliance_fees ===
                                                  3
                                                }
                                              >
                                                3.00%
                                              </option>
                                              <option
                                                value="3.50"
                                                selected={
                                                  PartnerDetails?.alliance_fees ===
                                                  3.5
                                                }
                                              >
                                                3.50%
                                              </option>
                                              <option
                                                value="5.00"
                                                selected={
                                                  PartnerDetails?.alliance_fees ===
                                                  5
                                                }
                                              >
                                                5.00%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>MULTIPLE ADDRESS</td>
                                          <td>
                                            Enables users to add more than 1
                                            address in the same app/log-in
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiaddress"
                                                id="multiaddressyes"
                                                value="multiaddressyesoption1"
                                                checked={
                                                  PartnerDetails?.multiple_adress ===
                                                  1
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="multiaddress"
                                                id="multiaddressno"
                                                value="multiaddressoption2"
                                                checked={
                                                  PartnerDetails?.multiple_adress ===
                                                  0
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>WITHOUT ADVTS</td>
                                          <td>
                                            App without third party break out
                                            advertisements
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadsyes"
                                                value="withoutadsyesoption1"
                                                checked={
                                                  PartnerDetails?.without_advts ===
                                                  1
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="without ads"
                                                id="withoutadsno"
                                                value="withoutadsoption2"
                                                checked={
                                                  PartnerDetails?.without_advts ===
                                                  0
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>DIRECT CONNECT</td>
                                          <td>
                                            Gives direct access to a dedicated
                                            replationship manager for al the
                                            needs
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="No"
                                                selected={
                                                  PartnerDetails?.direct_connect ===
                                                  "No"
                                                }
                                              >
                                                No
                                              </option>
                                              <option
                                                value="Self"
                                                selected={
                                                  PartnerDetails?.direct_connect ===
                                                  "Self"
                                                }
                                              >
                                                Self
                                              </option>
                                              <option
                                                value="Self + Family"
                                                selected={
                                                  PartnerDetails?.direct_connect ===
                                                  "Self + Family"
                                                }
                                              >
                                                Self + Family
                                              </option>
                                              <option
                                                value="Self + Staff"
                                                selected={
                                                  PartnerDetails?.direct_connect ===
                                                  "Self + Staff"
                                                }
                                              >
                                                   Self + Staff
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>EXPERT LEADS / yr</td>
                                          <td>
                                            Min Commitment on Consumer leads
                                            passed on the seller
                                          </td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="0"
                                                selected={
                                                  PartnerDetails?.expert_leads_per_yr ===
                                                  0
                                                }
                                              >
                                                0
                                              </option>
                                              <option
                                                value="25"
                                                selected={
                                                  PartnerDetails?.expert_leads_per_yr ===
                                                  25
                                                }
                                              >
                                                25
                                              </option>
                                              <option
                                                value="40"
                                                selected={
                                                  PartnerDetails?.expert_leads_per_yr ===
                                                  40
                                                }
                                              >
                                                40
                                              </option>
                                              <option
                                                value="75"
                                                selected={
                                                  PartnerDetails?.expert_leads_per_yr ===
                                                  75
                                                }
                                              >
                                                75
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>7</td>
                                          <td>BANNER ADVERTISEMENTS</td>
                                          <td></td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="banner ads"
                                                id="banneradsyes"
                                                value="banneradsoption1"
                                                checked={
                                                  PartnerDetails?.banner_advertisment ===
                                                  1
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="banner ads"
                                                id="banneradsno"
                                                value="banneradsoption2"
                                                checked={
                                                  PartnerDetails?.banner_advertisment ===
                                                  0
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>8</td>
                                          <td>BREAK ADVERTISEMENTS</td>
                                          <td></td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="break ads"
                                                id="breakadsyes"
                                                value="breakadsoption1"
                                                checked={
                                                  PartnerDetails?.break_advertisment ===
                                                  1
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="break ads"
                                                id="breakadsno"
                                                value="breakadsoption2"
                                                checked={
                                                  PartnerDetails?.break_advertisment ===
                                                  0
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>9</td>
                                          <td>PRIORITY LISTING</td>
                                          <td></td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="Last"selected={
                                                  PartnerDetails?.priority_listing ===
                                                  "Last"
                                                }
                                                >
                                                  Last
                                                </option>
                                              <option
                                                value="Third"
                                                selected={
                                                  PartnerDetails?.priority_listing ===
                                                  "Third"
                                                }
                                              >
                                                Third
                                              </option>
                                              <option
                                                value="Second"
                                                selected={
                                                  PartnerDetails?.priority_listing ===
                                                  "Second"
                                                }
                                              >
                                                Second
                                              </option>
                                              <option
                                                value="First"
                                                selected={
                                                  PartnerDetails?.priority_listing ===
                                                  "First"
                                                }
                                              >
                                                First
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>10</td>
                                          <td>VERIFIED LISTING</td>
                                          <td></td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="verified listing"
                                                id="verifiedlistingyes"
                                                value="verifiedlistingoption1"
                                                checked={
                                                  PartnerDetails?.verified_listing ===
                                                  1
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="verified listing"
                                                id="verifiedlistingno"
                                                value="verifiedlistingoption2"
                                                checked={
                                                  PartnerDetails?.verified_listing ===
                                                  0
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>11</td>
                                          <td>PAYMENT SCHEDULE</td>
                                          <td></td>
                                          <td colSpan={2}>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="30"
                                                selected={
                                                  PartnerDetails?.payment_schedule ===
                                                  30
                                                }
                                              >
                                                Every 30 days
                                              </option>
                                              <option
                                                value="21"
                                                selected={
                                                  PartnerDetails?.payment_schedule ===
                                                  21
                                                }
                                              >
                                                Every 21 days
                                              </option>
                                              <option
                                                value="15"
                                                selected={
                                                  PartnerDetails?.payment_schedule ===
                                                  15
                                                }
                                              >
                                                Every 15 days
                                              </option>
                                              <option
                                                value="7"
                                                selected={
                                                  PartnerDetails?.payment_schedule ===
                                                  7
                                                }
                                              >
                                                Every 7 days
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">Rewards & Discounts</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>We COINS on Subscription</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="0"
                                                selected={
                                                  PartnerDetails?.we_coin_subscription ===
                                                  0
                                                }
                                              >
                                                0
                                              </option>
                                              <option
                                                value="10"
                                                selected={
                                                  PartnerDetails?.we_coin_subscription ===
                                                  10
                                                }
                                              >
                                                10%
                                              </option>
                                              <option
                                                value="15"
                                                selected={
                                                  PartnerDetails?.we_coin_subscription ===
                                                  15
                                                }
                                              >
                                                15%
                                              </option>
                                              <option
                                                value="25"
                                                selected={
                                                  PartnerDetails?.we_coin_subscription ===
                                                  25
                                                }
                                              >
                                                25%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>WE COINS on Cust Sales</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="5%"
                                                selected={
                                                  PartnerDetails?.we_coin_cust_sale ===
                                                  5
                                                }
                                              >
                                                5%
                                              </option>
                                              <option
                                                value="7.5%"
                                                selected={
                                                  PartnerDetails?.we_coin_cust_sale ===
                                                  7.5
                                                }
                                              >
                                                7.5%
                                              </option>
                                              <option
                                                value="10%"
                                                selected={
                                                  PartnerDetails?.we_coin_cust_sale ===
                                                  10
                                                }
                                              >
                                                10%
                                              </option>
                                              <option
                                                value="15%"
                                                selected={
                                                  PartnerDetails?.we_coin_cust_sale ===
                                                  15
                                                }
                                              >
                                                15%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>We CASH on Ref Seller Sub</td>
                                          <td>In Piggy Bank</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="1.5%"
                                                selected={
                                                  PartnerDetails?.we_cash_ref_seller_sub ===
                                                  1.5
                                                }
                                              >
                                                1.5%
                                              </option>
                                              <option
                                                value="2%"
                                                selected={
                                                  PartnerDetails?.we_cash_ref_seller_sub ===
                                                  2
                                                }
                                              >
                                                2%
                                              </option>
                                              <option
                                                value="3%"
                                                selected={
                                                  PartnerDetails?.we_cash_ref_seller_sub ===
                                                  3
                                                }
                                              >
                                                3%
                                              </option>
                                              <option
                                                value="5%"
                                                selected={
                                                  PartnerDetails?.we_cash_ref_seller_sub ===
                                                  5
                                                }
                                              >
                                                5%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>4</td>
                                          <td>We COINS on Ref Seller Sub</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="2.5"
                                                selected={
                                                  PartnerDetails?.we_coin_ref_seller_sub ===
                                                  2.5
                                                }
                                              >
                                                2.5%
                                              </option>
                                              <option
                                                value="5"
                                                selected={
                                                  PartnerDetails?.we_coin_ref_seller_sub ===
                                                  5
                                                }
                                              >
                                                5%
                                              </option>
                                              <option
                                                value="7.5"
                                                selected={
                                                  PartnerDetails?.we_coin_ref_seller_sub ===
                                                  7.5
                                                }
                                              >
                                                7.5%
                                              </option>
                                              <option
                                                value="10"
                                                selected={
                                                  PartnerDetails?.we_coin_ref_seller_sub ===
                                                  10
                                                }
                                              >
                                                10%
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">
                              Pricing Plans - Seller or SC
                            </h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>One Time Sign Up Fee</td>
                                          <td>Shop Sign Up & Set Up Fee</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="1415"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_one_time_sign_up_free ===
                                                  1415
                                                }
                                              >
                                                1415
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>Annual Subscription Charges</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="0"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_annual_subscription_charges ===
                                                  0
                                                }
                                              >
                                                0
                                              </option>
                                              <option
                                                value="3999"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_annual_subscription_charges ===
                                                  3999
                                                }
                                              >
                                                3999
                                              </option>
                                              <option
                                                value="5999"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_annual_subscription_charges ===
                                                  5999
                                                }
                                              >
                                                5999
                                              </option>
                                              <option
                                                value="8999"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_annual_subscription_charges ===
                                                  8999
                                                }
                                              >
                                                8999
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>Refundable Security Deposit</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="3999"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_refundable_security_deposite ===
                                                  3999
                                                }
                                              >
                                                3999
                                              </option>
                                              <option
                                                value="6999"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_refundable_security_deposite ===
                                                  6999
                                                }
                                              >
                                                6999
                                              </option>
                                              <option
                                                value="9999"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_refundable_security_deposite ===
                                                  9999
                                                }
                                              >
                                                9999
                                              </option>
                                              <option
                                                value="12999"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_refundable_security_deposite ===
                                                  12999
                                                }
                                              >
                                                12999
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            Introductory Annual Subscription
                                            Charges
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option value="0">0</option>
                                              <option
                                                value="0"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_introductory_annual_subscription_Charges ===
                                                  0
                                                }
                                              >
                                                0
                                              </option>
                                              <option
                                                value="3303"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_introductory_annual_subscription_Charges ===
                                                  3303
                                                }
                                              >
                                                3303
                                              </option>
                                              <option
                                                value="4247"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_introductory_annual_subscription_Charges ===
                                                  4247
                                                }
                                              >
                                                4247
                                              </option>
                                              <option
                                                value="5309"
                                                selected={
                                                  PartnerDetails?.seller_or_sc_introductory_annual_subscription_Charges ===
                                                  5309
                                                }
                                              >
                                                5309
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>TOTAL PAYABLE</td>
                                          <td>
                                            {
                                              PartnerDetails?.seller_or_sc_total_payble
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">
                              Pricing Plans - Seller and SC
                            </h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>One Time Sign Up Fee</td>
                                          <td>Shop Sign Up & Set Up Fee</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="2359"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_one_time_sign_up_free ===
                                                  2359
                                                }
                                              >
                                                2359
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>2</td>
                                          <td>Annual Subscription Charges</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="0"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_annual_subscription_charges ===
                                                  0
                                                }
                                              >
                                                0
                                              </option>
                                              <option
                                                value="5499"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_annual_subscription_charges ===
                                                  5499
                                                }
                                              >
                                                5499
                                              </option>
                                              <option
                                                value="6499"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_annual_subscription_charges ===
                                                  6499
                                                }
                                              >
                                                6499
                                              </option>
                                              <option
                                                value="9499"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_annual_subscription_charges ===
                                                  9499
                                                }
                                              >
                                                9499
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>3</td>
                                          <td>Refundable Security Deposit</td>
                                          <td></td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="5999"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_refundable_security_deposite ===
                                                  5999
                                                }
                                              >
                                                5999
                                              </option>
                                              <option
                                                value="11999"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_refundable_security_deposite ===
                                                  11999
                                                }
                                              >
                                                11999
                                              </option>
                                              <option
                                                value="16999"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_refundable_security_deposite ===
                                                  16999
                                                }
                                              >
                                                16999
                                              </option>
                                              <option
                                                value="19999"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_refundable_security_deposite ===
                                                  19999
                                                }
                                              >
                                                19999
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            Introductory Annual Subscription
                                            Charges
                                          </td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="0"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_introductory_annual_subscription_Charges ===
                                                  0
                                                }
                                              >
                                                0
                                              </option>
                                              <option
                                                value="4542"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_introductory_annual_subscription_Charges ===
                                                  4542
                                                }
                                              >
                                                4542
                                              </option>
                                              <option
                                                value="4601"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_introductory_annual_subscription_Charges ===
                                                  4601
                                                }
                                              >
                                                4601
                                              </option>
                                              <option
                                                value="5604"
                                                selected={
                                                  PartnerDetails?.seller_and_sc_introductory_annual_subscription_Charges ===
                                                  5604
                                                }
                                              >
                                                5604
                                              </option>
                                            </select>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>TOTAL PAYABLE</td>
                                          <td>
                                            {
                                              PartnerDetails?.seller_and_sc_total_payble
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 float-start mb-2 mt-4">
                            <h6 className="fw-bold">
                              Pricing Plans - Warranty Advisors
                            </h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0">
                                            Details
                                          </th>
                                          <th className="border-top-0"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>Annual Subscription Charges</td>
                                          <td>Sign Up Fee</td>
                                          <td>
                                            <select
                                              className="form-select planSelect"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Select</option>
                                              <option
                                                value="0"
                                                selected={
                                                  PartnerDetails?.WA_annual_sbscription_charges ===
                                                  0
                                                }
                                              >
                                                0
                                              </option>
                                              <option
                                                value="499"
                                                selected={
                                                  PartnerDetails?.WA_annual_sbscription_charges ===
                                                  499
                                                }
                                              >
                                                499
                                              </option>
                                              <option
                                                value="699"
                                                selected={
                                                  PartnerDetails?.WA_annual_sbscription_charges ===
                                                  699
                                                }
                                              >
                                                699
                                              </option>
                                              <option
                                                value="999"
                                                selected={
                                                  PartnerDetails?.WA_annual_sbscription_charges ===
                                                  999
                                                }
                                              >
                                                999
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    {/* <NavLink
                      to={AdminRoutes.AdminSubscription}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                      Save
                    </NavLink> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSubscriptionPartnerDetails;
