/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const ServicePackList = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [ServicepackList, setServicepackList] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    getSubscriptionList();
    getServicepackList("638097cea983f4d90975085d");
  }, []);
  //api call for subscription list
  const getSubscriptionList = () => {
    var data = JSON.stringify({
      filterData: {
        subscription_for: 1,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}subscription/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("setSubscriptionList", response.data?.data?.rows);
        setSubscriptionList(response.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //api call for service pack list
  const getServicepackList = (Value) => {
    setLoading(true);
    var data = JSON.stringify({
      filterData: {
        subscription_id: Value,
      },
      success_msg: {
        message: "Success",
      },
      error_msg: {
        message: "Error",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}serv_pack/list`,
      headers: {
        "Content-Type": "application/json",
        Authorization: adminauth?.data?.token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("setServicepackList", response?.data?.data);
        setServicepackList(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Welcome to Ask The Expert</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 pageBack d-flex">
                    <p className="fw-bold font-size-18 h6">Service Packs</p>
                  </div>

                  <div className="col-7 d-flex justify-content-end">
                    <NavLink
                      type="button"
                      className="btn addBtns waves-effect waves-light ms-3 "
                      to={AdminRoutes.CreateServicePack}
                    >
                      <i className="fas fa-plus"></i> Create Service Packs
                    </NavLink>
                  </div>
                </div>

                <div className="mb-3 col-12">
                  <ul
                    className="nav nav-tabs nav-tabs-custom d-inline-flex"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#basic"
                        role="tab"
                        onClick={(e) =>
                          getServicepackList("638097cea983f4d90975085d")
                        }
                      >
                        <span>{SubscriptionList[0]?.title}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#elite"
                        role="tab"
                        onClick={(e) =>
                          getServicepackList("6380995fa983f4d90975086c")
                        }
                      >
                        <span>{SubscriptionList[3]?.title}</span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content p-4 tab-sub text-muted">
                    <div className="tab-pane active" id="basic" role="tabpanel">
                      <div className="row mt-5">
                        {Loading ? (
                          <div
                            className="spinner-grow text-info position-absolute tableSpinner"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <>
                            {!ServicepackList?.length ? (
                              <>
                                <span className="d-block text-center p-2 border">
                                  Data not found
                                </span>
                              </>
                            ) : (
                              <>
                                {ServicepackList?.length &&
                                  ServicepackList?.map((val, index) => (
                                    <>
                                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <div className="card">
                                          <div className="card-body box-shadow">
                                            <div className="row">
                                              <div className="mb-2 col-12 pageHeading">
                                                <h5 className="fw-bold text-center text-dark">
                                                  {val?.servpk_name}
                                                </h5>
                                              </div>
                                              <div className="col-12 d-flex justify-content-center">
                                                <NavLink
                                                  to={
                                                    "/service-pack-details/" +
                                                    val?._id
                                                  }
                                                  //  to={EWPlanType === 1 ? '/admin-indian-details/' + val._id : EWPlanType === 2 ? '/admin-nri-details/' + val?._id}
                                                  className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                                                >
                                                  View Details
                                                </NavLink>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePackList;
