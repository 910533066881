/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";

const AdminSubscription = () => {
  // Variables
  const adminauth = JSON.parse(localStorage.getItem('adminauth'));
  const [SubscriptionCardData, setSubscriptionCard] = useState();
  const [subscriptiontype, setSubscriptiontype] = useState(1)
  const [isActive, setisActive] = useState("Indian")
  const [Loading, setLoading] = useState(false)
  const params = useParams();

  // date time 
  const CurrentTime = moment().format('LT'); // htmlFor current Time
  const CurrentDate = moment().format('L'); // htmlFor current Date

  useEffect(() => {
    if(!params.ID === ":ID"){
      handleSubmitIndianFrom(params.ID)
    }else{
      handleSubmitIndianFrom(1)
    }
  }, [])

  // API CALL FOR INDIAN SUBSCRIPTION FORM LIST
  const handleSubmitIndianFrom = (type) => {
    console.log(type)
    setLoading(true)
    setSubscriptiontype(type)
    try {
      var data = JSON.stringify({
        "filterData": {
          "subscription_for": parseInt(type),
          "status": 1
        }
      });

      var config = {
        method: 'post',
        url: `${BaseAPI.nodeAPI}subscription/list`,
        headers: {
          'Authorization': adminauth?.data?.token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then((response) => {
          response?.data?.data.rows.map((value) => {
            if (value.mon_yr === "Hrs") {
              if (value.validity >= 24) {
                let date = new moment(value.createdAt).add(value.validity / 24, 'days');
                value['Validtill'] = date.format('DD/MM/YYYY')
              } else {
                let date = new moment(value.createdAt)
                value['Validtill'] = date.format('DD/MM/YYYY')
              }
            } else {
              let date = new moment(value.createdAt).add(value.validity, value.mon_yr === 'Months' ? 'months' : value.mon_yr === 'Days' ? 'Days' : value.mon_yr === 'Years' ? 'years' : null);
              value['Validtill'] = date.format('DD/MM/YYYY')
            }

            return value;

          });
          setSubscriptionCard(response?.data?.data)
          console.log("objectlist",response?.data?.data)
          setLoading(false)
        })
    }
    catch (error) {
      console.log(error);
      setLoading(false)
    };
  }


  // useEffect Indianlist
  // useEffect(() => {
  //   SubscriptionCardData?.rows?.map((val) => {
  //     return (
  //       console.log("SubscriptionCardData", val)
  //     )
  //   })
  // }, [SubscriptionCardData])



  const IndianSubsicption = () => {
    setisActive('Indian')
    handleSubmitIndianFrom(1)
  }

  const NRISubsicption = () => {
    setisActive('NRI')
    handleSubmitIndianFrom(2)
  }

  const PartnerSubsicption = () => {
    setisActive('Partners')
    handleSubmitIndianFrom(3)
  }
  useEffect(() => {
    console.log("date", SubscriptionCardData?.createdAt)
  }, [SubscriptionCardData])

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Subscription</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap">
                      {/* conditional rendering for active tab */}
                      {isActive === 'Indian' ?
                        <NavLink
                          to={AdminRoutes.AddNewSubscription}
                          type="button"
                          className="btn addBtns waves-effect waves-light ms-3 mt-4"
                        >
                          <i className="fas fa-plus"></i> Add New Indian
                          Subscription
                        </NavLink>
                        :
                        isActive === 'NRI' ?
                          <NavLink
                            to={AdminRoutes.AddNewNRISubscription}
                            type="button"
                            className="btn addBtns waves-effect waves-light ms-3 mt-4"
                          >
                            <i className="fas fa-plus"></i> Add New NRI Subscription
                          </NavLink>
                          :
                          <NavLink
                            to={AdminRoutes.AddNewPartnerSubscription}
                            type="button"
                            className="btn addBtns waves-effect waves-light ms-3 mt-4"
                          >
                            <i className="fas fa-plus"></i> Add New Partner
                            Subscription
                          </NavLink>
                      }
                      {/* <button
                        type="button"
                        className="btn addBtns waves-effect waves-light ms-3"
                        data-bs-toggle="modal"
                        data-bs-target="#addNewFeature"
                      >
                        <i className="fas fa-plus"></i> Add New Feature
                      </button> */}
                    </div>
                    {/* <!-- Nav tabs --> */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 reportsTabs mt-5 mt-md-0"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className={params.ID=== "1" || params.ID === ":ID" ? "nav-link active" : "nav-link"}
                          data-bs-toggle="tab"
                          href="#indian"
                          role="tab"
                          onClick={() => IndianSubsicption()}
                        >
                          <span>Indian</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={params.ID === "2" ? "nav-link rounded-0 active" : "nav-link rounded-0"}
                          data-bs-toggle="tab"
                          href="#nri"
                          role="tab"
                          onClick={() => NRISubsicption()}
                        >
                          <span>NRI</span>
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className="nav-link rounded-0"
                          data-bs-toggle="tab"
                          href="#msme"
                          role="tab"
                        >
                          <span>MSME</span>
                        </a>
                      </li> */}
                      <li className="nav-item">
                        <a
                          className={params.ID === "3" ? "nav-link active" : "nav-link "}
                          data-bs-toggle="tab"
                          href="#partners"
                          role="tab"
                          onClick={() => PartnerSubsicption()}
                        >
                          <span>Partners</span>
                        </a>
                      </li>
                    </ul>

                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-3">
                      {Loading ?
                        <div className="spinner-grow text-info position-absolute tableSpinner" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        :
                        <div
                          className="tab-pane active"
                          id="indian"
                          role="tabpanel"
                        >
                          <div className="mb-3 col-12 position-relative">
                            <div className="row mt-5">
                              {SubscriptionCardData?.rows?.map((val) => {
                                return (
                                  <>
                                    <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                      <div className="card">
                                        <div className="card-body box-shadow">
                                          <div className="row">
                                            <div className="mb-2 col-12 pageHeading">
                                              <h5 className="fw-bold text-center text-dark">
                                                {val?.title}
                                              </h5>
                                            </div>
                                            <div className="col-12">
                                              <div className="row">
                                                <div className="col-6 float-start px-2">
                                                  <p className="text-end">Duration :</p>
                                                </div>
                                                <div className="col-6 float-start px-2">
                                                  <p className="fw-bold text-start">
                                                    {val?.validity} {val?.mon_yr}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-6 float-start px-2">
                                                  <p className="text-end">Valid Till :</p>
                                                </div>
                                                <div className="col-6 float-start px-2">
                                                  <p className="fw-bold text-start">
                                                    {val?.Validtill ? val?.Validtill : '12/12/2022'}
                                                  </p>
                                                </div>
                                              </div>

                                              {/* <div className="row">
                                                <div className="col-6 float-start px-2">
                                                  <p className="text-end">Users :</p>
                                                </div>
                                                <div className="col-6 float-start px-2">
                                                  <p className="fw-bold text-start">
                                                    200
                                                  </p>
                                                </div>
                                              </div> */}
                                              {isActive !== 'Partners' ?
                                                <>
                                                  <div className="row">
                                                    <div className="col-6 float-start px-2">
                                                      <p className="text-end">Base Price:</p>
                                                    </div>
                                                    <div className="col-6 float-start px-2">
                                                      <p className="fw-bold text-start">
                                                        {val?.subscription_fees}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </>
                                                : null
                                              }

                                              {isActive === 'Partners' ?
                                                <>
                                                  <div className="row">
                                                    <div className="col-6 float-start px-2">
                                                      <p className="text-end">Service or SC:</p>
                                                    </div>
                                                    <div className="col-6 float-start px-2">
                                                      <p className="fw-bold text-start">
                                                        {val?.seller_or_sc_total_payble}
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-6 float-start px-2">
                                                      <p className="text-end">Service and SC:</p>
                                                    </div>
                                                    <div className="col-6 float-start px-2">
                                                      <p className="fw-bold text-start">
                                                        {val?.seller_and_sc_total_payble}
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-6 float-start px-2">
                                                      <p className="text-end">Warranty Advisor:</p>
                                                    </div>
                                                    <div className="col-6 float-start px-2">
                                                      <p className="fw-bold text-start">
                                                        {val?.WA_annual_sbscription_charges}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </>

                                                :
                                                null}

                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                              <NavLink
                                                to={subscriptiontype === 1 ? '/admin-subscription-details/' + val._id : subscriptiontype === 2 ? '/admin-subscription-nri-details/' + val._id : '/admin-subscription-partner-details/' + val._id}
                                                className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                                              >
                                                View Details
                                              </NavLink>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </>
                                )
                              })
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSubscription;
