/*eslint-disable*/
import React, { useEffect, useState } from "react";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import axios from "axios";
import { toast } from "react-toastify";
const CreateServicePack = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const params = useParams();
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [SubcategoryList, setSubcategoryList] = useState([]);
  const [SBrandList, setSBrandList] = useState([]);

  const [ServicePackName, setServicePackName] = useState("WE Protekt");
  const [FeatureName, setFeatureName] = useState("");
  const [InputBrandFields, setInputBrandFieldSub] = useState([]);
  const [Items, setItems] = useState([]);
  const [Sub_NameID, setSub_NameID] = useState("638097cea983f4d90975085d");
  const [FeatureList, setFeatureList] = useState("");
  const [Loading, setLoading] = useState(false);
  const [formErrors, setformError] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  const [Subcategory_id, setSubcategory_id] = useState("");

  const [DuplicateChildRow, setDuplicateChildRow] = useState([{}]);
  const [FeatureAdd, setFeatureAdd] = useState([]);

  useEffect(() => {
    getSubscriptionList();
    getSubcategoryList();
    getFeatureList();
    getSbrandlist();
  }, []);
  //////////////////////////basic api call / subscription list / subcategorylits/ brandlist  ///////////////////////////////////
  //api call for subscription list
  const getSubscriptionList = () => {
    var data = JSON.stringify({
      filterData: {
        subscription_for: 1,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}subscription/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("setSubscriptionList", response.data?.data?.rows);
        setSubscriptionList(response.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // APi call for subcategory Listing in pricing plans
  const getSubcategoryList = () => {
    var data = JSON.stringify({
      filterData: {
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}subcategory/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("subcategorylisting", response?.data?.data);
        setSubcategoryList(response?.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // api call for subcategorywise brand list
  const getSbrandlist = () => {
    // console.log("params.Subcatid", params.Subcatid);
    var data = JSON.stringify({
      filterData: {
        subcategory_id: Subcategory_id,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}brand/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setSBrandList(response?.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //////////////////////////addd feature coding/////////////////////////////////
  //validation for feature add

  const Validate = (values, Items) => {
    const error = {};
    const Nameregex = /^[a-zA-Z ]*$/;

    // if (!FeatureName) {
    //   error.FeatureName = "please select Feature Name!";
    // }

    if (!FeatureName) {
      error.FeatureName = "Feature Name is required!";
    } else if (!Nameregex.test(FeatureName)) {
      error.FeatureName = " Invalid Feature Name!";
    }

    if (!Items.length) {
      error.Items = "Field should not be blank";
    }
    return error;
  };

  // Function to add feature on plus sign
  const itemEvent = (e) => {
    setInputBrandFieldSub(e.target.value);
  };
  const AddSubBrand = () => {
    setItems((oldItems) => {
      return [...oldItems, InputBrandFields];
    });
    setInputBrandFieldSub("");
  };
  // console.log("Items", Items);
  // console.log("Sub_NameID", Sub_NameID);

  //Api call for Add feature
  const handleAddFeature = async (e) => {
    e.preventDefault();
    setformError(Validate(FeatureName, Items));
    SetIsSubmit(true);
  };
  //useeffect for add feature
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      try {
        var data = JSON.stringify({
          data: {
            servpk_name: ServicePackName,
            feature_name: FeatureName,
            subscription_id: Sub_NameID,
            options: Items,
          },
          success_msg: {
            message: "Success",
          },
          error_msg: {
            message: "Error",
          },
        });

        var config = {
          method: "post",
          url: `${BaseAPI.nodeAPI}serv_pk_feature/add`,
          headers: {
            Authorization: adminauth?.data?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config).then(function (response) {
          console.log(response.data);
          toast.success(response?.data?.message, { position: "top-right" });
          window.$("#addNewCategory").modal("hide");
          getFeatureList();
        });
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      }
    }
  }, [formErrors]);

  //Api call for Feature List
  const getFeatureList = () => {
    setLoading(true);
    var data = JSON.stringify({
      filterData: {
        servpk_name: ServicePackName,
        subscription_id: Sub_NameID,
      },
      success_msg: {
        message: "Success",
      },
      error_msg: {
        message: "Error",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}serv_pk_feature/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("featurelist", response?.data?.feature_record);
        setFeatureList(response?.data?.feature_record);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  // console.log(ServicePackName);
  // console.log(Sub_NameID);
  ///api call for add feature for service pack

  //////////////////////////feature api call obj/function///////////////////
  // fuction for to add the duplicate child row
  // const handleCheckboxFeature_id = (e, index) => {
  //   console.log(e.target.id);
  //   console.log("Handleselecvalue", e.target.id);
  //   console.log("Handleselectindex", index);
  //   const values = [
  //     ...FeatureAdd,
  //     { feature_value: "", serv_pk_feature_id: "" },
  //   ];
  //   setFeatureAdd(values);
  //   if (e.target.checked === true) {
  //     FeatureAdd[index].serv_pk_feature_id = e.target.id;
  //     setFeatureAdd((current) =>
  //       current.map((obj) => {
  //         if (obj.index === index && Object.keys(obj)) {
  //           return {
  //             ...obj,
  //             serv_pk_feature_id: e.target.id,
  //           };
  //         }
  //         return obj;
  //       })
  //     );
  //     FeatureAdd.push(e.target.id);
  //   }
  //   if (e.target.checked === false) {
  //     setFeatureAdd(FeatureAdd.filter((item) => item !== e.target.id));
  //   }
  // };

  const HandleselectOption = (value, index, val) => {
    if (value === "Select") {
      setFeatureAdd(
        FeatureAdd.filter((each) => each.serv_pk_feature_id !== val._id)
      );
    } else {
      for (var i = FeatureAdd.length - 1; i >= 0; --i) {
        if (FeatureAdd[i].serv_pk_feature_id === val._id) {
          FeatureAdd.splice(i, 1);
        }
      }
      setFeatureAdd([
        ...FeatureAdd,
        {
          feature_value: value,
          serv_pk_feature_id: val._id,
        },
      ]);
    }
  };

  // const handleCheckboxFeature_id = (e) => {
  //   console.log(e.target.id);
  //   if (e.target.checked === true) {
  //     FeatureAdd.push(e.target.id);
  //   }
  //   if (e.target.checked === false) {
  //     setFeatureAdd(FeatureAdd.filter((item) => item !== e.target.id));
  //   }
  // };

  useEffect(() => {
    console.log("console.log(=======>FeatureAdd==============>);", FeatureAdd);
  }, [FeatureAdd]);

  // usestate for Duplicate row child/parentchild
  // fuction for to add the duplicate child row
  // const handleSubmitDuplicaterow = (index, event) => {
  //   const values = [...DuplicateChildRow];
  //   values[index][event.target.name] = event.target.value;
  //   setDuplicateChildRow(values);
  // };

  const getHandleSubmitFature = () => {
    var data = JSON.stringify({
      data: {
        servpk_name: ServicePackName,
        subscription_id: Sub_NameID,
        serv_pk_feature: FeatureAdd,
      },
      filterData: {
        servpk_name: ServicePackName,
        subscription_id: Sub_NameID,
      },
      success_msg: {
        message: "Success",
      },
      error_msg: {
        message: "Error",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}serv_pack/add`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("submit feature form ====>", response.data);
        handleSubmitpricingPlan(response?.data?.data?._id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  ////////////////////////////////pricing plan coding////////////////////////////////////////////
  useEffect(() => {
    console.log("DuplicateChildRow", DuplicateChildRow);
  }, [DuplicateChildRow]);

  const handleAddDuplicate = (index) => {
    setDuplicateChildRow([
      ...DuplicateChildRow,
      {
        subcategory_id: "",
        brand_id: "",
        discount_percentage: "",
        serv_pack_id: "",
      },
    ]);
  };
  // Function to store the Subcategoryid in object
  const Handlesubcategory_id = (value, index) => {
    // console.log("Handlesubcategory_value", value)
    // console.log("Handlesubcategory_index", index)
    DuplicateChildRow[index].subcategory_id = value;
  };

  // Function to store the sub_brand in object
  const Handlesubcategory_Brands = (value, index) => {
    console.log("Handlesub_brand_value", value);
    console.log("Handlesub_brand_index", index);
    DuplicateChildRow[index].brand_id = value;
  };

  //  // Function to store the discount_percentage in object
  const Handlesubcategory_Percentage = (value, index) => {
    console.log("Handlesubcategory_value", typeof parseInt(value));
    console.log("Handlesubcategory_index", index);
    DuplicateChildRow[index].discount_percentage = parseFloat(value);
  };

  //api call for submit pricing plan

  const handleSubmitpricingPlan = (myID) => {
    const discountData = DuplicateChildRow.map((val) => {
      val["serv_pack_id"] = myID;
      return val;
    });
    console.log("DuplicateChildRow", discountData);
    var data = JSON.stringify({
      serv_pack_discount_data: discountData,
      success_msg: {
        message: "Data found",
      },
      error_msg: {
        message: "Data not found",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}serv_pk_discount/add`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        toast.success(response.data.message, { position: "top-right" });
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  const reset = (e) => {
    setInputBrandFieldSub([]);
    setFeatureName("");
    setItems([]);
    // setformProductValues({
    //   ...initialValues,
    //   Category: "",
    //   Product: "",
    // });
  };
  //===========================jsx=============================
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Welcome to Service Pack</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 pageBack d-flex">
                    <NavLink to={AdminRoutes.ServicePackList}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      <span className="me-2">Create Service Packs</span>
                    </p>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0 pt-3">
                                <div className="row">
                                  <div className="col-md-6 col-sm-12 float-start px-5 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="noOfPages"
                                    >
                                      Service Pack Name
                                    </label>
                                    <div className="d-flex">
                                      <select
                                        className="form-select planSelect w-100"
                                        aria-label="Default select example"
                                        onChange={(e) =>
                                          setServicePackName(e.target.value)
                                        }

                                        // onChange={(e) =>
                                        //   console.log(e.target.value)
                                        // }
                                      >
                                        {/* <option selected>Select</option> */}
                                        <option selected value="WE Protekt">
                                          WE PROTEKT
                                        </option>
                                        <option value="WE Protekt+">
                                          WE PROTEKT+
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12 float-start px-5 mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="noOfPages"
                                    >
                                      Subscription
                                    </label>
                                    <div className="d-flex">
                                      <select
                                        className="form-select planSelect w-100"
                                        aria-label="Default select example"
                                        onClick={(e) => getFeatureList()}
                                        onChange={(e) => {
                                          setSub_NameID(e.target.value);

                                          // console.log("a2", e.target.value);
                                        }}
                                      >
                                        {/* <option selected>Select</option> */}

                                        <option
                                          id={SubscriptionList[0]?._id}
                                          value={SubscriptionList[0]?._id}
                                        >
                                          {SubscriptionList[0]?.title}
                                        </option>
                                        <option
                                          id={SubscriptionList[3]?._id}
                                          value={SubscriptionList[3]?._id}
                                        >
                                          {SubscriptionList[3]?.title}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Select
                                          </th>
                                          {/* <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th> */}
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0 text-end">
                                            <button
                                              type="button"
                                              className="btn whitebtns waves-effect waves-light text-white"
                                              data-bs-toggle="modal"
                                              data-bs-target="#addNewCategory"
                                            >
                                              <i className="fas fa-plus"></i>{" "}
                                              Add Feature
                                            </button>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Loading ? (
                                          <div
                                            className="spinner-grow text-info position-absolute tableSpinner"
                                            role="status"
                                          >
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>
                                        ) : (
                                          <>
                                            {!FeatureList?.length ? (
                                              <>
                                                <span className="d-block text-center p-2 border">
                                                  Data not found
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                {FeatureList?.length &&
                                                  FeatureList?.map(
                                                    (val, index) => (
                                                      <>
                                                        <tr>
                                                          {/* <td>
                                                            {" "}
                                                            <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              value=""
                                                              id={val?._id}
                                                              onChange={(e) =>
                                                                handleCheckboxFeature_id(
                                                                  e,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                          </td> */}
                                                          <td key={index}>
                                                            {index + 1}
                                                          </td>
                                                          <td>
                                                            {val?.feature_name}
                                                          </td>
                                                          <td>
                                                            <select
                                                              className="form-select planSelect planSelect-servicepack"
                                                              aria-label="Default select example"
                                                              onChange={(e) =>
                                                                HandleselectOption(
                                                                  e.target
                                                                    .value,
                                                                  index,
                                                                  val
                                                                )
                                                              }
                                                            >
                                                              <option selected="Select">
                                                                Select
                                                              </option>
                                                              {val?.options
                                                                ?.length &&
                                                                val?.options?.map(
                                                                  (ele) => {
                                                                    return (
                                                                      <>
                                                                        <option
                                                                          value={
                                                                            ele
                                                                          }
                                                                        >
                                                                          {ele}
                                                                        </option>
                                                                      </>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* ======================pricing plan============================ */}
                          <div className="col-12 float-start mb-2 mt-5">
                            <h6 className="fw-bold">Price Planning</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Products
                                          </th>
                                          <th className="border-top-0">
                                            Brand
                                          </th>
                                          <th className="border-top-0">
                                            Percentage
                                          </th>
                                          <th>Add </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <>
                                          {DuplicateChildRow?.map(
                                            (val, index) => (
                                              <>
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>
                                                    {" "}
                                                    <select
                                                      className="form-select planSelect float-start planSelect-servicepack"
                                                      aria-label="Default select example"
                                                      value={val?.name}
                                                      id={"check" + index}
                                                      onClick={() =>
                                                        getSubcategoryList()
                                                      }
                                                      onChange={(e) => [
                                                        Handlesubcategory_id(
                                                          e.target.value,
                                                          index
                                                        ),
                                                        setSubcategory_id(
                                                          e.target.value
                                                        ),
                                                      ]}
                                                    >
                                                      <option selected>
                                                        Select
                                                      </option>
                                                      {SubcategoryList?.length &&
                                                        SubcategoryList?.map(
                                                          (val) => (
                                                            <>
                                                              <option
                                                                id={val?._id}
                                                                value={val?._id}
                                                              >
                                                                {val?.name}
                                                              </option>
                                                            </>
                                                          )
                                                        )}
                                                    </select>
                                                  </td>
                                                  <td>
                                                    <select
                                                      className="form-select planSelect float-start planSelect-servicepack"
                                                      aria-label="Default select example"
                                                      value={val?.name}
                                                      id={"check" + index}
                                                      onClick={() =>
                                                        getSbrandlist()
                                                      }
                                                      onChange={(e) =>
                                                        Handlesubcategory_Brands(
                                                          e.target.value,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <option selected>
                                                        Select
                                                      </option>
                                                      {SBrandList?.length &&
                                                        SBrandList?.map(
                                                          (val) => (
                                                            <>
                                                              <option
                                                                id={val?._id}
                                                                value={val?._id}
                                                              >
                                                                {val?.name}
                                                              </option>
                                                            </>
                                                          )
                                                        )}
                                                    </select>
                                                  </td>

                                                  <td>
                                                    <input
                                                      type="number"
                                                      className="form-control me-3"
                                                      id="noOfPages"
                                                      placeholder="Enter Percentage"
                                                      onChange={(e) =>
                                                        Handlesubcategory_Percentage(
                                                          e.target.value,
                                                          index
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td className="align-middle">
                                                    <i
                                                      className="fas fa-plus-circle blueText h4 mb-0"
                                                      onClick={() =>
                                                        handleAddDuplicate(
                                                          index
                                                        )
                                                      }
                                                    ></i>
                                                  </td>
                                                </tr>
                                              </>
                                            )
                                          )}
                                        </>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ====================== buttons  ============================== */}
                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    <button
                      type="button"
                      className="btn blueBtns waves-effect waves-light px-4 me-3"
                      onClick={() => getHandleSubmitFature()}
                    >
                      Submit
                    </button>
                    <NavLink
                      to={AdminRoutes.ServicePackList}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    {/* <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                    </NavLink>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        id="addNewCategory"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style={{ height: "auto", width: "auto" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg service-pack-modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title mt-0 fw-bold fs-5">
                Service Pack Featue Add
              </h5>
              <button
                type="button"
                id="modal"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="productname">
                    Feature
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="feature"
                    placeholder="Enter Feature Name"
                    required
                    name="FeatureName"
                    onChange={(e) => setFeatureName(e.target.value)}
                  />
                  <p className="text-danger">{formErrors.FeatureName}</p>
                </div>
                <div className="col">
                  <label className="form-label" htmlFor="brandname">
                    Service Pack Option
                  </label>

                  <input
                    type="text"
                    className="form-control mb-2"
                    id="servicepackoption"
                    placeholder="Enter ServicePack Option"
                    required
                    name="servicepackoption"
                    value={InputBrandFields}
                    onChange={itemEvent}
                  />
                  <p className="text-danger">{formErrors.Items}</p>
                  <span className="newCategoryAdd formIcons">
                    <i
                      className="fas fa-plus-circle blueText h4 cursorPointer"
                      onClick={(e) => AddSubBrand(e)}
                    ></i>
                  </span>
                  <ol>
                    {Items?.map((itemval) => {
                      return <li>{itemval}</li>;
                    })}
                  </ol>
                </div>
              </div>
            </div>
            <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
              <button
                type="button"
                className="btn addBtns waves-effect waves-light px-4"
                data-bs-dismiss="modal"
                onClick={() => {
                  reset();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn filledaddBtns waves-effect waves-light px-4"
                onClick={(e) => handleAddFeature(e)}
              >
                Add Feature
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateServicePack;
