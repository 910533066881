/*eslint-disable*/
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";

const PartnerLinkdata = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
//api call state
  const [Loading, setLoading] = useState(false);
  const [Partnerlinkdata, setPartnerlinkdata] = useState([]);
  const [datavalue, setdatavalue] = useState(false);
  //pagination state
  const [Page, setPage] = useState(1);
  const [Totalcount, setTotalcount] = useState(0);
  const [pagecount, setpagecount] = useState(0);
  //downlaod excel state
  const [date, setDate] = useState(1);


  var today = new Date();
  today.setDate(today.getDate() - 1);
console.log(today);
  useEffect(() => {
    setDate(`${moment(today).format("YYYY-MM-DD")}`)
    
  console.log("=============",date);

  }, [date]);
  //api call for partnerlist
  const getPartnerLinkdata = (value) => {
    console.log("value string", value);
    setLoading(true);
    try {
      var data = JSON.stringify({
        status: value,
        page: Page,
        perpage: 50,
        "sort_cond": {
          "consumer_data.createdAt": -1
        }
      });
      var config = {
        method: "post",
        url: `${BaseAPI.nodeAPI}partner_send_link_consumer/list_for_admin`,
        headers: {
          Authorization: adminauth?.data?.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config).then(function (response) {
        console.log("Partnerlinkdata", response?.data);
        setPartnerlinkdata(response?.data?.data);
        setTotalcount(Math.ceil(response?.data?.totalCount[0].totalCount/50));
        setpagecount(response?.data?.pagescount_arr);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getPartnerRbdata = (value) => {
    console.log("value string", value);
    setLoading(true);
    try {
      var data = JSON.stringify({
        status: value,
      });
      var config = {
        method: "post",
        url: `${BaseAPI.nodeAPI}partner_send_link_consumer/list_for_admin`,
        headers: {
          Authorization: adminauth?.data?.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config).then(function (response) {
        console.log("getPartnerRbdata", response?.data?.data);
        setPartnerlinkdata(response?.data?.data);

        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // console.log("=================", Partnerlinkdata[0]?.status);
  useEffect(() => {
    getPartnerLinkdata("Linked");
    tabSelect(true);
  }, []);

  useEffect(() => {
    getPartnerLinkdata("Linked");
  }, [Page]);

  const tabSelect = (status) => {
    setdatavalue(status);
  };

  const pagination = (selectedpage) => {
    if (selectedpage === 1) {
      setPage(1);
      // } else if (pagecount[pagecount.length - 1] === Page) {
      //   setPage(pagecount[pagecount.length - 1]);
    } else {
      setPage(selectedpage);
    }
  };

  const paginationh = () => {
		let items = [];

		let i = Page - 1;
		while (i >= Page - 1 && i > 0) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page - 1)}>
          <a class="page-link">{i}</a>
				</li>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<li className="page-item active" key={Page}  onClick={() => setPage(Page)}>
				 <a class="page-link">{Page} </a>
			</li>,
		);

		i = Page + 1;
		while (i <= Page + 1 && i <= Totalcount) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page + 1)}>
					          <a class="page-link">{i}</a>
				</li>,
			);

			i += 1;
		}

		return items;
	};
  //JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Partner Link</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap d-flex">
                      {/* <!-- App Search--> */}
                      <form className="app-search d-none d-xl-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />
                          <span className="bx bx-search-alt"></span>
                         
                        </div>

                       
                      </form>


                      <div className="d-flex d-none d-xl-inline-block position-relative mt-4 ml-2">
                      <a
                        className="btn blueBtns borderradius10 waves-effect waves-light ms-3"
                       href ={"https://admin.warranty.express/api/partner_send_link_consumer/report_consumer_partner?date="+date}
                      >
                        Download
                      </a>
                          </div> 
                     

                      <div className="dropdown d-inline-block d-xl-none ms-2">
                        <button
                          type="button"
                          className="btn header-item noti-icon waves-effect"
                          id="page-header-search-dropdown"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="mdi mdi-magnify"></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                          aria-labelledby="page-header-search-dropdown"
                        >
                          <form className="p-3">
                            <div className="m-0">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  aria-label="Recipient's username"
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    <i className="mdi mdi-magnify"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Nav tabs --> */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                      role="tablist"
                    >
                      <li
                        className="nav-item"
                        onClick={() => getPartnerLinkdata("Linked")}
                      >
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#Linked"
                          role="tab"
                          onClick={() => {
                            tabSelect(true);
                          }}
                        >
                          <span>Linked</span>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => getPartnerRbdata("Released")}
                      >
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#Released"
                          role="tab"
                          onClick={() => {
                            tabSelect(false);
                          }}
                        >
                          <span>Released</span>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => getPartnerRbdata("Blocked")}
                      >
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#Blocked"
                          role="tab"
                          onClick={() => {
                            tabSelect(false);
                          }}
                        >
                          <span>Blocked</span>
                        </a>
                      </li>
                    </ul>

                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-3">
                      <div
                        className="tab-pane active"
                        id="indian"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              {datavalue ? (
                                <tr>
                                  <th>#</th>
                                  <th>Partner 16 Digit</th>
                                  <th>Partner Name</th>
                                  <th>Name</th>
                                  <th>Email ID</th>
                                  <th>Mobile Number</th>
                                  <th>City</th>
                                  <th>State</th>
                                  <th>Linked 16 digits code</th>
                                  <th>Subscription Type</th>
                                  <th>Date/Time</th>
                                  <th>Status</th>
                                </tr>
                              ) : (
                                <tr>
                                  <th>#</th>
                                  <th>Partner 16 Digit</th>
                                  <th>Partner Name</th>
                                  <th>Name</th>
                                  <th>Email ID</th>
                                  <th>Mobile Number</th>
                                  <th>Date/Time</th>
                                  <th>Status</th>
                                </tr>
                              )}
                            </thead>
                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                              {!Partnerlinkdata?.length 
                                ?
                                <>
                                  <span className="d-block text-center p-2 border">Data not found</span>
                                </>
                                :
                              <tbody>
                                <>
                                  {Partnerlinkdata?.length &&
                                    Partnerlinkdata?.map((val, index) => {
                                      return (
                                        <tr key={index}>
                                          {datavalue ? (
                                            <>
                                         <td>{Page*50-50+index+1}</td>
                                              <td>
                                                {val?.partner_data[0]?.code}
                                              </td>
                                              <td>
                                                {val?.partner_data[0]?.title}
                                              </td>
                                              <td>{val?.consumer_name}</td>
                                              <td>{val?.consumer_email}</td>
                                              <td>
                                                {
                                                  val?.consumer_mobile

                                                }
                                              </td>
                                              <td>
                                                {
                                                  val?.consumer_data[0]
                                                  ?.city
                                                }
                                              </td>
                                              <td>
                                                {
                                                  val?.consumer_data[0]?.state
                                                }
                                              </td>
                                              <td>
                                                {
                                                  val?.consumer_data[0]?.code
                                                }
                                              </td>
                                              <td>
                                              {val?.paid_subsc_details ?
                                            <td> {val?.paid_subsc_details[0]?.title}</td>
                                            :
                                            <td> {val?.free_subsc_data
                                              [0]?.title}</td>
                                              }
                                              </td>
                                              <td>
                                              {moment(val?.consumer_data[0]?.createdAt).format(
                                                  "LLLL"
                                                )}
                                              </td>
                                              <td>{val?.status}</td>
                                            </>
                                          ) : (
                                            <>
                                              <td>{index + 1}</td>
                                              <td>
                                                {val?.partner_details?.code}
                                              </td>
                                              <td>
                                                {val?.partner_details?.title}
                                              </td>
                                              <td>{val?.consumer_name}</td>
                                              <td>{val?.consumer_email}</td>
                                              <td>{val?.consumer_details}</td>
                                              <td>
                                                {moment(val?.updatedAt).format(
                                                  "LLLL"
                                                )}
                                              </td>
                                              <td>{val?.status}</td>
                                            </>
                                          )}
                                        </tr>
                                      );
                                    })}
                                </>
                              </tbody>
                              } </>)} 
                          </table>
                        
                        </div>
                          {/* /////////////// Pagination/////////////////////////// */}
                          {Partnerlinkdata?.length && Partnerlinkdata[0]?.status === "Linked" ? (
                                 <nav
                                 aria-label="Page navigation example"
                                 className="my-5"
                                 // style={{
                                 //   color: "#208d8e",
                                 // }}
                               >
                                 <ul className="pagination justify-content-end pe-5">
                                   <li
                                     className={
                                       Page === 1 ? "page-item disabled" : ""
                                     }
                                   >
                                     <a
                                       className="page-link"
                                       aria-label="Previous"
                                     >
                                       <span
                                         aria-hidden="true"
                                         onClick={() => pagination(1)}
                                       >
                                         &laquo;
                                       </span>
                                     </a>
                                   </li>
                                   {/* <>
                                     {Totalcount / 2 > 0 &&
                                       pagecount.length &&
                                       pagecount?.map((val, index) => {
                                         return (
                                           <>
                                             <li
                                               className={
                                                 Page === index + 1
                                                   ? "page-item active"
                                                   : ""
                                               }
                                               key={index}
                                             >
                                               <a
                                                 className="page-link "
                                                 onClick={() => pagination(val)}
                                               >
                                                 {val}
                                               </a>
                                             </li>
                                           </>
                                         );
                                       })}
                                   </> */}
                                   {Page - 1 > 1 && (
                                         <li class="page-item">
                                           <a class="page-link" onClick={() => setPage(Page - 2)}>...</a>
                                         </li>
                                         )}
                                   {paginationh()}
                                   {Page + 1 < Totalcount && (
                                   <li class="page-item">
                                     <a class="page-link" onClick={() => setPage(Page + 2)}>...</a>
                                   </li>
                                   )}
                                   <li
                                     className={
                                       pagecount[pagecount.length - 1] === Page
                                         ? "page-item disabled"
                                         : ""
                                     }
                                   >
                                     <a className="page-link" aria-label="Next">
                                       <span
                                         aria-hidden="true"
                                         onClick={() =>
                                           pagination(
                                             // pagecount[pagecount.length - 1] ===
                                             //   Page
                                             //   ? pagecount[pagecount.length - 1]
                                             //   : Page + 1
                                             Totalcount
                                           )
                                         }
                                       >
                                         &raquo;
                                       </span>
                                     </a>
                                   </li>
                                 </ul>
                               </nav>
                          ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerLinkdata;
