/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import axios from "axios";
import { toast } from "react-toastify";

const AdminProductsDetails = (props) => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const params = useParams();
  const [SBrandList, setSBrandList] = useState([]);
  const [ImageUpload, setImageUpload] = useState("");
  const [MultipleBrandValues, setMultipleBrandValues] = useState([
    {
      name: "",
      category_id: params.pid,
      subcategory_id: params.Subcatid,
      Branderror: "",
    },
  ]);
  const [isVisible, setisVisible] = useState("");
  const [BrandName, setBrandName] = useState("");
  const [BrandID, setBrandID] = useState("");
  const [DelBrand, setDelBrand] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const Navigate = useNavigate();

  console.log("object", params.pid);
  //api call for  subcategory wise brnads
  useEffect(() => {
    // console.log("params.Subcatid", params.Subcatid);
    var data = JSON.stringify({
      filterData: {
        subcategory_id: params.Subcatid,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}brand/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setSBrandList(response?.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // console.log("SBrandList====>", SBrandList);
  // console.log("params.Subcatname", params.Subcatname);

  //Validation for upload image product

  const validateProductImage = (values) => {
    const errors = {};
    if (!ImageUpload) {
      errors.ImageUpload = "Field should not be blank";
    }
    return errors;
  };
  useEffect(() => {
    console.log("ImageUploadImg", formErrors.ImageUpload);
  }, [ImageUpload]);

  const HandleUpdateImage = (e) => {
    e.preventDefault();
    setFormErrors(validateProductImage());
    setIsSubmit(true);
    if (ImageUpload) {
      getuploadprodimage();
      console.log("object", formErrors);
    }
  };

  // product image upload api call(s3bucket)
  const ImageUploadproduct = (e) => {
    console.log(e.target.files[0]);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", adminauth?.data?.token);

    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    console.log(formdata);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BaseAPI.nodeAPI}fileupload`, requestOptions)
      .then((response) => response.text())
      .then((result) => setImageUpload(JSON.parse(result).file_path))
      .catch((error) => console.log("error", error));
  };

  //api call to submit product image

  const getuploadprodimage = () => {
    var data = JSON.stringify({
      image: ImageUpload,
    });

    var config = {
      method: "put",
      url: `${BaseAPI.nodeAPI}subcategory/${params.Subcatid}`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("upoladed product image", response.data);
        Navigate(
          `/admin-products-category-details/${params?.pid}/${params?.pname}`
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //VALIDATION
  const CBrandValidation = () => {
    setMultipleBrandValues((current) =>
      current.map((obj) => {
        if (!obj.name) {
          return { ...obj, Branderror: "Field Should Not Be Empty!" };
        } else {
          return { ...obj, Branderror: "" };
        }
      })
    );
  };

  //function toadd mulitle input filed to brand  on +sign

  const AddSubBrand = () => {
    CBrandValidation();
    console.log("MultipleBrandValuesMultipleBrandValues", MultipleBrandValues);
    setMultipleBrandValues([
      ...MultipleBrandValues,
      {
        name: "",
        category_id: params.pid,
        subcategory_id: params.Subcatid,
        Branderror: "",
      },
    ]);
  };

  //function to multiple brnad in obj
  const HandleMultipleBrand = (value, index) => {
    CBrandValidation();
    console.log("HandleMultipleBrand", value);
    console.log("HandleMultipleBrand", index);
    MultipleBrandValues[index].name = value;
    setMultipleBrandValues((current) =>
      current.map((obj) => {
        if (obj.index === index) {
          return { ...obj, name: value };
        }
        return obj;
      })
    );
  };

  //api call for add brand
  const getaddmultiplebrandname = () => {
    var new_arrayC = MultipleBrandValues.map((obj) => ({
      name: obj.name,
      category_id: params.pid,
      subcategory_id: params.Subcatid,
    }));
    console.log("new_arrayCnew_arrayCnew_arrayC===============>", new_arrayC);

    var data = JSON.stringify({
      data: new_arrayC,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}brand/add_brands`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response?.data?.status === 1) {
          console.log(response.data);
          toast.success(response.data.message, { position: "top-right" });
          window.$("#addNewCategory").modal("hide");
          Refresh();
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  const api_call_ADDMultipleBrands = () => {
    console.log("====================================");
    console.log("AT MUltiple brand API CALL");
    console.log("====================================");
    CBrandValidation();
    const error_array = MultipleBrandValues.filter((value) => {
      return !value.name;
    });
    if (!error_array.length) {
      getaddmultiplebrandname();
    }
  };

  //api call for update brnd name
  const getupdatebrandname = () => {
    var data = JSON.stringify({
      status: 1,
      name: BrandName,
      category_id: params.pid,
      subcategory_id: params.Subcatid,
    });

    var config = {
      method: "put",
      url: `${BaseAPI.nodeAPI}brand/${BrandID}`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response?.data?.data?.status === 1) {
          toast.success(response.data.message, { position: "top-right" });
          console.log(response.data);
          Refresh();
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };
  console.log("DelBrandDelBrand===>>>", DelBrand);

  //function for to add multiple brand in array for soft delete
  const HandleDeleteMultipleBrand = (e, val, index) => {
    if (e.target.checked === true) {
      setDelBrand([...DelBrand, { _id: val?._id, status: 0 }]);
    }
    console.log("HandleDeleteMultipleBrandid =>", val?._id);
    console.log("HandleDeleteMultipleBrandindex", index);

    if (e.target.checked === false) {
      setDelBrand(
        DelBrand.filter((obj) => {
          return obj._id !== val?._id;
        })
      );
    }

    // if (e.target.checked === true) {
    //     setSubProduct(prevState => [...prevState, e.target.id])

    //   }
    //   if (e.target.checked === false) {
    //     setSubProduct(SubProduct.filter(item => item !== e.target.id))

    //   }
  };

  //api call for multiple soft delete brand
  const getSoftDeleteBrand = () => {
    if (!DelBrand.length) {
      toast.error("please select brand to delete", { position: "top-right" });
    } else {
      var data = JSON.stringify({
        data: DelBrand,
      });

      var config = {
        method: "post",
        url: `${BaseAPI.nodeAPI}brand/soft_delete_multiple_brands`,
        headers: {
          Authorization: adminauth?.data?.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response?.data?.status == 1) {
            console.log(response.data);
            toast.success(response?.data?.message, { position: "top-right" });
            Refresh();
          } else {
            toast.error(response?.data?.message, { position: "top-right" });
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error(error.response?.data?.message, { position: "top-right" });
        });
    }
  };
  //function to refresh the page
  const Refresh = () => {
    window.location.reload(false);
  };

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Products Details</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 pageBack d-flex">
                    <NavLink
                      to={`/admin-products-category-details/${params?.pid}/${params?.pname}`}
                    >
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      Product Name:{params.Subcatname}
                    </p>
                  </div>

                  <div className="col-7 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn addBtns waves-effect waves-light"
                      data-bs-toggle="modal"
                      data-bs-target="#addNewCategory"
                    >
                      <i className="fas fa-plus me-3"></i> Add New Brand
                    </button>
                  </div>
                </div>

                <form className="needs-validation mt-4">
                  <div className="row">
                    <div className="col-md-3 px-xxl-5">
                      <div className="computerDetails p-5">
                        <p className="addUserPic new_products_btn text-center d-flex justify-content-center align-items-center mx-auto">
                          {!ImageUpload ? (
                            <>
                              <div className="text-center">
                                <i class="fa-sharp fa-solid fa-upload font-size-24 mb-3 blueText"></i>
                                <p className="blueText">Upload Image</p>
                              </div>

                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                name="media"
                                multiple="multiple"
                                accept="image/*"
                                onChange={(e) => ImageUploadproduct(e)}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={ImageUpload}
                                className="img-fluid h-100"
                                alt="das"
                              />
                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                name="media"
                                multiple="multiple"
                                accept="image/*"
                                onChange={(e) => ImageUploadproduct(e)}
                              />
                            </>
                          )}
                          <label
                            className="custom-file-label mb-0"
                            htmlFor="customFile"
                          ></label>
                        </p>

                        <p className="fw-bold blueText col-12 fs-4 mt-4">
                          {params.Subcatname}
                        </p>
                        <p className="text-danger">{formErrors?.ImageUpload}</p>
                        <button
                          type="submit"
                          className="btn successBtns waves-effect waves-light me-2 px-4"
                          onClick={(e) => HandleUpdateImage(e)}
                        >
                          Uplaod Image
                        </button>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="brands-bg p-5">
                        <div className="d-flex justify-content-between align-items-center mb-5">
                          <p className="fw-bold blueText fs-4">Brands</p>
                          <div className="d-flex align-items-center">
                            {/* <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="inlineCheckbox1"
                                                            value="option1"
                                                        />
                                                        <a href="" className="btn blueText waves-effect waves-light p-0 me-4"><u className="ms-2">Select All</u></a> */}

                            <button
                              type="button"
                              className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                              onClick={(e) => getSoftDeleteBrand(e)}
                            >
                              {" "}
                              Delete Brands
                            </button>
                          </div>
                        </div>
                        <div className="row d-flex">
                          {SBrandList &&
                            SBrandList?.map((val, index) => {
                              return (
                                <>
                                  <div className="col-xl-4 col-lg-6 col-md-6 checkSection d-flex justify-content-between mb-3">
                                    {isVisible !== val?._id ? (
                                      <div className="form-check mb-4">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="b"
                                          value={val?._id}
                                          onChange={(e) =>
                                            HandleDeleteMultipleBrand(
                                              e,
                                              val,
                                              index
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="inlineCheckbox1"
                                        >
                                          {val?.name}
                                        </label>
                                      </div>
                                    ) : (
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={val?._id}
                                        defaultValue={val?.name}
                                        onChange={(e) => [
                                          setBrandName(e.target.value),
                                          setBrandID(val?._id),
                                        ]}
                                      />
                                    )}
                                    <a>
                                      <i
                                        className="fa-solid fa-pen ms-4 mt-1 text-dark"
                                        onClick={() => {
                                          isVisible == val?._id
                                            ? setisVisible()
                                            : setisVisible(val?._id);
                                        }}
                                      ></i>
                                    </a>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                        <button
                          type="button"
                          className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                          onClick={(e) => getupdatebrandname(e)}
                        >
                          {" "}
                          Update & Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal for add multiple brands */}
      <div
        className="modal fade w-100 h-100"
        tabIndex="-1"
        role="dialog"
        id="addNewCategory"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title mt-0">Add New Category</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {MultipleBrandValues &&
                MultipleBrandValues?.map((val, index) => {
                  return (
                    <div className="row">
                      <div className="col">
                        <label className="form-label" htmlFor="categoryname">
                          Category Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="categoryName"
                          placeholder="Enter Category Name"
                          required
                          name="Category"
                          defaultValue={params.pname}
                        />
                      </div>

                      <div className="col">
                        <label className="form-label" htmlFor="productname">
                          Product Name
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="categoryName"
                          placeholder="Enter Product Name"
                          required
                          name="Product"
                          defaultValue={params.Subcatname}
                        />
                      </div>
                      <div className="col">
                        <label className="form-label" htmlFor="brandname">
                          Brand Name
                        </label>
                        <input
                          type="text"
                          className="form-control mb-2"
                          id={val?._id}
                          placeholder="Enter Brand Name"
                          required
                          name="Brand"
                          onChange={(e) =>
                            HandleMultipleBrand(e.target.value, index)
                          }
                        />
                        <p className="text-danger">{val.Branderror}</p>
                      </div>
                      <div className="col-1">
                        <span className="newCategoryAdd formIcons categoryNew">
                          <i
                            className="fas fa-plus-circle blueText h4 cursorPointer fs-1"
                            onClick={AddSubBrand}
                          ></i>
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
              <button
                type="button"
                className="btn addBtns waves-effect waves-light px-4"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn filledaddBtns waves-effect waves-light px-4"
                onClick={(e) => api_call_ADDMultipleBrands(e)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductsDetails;
