/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const AddNewServiceProvider = () => {
  const navigate = useNavigate();
  // Variables
  const adminauth = JSON.parse(localStorage.getItem('adminauth'));
  const [checklist, setCheckList] = useState("");
  const [checksubcatg, setCheckSubcatgList] = useState("");
  const [brandlist, setBrandList] = useState("");
  const initialServiceValues = { shopname: "", firstname: "", contactno: "", email: "", address: "", pincode: "", city: "", state: "" };
  const [formValues, setformValues] = useState(initialServiceValues);
  const [isSubmitForm, SetIsSubmitForm] = useState(false)
  const [formErrors, SetformError] = useState({});
  const [catg, Setcatg] = useState([]);
  const [subcatg, SetSubcatg] = useState([]);
  const [brand, SetBrand] = useState([]);
  const [ImageUpload, setImageUpload] = useState()
  


  // date time 
  const CurrentTime = moment().format('LT'); // htmlFor current Time
  const CurrentDate = moment().format('L'); // htmlFor current Date



  // Form Validationregex
  const Validate = (values) => {
    const error = {};
    const firstnameregex = /^[a-zA-Z ]*$/
    const emailregex = (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/);
    const mobileregex = /^[6-9]\d{9}$/gi;
    var numbers = /^[0-9]+$/;

    if (!values?.shopname) {
      error.shopname = "Shop name is required!";
    }
    if (!values?.pincode) {
      error.pincode = "Pincode is required!'";
    }
    // } else if (!regpincode.test(values.pincode)) {
    //   error.pincode = " Invalid  Pincode!"
    // }
    else if (!numbers?.test(values.pincode)) {
      error.pincode = " Invalid  Pincode!";
    }
    if (!values?.city) {
      error.city = "City name is required!";
    }
    if (!values?.state) {
      error.state = "State name is required!";
    }
    else if (!firstnameregex.test(values.state)) {
      error.state = " Invalid state!"
    }
    if (!values.firstname) {
      error.firstname = "Name is required!"
    } else if (!firstnameregex.test(values.firstname)) {
      error.firstname = " Invalid name!"

    }
    // if (!values.lastname) {
    //   error.lastname = " lastname is required!"
    // } else if (!firstnameregex.test(values.lastname)) {
    //   error.lastname = " Invalid Last Name!"

    // }
    if (!values.contactno) {
      error.contactno = " Contact No. is required!"
    }
    else if (!mobileregex.test(values.contactno)) {
      error.contactno = " Invalid Contact Number!";
    }

    if (!values.email) {
      error.email = " E-mail is required!"
    } else if (!emailregex.test(values.email)) {
      error.email = " Invalid E-mail!"

    }
    if (!values.address) {
      error.address = " Address is required!"
    }
    return error;

  }
  // function for form input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
    console.log(formValues.firstname)
  }

  // // function for brands

  const handleCheckboxBrands = (e) => {
    if (e.target.checked === true) {
      brand.push(e.target.id);
      // console.log("brandid", brand)
    }
    if (e.target.checked === false) {
      SetBrand(brand.filter(item => item !== e.target.id))
    }

  }
  // console.log("brandid", brand)

  // add new Serviceprovider from submistion
  const handleSubmit = async (e) => {
    e.preventDefault();
    SetformError(Validate(formValues));
    SetIsSubmitForm(true)
  }
  // add new ServiceProvide from submistion
  useEffect(async () => {
    if (Object.keys(formErrors).length === 0 && isSubmitForm) {
      try {
        var data = JSON.stringify({
          "name": formValues.firstname,
          "last_name": "gh",
          "phone": formValues.contactno,
          "email": formValues.email,
          "password": "",
          "address": formValues.address,
          "image": ImageUpload,
          "roles": [],
          "category": catg,

          "subcategory": subcatg,
          "brand": brand,
          "access_login": 2,
          "start_time": "1:00 pm",
          "close_time": "4:00 pm",
          "authorized": 1,
          "status": 1,
          "shop_name": formValues.shopname,
          "pincode": formValues.pincode,
          "city": formValues.city,
          "state": formValues.state,
          "code": 3
        });

        var config = {
          method: 'post',
          url: `${BaseAPI.nodeAPI}users/create`,
          headers: {
            'Authorization': adminauth?.data?.token,
            'Content-Type': 'application/json'
          },
          data: data
        };

        await axios(config)
          .then(function (response) {

            console.log("create new", response?.data);
            toast.success(response?.data?.message, { position: 'top-right' });
            navigate("/admin-service-provider");

          })
      }
      catch (error) {
        console.log(error);
        toast.error(error.response?.data?.message, { position: 'top-right' });
      };
    }
  }, [formErrors])



  // Api call For category list
  useEffect(() => {
    var data = JSON.stringify({
      "filterData": {
        "status": 1
      }
    });
    var config = {
      method: 'POST',
      url: `${BaseAPI.nodeAPI}category/list`,
      headers: {
        'Authorization': adminauth?.data?.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        setCheckList(response?.data?.data?.rows);
        console.log("category", response?.data?.data?.rows);


      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  // function for category id
  const handleCheckboxCatg = (e) => {
    if (e.target.checked === true) {
      catg.push(e.target.id);
    }
    if (e.target.checked === false) {
      Setcatg(catg.filter(item => item !== e.target.id))
    }
    // API Call for category wise Subcategory list

    var data = JSON.stringify({
      "category_id": catg
    });

    var config = {
      method: 'post',
      url: `${BaseAPI.nodeAPI}subcategory/categorywise/multiple`,
      headers: {
        'Authorization': adminauth?.data?.token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log("subcategorylist", response?.data?.data);
        setCheckSubcatgList(response?.data?.data.reduce((a, b) => [...a, ...b], []))
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  // function for subcategorywise brand
  const handleCheckboxSubcatg = (e) => {
    if (e.target.checked === true) {
      subcatg.push(e.target.id);
      getBrandData(subcatg)
    }
    if (e.target.checked === false) {
      SetSubcatg(subcatg.filter(item => item !== e.target.id))
      getBrandData(subcatg)
    }

  }
  // Api call for subcategorywise brands
  const getBrandData = (subcatg) => {

    var data = JSON.stringify({
      "subcategory_id": subcatg
    });

    var config = {
      method: 'post',
      url: `${BaseAPI.nodeAPI}brand/subcategorywise/multiple`,
      headers: {
        'Authorization': adminauth?.data?.token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log("SubBrandLIst", response?.data?.data);
        setBrandList(response?.data?.data.reduce((a, b) => [...a, ...b], []))

      })
      .catch(function (error) {
        console.log(error);
      });

  }

  // image upload api call
  const ImageUploadServiceCenter = (e) => {
    console.log(e)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", adminauth?.data?.token);

    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    console.log(formdata)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${BaseAPI.nodeAPI}fileupload`, requestOptions)
      .then(response => response.text())
      .then(result => setImageUpload(JSON.parse(result).file_path))
      .catch(error => console.log('error', error));
  }

  // jsx
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Service Provider</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 pageBack d-flex">
                    <NavLink to={AdminRoutes.AdminServiceProvider}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">
                      Add New Service Center
                    </p>
                  </div>

                  <div className="col-7 d-flex justify-content-end">
                    <NavLink
                      to={AdminRoutes.AdminServiceProvider}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    <NavLink
                      to={AdminRoutes.AdminServiceProvider}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </NavLink>
                  </div>
                </div>

                <form className="needs-validation" noValidate>
                  <div className="row">
                    <div className="mb-3 col-12">
                      <div className="col-md-4 col-sm-12 col-12 float-start">
                        <div className="col-12 float-start mt-2 mt-md-4 mb-4">
                          <p className="addUserPic mx-auto mt-1 mb-1">
                            <span className="addPicIcon">
                              <i className="fas fa-pen"></i>
                            </span>
                            {!ImageUpload ? (
                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                name="media"
                                multiple="multiple"
                                accept="image/*"
                                onChange={(e) => ImageUploadServiceCenter(e)}
                              />
                            ) : (
                              <div className="uplodedImg d-flex align-items-center rounded-circle">
                                <img
                                  src={ImageUpload}
                                 
                                  className="img-fluid h-100"
                                  alt="das"
                                />
                                <br />
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="customFile"
                                  name="media"
                                  multiple="multiple"
                                  accept="image/*"
                                  onChange={(e) => ImageUploadServiceCenter(e)}
                                />
                              </div>
                            )}
                            <label
                              className="custom-file-label mb-0"
                              htmlFor="customFile"
                            ></label>
                          </p>
                          <p className="text-center font14 mb-1 pt-3">
                            Profile Picture
                          </p>
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12 col-12 float-start mb-4">
                        <div className="row ps-0 ps-md-4 mt-2 mt-md-4 mb-4">
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="shopname">
                              Shop Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="shopname"
                              placeholder="Enter Shop Name"
                              required
                              onChange={handleChange}
                              value={formValues.shopname}
                              name="shopname"
                            />
                            <p className='text-danger'>{formErrors.shopname}</p>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="firstname">
                              Contact Person
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstname"
                              placeholder="Enter Full Name"
                              required
                              onChange={handleChange}
                              value={formValues.firstname}
                              name="firstname"
                            />
                            <p className='text-danger'>{formErrors.firstname}</p>
                          </div>

                          {/* <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="lastname">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              placeholder="Enter Last Name"
                              required
                              onChange={handleChange}
                              value={formValues.lastname}
                              name="lastname"
                            />
                            <p className='text-danger'>{formErrors.lastname}</p>
                          </div> */}
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="contact">
                              Contact No.
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contact"
                              placeholder="Enter Contact No"
                              required
                              onChange={handleChange}
                              value={formValues.contactno}
                              name="contactno"
                            />
                            <p className='text-danger'>{formErrors.contactno}</p>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="useremail">
                              E- Mail Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              required
                              onChange={handleChange}
                              value={formValues.email}
                              name="email"
                            />
                            <p className='text-danger'>{formErrors.email}</p>

                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="useraddress">
                              Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="useraddress"
                              placeholder="Enter Address"
                              required
                              onChange={handleChange}
                              value={formValues.address}
                              name="address"
                            />
                            <p className='text-danger'>{formErrors.address}</p>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="useraddress">
                              Pincode
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="useraddress"
                              placeholder="Enter pincode"
                              required
                              onChange={handleChange}
                              value={formValues.pincode}
                              name="pincode"
                              maxLength={6}
                            />
                            <p className='text-danger'>{formErrors.pincode}</p>

                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="usercity">
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="usercity"
                              placeholder="Enter city"
                              required
                              onChange={handleChange}
                              value={formValues.city}
                              name="city"
                            />
                            <p className='text-danger'>{formErrors.city}</p>

                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="usercity">
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="userState"
                              placeholder="Enter State"
                              required
                              onChange={handleChange}
                              value={formValues.state}
                              name="state"
                            />
                            <p className='text-danger'>{formErrors.state}</p>

                          </div>
                        </div>
                      </div>

                      <div className="col-12 float-start">
                        <div className="row">

                          <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                            <div className="col-12 mb-3">
                              <h6 className="heliosRounded robotofont fw-bold regSecondHead">
                                CATEGORY
                              </h6>
                            </div>
                            <>
                              {checklist && checklist?.map((arr) => {
                                return (
                                  <>
                                    <div className="form-check mb-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={arr?._id}
                                        required
                                        onChange={e => handleCheckboxCatg(e)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="category1"
                                      >
                                        {arr?.name}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          </div>

                          <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                            <div className="col-12 mb-3">
                              <h6 className="heliosRounded robotofont fw-bold regSecondHead">
                                SUB CATEGORY
                              </h6>
                            </div>
                            <>
                              {checksubcatg && checksubcatg?.map((arr) => {
                                return (
                                  <>

                                    <div className="form-check mb-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={arr?._id}
                                        required
                                        onChange={e => handleCheckboxSubcatg(e)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="subcategory1"
                                      >
                                        {arr?.name}

                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 col-12 float-start px-2 mb-3 downloadDocsWrap downloadDocsEditWrap">
                            <div className="col-12 mb-3">
                              <h6 className="heliosRounded robotofont fw-bold regSecondHead">
                                BRANDS
                              </h6>
                            </div>

                            <>
                              {brandlist?.length && brandlist?.map(arr => {
                                return (
                                  <>
                                    <div className="row">
                                      <div className="col-4 mb-3">
                                        <div className="form-check mb-2">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={arr?._id}
                                            required
                                            onChange={e => handleCheckboxBrands(e)}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="brand1" >

                                            {arr?.name}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              })}

                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewServiceProvider;
