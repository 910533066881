/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
// import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const AdminUserManagementConsumer = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  // Variable
  const [ConsumerList, setConsumerList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Incompletedata, setIncompletedata] = useState('Completed_data');
  //pagination state
  const [Page, setPage] = useState(1);
  const [Totalcount, setTotalcount] = useState(0);
  const [pagecount, setpagecount] = useState(0);
  useEffect(() => {
    console.log(pagecount);
    getConsumerListData("Indian",Incompletedata);
  }, [Page]);

  // const Refresh = () => {
  //   window.location.reload(false);
  // };

  //delete consumer without data
  // const Deleteconsumerwithoudata = async (phone_no) => {
  //   var data = JSON.stringify({
  //     "phone": phone_no,
  //     "access_login": 4
  //   });

  //   var config = {
  //     method: 'post',
  //     url: `${BaseAPI.nodeAPI}data_remove/user_delete_complete_data`,
  //     headers: {
  //       'Authorization':adminauth?.data?.token,
  //       'Content-Type': 'application/json',

  //     },
  //     data : data
  //   };

  //   axios(config)
  //   .then(function (response) {
  //     console.log(JSON.stringify(response.data));
  //     Refresh()
  //     toast.success(response.data.message, { position: 'top-right' });
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //     toast.error(error.response.data.message, { position: 'top-right' });
  //   });

  // }

  // serach service probvider
  const getsearConsumerList = (e) => {
    var data = JSON.stringify({
      filterData: {
        access_login: 4,
        citizen: "Indian",
        city: e.target.value && e.target.value ? e.target.value : undefined,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}users/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setConsumerList(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  //api call for incomplete data pagination 
  // API CALL FOR INDIAN/NRI CONSUMER LIST DATA
  const getConsumerListData =(type,tab_cond)=>{
     console.log("tab cond0",tab_cond)
     console.log(type)
    setLoading(true);
    var data = JSON.stringify({
      "filterData": {
        "access_login": 4,
        "citizen": type,
        "is_deleted": false
      },
      "sort_cond": {
        "id": -1
      },
      "tab_cond": tab_cond,
      "page":Page,
      "perpage": 50,
      "success_msg": {
        "data":[],
        "status": 1,
        "message": "Success"
      },
      "error_msg": {
        "status": 0,
        "message": "Error"
      }
    });
    
    var config = {
      method: 'post',
      url: `${BaseAPI.nodeAPI}users/list`,
      headers: { 
        'Authorization': adminauth?.data?.token,
        'Content-Type': 'application/json', 
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log("paginationdata",response?.data?.pagescount_arr);
      setConsumerList(response?.data?.data);
      setTotalcount(Math.ceil(response?.data?.totalCount[0].totalCount/50));
      setpagecount(response?.data?.pagescount_arr);
      setLoading(false);

    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);

    });
    
  }


  ///consumer list pagination function
  const pagination = (selectedpage) => {
    if (selectedpage === 1) {
      setPage(1);
    } else {
      setPage(selectedpage);
    }
  };

  const paginationh = () => {
		let items = [];

		let i = Page - 1;
		while (i >= Page - 1 && i > 0) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page - 1)}>
          <a class="page-link">{i}</a>
				</li>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<li className="page-item active" key={Page}  onClick={() => setPage(Page)}>
				 <a class="page-link">{Page} </a>
			</li>,
		);

		i = Page + 1;
		while (i <= Page + 1 && i <= Totalcount) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page + 1)}>
					          <a class="page-link">{i}</a>
				</li>,
			);

			i += 1;
		}

		return items;
	};


  useEffect(()=>{
    console.log("ConsumerList",ConsumerList)
  },[Page])

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Consumer</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap d-flex">
                      {/* <!-- App Search--> */}
                      <form className="app-search d-none d-xl-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by city"
                            onChange={(e) => {
                              getsearConsumerList(e);
                            }}
                          />
                          <span className="bx bx-search-alt"></span>
                        </div>
                      </form>

                      <div className="dropdown d-inline-block d-xl-none ms-2">
                        <button
                          type="button"
                          className="btn header-item noti-icon waves-effect"
                          id="page-header-search-dropdown"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="mdi mdi-magnify"></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                          aria-labelledby="page-header-search-dropdown"
                        >
                          <form className="p-3">
                            <div className="m-0">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  aria-label="Recipient's username"
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    <i className="mdi mdi-magnify"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      {/* <div className="filter d-flex justify-content-center align-items-center ms-4">
                        <button
                          type="button"
                          className="btn dropdown-toggle p-0 dayFilter"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {" "}
                          Filtter
                          <i className="fas fa-filter ps-1"></i>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <a className="dropdown-item" href="#">
                              Under Review
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              To Be Reviewed
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Reviewed
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>

                    {/* <!-- Nav tabs --> */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active "
                          data-bs-toggle="tab"
                          href="#indian"
                          role="tab"
                          onClick={() => [getConsumerListData("Indian","Completed_data"),setPage(1),setIncompletedata("Completed_data")]}
                        >
                          <span>Indian</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link "
                          data-bs-toggle="tab"
                          href="#indian"
                          role="tab"
                          onClick={() => [getConsumerListData("Nri","Completed_data"),setPage(1),setIncompletedata("Completed_data")]}
                        >
                          <span>NRI</span>
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#withoutData"
                          role="tab"
                          onClick={() => [getConsumerListData("Indian","Incompleted_data"),setPage(1),setIncompletedata("Incompleted_data")]}
                        >
                          <span>Without Data Consumer</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#withoutData"
                          role="tab"
                          onClick={() => [getConsumerListData("Nri","Incompleted_data"),setPage(1),setIncompletedata("Incompleted_data")]}
                        >
                          <span>Without Data NRI</span>
                        </a>
                      </li>
                    </ul>

                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-3">
                      <div
                        className="tab-pane active"
                        id="indian"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Subscription ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>State</th>
                                <th>City</th>
                                <th>Status</th>
                                <th>Subscription Type</th>
                                <th>Tracking ID</th>
                                <th>Date/Time</th>
                                <th>Details</th>
                                {/* {adminauth?.data?.access_login === 9
                                  ?
                                  <th>Delete</th>
                                  :
                                  <></>
                                } */}
                              </tr>
                            </thead>
                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                                {!ConsumerList?.length ? (
                                  <>
                                    <span className="d-block text-center p-2 border">
                                      Data not found
                                    </span>
                                  </>
                                ) : (
                                  <tbody>
                                    <>
                                      {ConsumerList?.length && ConsumerList?.map(
                                        (val, index) => (
                                          <tr key={index}>
                                           {/* <td>{`${
                                                Page === 1 &&  index === 9
                                                  ? "1"
                                                  :  index === 9 ? Page : Page - 1
                                              }${index === 9 ? '0' :index + 1}`}</td> */}
                                            {/* <td>{`${index === 9 || Page === 1? "" : Page - 1}${index + 1}`}</td> */}
                                            <td>{Page*50-50+index+1}</td>
                                            <td>{val?.user_data?.code}</td>
                                            <td>{val?.user_data?.name}{" "} {val?.user_data?.last_name}</td>
                                            <td>{val?.user_data?.email}</td>
                                            <td>{val?.user_data?.phone}</td>
                                            <td>{val?.user_data?.state}</td>
                                            <td>{val?.user_data?.city}</td>
                                            <td>
                                              <span
                                                className={
                                                  val?.user_data?.status === 1
                                                    ? "badge bg-success"
                                                    : "badge rounded bg-warning"
                                                }
                                              >
                                                {val?.user_data.status === 1
                                                  ? "Verified"
                                                  : "Not-Verified"}
                                              </span>
                                            </td>
                                            <td>
                                              {val?.subscription_data?.title}
                                            </td>
                                            <td>
                                              {val?.buysubsc_data?.tracking_id}
                                            </td>
                                            <td>
                                              {moment(
                                                val?.user_data?.createdAt
                                              ).format("LLLL")}
                                            </td>
                                            <td>
                                              <NavLink
                                                to={
                                                  "/consumer-details/" +
                                                  val?.user_data?._id
                                                }
                                                type="button"
                                                className="btn btn-sm waves-effect waves-light"
                                              >
                                                View More
                                              </NavLink>
                                            </td>
                                            {/* {adminauth?.data?.access_login === 9
                                        ?
                                        <td><i className="fas fa-trash" onClick={(e) => DeleteConsumer(val?.user_data?._id)}></i></td>
                                        :
                                        <></>
                                      } */}
                                          </tr>
                                        )
                                      )}
                                    </>
                                  </tbody>
                                )}{" "}
                              </>
                            )}
                          </table>
                        </div>
                        {ConsumerList?.length && ConsumerList[0]?.user_data?.citizen === "Indian" || ConsumerList?.user_data?.citizen === "Indian"?
                          <nav
                              aria-label="Page navigation example"
                              className="my-5"
                              // style={{
                              //   color: "#208d8e",
                              // }}
                            >
                              <ul className="pagination justify-content-end pe-5">
                                <li
                                  className={
                                    Page === 1 ? "page-item disabled" : ""
                                  }
                                >
                                  <a
                                    className="page-link"
                                    aria-label="Previous"
                                  >
                                    <span
                                      aria-hidden="true"
                                      onClick={() => pagination(1)}
                                    >
                                      &laquo;
                                    </span>
                                  </a>
                                </li>
                                {/* <>
                                  {Totalcount / 2 > 0 &&
                                    pagecount.length &&
                                    pagecount?.map((val, index) => {
                                      return (
                                        <>
                                          <li
                                            className={
                                              Page === index + 1
                                                ? "page-item active"
                                                : ""
                                            }
                                            key={index}
                                          >
                                            <a
                                              className="page-link "
                                              onClick={() => pagination(val)}
                                            >
                                              {val}
                                            </a>
                                          </li>
                                        </>
                                      );
                                    })}
                                </> */}
                                {Page - 1 > 1 && (
                                      <li class="page-item">
                                        <a class="page-link" onClick={() => setPage(Page - 2)}>...</a>
                                      </li>
                                      )}
                                {paginationh()}
                                {Page + 1 < Totalcount && (
                                <li class="page-item">
                                  <a class="page-link" onClick={() => setPage(Page + 2)}>...</a>
                                </li>
                                )}
                                <li
                                  className={
                                    pagecount[pagecount.length - 1] === Page
                                      ? "page-item disabled"
                                      : ""
                                  }
                                >
                                  <a className="page-link" aria-label="Next">
                                    <span
                                      aria-hidden="true"
                                      onClick={() =>
                                        pagination(
                                          // pagecount[pagecount.length - 1] ===
                                          //   Page
                                          //   ? pagecount[pagecount.length - 1]
                                          //   : Page + 1
                                          Totalcount
                                        )
                                      }
                                    >
                                      &raquo;
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            :null}
                      </div>

                      {/* ============================//withou data=========================== */}
                      <div
                        className="tab-pane"
                        id="withoutData"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Subscription ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>State</th>
                                <th>City</th>
                                <th>Status</th>
                                <th>Subscription Type</th>
                                <th>Date/Time</th>
                                <th>Details</th>
                                {/* {adminauth?.data?.access_login === 9
                                  ?
                                  <th>Delete</th>
                                  :
                                  <></>
                                }  */}
                              </tr>
                            </thead>
                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                                {!ConsumerList
                                  ?.length ? (
                                  <>
                                    <span className="d-block text-center p-2 border">
                                      Data not found
                                    </span>
                                  </>
                                ) : (
                                  <tbody>
                                    <>
                                      {ConsumerList?.map(
                                        (val, index) => (
                                          <tr key={index}>
                                           {/* <td>{`${
                                                Page === 1 &&  index === 9
                                                  ? "1"
                                                  :  index === 9 ? Page : Page - 1
                                              }${index === 9 ? '0' :index + 1}`}</td> */}
                                            {/* <td>{`${index === 9 || Page === 1? "" : Page - 1}${index + 1}`}</td> */}
                                            <td>{Page*50-50+index+1}</td>
                                            <td>{val?.user_data?.code}</td>
                                            <td>{val?.user_data?.name}</td>
                                            <td>{val?.user_data?.email}</td>
                                            <td>{val?.user_data?.phone}</td>
                                            <td>{val?.user_data?.state}</td>
                                            <td>{val?.user_data?.city}</td>
                                            <td>
                                              <span
                                                className={
                                                  val?.user_data?.status === 1
                                                    ? "badge bg-success"
                                                    : "badge rounded bg-warning"
                                                }
                                              >
                                                {val?.user_data.status === 1
                                                  ? "Verified"
                                                  : "Not-Verified"}
                                              </span>
                                            </td>
                                            <td>
                                              {val?.subscription_data?.title}
                                            </td>
                                            <td>
                                              {moment(
                                                val?.user_data?.createdAt
                                              ).format("LLLL")}
                                            </td>
                                            <td>
                                              <NavLink
                                                to={
                                                  "/consumer-details/" +
                                                  val?.user_data?._id
                                                }
                                                type="button"
                                                className="btn btn-sm waves-effect waves-light"
                                              >
                                                View More
                                              </NavLink>
                                            </td>
                                            {/* {adminauth?.data?.access_login === 9
                                        ?
                                        <td><i className="fas fa-trash" onClick={(e) => Deleteconsumerwithoudata(val?.user_data?.phone)}></i></td>
                                        :
                                        <></>
                                      } */}
                                          </tr>
                                        )
                                      )}
                                    </>
                                  </tbody>
                                )}{" "}
                              </>
                            )}
                          </table>
                         
                        </div>
                        {ConsumerList?
                               <nav
                               aria-label="Page navigation example"
                               className="my-5"
                               // style={{
                               //   color: "#208d8e",
                               // }}
                             >
                               <ul className="pagination justify-content-end pe-5">
                                 <li
                                   className={
                                     Page === 1 ? "page-item disabled" : ""
                                   }
                                 >
                                   <a
                                     className="page-link"
                                     aria-label="Previous"
                                   >
                                     <span
                                       aria-hidden="true"
                                       onClick={() => pagination(1)}
                                     >
                                       &laquo;
                                     </span>
                                   </a>
                                 </li>
                                 {/* <>
                                   {Totalcount / 2 > 0 &&
                                     pagecount.length &&
                                     pagecount?.map((val, index) => {
                                       return (
                                         <>
                                           <li
                                             className={
                                               Page === index + 1
                                                 ? "page-item active"
                                                 : ""
                                             }
                                             key={index}
                                           >
                                             <a
                                               className="page-link "
                                               onClick={() => pagination(val)}
                                             >
                                               {val}
                                             </a>
                                           </li>
                                         </>
                                       );
                                     })}
                                 </> */}
                                 {Page - 1 > 1 && (
                                       <li class="page-item">
                                         <a class="page-link" onClick={() => setPage(Page - 2)}>...</a>
                                       </li>
                                       )}
                                 {paginationh()}
                                 {Page + 1 < Totalcount && (
                                 <li class="page-item">
                                   <a class="page-link" onClick={() => setPage(Page + 2)}>...</a>
                                 </li>
                                 )}
                                 <li
                                   className={
                                     pagecount[pagecount.length - 1] === Page
                                       ? "page-item disabled"
                                       : ""
                                   }
                                 >
                                   <a className="page-link" aria-label="Next">
                                     <span
                                       aria-hidden="true"
                                       onClick={() =>
                                         pagination(
                                           // pagecount[pagecount.length - 1] ===
                                           //   Page
                                           //   ? pagecount[pagecount.length - 1]
                                           //   : Page + 1
                                           Totalcount
                                         )
                                       }
                                     >
                                       &raquo;
                                     </span>
                                   </a>
                                 </li>
                               </ul>
                             </nav>
                            :null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserManagementConsumer;