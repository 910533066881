/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import moment from "moment";

const OfferData = () => {
  // Date Time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // fo
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const [offerlist, setofferlist] = useState();
  const [Loading, setLoading] = useState(false);
  const [EarnList, setEarnList] = useState("");

  //api call for offer list

  useEffect(() => {
    getOfferList();
    getEarnList();
  }, []);

  const getOfferList = () => {
    setLoading(true);

    var data = JSON.stringify({});
    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}offer/list`,

      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data?.data);
        setofferlist(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const getEarnList = () => {
    setLoading(true);

    var data = JSON.stringify({});

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}earn/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data?.data);
        setEarnList(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  //jsx
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Offer/Earn</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-7">
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 reportsTabs mt-md-0 ewPlans"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          data-bs-target="#offertab"
                          role="tab"
                          onClick={(e) => getOfferList(e)}
                        >
                          <span>OFFER</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link rounded-0"
                          data-bs-toggle="tab"
                          data-bs-target="#earntab"
                          role="tab"
                          onClick={(e) => getEarnList(e)}
                        >
                          <span>EARNS</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-5 d-flex justify-content-end">
                    <NavLink
                      type="button"
                      className="btn addBtns waves-effect waves-light ms-3 "
                      to="/Create-Offer"
                    >
                      <i className="fas fa-plus"></i> Create Offer/Earn
                    </NavLink>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 pageBack d-flex">
                    <p className="fw-bold font-size-18 h6">All Categories</p>
                  </div>

                  {/* <div className="col-12 d-flex justify-content-end">
                    <NavLink
                      type="button"
                      className="btn addBtns waves-effect waves-light ms-3 "
                      to="/Create-Offer"
                    >
                      <i className="fas fa-plus"></i> Create Offer/Earn
                    </NavLink>
                  </div> */}
                </div>
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="tab-content text-muted mt-3">
                      <div
                        className="tab-pane active"
                        id="offertab"
                        role="tabpanel"
                      >
                        <div className="row">
                          <div className="mb-3 col-12">
                            <div className="table-responsive">
                              <table className="table table-striped mb-0 tablesWrap">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Offer Type</th>
                                    <th>Create OfferDate</th>
                                    <th>Expiry Date</th>
                                    <th>Recipient</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Loading ? (
                                    <div
                                      className="spinner-grow text-info position-absolute tableSpinner"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      {!offerlist?.length ? (
                                        <>
                                          <span className="d-block text-center p-2 border">
                                            Data not found
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          {offerlist?.length &&
                                            offerlist?.map((val, index) => {
                                              return (
                                                <>
                                                  <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{val?.offer_name}</td>
                                                    {val?.offer_on_ewplan_name ? (
                                                      <td>
                                                        {
                                                          val?.offer_on_ewplan_name
                                                        }
                                                      </td>
                                                    ) : (
                                                      <td>
                                                        {
                                                          val?.offer_on_subscription_name
                                                        }
                                                      </td>
                                                    )}

                                                    <td>
                                                      {moment(
                                                        val?.createdAt
                                                      ).format("LL")}
                                                    </td>
                                                    <td>
                                                      {
                                                        val?.expiry_date.split(
                                                          "T"
                                                        )[0]
                                                      }
                                                    </td>

                                                    {val?.access_login === 4 ? (
                                                      <td>Consumer</td>
                                                    ) : (
                                                      <td>Partner</td>
                                                    )}
                                                    <td>
                                                      <span
                                                        className={
                                                          val?.offer_status ===
                                                          "Avail"
                                                            ? "badge bg-success"
                                                            : "badge rounded bg-warning"
                                                        }
                                                      >
                                                        {val?.offer_status ===
                                                        "Avail"
                                                          ? "Avail"
                                                          : "Expired"}
                                                      </span>
                                                    </td>
                                                    {/* <td>
                                                  <NavLink
                                                    to="/Create-Offer"
                                                    className="btn btn-sm waves-effect waves-light"
                                                  >
                                                    View More
                                                  </NavLink>
                                                </td> */}
                                                  </tr>
                                                </>
                                              );
                                            })}
                                        </>
                                      )}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="earntab" role="tabpanel">
                        <div className="row">
                          <div className="mb-3 col-12">
                            <div className="table-responsive">
                              <table className="table table-striped mb-0 tablesWrap">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Date/Time</th>
                                    <th>Earn Title</th>
                                    <th>Recipient</th>
                                    {/* <th>Details</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {Loading ? (
                                    <div
                                      className="spinner-grow text-info position-absolute tableSpinner"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      {!EarnList?.length ? (
                                        <>
                                          <span className="d-block text-center p-2 border">
                                            Data not found
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          {EarnList?.length &&
                                            EarnList?.map((val, index) => {
                                              return (
                                                <>
                                                  <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                      {moment(
                                                        val?.createdAt
                                                      ).format("LLLL")}
                                                    </td>
                                                    <td>{val?.earn_name}</td>

                                                    {val?.access_login === 4 ? (
                                                      <td>Consumer</td>
                                                    ) : (
                                                      <td>Partner</td>
                                                    )}
                                                    {/* <td>
                                                  <NavLink
                                                    to="/Create-Offer"
                                                    className="btn btn-sm waves-effect waves-light"
                                                  >
                                                    View More
                                                  </NavLink>
                                                </td> */}
                                                  </tr>
                                                </>
                                              );
                                            })}
                                        </>
                                      )}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferData;
