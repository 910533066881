/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BaseAPI } from "../../../../api/admin/BaseAPI";

const PartnerData = () => {
  // Date Time
  const CurrentTime = moment().format("LT"); // for current Time
  const CurrentDate = moment().format("L"); // fo
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  //states for Seller Subscription list
  const [getSellSublistData, setgetSellSublistData] = useState([]);
  const [Loading, setLoading] = useState(false);
  //states for Sell Extended Warrenty
  const [getSellWElistData, setgetSellWElistData] = useState([]);
  const [Loyalti, setLoyalti] = useState([]);
    //pagination state
    const [Page, setPage] = useState(1);
    const [Totalcount, setTotalcount] = useState(0);
    const [pagecount, setpagecount] = useState(0);

  //api call for sell subscription
  useEffect(() => {
    handleSellSublistData();
    handleSellWElistData();
  }, []);

  const handleSellSublistData = () => {
    setLoading(true);
    var data = JSON.stringify({
      "filterData": {
        "title": "Subscription"
      },
      "sort_cond": {
        "createdAt": -1
      },
      "page": 1,
      "perpage": 50
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}partnerdetails/sell_subscription`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
         console.log("getSellSublistData", response.data.data);
        setgetSellSublistData(response?.data?.data);
        setTotalcount(Math.ceil(response?.data?.totalCount[0].totalCount/50));
        setpagecount(response?.data?.pagescount_arr);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  //Api call for sell extended warrenty
  const handleSellWElistData = () => {
    var data = JSON.stringify({
      "filterData": {
        "title": "EW"
      },
      "sort_cond": {
        "createdAt": -1
      },
      "page": 1,
      "perpage": 50
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}partnerdetails/sell_ew`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
         console.log("getSellWElistData", response?.data?.data);
        setgetSellWElistData(response?.data?.data);
        setTotalcount(Math.ceil(response?.data?.totalCount[0].totalCount/50));
        setpagecount(response?.data?.pagescount_arr);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //api call for Loyalty/alliance
  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseAPI.nodeAPI}partnerdetails/loyalty_alliance`,
      headers: {
        Authorization: adminauth?.data?.token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data?.data);
        setLoyalti(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


   // pagination function
   const pagination = (selectedpage) => {
    if (selectedpage === 1) {
      setPage(1);
    } else {
      setPage(selectedpage);
    }
  };

  const paginationh = () => {
		let items = [];

		let i = Page - 1;
		while (i >= Page - 1 && i > 0) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page - 1)}>
          <a class="page-link">{i}</a>
				</li>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<li className="page-item active" key={Page}  onClick={() => setPage(Page)}>
				 <a class="page-link">{Page} </a>
			</li>,
		);

		i = Page + 1;
		while (i <= Page + 1 && i <= Totalcount) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page + 1)}>
					          <a class="page-link">{i}</a>
				</li>,
			);

			i += 1;
		}

		return items;
	};
  //JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Partner Data</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap d-flex">
                      <form className="app-search d-none d-xl-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />
                          <span className="bx bx-search-alt"></span>
                        </div>
                      </form>

                      <div className="dropdown d-inline-block d-xl-none ms-2">
                        <button
                          type="button"
                          className="btn header-item noti-icon waves-effect"
                          id="page-header-search-dropdown"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="mdi mdi-magnify"></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                          aria-labelledby="page-header-search-dropdown"
                        >
                          <form className="p-3">
                            <div className="m-0">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search by order ID"
                                  aria-label="Recipient's username"
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    <i className="mdi mdi-magnify"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#sellSubscription"
                          role="tab"
                        >
                          <span>Sell Subscription</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#sellExtendedWarranty"
                          role="tab"
                          onClick={(e) => handleSellWElistData(e)}
                        >
                          <span>Sell Extended Warranty</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#loyaltyAlliance"
                          role="tab"
                        >
                          <span>Loyalty/Alliance</span>
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content text-muted mt-3">
                      {/* //map for sell subscription data     */}
                      <div
                        className="tab-pane active"
                        id="sellSubscription"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Channel Partner 16 Digits number</th>
                                <th>Channel Partner Name</th>
                                <th>Consumer ID</th>
                                <th>Consumer Name</th>
                                <th>Mobile Number</th>
                                <th>Email ID</th>
                                <th>Subscription Type /Name</th>
                                <th>Amount</th>
                                <th>Amount Paid Date</th>
                                <th>Margin Calculation</th>
                              </tr>
                            </thead>
                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                                {!getSellSublistData?.length ? (
                                  <>
                                    <span className="d-block text-center p-2 border">
                                      Data not found
                                    </span>
                                  </>
                                ) : (
                                  <tbody>
                                    <>
                                      {getSellSublistData?.length &&
                                        getSellSublistData?.map(
                                          (val, index) => (
                                            <tr key={index}>
                                              <td>{Page*50-50+index+1}</td>
                                              <td>{val?.partner_data[0]?.code}</td>
                                              <td>
                                                {val?.partner_data[0]?.title}
                                              </td>
                                              <td>
                                                {val?.consumer_data[0]?.code}
                                              </td>
                                              <td>
                                                {val?.consumer_data[0]?.name}{" "}
                                                {val?.consumer_data[0]?.last_name}
                                              </td>
                                              <td>
                                                {val?.consumer_data[0]?.phone}
                                              </td>
                                              <td>
                                                {val?.consumer_data[0]?.email}
                                              </td>
                                              <td>
                                                {val?.subsc_data?.title}
                                              </td>
                                              <td>
                                                {
                                                  val?.buysubsc_data
                                                    ?.amount
                                                }
                                              </td>
                                              <td>
                                                {
                                                  val?.buysubsc_data
                                                    ?.trans_date
                                                }
                                              </td>
                                              <td>
                                                {
                                                  val?.buysubsc_data
                                                    ?.mer_amount
                                                }
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </>
                                  </tbody>
                                )}
                              </>
                            )}
                          </table>
                        </div>
                        {getSellSublistData?.length ?
                          <nav
                              aria-label="Page navigation example"
                              className="my-5"
                              // style={{
                              //   color: "#208d8e",
                              // }}
                            >
                              <ul className="pagination justify-content-end pe-5">
                                <li
                                  className={
                                    Page === 1 ? "page-item disabled" : ""
                                  }
                                >
                                  <a
                                    className="page-link"
                                    aria-label="Previous"
                                  >
                                    <span
                                      aria-hidden="true"
                                      onClick={() => pagination(1)}
                                    >
                                      &laquo;
                                    </span>
                                  </a>
                                </li>
                                {/* <>
                                  {Totalcount / 2 > 0 &&
                                    pagecount.length &&
                                    pagecount?.map((val, index) => {
                                      return (
                                        <>
                                          <li
                                            className={
                                              Page === index + 1
                                                ? "page-item active"
                                                : ""
                                            }
                                            key={index}
                                          >
                                            <a
                                              className="page-link "
                                              onClick={() => pagination(val)}
                                            >
                                              {val}
                                            </a>
                                          </li>
                                        </>
                                      );
                                    })}
                                </> */}
                                {Page - 1 > 1 && (
                                      <li class="page-item">
                                        <a class="page-link" onClick={() => setPage(Page - 2)}>...</a>
                                      </li>
                                      )}
                                {paginationh()}
                                {Page + 1 < Totalcount && (
                                <li class="page-item">
                                  <a class="page-link" onClick={() => setPage(Page + 2)}>...</a>
                                </li>
                                )}
                                <li
                                  className={
                                    pagecount[pagecount.length - 1] === Page
                                      ? "page-item disabled"
                                      : ""
                                  }
                                >
                                  <a className="page-link" aria-label="Next">
                                    <span
                                      aria-hidden="true"
                                      onClick={() =>
                                        pagination(
                                          // pagecount[pagecount.length - 1] ===
                                          //   Page
                                          //   ? pagecount[pagecount.length - 1]
                                          //   : Page + 1
                                          Totalcount
                                        )
                                      }
                                    >
                                      &raquo;
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            :null}
                      </div>
                      <div
                        className="tab-pane"
                        id="sellExtendedWarranty"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Channel Partner 16 Digits number</th>
                                <th> Channel Partner Name</th>
                                <th>Consumer ID</th>
                                <th>Consumer Name</th>
                                <th>Mobile Number</th>
                                <th>Email ID</th>
                                <th>Sub-Category</th>
                                <th>Product</th>
                                <th>Product Purchase Price</th>
                                <th>OEM Warranty Period</th>
                                <th>Warranty Expiry Date</th>
                                <th>Terms for Extended Warranty</th>
                                <th>Select Plan</th>
                                <th>Amount Paid</th>
                                <th>Amount Paid Date</th>
                                <th>Margin Calculation</th>
                              </tr>
                            </thead>
                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                                {!getSellWElistData?.length ? (
                                  <>
                                    <span className="d-block text-center p-2 border">
                                      Data not found
                                    </span>
                                  </>
                                ) : (
                                  <tbody>
                                    <>
                                      {getSellWElistData?.length &&
                                        getSellWElistData?.map((val, index) => (
                                          <tr key={index}>
                                             <td>{Page*50-50+index+1}</td>
                                            <td>{val?.partner_data[0]?.code}</td>
                                            <td>{val?.partner_data[0]?.title}</td>
                                            <td>{val?.consumer_data[0]?.code}</td>
                                            <td>
                                              {val?.consumer_data[0]?.name}{" "}
                                              {val?.consumer_data[0]?.last_name}
                                            </td>
                                            <td>{val?.consumer_data[0]?.phone}</td>
                                            <td>{val?.consumer_data[0]?.email}</td>
                                            <td>
                                              {val?.subcategory_data?.name}
                                            </td>
                                            <td>{val?.ewproduct_data?.name}</td>
                                            <td>
                                              {
                                                val?.ewproduct_data
                                                  ?.purchase_price
                                              }
                                            </td>
                                            <td>
                                              {
                                                val?.ewproduct_data
                                                  ?.warrenty_period_year
                                              }
                                              Year,
                                              {
                                                val?.ewproduct_data
                                                  ?.warrenty_period_month
                                              }
                                              Months
                                            </td>
                                            <td>
                                              {
                                                val?.ew_payment_data
                                                  ?.expire_date
                                              }
                                            </td>
                                            <td>
                                              {
                                                val?.ew_discount_data
                                                  ?.ew_plan_month
                                              }
                                              Months
                                            </td>
                                            <td>{val?.ew_plan_data?.title}</td>
                                            <td>
                                              {val?.ew_payment_data?.amount}
                                            </td>
                                            <td>
                                              {val?.ew_payment_data?.trans_date}
                                            </td>
                                            <td>
                                              {val?.ew_payment_data?.mer_amount}
                                            </td>
                                          </tr>
                                        ))}
                                    </>
                                  </tbody>
                                )}{" "}
                              </>
                            )}
                          </table>
                        </div>
                        {getSellWElistData?.length ?
                          <nav
                              aria-label="Page navigation example"
                              className="my-5"
                              // style={{
                              //   color: "#208d8e",
                              // }}
                            >
                              <ul className="pagination justify-content-end pe-5">
                                <li
                                  className={
                                    Page === 1 ? "page-item disabled" : ""
                                  }
                                >
                                  <a
                                    className="page-link"
                                    aria-label="Previous"
                                  >
                                    <span
                                      aria-hidden="true"
                                      onClick={() => pagination(1)}
                                    >
                                      &laquo;
                                    </span>
                                  </a>
                                </li>
                                {/* <>
                                  {Totalcount / 2 > 0 &&
                                    pagecount.length &&
                                    pagecount?.map((val, index) => {
                                      return (
                                        <>
                                          <li
                                            className={
                                              Page === index + 1
                                                ? "page-item active"
                                                : ""
                                            }
                                            key={index}
                                          >
                                            <a
                                              className="page-link "
                                              onClick={() => pagination(val)}
                                            >
                                              {val}
                                            </a>
                                          </li>
                                        </>
                                      );
                                    })}
                                </> */}
                                {Page - 1 > 1 && (
                                      <li class="page-item">
                                        <a class="page-link" onClick={() => setPage(Page - 2)}>...</a>
                                      </li>
                                      )}
                                {paginationh()}
                                {Page + 1 < Totalcount && (
                                <li class="page-item">
                                  <a class="page-link" onClick={() => setPage(Page + 2)}>...</a>
                                </li>
                                )}
                                <li
                                  className={
                                    pagecount[pagecount.length - 1] === Page
                                      ? "page-item disabled"
                                      : ""
                                  }
                                >
                                  <a className="page-link" aria-label="Next">
                                    <span
                                      aria-hidden="true"
                                      onClick={() =>
                                        pagination(
                                          // pagecount[pagecount.length - 1] ===
                                          //   Page
                                          //   ? pagecount[pagecount.length - 1]
                                          //   : Page + 1
                                          Totalcount
                                        )
                                      }
                                    >
                                      &raquo;
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            :null}
                      </div>

                      <div
                        className="tab-pane"
                        id="loyaltyAlliance"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Channel Partner 16 Digits number</th>
                                <th>Channel Partner Name</th>
                                <th>Consumer ID</th>
                                <th>Consumer Name </th>
                                <th>Transaction Type</th>
                                <th>Type of Plan</th>
                                <th>Terms Date</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Loyalty Amount</th>
                              </tr>
                            </thead>
                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                                {!Loyalti?.length ? (
                                  <>
                                    <span className="d-block text-center p-2 border">
                                      Data not found
                                    </span>
                                  </>
                                ) : (
                                  <tbody>
                                    <>
                                      {Loyalti?.length &&
                                        Loyalti?.map((val, index) => (
                                          <tr>
                                             <td>{Page*50-50+index+1}</td>
                                            <td>{val?.partner[0]?.code}</td>
                                            <td>{val?.partner[0]?.title}</td>
                                            <td>{val?.consumer[0]?.code}</td>
                                            <td>
                                              {val?.consumer[0]?.name}{" "}
                                              {val?.consumer[0]?.last_name}
                                            </td>
                                            <td>{val?.reason}</td>
                                            {val?.reason ==
                                            "Sell_subscription" ? (
                                              <td>
                                                {val?.subscription[0]?.title}
                                              </td>
                                            ) : (
                                              <td>
                                                {val?.ewplansdata[0]?.title}
                                              </td>
                                            )}
                                            {val?.reason ==
                                            "Sell_subscription" ? (
                                              <td>
                                                {val?.subscription[0]?.validity}{" "}
                                                {val?.subscription[0]?.mon_yr}
                                              </td>
                                            ) : (
                                              <td>
                                                {
                                                  val?.ewdiscountdata[0]
                                                    ?.ew_plan_month
                                                }{" "}
                                                Months
                                              </td>
                                            )}
                                            {val?.reason ==
                                            "Sell_subscription" ? (
                                              <td>
                                                {
                                                  val?.bysubscriptiondata[0]
                                                    ?.amount
                                                }
                                              </td>
                                            ) : (
                                              <td>
                                                {val?.buyewplansdata[0]?.amount}
                                              </td>
                                            )}
                                            {val?.reason ==
                                            "Sell_subscription" ? (
                                              <td>
                                                {moment(
                                                  val?.bysubscriptiondata[0]
                                                    ?.createdAt
                                                ).format("LLLL")}
                                              </td>
                                            ) : (
                                              <td>
                                                {moment(
                                                  val?.buyewplansdata[0]
                                                    ?.createdAt
                                                ).format("LLLL")}
                                              </td>
                                            )}
                                            <td>{val?.we_coin}</td>
                                          </tr>
                                        ))}
                                    </>
                                  </tbody>
                                )}{" "}
                              </>
                            )}
                          </table>
                        </div>
                        {Loyalti?.length ?
                          <nav
                              aria-label="Page navigation example"
                              className="my-5"
                              // style={{
                              //   color: "#208d8e",
                              // }}
                            >
                              <ul className="pagination justify-content-end pe-5">
                                <li
                                  className={
                                    Page === 1 ? "page-item disabled" : ""
                                  }
                                >
                                  <a
                                    className="page-link"
                                    aria-label="Previous"
                                  >
                                    <span
                                      aria-hidden="true"
                                      onClick={() => pagination(1)}
                                    >
                                      &laquo;
                                    </span>
                                  </a>
                                </li>
                                {/* <>
                                  {Totalcount / 2 > 0 &&
                                    pagecount.length &&
                                    pagecount?.map((val, index) => {
                                      return (
                                        <>
                                          <li
                                            className={
                                              Page === index + 1
                                                ? "page-item active"
                                                : ""
                                            }
                                            key={index}
                                          >
                                            <a
                                              className="page-link "
                                              onClick={() => pagination(val)}
                                            >
                                              {val}
                                            </a>
                                          </li>
                                        </>
                                      );
                                    })}
                                </> */}
                                {Page - 1 > 1 && (
                                      <li class="page-item">
                                        <a class="page-link" onClick={() => setPage(Page - 2)}>...</a>
                                      </li>
                                      )}
                                {paginationh()}
                                {Page + 1 < Totalcount && (
                                <li class="page-item">
                                  <a class="page-link" onClick={() => setPage(Page + 2)}>...</a>
                                </li>
                                )}
                                <li
                                  className={
                                    pagecount[pagecount.length - 1] === Page
                                      ? "page-item disabled"
                                      : ""
                                  }
                                >
                                  <a className="page-link" aria-label="Next">
                                    <span
                                      aria-hidden="true"
                                      onClick={() =>
                                        pagination(
                                          // pagecount[pagecount.length - 1] ===
                                          //   Page
                                          //   ? pagecount[pagecount.length - 1]
                                          //   : Page + 1
                                          Totalcount
                                        )
                                      }
                                    >
                                      &raquo;
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            :null}
                      </div>

                      <div
                        className="tab-pane"
                        id="partnerlink"
                        role="tabpanel"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerData;
