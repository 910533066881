/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";


const ForgotPassword = () => {
  // variable
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [FormError, setFormError] = useState({});
  const [isSubmit, setisSubmit] = useState(false);
  const isUserCheck = JSON.parse(localStorage.getItem('adminauth'));



  // email validation for forget password
  const validate = (email) => {
    const err = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!email) {
      err.email = "Please enter your email address.";
    } else if (!regex.test(email)) {
      err.email = "Incorrect email Format.";
    }
    return err
  }

  // api call for forgot ForgotPassword 
  
  useEffect((e) => {
    if (Object.keys(FormError).length === 0 && isSubmit) {
      var data = JSON.stringify({
        "email": email
      });

      var config = {
        method: 'POST',
        url: `${BaseAPI.nodeAPI}users/forgot-password`,
        headers: {
          'Content-Type': 'application/json',
          'Cookie': `token=${isUserCheck?.token}`
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          if (response?.data.status === 0) {
            toast.error(response?.data?.message, { position: 'top-right' });
          } else {
            console.log("dzdfzsdf")
            toast.success(response?.data?.message, { position: 'top-right' });
            navigate(AdminRoutes.ResetPassword);
          }


        })
        .catch(function (error) {
          toast.error(error?.response?.data.message, { position: 'top-right' });
        })
    }
  }, [FormError]);

  const getForgotPassword = (e) => {
    e.preventDefault();
    localStorage.setItem("myMail", email)
    setFormError(validate(email));
    setisSubmit(true)
  }

  useEffect(() => {
    console.log("FormError", FormError)
  }, [FormError])

  useEffect(() => {
    console.log("Email", email)
  }, [email])


  // JSX
  return (
    <div className="d-flex align-items-center h-100 w-100">
      <div className="account-pages col-md-4 mx-auto form-signin">
        <div className="loginForm">
          <div className="card overflow-hidden mb-0">
            <div className="card-body p-0">
              <form className="form-horizontal" noValidate>
                <div className="mb-1 w-100">
                  <div className="loginBackground text-center">
                    <img
                      src="./assets/images/logo-dark.png"
                      width="100px"
                      alt=""
                      className="img-fluid py-4"
                    />
                  </div>
                </div>
                <h5 className="px-4 fw-bold mb-4 text-center">
                  Forgot Your Password?
                </h5>

                {/* email */}
                <div className="mb-4 float-start w-100 px-4">
                  <label className="form-label fw-normal" htmlFor="email">
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Please enter your email address"

                  />
                  <p className="mb-0" style={{ color: "red" }}>
                  {FormError?.email}
                  </p>
                </div>
                <div className="mb-4 float-start w-100 px-4">
                  {/* <NavLink
                    to={AdminRoutes.ResetPassword}
                    type="submit"
                    className="btn blueBtns borderradius10 waves-effect waves-light px-5 mt-3 w-100"
                    onClick={(e) => getForgotPassword(e)}
                  >
                    Submit
                  </NavLink> */}
                  <button type="submit" className="btn blueBtns borderradius10 waves-effect waves-light px-5 mt-3 w-100" onClick={(e) => getForgotPassword(e)}>Submit</button>
                </div>
                <div className="text-center float-start w-100 px-3 pb-4">
                  <NavLink to={AdminRoutes.AdminLogin}>
                    <span className="ms-2 fst-underline">
                      Go back to Log In
                    </span>
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
