/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import moment from "moment";

const AdminUserManagementWeStaff = () => {
  // Date Time
  const CurrentTime = moment().format("LT"); // for current Time
  const CurrentDate = moment().format("L"); // for current Date
  // auth
  const adminauth1 = JSON.parse(localStorage.getItem("adminauth"));
  // Variables
  const [WestaffData, setwestaffData] = useState([]);
  const [Wendians_id, setWendians_id] = useState("");
  const [Loading, setLoading] = useState(false);
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
    //pagination state
    const [Page, setPage] = useState(1);
    const [Totalcount, setTotalcount] = useState(0);
    const [pagecount, setpagecount] = useState(0);

  useEffect(() => {
    getWestaffData();
    console.log("Wendians_id", Wendians_id);
  }, [Page]);

  // API Call for Westafflist
  const getWestaffData = async (value) => {
    setLoading(true);
    console.log("object", value);
      try {
        var data = JSON.stringify({
          "filterData": {
            "access_login": 1,
            "is_deleted": false,
            status: value,

          },
          "sort_cond": {
            "createdAt": -1
          },
          "page": 1,
          "perpage": 50
        });

        var config = {
          method: "POST",
          url: `${BaseAPI.nodeAPI}users/userlist`,
          headers: {
            Authorization: adminauth1?.data?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config).then((response) => {
          console.log(response?.data.data);
          setwestaffData(response?.data?.data);
          // setWendians_id(response?.data?.data?.rows._id);
          setTotalcount(Math.ceil(response?.data?.totalCount[0].totalCount/50));
          setpagecount(response?.data?.pagescount_arr);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
  
  };

  // //delete api call for wendians
  // const DeleteWendians = (Wendians_id) => {
  //   var data = JSON.stringify({
  //     "_id": Wendians_id
  //   });

  //   var config = {
  //     method: 'post',
  //     url: `${BaseAPI.nodeAPI}data_remove/user`,
  //     headers: {
  //       'Authorization': adminauth?.data?.token,
  //       'Content-Type': 'application/json',

  //     },
  //     data: data
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response.data);
  //       toast.success(response.data.message, { position: 'top-right' });
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       toast.error(error.response.data.message, { position: 'top-right' });
  //     });

  // }

  //pagination function
  const pagination = (selectedpage) => {
    if (selectedpage === 1) {
      setPage(1);
    } else {
      setPage(selectedpage);
    }
  };

  const paginationh = () => {
		let items = [];

		let i = Page - 1;
		while (i >= Page - 1 && i > 0) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page - 1)}>
          <a class="page-link">{i}</a>
				</li>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<li className="page-item active" key={Page}  onClick={() => setPage(Page)}>
				 <a class="page-link">{Page} </a>
			</li>,
		);

		i = Page + 1;
		while (i <= Page + 1 && i <= Totalcount) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page + 1)}>
					          <a class="page-link">{i}</a>
				</li>,
			);

			i += 1;
		}

		return items;
	};

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Wendians</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap">
                      <form className="app-search d-none d-xl-inline-block">
                        {/* <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />
                          <span className="bx bx-search-alt"></span>

                        </div> */}
                      </form>

                      <div className="dropdown d-inline-block d-xl-none ms-2">
                        <button
                          type="button"
                          className="btn header-item noti-icon waves-effect"
                          id="page-header-search-dropdown"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="mdi mdi-magnify"></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                          aria-labelledby="page-header-search-dropdown"
                        >
                          <form className="p-3">
                            <div className="m-0">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  aria-label="Recipient's username"
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    <i className="mdi mdi-magnify"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <NavLink
                        to={AdminRoutes.AddNewWeStaff}
                        type="button"
                        className="btn addBtns waves-effect waves-light ms-3 mt-5"
                      >
                        <i className="fas fa-plus"></i> Add New
                      </NavLink>
                    </div>

                    {/* <!-- Nav tabs --> */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                      role="tablist"
                    >
                      <li className="nav-item" onClick={() => getWestaffData()}>
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#all"
                          role="tab"
                        >
                          <span>All</span>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => getWestaffData(1)}
                      >
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#active"
                          role="tab"
                        >
                          <span>Active</span>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => getWestaffData(0)}
                      >
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#inactive"
                          role="tab"
                        >
                          <span>Inactive</span>
                        </a>
                      </li>
                    </ul>

                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-3">
                      <div className="tab-pane active" id="all" role="tabpanel">
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Employee Id</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Contact</th>
                                {/* <th>Role Assign</th> */}
                                <th>Status</th>
                                <th>Details</th>
                                {/* <th>Delete</th> */}
                              </tr>
                            </thead>
                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                                {!WestaffData?.length ? (
                                  <>
                                    <span className="d-block text-center p-2 border">
                                      Data not found
                                    </span>
                                  </>
                                ) : (
                                  <tbody>
                                    <>
                                      {WestaffData?.length
                                        ? WestaffData?.map((val, index) => (
                                            <tr key={index}>
                                            <td>{Page*50-50+index+1}</td>
                                              <td>{val?.employee_id}</td>
                                              <td>{val?.name}</td>
                                              <td>{val?.email}</td>
                                              <td>{val?.phone}</td>
                                              {/* <td>{val?.roles}</td>  */}
                                              <td>
                                                <span
                                                  className={
                                                    val?.status === 1
                                                      ? "badge bg-success px-2 py-1"
                                                      : "badge rounded bg-warning"
                                                  }
                                                >
                                                  {val?.status === 1
                                                    ? " Active"
                                                    : " Inactive"}
                                                </span>
                                              </td>
                                              <td>
                                                <NavLink
                                                  to={
                                                    "/we-staff-details/" +
                                                    val?._id
                                                  }
                                                  type="button"
                                                  className="btn btn-sm waves-effect waves-light"
                                                >
                                                  View More
                                                </NavLink>
                                              </td>
                                            </tr>
                                          ))
                                        : null}
                                    </>
                                  </tbody>
                                )}{" "}
                              </>
                            )}
                          </table>
                        </div>
                        {WestaffData?.length?
                          <nav
                              aria-label="Page navigation example"
                              className="my-5"
                              // style={{
                              //   color: "#208d8e",
                              // }}
                            >
                              <ul className="pagination justify-content-end pe-5">
                                <li
                                  className={
                                    Page === 1 ? "page-item disabled" : ""
                                  }
                                >
                                  <a
                                    className="page-link"
                                    aria-label="Previous"
                                  >
                                    <span
                                      aria-hidden="true"
                                      onClick={() => pagination(1)}
                                    >
                                      &laquo;
                                    </span>
                                  </a>
                                </li>
                                {/* <>
                                  {Totalcount / 2 > 0 &&
                                    pagecount.length &&
                                    pagecount?.map((val, index) => {
                                      return (
                                        <>
                                          <li
                                            className={
                                              Page === index + 1
                                                ? "page-item active"
                                                : ""
                                            }
                                            key={index}
                                          >
                                            <a
                                              className="page-link "
                                              onClick={() => pagination(val)}
                                            >
                                              {val}
                                            </a>
                                          </li>
                                        </>
                                      );
                                    })}
                                </> */}
                                {Page - 1 > 1 && (
                                      <li class="page-item">
                                        <a class="page-link" onClick={() => setPage(Page - 2)}>...</a>
                                      </li>
                                      )}
                                {paginationh()}
                                {Page + 1 < Totalcount && (
                                <li class="page-item">
                                  <a class="page-link" onClick={() => setPage(Page + 2)}>...</a>
                                </li>
                                )}
                                <li
                                  className={
                                    pagecount[pagecount.length - 1] === Page
                                      ? "page-item disabled"
                                      : ""
                                  }
                                >
                                  <a className="page-link" aria-label="Next">
                                    <span
                                      aria-hidden="true"
                                      onClick={() =>
                                        pagination(
                                          // pagecount[pagecount.length - 1] ===
                                          //   Page
                                          //   ? pagecount[pagecount.length - 1]
                                          //   : Page + 1
                                          Totalcount
                                        )
                                      }
                                    >
                                      &raquo;
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            :null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal
      <div className="modal fade" id="deletModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger" id="exampleModalLabel">Delete Conformation</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure to delete this user?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn addBtns waves-effect waves-light ms-3 px-4" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn blueBtns borderradius10 waves-effect waves-light px-5" onClick={(e)=> DeleteWendians(Wendians_id)}>Delete</button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AdminUserManagementWeStaff;
