/*eslint-disable*/
import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const CreateNewNotification = () => {
  const navigate = useNavigate();
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  //auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  // const [isActive, setisActive] = useState("");
  const [ConsumerList, setConsumerList] = useState("");
  const [selected_list, setselected_list] = useState([]);
  const [GroupName, setGroupName] = useState("");
  const [ImageUpload, setImageUpload] = useState("");
  const [Title, setTitle] = useState("");
  const [SelectType, setSelectType] = useState("");
  const [Description, setDescription] = useState("");
  const [GroupList, setGroupList] = useState("");
  const [Group_Name, setGroup_Name] = useState("");
  const [selected_group_id, setselected_group_id] = useState("");
  const [IsActive, setIsActive] = useState();
  const [Citizen, setCitizen] = useState("");
  const [User_id, setUser_id] = useState("");
  const [notif_token, setnotif_token] = useState("");
  const [formErrors, setformError] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  const [Loading, setLoading] = useState(false);

  // console.log("IsActive", IsActive);
  // console.log("Citizen", Citizen);
  //api call to get consumer list (for modal)
  const getUser_List = (val) => {
    setLoading(true);
    setselected_list([]);
    var data = JSON.stringify({
      access_login: parseInt(val),
      is_deleted: false,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}notification/user_list_for_notification`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response?.data?.data);
        setConsumerList(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  //   useEffect(() => {
  //     setIsActive("4");
  //     setCitizen("Indian");
  //     getUser_List("4");
  //   }, []);

  // function to set the user type
  const getConsumerList = () => {
    setIsActive("4");
    setCitizen("Indian");
    getUser_List("4");
  };

  const getPartnerList = () => {
    setIsActive("5");
    setCitizen("Channel Partner");
    getUser_List("5");
  };

  //function to add selected list
  const add_selected_list = (value) => {
    // console.log('====================================');
    // console.log(value);
    // console.log('====================================');

    ConsumerList.filter((va) => {
      if (va?._id === value) {
        setselected_list([...selected_list, va]);
      }
    });
  };

  // console.log('====================================');
  // console.log(selected_list);
  // console.log('====================================');

  //function for to remove selected name
  const remove_selected_list = (value) => {
    const a = selected_list.filter((va) => {
      return va?._id !== value?._id;
    });
    setselected_list(a);
  };

  //validation for add new group

  const Validate = (GroupName, selected_list) => {
    const error = {};
    const titlenameregex = /^[a-zA-Z]*$/;

    if (!GroupName) {
      error.GroupName = "GroupName is required!";
    } else if (!titlenameregex.test(Title)) {
      error.GroupName = " Invalid GroupName is Title !";
    }

    if (!selected_list) {
      error.selected_list = "please select user type!";
    }

    return error;
  };

  const reset = (e) => {
    window.location.reload(false);
  };

  //add cosnumer group
  const getAddgroup = (e) => {
    e.preventDefault();
    setformError(Validate(GroupName, selected_list));
    SetIsSubmit(true);
    console.log("objectTitleTitle", Title);
    console.log("objectSelectTypeSelectType", selected_list);
  };

  useEffect(() => {
    console.log("objectTitleTitle===>", GroupName);
    console.log("objectSelectTypeSelectType===>", selected_list);
    console.log("==>>>", Object.keys(formErrors).length === 0 && isSubmit);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      try {
        var new_arrayC = selected_list.map((obj) => ({
          notif_token: obj?.device_id,
          access_login: parseInt(obj?.access_login),
          citizen: obj?.citizen,
          user_id: obj?._id,
        }));
        //console.log("new_arrayCnew_arrayC",new_arrayC);
        var data = JSON.stringify({
          title: "",
          group_name: GroupName,
          access_login: parseInt(IsActive),
          citizen: Citizen,
          user_data: new_arrayC,
        });

        var config = {
          method: "post",
          url: `${BaseAPI.nodeAPI}notif_group/add`,
          headers: {
            Authorization: adminauth?.data?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config).then(function (response) {
          if (response?.data?.status === 1) {
            console.log(response.data);
            toast.success(response.data?.message, { position: "top-right" });
            window.$("#Notification_modal").modal("hide");
          } else {
            toast.error(response.data.message, { position: "top-right" });
          }
        });
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      }
    }
  }, [formErrors]);

  ////////===========================================<><>><><><><>><><>><>?<><><><><><><><><><============================================

  //Image upload api call for notification

  const ImageUploadWendians = (e) => {
    console.log(e);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", adminauth?.data?.token);

    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    console.log(formdata);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BaseAPI.nodeAPI}fileupload`, requestOptions)
      .then((response) => response.text())
      .then((result) => setImageUpload(JSON.parse(result).file_path))
      .catch((error) => console.log("error", error));
  };

  const getConsumerListGroup = () => {
    setIsActive("4");
    setCitizen("Indian");
    getGroupList("4");
  };

  const getPartnerListGroup = () => {
    setIsActive("5");
    setCitizen("Channel Partner");
    getGroupList("5");
  };

  //api call for group list
  const getGroupList = (val) => {
    var data = JSON.stringify({
      access_login: parseInt(val),
      status: 1,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}notif_group/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("grouplist", response?.data?.data);
        setGroupList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getGroupList(4);
  }, []);

  const handleChangeValue = (value) => {
    setselected_group_id(value);
    // console.log('============<><><<<>><<<><>========================');
    // console.log(value);
    // console.log('====================================');

    GroupList.filter((va) => {
      if (va?._id === value) {
        setGroup_Name(va?.group_name);
      }
    });
  };

  // console.log('================/////////====================');
  // console.log(Group_Name);
  // console.log('====================================');

  //api call for to push group notification
  const PublishGroupNotification = () => {
    var data = JSON.stringify({
      title: Title,
      description: Description,
      file: ImageUpload,
      access_login: parseInt(IsActive),
      citizen: Citizen,
      group_name: Group_Name,
      group_id: selected_group_id,
      notif_type: SelectType,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}notification/add`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        toast.success(response.data.message, { position: "top-right" });
        navigate("/notifications");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  //function to add the values

  const handleSaveValues = (value) => {
    setUser_id(value);
    // console.log('============<><><<<>><<<><>========================');
    // console.log("setUser_idsetUser_idsetUser_id", value);
    // console.log('====================================');

    ConsumerList.filter((va) => {
      if (va?._id === value) {
        setnotif_token(va?.device_id);
      }
    });
  };

  // console.log('============<><><<<>><<<><>========================');
  // console.log("notif_tokennotif_tokennotif_token=>>>", notif_token);
  // console.log('====================================');

  //api call for to push individual notification

  const PublistIndividualNotification = () => {
    var data = JSON.stringify({
      title: Title,
      description: Description,
      file: ImageUpload,
      notif_token: notif_token,
      access_login: parseInt(IsActive),
      citizen: Citizen,
      user_id: User_id,
      notif_type: SelectType,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}notification/add`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        toast.success(response.data.message, { position: "top-right" });
        navigate("/notifications");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  // api call to send notification for all consumer /all partner

  const PublistNotificationToall = () => {
    var data = JSON.stringify({
      title: Title,
      description: Description,
      file: ImageUpload,
      access_login: parseInt(IsActive),
      citizen: Citizen,
      notif_type: SelectType,
      key: `${Math.floor(
        Math.random() * (99999999 - 11111111 + 1) + 11111111
      )}`,
    });

    // console.log("data<><>", data);

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}notification/add`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response.data.message, { position: "top-right" });
        navigate("/notifications");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  //jsx
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Create New Notification</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="float-start col-6">
                    <a className="" href="#/notifications">
                      <i
                        className="fas fa-arrow-left text-dark"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <span className="fw-bold h5 ps-3">
                      Create New Notifications
                    </span>
                  </div>
                  <div className="col-6">
                    {/* Button trigger modal */}
                    <button
                      type="button"
                      className="btn blueBtns borderradius10 waves-effect waves-light px-5 float-end"
                      data-bs-toggle="modal"
                      data-bs-target="#Notification_modal"
                    >
                      Create New Group
                    </button>

                    {/* Modal */}
                    <div
                      className="modal fade w-100 h-100"
                      id="Notification_modal"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content p-5">
                          <div className="modal-header">
                            <p
                              className="modal-title fs-3"
                              id="staticBackdropLabel"
                            >
                              Add Recipients for group creation
                            </p>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => {
                                reset(e);
                              }}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <label
                              className="form-label blueText"
                              htmlFor="firstname"
                            >
                              *Enter Group Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Group Name"
                              name="Title"
                              onChange={(e) => setGroupName(e.target.value)}
                            />
                            <p className="text-danger">
                              {formErrors?.GroupName}
                            </p>

                            <label
                              className="form-label blueText mt-5"
                              htmlFor="firstname"
                            >
                              *Add Recipients
                            </label>
                            <br></br>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="option2"
                                onClick={() => getConsumerList(4)}
                              />

                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                Consumer
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="option1"
                                onClick={() => getPartnerList(5)}
                              />

                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                Partner
                              </label>
                            </div>

                            {IsActive == 4 ? (
                              <>
                                <div className="add_recipients_name mt-3 p-4">
                                  <div className="d-flex position-relative">
                                    <select
                                      className="form-select planSelect w-100"
                                      aria-label="Default select example"
                                      value="name"
                                      id="name"
                                      onChange={(e) =>
                                        add_selected_list(e.target.value)
                                      }
                                    >
                                      {Loading ? (
                                        <option>Loading...</option>
                                      ) : (
                                        <>
                                          <option selected>Select</option>
                                          <>
                                            {ConsumerList.length &&
                                              ConsumerList?.map(
                                                (val, index) => (
                                                  <>
                                                    <option value={val?._id}>
                                                      {val?.name}{" "}
                                                      {val?.last_name}
                                                    </option>
                                                  </>
                                                )
                                              )}
                                          </>
                                        </>
                                      )}
                                    </select>
                                    {/* <div className="spinner-grow text-info position-absolute tableSpinner mt-5" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div> */}
                                  </div>
                                </div>
                                {selected_list.map((value) => {
                                  return (
                                    <p className="p-3 add_recipients_text me-3">
                                      {value.name}
                                      <span
                                        onClick={() =>
                                          remove_selected_list(value)
                                        }
                                      >
                                        <i className="fa-sharp fa-solid fa-xmark ms-3"></i>
                                      </span>
                                    </p>
                                  );
                                })}
                              </>
                            ) : null}

                            {IsActive == 5 ? (
                              <>
                                <div className="add_recipients_name mt-3 p-4">
                                  <div className="d-flex">
                                    <select
                                      className="form-select planSelect w-100"
                                      aria-label="Default select example"
                                      value="name"
                                      id="name"
                                      onChange={(e) =>
                                        add_selected_list(e.target.value)
                                      }
                                    >
                                      {Loading ? (
                                        <option>Loading...</option>
                                      ) : (
                                        <>
                                          <option selected>Select</option>
                                          <>
                                            {ConsumerList.length &&
                                              ConsumerList?.map((val) => (
                                                <>
                                                  <option value={val?._id}>
                                                    {val?.title}
                                                  </option>
                                                </>
                                              ))}
                                          </>
                                        </>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                {selected_list.map((value) => {
                                  return (
                                    <p className="p-3 add_recipients_text me-3">
                                      {value.title}
                                      <span
                                        onClick={() =>
                                          remove_selected_list(value)
                                        }
                                      >
                                        <i className="fa-sharp fa-solid fa-xmark ms-3"></i>
                                      </span>
                                    </p>
                                  );
                                })}
                              </>
                            ) : null}
                          </div>
                          <p className="text-danger">
                            {formErrors?.selected_list}
                          </p>
                          <div className="modal-footer">
                            {/* <button className="btn greyBtns borderradius10 waves-effect waves-light px-5 me-3">Cancel</button> */}
                            <button
                              className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                              onClick={(e) => getAddgroup(e)}
                            >
                              Create group
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-3 col-sm-12 col-12 float-start">
                    <div className="col-12 d-flex justify-content-center mt-2 mt-md-4 mb-4">
                      <p className="addUserPic new_notification_btn text-center d-flex justify-content-center align-items-center">
                        {!ImageUpload ? (
                          <>
                            <div className="text-center">
                              <i className="fa-sharp fa-solid fa-upload font-size-24 mb-3 blueText"></i>
                              <p className="blueText">Upload Image/Icon</p>
                            </div>

                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFile"
                              name="media"
                              multiple="multiple"
                              accept="image/*"
                              onChange={(e) => ImageUploadWendians(e)}
                            />
                          </>
                        ) : (
                          <>
                            <div className="uplodedImg d-flex align-items-center">
                              <img
                                src={ImageUpload}
                                className="img-fluid h-100"
                                alt="das"
                              />
                              <br />
                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                name="media"
                                multiple="multiple"
                                accept="image/*"
                                onChange={(e) => ImageUploadWendians(e)}
                              />
                            </div>
                          </>
                        )}
                        <label
                          className="custom-file-label mb-0"
                          htmlFor="customFile"
                        ></label>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-9 col-sm-12 col-12 float-start">
                    <div className="row ps-0 ps-md-4 mt-2 mt-md-4">
                      <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                        <label
                          className="form-label blueText"
                          htmlFor="firstname"
                        >
                          *Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Enter Title"
                        />

                        <label
                          className="form-label mt-5 blueText"
                          htmlFor="firstname"
                        >
                          *Select type
                        </label>
                        <select
                          className="form-select form-control"
                          ria-label="Default select example"
                          onChange={(e) => setSelectType(e.target.value)}
                        >
                          <option value="Select">Select type</option>
                          <option value="Group">Group Notifications</option>
                          <option value="Individual">
                            Individual Notifications
                          </option>
                          <option value="All">Send to All</option>
                        </select>

                        {/* ======================== code for group list "Select type====Group Notifications" ============================== */}
                        {SelectType === "Group" ? (
                          <div className=" mt-5">
                            <label
                              className="form-label blueText"
                              htmlFor="firstname"
                            >
                              *Add Recipients
                            </label>
                            <br />
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="option2"
                                onClick={() => getConsumerListGroup(4)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                Consumer
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="option1"
                                onClick={() => getPartnerListGroup(5)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                Partner
                              </label>
                            </div>
                            <div className="add_recipients_name mt-3 p-4">
                              <div className="d-flex">
                                <label
                                  className="form-label mt-5 blueText"
                                  htmlFor="firstname"
                                >
                                  *Add Group
                                </label>
                                <select
                                  className="form-select form-control"
                                  aria-label="Default select example"
                                  onChange={(e) =>
                                    handleChangeValue(e.target.value)
                                  }
                                >
                                  <option value="Select">Select type</option>
                                  {GroupList.length &&
                                    GroupList?.map((val) => {
                                      console.log("group", val);
                                      return (
                                        <>
                                          <option value={val?._id}>
                                            {val?.group_name}
                                          </option>
                                        </>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {/* ======================== code for group list "Select type====Individual Notifications" ============================== */}

                        {SelectType === "Individual" ? (
                          <div className=" mt-5">
                            <label
                              className="form-label blueText"
                              htmlFor="firstname"
                            >
                              *Add Recipients
                            </label>
                            <br />
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="option2"
                                onClick={() => getConsumerList(4)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                Consumer
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="option1"
                                onClick={() => getPartnerList(5)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                Partner
                              </label>
                            </div>
                            <div className="add_recipients_name mt-3 p-4">
                              {IsActive == 4 ? (
                                <div className="d-flex">
                                  <select
                                    className="form-select planSelect w-100"
                                    aria-label="Default select example"
                                    id="name"
                                    onChange={(e) =>
                                      handleSaveValues(e.target.value)
                                    }
                                  >
                                    {Loading ? (
                                      <option>Loading...</option>
                                    ) : (
                                      <>
                                        <option selected>Select</option>
                                        <>
                                          {ConsumerList.length &&
                                            ConsumerList?.map((val, index) => (
                                              <>
                                                <option value={val?._id}>
                                                  {val?.name} {val?.last_name}
                                                </option>
                                              </>
                                            ))}
                                        </>
                                      </>
                                    )}
                                  </select>
                                </div>
                              ) : null}

                              {IsActive == 5 ? (
                                <div className="d-flex">
                                  <select
                                    className="form-select planSelect w-100"
                                    aria-label="Default select example"
                                    id="name"
                                    onChange={(e) =>
                                      handleSaveValues(e.target.value)
                                    }
                                  >
                                    {Loading ? (
                                      <option>Loading...</option>
                                    ) : (
                                      <>
                                        <option selected>Select</option>
                                        <>
                                          {ConsumerList.length &&
                                            ConsumerList?.map((val) => (
                                              <>
                                                <option value={val?._id}>
                                                  {val?.title}
                                                </option>
                                              </>
                                            ))}
                                        </>
                                      </>
                                    )}
                                  </select>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                        {SelectType === "All" ? (
                          <div className=" mt-5">
                            <label
                              className="form-label blueText"
                              htmlFor="firstname"
                            >
                              *Add Recipients
                            </label>
                            <br />
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="option2"
                                onClick={() => getConsumerList(4)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                Consumer
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="option1"
                                onClick={() => getPartnerList(5)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                Partner
                              </label>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                        <label
                          className="form-label blueText"
                          htmlFor="firstname"
                        >
                          *Description
                        </label>
                        <textarea
                          className="form-control mb-3"
                          rows="10"
                          placeholder="Enter Description"
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {SelectType === "Group" ? (
                      <div className="float-end">
                        <NavLink
                          to={AdminRoutes.Notifications}
                          type="button"
                          className="btn greyBtns borderradius10 waves-effect waves-light px-5 me-3"
                        >
                          Cancel
                        </NavLink>
                        <button
                          className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                          onClick={(e) => PublishGroupNotification()}
                        >
                          Publish to Group
                        </button>
                      </div>
                    ) : null}
                    {SelectType === "Individual" ? (
                      <div className="float-end">
                        <button
                          className="btn greyBtns borderradius10 waves-effect waves-light px-5 me-3"
                          href="#/"
                        >
                          Cancel
                        </button>
                        <button
                          className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                          onClick={(e) => PublistIndividualNotification()}
                        >
                          Publish to Individual
                        </button>
                      </div>
                    ) : null}

                    {SelectType === "All" ? (
                      <div className="float-end">
                        <button
                          className="btn greyBtns borderradius10 waves-effect waves-light px-5 me-3"
                          href="#/"
                        >
                          Cancel
                        </button>
                        <button
                          className="btn blueBtns borderradius10 waves-effect waves-light px-5"
                          onClick={(e) => PublistNotificationToall()}
                        >
                          Publish to All
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewNotification;
