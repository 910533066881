/*eslint-disable*/
import React, { useEffect, useState } from "react";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import axios from "axios";
import { toast } from "react-toastify";
const ServicePackDetails = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const params = useParams();
  const [ServicePckDetails, setServicePckDetails] = useState([]);
  const [PricingplanDetails, setPricingplanDetails] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    var data = JSON.stringify({
      filterData: {
        _id: params.ServicepkID,
      },
      success_msg: {
        message: "Success",
      },
      error_msg: {
        message: "Error",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}serv_pack/edit`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("service pack details", response.data.data);
        setServicePckDetails(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    var data = JSON.stringify({
      filterData: {
        serv_pack_id: params.ServicepkID,
      },
      success_msg: {
        message: "Data found",
      },
      error_msg: {
        message: "Data not found",
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}serv_pk_discount/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("serpck pricingplan details", response?.data?.data);
        setPricingplanDetails(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  //===========================jsx=============================
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Welcome to Ask The Expert</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 pageBack d-flex">
                    <NavLink to={AdminRoutes.ServicePackList}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-16 h6">
                      <span className="me-2">Service Pack Details</span>
                      <i
                        className="fa fa-chevron-right me-2"
                        aria-hidden="true"
                      ></i>
                      <span className="blueText">
                        {ServicePckDetails?.servpk_name}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-12 float-start px-2 mb-3 position-relative">
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Features
                                          </th>
                                          <th className="border-top-0 text-end">
                                            {/* <button
                                              type="button"
                                              className="btn whitebtns waves-effect waves-light text-white"
                                              data-bs-toggle="modal"
                                              data-bs-target="#addNewCategory"
                                            >
                                              <i className="fas fa-plus"></i>{" "}
                                              Add Feature
                                            </button> */}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Loading ? (
                                          <div
                                            className="spinner-grow text-info position-absolute tableSpinner"
                                            role="status"
                                          >
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>
                                        ) : (
                                          <>
                                            {!ServicePckDetails?.serv_pk_feature
                                              ?.length ? (
                                              <>
                                                <span className="d-block text-center p-2 border">
                                                  Data not found
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                {ServicePckDetails
                                                  ?.serv_pk_feature?.length &&
                                                  ServicePckDetails?.serv_pk_feature?.map(
                                                    (val, index) => (
                                                      <>
                                                        <tr>
                                                          <td key={index}>
                                                            {index + 1}
                                                          </td>
                                                          <td>
                                                            {
                                                              val?.feature_data
                                                                ?.feature_name
                                                            }
                                                          </td>
                                                          <td>
                                                            <select
                                                              className="form-select planSelect planSelect-servicepack"
                                                              aria-label="Default select example"
                                                            >
                                                              {val?.feature_data
                                                                ?.options
                                                                ?.length &&
                                                                val?.feature_data?.options?.map(
                                                                  (ele) => {
                                                                    return (
                                                                      <>
                                                                        <option
                                                                          value={
                                                                            ele
                                                                          }
                                                                        >
                                                                          {ele}
                                                                        </option>
                                                                      </>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* ======================edit update============================ */}
                          <div className="col-12 float-start mb-2 mt-5">
                            <h6 className="fw-bold">Price Planning</h6>
                          </div>
                          <div className="col-12 float-start position-relative">
                            <div className="card mb-0">
                              <div className="card-body box-shadow p-0">
                                <div className="row">
                                  <div className="table-responsive">
                                    <table className="table table-striped mb-0 tablesWrap">
                                      <thead>
                                        <tr>
                                          <th className="border-top-0 text-nowrap">
                                            Sr. No.
                                          </th>
                                          <th className="border-top-0">
                                            Products
                                          </th>
                                          <th className="border-top-0">
                                            Brand
                                          </th>
                                          <th className="border-top-0">
                                            Percentage
                                          </th>
                                          <th>
                                            <i
                                              className="fas fa-plus-circle text-white h4 cursorPointer"
                                              aria-hidden="true"
                                            ></i>
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {Loading ? (
                                          <div
                                            className="spinner-grow text-info position-absolute tableSpinner"
                                            role="status"
                                          >
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>
                                        ) : (
                                          <>
                                            {!PricingplanDetails?.length ? (
                                              <>
                                                <span className="d-block text-center p-2 border">
                                                  Data not found
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                {PricingplanDetails?.length &&
                                                  PricingplanDetails?.map(
                                                    (val, index) => (
                                                      <>
                                                        <tr>
                                                          <td key={index}>
                                                            {index + 1}
                                                          </td>
                                                          <td>
                                                            <select
                                                              className="form-select planSelect planSelect-servicepack"
                                                              aria-label="Default select example"
                                                            >
                                                              {val
                                                                ?.subcategory_data
                                                                ?.length &&
                                                                val?.subcategory_data?.map(
                                                                  (ele) => {
                                                                    return (
                                                                      <>
                                                                        <option
                                                                          value={
                                                                            ele.name
                                                                          }
                                                                        >
                                                                          {
                                                                            ele?.name
                                                                          }
                                                                        </option>
                                                                      </>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                          </td>
                                                          <td>
                                                            <select
                                                              className="form-select planSelect planSelect-servicepack"
                                                              aria-label="Default select example"
                                                            >
                                                              {val?.brand_data
                                                                ?.length &&
                                                                val?.brand_data?.map(
                                                                  (ele) => {
                                                                    return (
                                                                      <>
                                                                        <option>
                                                                          {
                                                                            ele?.name
                                                                          }
                                                                        </option>
                                                                      </>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                          </td>

                                                          <td>
                                                            <input
                                                              type="number"
                                                              className="form-control me-3"
                                                              id="noOfPages"
                                                              placeholder="Enter Percentage"
                                                              defaultValue={
                                                                val?.discount_percentage
                                                              }
                                                            />
                                                          </td>
                                                          <td>
                                                            <i
                                                              className="fas fa-plus-circle blueText h4 cursorPointer"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ====================== buttons  ============================== */}
                  <div className="col-md-12 col-sm-12 float-start text-end mt-3">
                    {/* <button
                      type="button"
                      className="btn blueBtns waves-effect waves-light px-4 me-3"
                      onClick={(e) => handleUpdatePricingPlan()}
                    >
                      Update & Submit
                    </button> */}
                    <NavLink
                      to={AdminRoutes.ServicePackList}
                      type="button"
                      className="btn addBtns waves-effect waves-light px-5 me-3"
                    >
                      Cancel
                    </NavLink>
                    {/* <NavLink
                      to={AdminRoutes.EwPlans}
                      type="button"
                      className="btn successBtns borderradius10 waves-effect waves-light px-5"
                    >
                    </NavLink>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        id="addNewCategory"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style={{ height: "auto", width: "auto" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg service-pack-modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title mt-0 fw-bold fs-5">
                Service Pack Feature Add
              </h5>
              <button
                type="button"
                id="modal"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="productname">
                    Feature
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="feature"
                    placeholder=""
                    required
                    name="feature"
                    // value={formProductValues.Product}
                    // onChange={handleChange}
                  />
                  {/* <p className="text-danger">{formErrors.Product}</p> */}
                </div>
                <div className="col">
                  <label className="form-label" htmlFor="brandname">
                    Service Pack Option
                  </label>

                  <input
                    type="text"
                    className="form-control mb-2"
                    id="servicepackoption"
                    placeholder=""
                    required
                    name="servicepackoption"
                    // value={InputBrandFields}
                    // onChange={itemEvent}
                  />
                  {/* <p className="text-danger">{formErrors.Items}</p> */}
                  <span className="newCategoryAdd formIcons">
                    <i
                      className="fas fa-plus-circle blueText h4 cursorPointer"
                      onClick={(e) => AddSubBrand(e)}
                    ></i>
                  </span>
                  {/* <ol>
                    {Items?.map((itemval) => {
                      return <li>{itemval}</li>;
                    })}
                  </ol> */}
                </div>
              </div>
            </div>
            <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
              <button
                type="button"
                className="btn addBtns waves-effect waves-light px-4"
                data-bs-dismiss="modal"
                onClick={() => {
                  reset();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn filledaddBtns waves-effect waves-light px-4"
                onClick={(e) => handleSubmitProduct(e)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePackDetails;
