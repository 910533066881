/*eslint-disable*/
import React, { useState } from 'react'
import { Document, Page } from 'react-pdf';
import PrivacypolicyC from '../auth/login/SharedConsumerPDF/PrivacypolicyC.pdf'
import PDFViewer from 'pdf-viewer-reactjs';

const ConsumerAppPrivacyPolicyPDF = () => {
  //varibale for to show pdf
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  //function for pdf view 
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1)
  }

  //function for to change pages
  function changePage(offSet) {
    setPageNumber(prevPageNumber => prevPageNumber + offSet)
  }

  //function for to change pages on back  button
  function ChangePageBack() {
    changePage(-1)
  }

  //function for to change pdf pages on next buttopn 
  function ChangePageNext() {
    changePage(+1)
  }


  //jsx   
  return (
    <div className="container custom-container">
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5" id='policy'>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <p className='policyText mb-3'>At www.auxill.in, accessible from www.auxill.in, one of our main priorities is the privacy of our
                    visitors. This Privacy Policy document contains types of information that is collected and
                    recorded by www.auxill.in and how we use it.</p>
                  <p className='policyText mb-3'>If you have additional questions or require more information about our Privacy Policy, do not
                    hesitate to contact us.</p>
                  <p className='policyText mb-3'>This Privacy Policy applies only to our online activities and is valid for visitors to our website with
                    regards to the information that they shared and/or collect in www.auxill.in. This policy is not
                    applicable to any information collected offline or via channels other than this website. Our Privacy
                    Policy was created with the help of the Free Privacy Policy Generator.</p>
                  <h4 className='policyHeading my-5'>
                    Consent
                  </h4>
                  <p className='policyText'>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                  <h4 className='policyHeading my-5'>
                    Information we collect
                  </h4>

                  <p className='policyText'>The personal information that you are asked to provide, and the reasons why you are asked to
                    provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                  <br></br>
                  <p className='policyText'>If you contact us directly, we may receive additional information about you such as your name,
                    email address, phone number, the contents of the message and/or attachments you may send
                    us, and any other information you may choose to provide.</p>
                  <br></br>
                  <p className='policyText'>When you register for an Account, we may ask for your contact information, including items such
                    as name, company name, address, email address, and telephone number.</p>
                  <h4 className='policyHeading my-5'>
                    How we use your information
                  </h4>

                  <p className='policyText mb-3'>We use the information we collect in various ways, including to:</p>
                  <ul className='ms-5'>
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalize, and expand our website</li>
                    <li>Understand and analyze how you use our website</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>Communicate with you, either directly or through one of our partners, including for
                      customer service, to provide you with updates and other information relating to the
                      website, and for marketing and promotional purposes</li>
                    <li>Send you emails</li>
                    <li>Find and prevent fraud</li>
                  </ul>

                  <h4 className='policyHeading my-5'>
                    Log Files
                  </h4>
                  <p className='policyText'>www.auxill.in follows a standard procedure of using log files. These files log visitors when they
                    visit websites. All hosting companies do this and a part of hosting services' analytics. The
                    information collected by log files include internet protocol (IP) addresses, browser type, Internet
                    Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of
                    clicks. These are not linked to any information that is personally identifiable. The purpose of the
                    information is for analyzing trends, administering the site, tracking users' movement on the
                    website, and gathering demographic information.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5" id='policy'>
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className='policyHeading my-5'>
                    Cookies and Web Beacons
                  </h4>
                  <p className='policyText'>Like any other website, www.auxill.in uses 'cookies'. These cookies are used to store information
                    including visitors' preferences, and the pages on the website that the visitor accessed or visited.
                    The information is used to optimize the users' experience by customizing our web page content
                    based on visitors' browser type and/or other information.</p>
                  <h4 className='policyHeading my-5'>
                    Google DoubleClick DART Cookie
                  </h4>

                  <p className='policyText'>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies,
                    to serve ads to our site visitors based upon their visit to www.website.com and other sites on the
                    internet. However, visitors may choose to decline the use of DART cookies by visiting the Google
                    ad and content network Privacy Policy at the following URL</p>

                  <p className='policyText'>– https://policies.google.com/technologies/ads</p>


                  <h4 className='policyHeading my-5'>
                    Our Advertising Partners
                  </h4>

                  <p className='policyText'>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are
                    listed below. Each of our advertising partners has their own Privacy Policy for their policies on
                    user data. For easier access, we hyperlinked to their Privacy Policies below.</p>
                  <ul className='ms-5'>
                    <li>Google
                      <p className='policyText mt-5'>https://policies.google.com/technologies/ads</p>
                    </li>
                  </ul>
                  <h4 className='policyHeading my-5'>
                    Advertising Partners Privacy Policies
                  </h4>
                  <p className='policyText'>You may consult this list to find the Privacy Policy for each of the advertising partners of
                    www.auxill.in.</p>
                  <p className='policyText my-4'>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web
                    Beacons that are used in their respective advertisements and links that appear on www.auxill.in,
                    which are sent directly to users' browser. They automatically receive your IP address when this
                    occurs. These technologies are used to measure the effectiveness of their advertising campaigns
                    and/or to personalize the advertising content that you see on websites that you visit.</p>
                  <p className='policyText'>Note that www.auxill.in has no access to or control over these cookies that are used by third-
                    party advertisers.</p>

                  <h4 className='policyHeading my-5'>
                    Third Party Privacy Policies
                  </h4>
                  <p className='policyText mb-4'>www.auxill.in's Privacy Policy does not apply to other advertisers or websites. Thus, we are
                    advising you to consult the respective Privacy Policies of these third-party ad servers for more
                    detailed information. It may include their practices and instructions about how to opt-out of
                    certain options.</p>
                  <p className='policyText'>You can choose to disable cookies through your individual browser options. To know more
                    detailed information about cookie management with specific web browsers, it can be found at the
                    browsers' respective websites.</p>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="card p-5 mt-3 mb-5">
        <div class="card-body mb-5">
          <div className="pdfContent p-xxl-5 mb-5" id='policy'>
            <div className="pdfBody mb-5">
              <div className="row mb-5">
                <div className="col-12">
                  <h4 className='policyHeading my-5'>
                    CCPA Privacy Rights (Do Not Sell My Personal Information)
                  </h4>
                  <p className='policyText mb-4'>Under the CCPA, among other rights, California consumers have the right to:</p>
                  <p className='policyText mb-4'>Request that a business that collects a consumer's personal data disclose the categories and
                    specific pieces of personal data that a business has collected about consumers.</p>
                  <p className='policyText mb-4'>Request that a business delete any personal data about the consumer that a business has
                    collected.</p>
                  <p className='policyText mb-4'>Request that a business that sells a consumer's personal data, not sell the consumer's personal
                    data.</p>
                  <p className='policyText'>If you make a request, we have one month to respond to you. If you would like to exercise any of
                    these rights, please contact us.</p>

                  <h4 className='policyHeading my-5'>
                    GDPR Data Protection Rights
                  </h4>

                  <p className='policyText mb-4'>We would like to make sure you are fully aware of all of your data protection rights. Every user is
                    entitled to the following:</p>
                  <p className='policyText mb-4'>The right to access – You have the right to request copies of your personal data. We may charge
                    you a small fee for this service.</p>
                  <p className='policyText mb-4'>The right to rectification – You have the right to request that we correct any information you
                    believe is inaccurate. You also have the right to request that we complete the information you
                    believe is incomplete.</p>
                  <p className='policyText mb-4'>The right to erasure – You have the right to request that we erase your personal data, under
                    certain conditions.</p>
                  <p className='policyText mb-4'>The right to restrict processing – You have the right to request that we restrict the processing of
                    your personal data, under certain conditions.</p>
                  <p className='policyText mb-4'>The right to object to processing – You have the right to object to our processing of your personal
                    data, under certain conditions.</p>
                  <p className='policyText mb-4'>The right to data portability – You have the right to request that we transfer the data that we have
                    collected to another organization, or directly to you, under certain conditions.</p>
                  <p className='policyText mb-4'>If you make a request, we have one month to respond to you. If you would like to exercise any of
                    these rights, please contact us.</p>


                  <h4 className='policyHeading my-5'>
                    Children's Information
                  </h4>

                  <p className='policyText mb-4'>Another part of our priority is adding protection for children while using the internet. We
                    encourage parents and guardians to observe, participate in, and/or monitor and guide their online
                    activity.</p>

                  <p className='policyText'>www.auxill.in does not knowingly collect any Personal Identifiable Information from children
                    under the age of 13. If you think that your child provided this kind of information on our website,
                    we strongly encourage you to contact us immediately and we will do our best efforts to promptly
                    remove such information from our records.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default ConsumerAppPrivacyPolicyPDF