/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";
import moment from "moment";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import { toast } from "react-toastify";

const AdminRMDetails = () => {
  // Date Time
  const CurrentTime = moment().format("LT"); // for current Time
  const CurrentDate = moment().format("L"); // for current Date
  //  auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  // Variables
  const [StoreAssignUsers, setStoreAssignUsers] = useState([]);
  const params = useParams();
  const [RecentRequestList, setRecentRequestList] = useState();
  const [Loading, setLoading] = useState(false);

  // Api call for to add consumers to rm
  const handleSubmitAssignUsers = () => {
    var data = JSON.stringify({
      user_id: StoreAssignUsers,
      rm_id: params.rmid,
      status: 1,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}rm_assign/add`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("Assigneduserslist", response?.data);
        setStoreAssignUsers(response?.data);
        toast.success(response.data.message, { position: "top-right" });
        window.$("#assignusers").modal("hide");
      })
      .catch(function (err) {
        console.log(err);
        toast.error(err.response.data.message, { position: "top-right" });
      });
  };

  // function for to store the id of assign users
  const handleCheckboxUsers = (e) => {
    if (e.target.checked === true) {
      StoreAssignUsers.push(e.target.id);
    }
    if (e.target.checked === false) {
      setStoreAssignUsers(
        StoreAssignUsers.filter((item) => item !== e.target.id)
      );
    }
  };

  // useeffect for recent requestlist
  // useEffect(() => {
  //   getRecentRequestData("Pending")
  // }, [])

  //Listing Api call for Recent request list which need to assign also for rm/direct connect for modal (assign new users button)
  const getRecentRequestData = (value, type) => {
    console.log("acesslogin", params);
    console.log("value", value);
    setLoading(true);
    var data = JSON.stringify({
      obj_1: {
        access_login: params.accesslogin,
        citizen: params.citizentype,
      },
      obj_2: {
        status: value,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}rm_assign/byrmid/${params.rmid}`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("call twooooo", response.data.final_list);
        setRecentRequestList(response.data.final_list);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  //api call for pending  partner list
  const getPartnerPendingData = () => {
    var data = JSON.stringify({
      access_login: parseInt(params.accesslogin),
      citizen: params.citizentype,
      is_deleted: false,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}rm_assign/user_list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("====>>>>call one", response.data);
        setRecentRequestList(response.data.final_list);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPendinglistdata = (value) => {
    if (params.accesslogin === 4) {
      getRecentRequestData();
    } else {
      getPartnerPendingData();
    }
  };

  useEffect(() => {
    getPendinglistdata("Pending");
  }, []);

  useEffect(() => {
    console.log("RecentRequestListRecentRequestList", RecentRequestList);
    console.log("params.accesslogin ===>>> ", params.accesslogin);
  }, [RecentRequestList]);

  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">RM Mapping</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 pageBack d-flex">
                    <NavLink to={AdminRoutes.AdminRMMapping}>
                      <i className="fas fa-arrow-left text-dark me-3"></i>
                    </NavLink>
                    <p className="fw-bold font-size-18 h6">RM Details</p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap">
                      <button
                        type="button"
                        className="btn addBtns waves-effect waves-light ms-3 mt-4"
                        data-bs-toggle="modal"
                        data-bs-target=".assignUsers"
                        onClick={() => getPendinglistdata("Pending")}
                      >
                        <i className="fas fa-plus"></i> Assign New Users
                      </button>
                    </div>
                    {/* <!-- Nav tabs --> */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 reportsTabs reportsTabs__duplicate rmMappingTabs mt-5 mt-md-0"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active recent__button"
                          data-bs-toggle="tab"
                          href="#recent"
                          role="tab"
                        >
                          <span>Recent Requests</span>
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#assignedusers"
                          role="tab"
                        >
                          <span>Assigned Users</span>
                        </a>
                      </li> */}
                    </ul>

                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-4">
                      <div
                        className="tab-pane active"
                        id="recent"
                        role="tabpanel"
                      >
                        {/* <!-- Nav tabs --> */}
                        <ul
                          className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                          role="tablist"
                        >
                          {/* <li className="nav-item" onClick={(e) => setRecentRequestFilter("All")}>
                            <a
                              className="nav-link active"
                              data-bs-toggle="tab"
                              href="#all"
                              role="tab"
                            >
                              <span>All</span>
                            </a>
                          </li> */}
                          <li
                            className="nav-item"
                            onClick={() => getPendinglistdata("Pending")}
                          >
                            <a
                              className="nav-link active"
                              data-bs-toggle="tab"
                              href="#pending"
                              role="tab"
                            >
                              <span>Pending</span>
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() => getRecentRequestData("Complete")}
                          >
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#completed"
                              role="tab"
                            >
                              <span>Completed</span>
                            </a>
                          </li>
                        </ul>

                        {/* <!-- Tab panes --> */}
                        <div className="tab-content text-muted mt-3">
                          <div className="table-responsive">
                            <table className="table table-striped mb-0 tablesWrap">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  {params.accesslogin == 4 ? (
                                    <th>Name</th>
                                  ) : (
                                    <th>Company Name</th>
                                  )}
                                  <th>Email</th>
                                  <th>Contact</th>
                                  <th>Subscription</th>
                                  <th>State</th>
                                  <th>Date/Time</th>
                                  {/* <th>Status</th> */}
                                  {/* <th>Details</th> */}
                                </tr>
                              </thead>
                              {Loading ? (
                                <div
                                  className="spinner-grow text-info position-absolute tableSpinner"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {!RecentRequestList?.length ? (
                                    <>
                                      <span className="d-block text-center p-2 border">
                                        Data not found
                                      </span>
                                    </>
                                  ) : (
                                    <tbody>
                                      {RecentRequestList?.length &&
                                        RecentRequestList?.map((val, index) => {
                                          return (
                                            <>
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                {params.accesslogin == 4 ? (
                                                  <td>
                                                    {val?.user_id?.name}{" "}
                                                    {val?.user_id?.last_name}
                                                  </td>
                                                ) : (
                                                  <td>{val?.company?.name}</td>
                                                )}
                                                <td>{val?.user_id?.email}</td>
                                                <td>{val?.user_id?.phone}</td>
                                                <td>
                                                  {val?.subscription_id?.title}
                                                </td>
                                                <td>{val?.user_id?.state}</td>
                                                <td>
                                                  {moment(
                                                    val?.user_id?.createdAt
                                                  ).format("LLLL")}
                                                </td>
                                                {/* <td>
                                              <span className={val?.status === "Complete" ? "badge bg-success" : 'badge rounded bg-warning'}>{val?.status === "Complete" ? ' Complete' : 'Pending'}
                                              </span>
                                            </td> */}
                                                {/* <td>
                                                <NavLink
                                                  to={AdminRoutes.AdminRequestDetails}
                                                  type="button"
                                                  className="btn btn-sm waves-effect waves-light"
                                                >
                                                  View More
                                                </NavLink>
                                              </td> */}
                                              </tr>
                                            </>
                                          );
                                        })}
                                    </tbody>
                                  )}{" "}
                                </>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*modal*/}
      <div
        className="modal fade assignUsers"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        id="assignusers"
        style={{ height: "auto", width: "auto" }}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content" id="assignusers">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title mt-0">Assign Users</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-striped mb-0 tablesWrap">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Id</th>
                      {params.accesslogin == 4 ? (
                        <th>Name</th>
                      ) : (
                        <th>Company Name</th>
                      )}
                      <th>Email</th>
                      <th>Contact</th>
                      <th>Subscription</th>
                      <th>Date</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  {Loading ? (
                    <div
                      className="spinner-grow text-info position-absolute tableSpinner"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <>
                      {!RecentRequestList?.length ? (
                        <>
                          <span className="d-block text-center p-2 border">
                            Data not found
                          </span>
                        </>
                      ) : (
                        <tbody>
                          <>
                            {RecentRequestList?.length
                              ? RecentRequestList?.map((val, index) => (
                                  <>
                                    <tr key={index}>
                                      <td>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id={val?.user_id?._id}
                                            onChange={(e) =>
                                              handleCheckboxUsers(e)
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td>{index + 1}</td>
                                      {params.accesslogin == 4 ? (
                                        <td>
                                          {val?.user_id?.name}{" "}
                                          {val?.user_id?.last_name}
                                        </td>
                                      ) : (
                                        <td>{val?.company?.name}</td>
                                      )}
                                      <td>{val?.user_id?.email}</td>
                                      <td>{val?.user_id?.phone}</td>
                                      <td>{val?.subscription_id?.title}</td>
                                      <td>
                                        {" "}
                                        {moment(
                                          val?.subscription_data?.createdAt
                                        ).format("L")}
                                      </td>
                                      <td>
                                        {" "}
                                        {moment(
                                          val?.subscription_data?.createdAt
                                        ).format("LT")}
                                      </td>
                                    </tr>
                                  </>
                                ))
                              : null}
                          </>
                        </tbody>
                      )}{" "}
                    </>
                  )}
                </table>
              </div>
            </div>
            <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
              <button
                type="button"
                className="btn addBtns waves-effect waves-light px-5"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn filledaddBtns waves-effect waves-light px-5"
                onClick={handleSubmitAssignUsers}
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRMDetails;
