/*eslint-disable*/
import axios from 'axios';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { BaseAPI } from '../../../../api/admin/BaseAPI';
import AdminRoutes from '../../../../routes/admin/AdminRoutes';
import { toast } from "react-toastify";
const AdminAddNewRM = () => {
  // auth
  const adminauth = JSON.parse(localStorage.getItem('adminauth'));
  // date time 
  const CurrentTime = moment().format('LT'); // htmlFor current Time
  const CurrentDate = moment().format('L'); // htmlFor current Date
  const initialValues = { emp_id: "", firstname: "", lastname: "", contactno: "", email: "" };
  const [formValues, setformValues] = useState(initialValues);
  const [formErrors, setformError] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false)
  const [assignfor, setassignfor] = useState("");
  const [CitizenType, setCitizenType] = useState("");
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
    // console.log(formValues)
  }

  // Form Validationregex
  const Validate = (values, Citizen, Assignfor) => {
    const error = {};
    const firstnameregex = /^[a-zA-Z]*$/
    const emailregex = (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/);
    const mobileregex = /^[6-9]\d{9}$/gi;
    if (!values?.emp_id) {
      error.emp_id = " Employee Id is required!";
    }

    if (!values.firstname) {
      error.firstname = "First name is required!"
    } else if (!firstnameregex.test(values.firstname)) {
      error.firstname = " Invalid First Name!"

    }
    if (!values.lastname) {
      error.lastname = " Last name is required!"
    } else if (!firstnameregex.test(values.lastname)) {
      error.lastname = "Invalid Last Name!"

    }
    if (!values.contactno) {
      error.contactno = "Contact No. is required!"
    }
    else if (!mobileregex.test(values.contactno)) {
      error.contactno = "Invalid Contact Number!";
    }

    if (!values.email) {
      error.email = "E-mail is required!"
    } else if (!emailregex.test(values.email)) {
      error.email = "This is not valid email!"
    }
    if (!Citizen) {
      error.Citizen = "please select citizen type!"
    }
    if (!Assignfor) {
      error.Assignfor = "please select user type!"
    }

    return error;

  }
  // add new WEstaff from submistion
  const handleSubmit = async (e) => {
    e.preventDefault();
    setformError(Validate(formValues, CitizenType, assignfor));
    SetIsSubmit(true)
  }


  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      try {
        var data = JSON.stringify({
          "name": formValues.firstname,
          "email": formValues.email,
          "contact": formValues.contactno,
          "assign_for": assignfor,
          "citizen": CitizenType,
          "employee_id": formValues.emp_id,
          "status": 1
        });

        var config = {
          method: 'post',
          url: `${BaseAPI.nodeAPI}rm_manager/add`,
          headers: {
            'Authorization': adminauth?.data?.token,
            'Content-Type': 'application/json'

          },
          data: data
        };

        axios(config)
          .then(function (response) {
            console.log(response.data);
            toast.success(response.data.message, { position: 'top-right' });
            navigate("/admin-rm-mapping")

          })
      }

      catch (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: 'top-right' });
      };
    }
  }, [formErrors]);

  //jsx 
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Direct Connect</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">{CurrentTime},{CurrentDate}</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row pageBack">
                  <div className="float-start col-12">
                    <NavLink to={AdminRoutes.AdminRMMapping}>
                      <i className="fas fa-arrow-left text-dark"></i>
                    </NavLink>
                    <span className="fw-bold h5 ps-3">Add New Direct Connect</span>
                  </div>
                  <div className="col-md-12 col-sm-12 float-start px-0  text-end">
                    {/* <button
                      type="submit"
                      className="btn addBtns waves-effect waves-light me-2 px-4"
                    >
                      Save as Draft
                    </button> */}
                    <button
                      type="submit"
                      className="btn successBtns waves-effect waves-light me-2 px-4"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <form className="needs-validation" noValidate>
                  <div className="row">
                    <div className="mb-3 col-12">
                      <div className="col-md-8 col-sm-12 col-12 float-start">
                        <div className="row ps-0 ps-md-4 mt-2 mt-md-4">

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="emp_id">
                              Employee Id
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="emp_id"
                              name="emp_id"
                              placeholder="Enter Employee Id"
                              value={formValues?.emp_id}
                              required
                              onChange={handleChange}

                            />
                            <p className='text-danger'>{formErrors?.emp_id}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="firstname">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstname"
                              name="firstname"
                              placeholder="Enter First Name"
                              value={formValues.firstname}
                              required
                              onChange={handleChange}
                            />
                            <p className='text-danger'>{formErrors.firstname}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="lastname">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              placeholder="Enter Last Name"
                              required
                              name="lastname"
                              value={formValues.lastname}
                              onChange={handleChange}
                            />
                            <p className='text-danger'>{formErrors.lastname}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="contact">
                              Contact No.
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="contact"
                              name="contactno"
                              placeholder="Enter Contact No"
                              required
                              value={formValues.contactno}
                              onChange={handleChange}
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Contact No.
                            </div>
                            <p className='text-danger'>{formErrors.contactno}</p>
                          </div>


                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="useremail">
                              E- Mail Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="useremail"
                              name="email"
                              placeholder="Enter email"
                              required
                              onChange={handleChange}
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Email
                            </div>
                            <p className='text-danger'>{formErrors.email}</p>
                          </div>
                          <div className="col-md-3 col-sm-12 float-start px-2 mb-3">
                            <label className="form-label" htmlFor="noOfPages">
                              Citizen
                            </label>
                            <div className="d-flex">
                              <select
                                className="form-select planSelect w-25"
                                aria-label="Default select example"
                                onChange={(e) => setCitizenType(e.target.value)}
                              //onChange={(e) => console.log(e.target.value)}
                              >
                                <option selected>Select</option>
                                <option value="Indian">Indian</option>
                                {/* <option value="Nri">Nri</option> */}
                                <option value="Channel Partner">Channel Partner</option>
                              </select>
                            </div>
                            <p className='text-danger'>{formErrors.Citizen}</p>
                          </div>
                          <label className="form-label" htmlFor="noOfPages">
                              Assign For :
                            </label>
                            {CitizenType === "Indian"?  
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="vault"
                              id="vaultyes"
                              value="vaultyesoption1"
                              onChange={() => setassignfor(4)}

                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              Consumer
                            </label>
                          </div>
                          :
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="vault"
                              id="vaultno"
                              defaultValue="vaultoption2"
                              onChange={() => setassignfor(5)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              Partner
                            </label>
                          </div>
                          }
                          <p className='text-danger'>{formErrors.Assignfor}</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminAddNewRM;
