/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import AdminRoutes from "../../routes/admin/AdminRoutes";

const AdminDetailsLayout = (props) => {
  // Variables
  const navigate = useNavigate();
  const location = useLocation();
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));

  const [adminObject, setAdminObject] = useState(
    JSON.parse(localStorage.getItem("adminauth"))
  );
  const [userManagementTab, setUserManagementTab] = useState(false);
  // Side Effect
  useEffect(() => {
    if (!localStorage.getItem("adminauth")) {
      setTimeout(() => navigate("/admin-login"));
    }
  }, [adminObject]);
  // var cdf = false;
  // const abc = (value) => {
  //   adminauth?.data?.roles.find((name) => {
  //     console.log("====================================");
  //     console.log("value   >", name, value);
  //     console.log("====================================");
  //     if (name == value) {
  //       return true;
  //     }
  //   });
  // };

  console.log("admiauth", adminauth?.data?.roles);

  // JSX
  return (
    <div className="container-fluid">
      <div id="layout-wrapper">
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="container-fluid">
              <div className="float-end">
                <div className="dropdown d-inline-block d-lg-none ms-2">
                  <button
                    type="button"
                    className="btn header-item waves-effect d-flex align-items-center"
                    id="page-header-search-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-magnify"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                    aria-labelledby="page-header-search-dropdown"
                  >
                    <form className="p-3">
                      <div className="m-0">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search ..."
                            aria-label="Recipient's username"
                          />
                          <div className="input-group-append">
                            <button className="btn btn-primary" type="submit">
                              <i className="mdi mdi-magnify"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="dropdown d-inline-block">
                  {/* <button type="button" className="btn header-item noti-icon waves-effect"
										id="page-header-notifications-dropdown" data-bs-toggle="dropdown"
										aria-haspopup="true" aria-expanded="false">
										<i className="mdi mdi-bell-outline"></i>
										<span className="badge rounded-pill bg-danger ">3</span>
									</button> */}
                  <div
                    className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                    aria-labelledby="page-header-notifications-dropdown"
                  >
                    <div className="p-3">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="m-0"> Notifications </h6>
                        </div>
                        <div className="col-auto">
                          <a href="#!" className="small">
                            {" "}
                            View All
                          </a>
                        </div>
                      </div>
                    </div>
                    <div data-simplebar style={{ maxHeight: "230px" }}>
                      <a href="/" className="text-reset notification-item">
                        <div className="d-flex align-items-start">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-cart"></i>
                            </span>
                          </div>
                          <div className="flex-1">
                            <h6 className="mt-0 mb-1">Your order is placed</h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                If several languages coalesce the grammar
                              </p>
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline"></i> 3 min
                                ago
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a href="/" className="text-reset notification-item">
                        <div className="d-flex align-items-start">
                          <img
                            src="assets/images/users/avatar-3.jpg"
                            className="me-3 rounded-circle avatar-xs"
                            alt="user-pic"
                          />
                          <div className="flex-1">
                            <h6 className="mt-0 mb-1">James Lemire</h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                It will seem like simplified English.
                              </p>
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline"></i> 1
                                hours ago
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a href="/" className="text-reset notification-item">
                        <div className="d-flex align-items-start">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title bg-success rounded-circle font-size-16">
                              <i className="bx bx-badge-check"></i>
                            </span>
                          </div>
                          <div className="flex-1">
                            <h6 className="mt-0 mb-1">Your item is shipped</h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                If several languages coalesce the grammar
                              </p>
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline"></i> 3 min
                                ago
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>

                      <a href="/" className="text-reset notification-item">
                        <div className="d-flex align-items-start">
                          <img
                            src="assets/images/users/avatar-4.jpg"
                            className="me-3 rounded-circle avatar-xs"
                            alt="user-pic"
                          />
                          <div className="flex-1">
                            <h6 className="mt-0 mb-1">Salena Layfield</h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                As a skeptical Cambridge friend of mine
                                occidental.
                              </p>
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline"></i> 1
                                hours ago
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="p-2 border-top d-grid">
                      <a
                        className="btn btn-sm btn-link font-size-14 "
                        href="/#"
                      >
                        <i className="mdi mdi-arrow-right-circle me-1"></i> View
                        More..
                      </a>
                    </div>
                  </div>
                </div>

                <div className="dropdown d-inline-block">
                  <button
                    type="button"
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      className="rounded-circle header-profile-user"
                      src="assets/images/default.png"
                      alt="Header Avatar"
                    />
                    <span className="d-none d-xl-inline-block ms-1"></span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block text-dark h4 mb-0"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <NavLink
                      to={AdminRoutes.Profile}
                      className="dropdown-item"
                      href="/#"
                    >
                      <i className="bx bx-user font-size-16 align-middle me-1"></i>
                      Profile
                    </NavLink>
                    {/* <a className="dropdown-item" href="/#"><i
												className="bx bx-wallet font-size-16 align-middle me-1"></i> My
											Wallet</a>
										<a className="dropdown-item d-block" href="/#"><span
												className="badge bg-success float-end">11</span><i
												className="bx bx-wrench font-size-16 align-middle me-1"></i> Settings</a> */}
                    <div className="dropdown-divider"></div>
                    <NavLink
                      to={AdminRoutes.AdminLogout}
                      className="dropdown-item text-danger"
                      href="/#"
                    >
                      <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                      Logout
                    </NavLink>
                  </div>
                </div>
              </div>
              <div>
                <div className="navbar-brand-box">
                  <a
                    href={AdminRoutes.AdminDashboard}
                    className="logo logo-light text-start"
                  >
                    <span className="logo-sm">
                      <img src="assets/images/logo-sm.png" alt="" width="90" />
                    </span>
                    <span className="logo-lg">
                      <img
                        src="assets/images/logo-light.png"
                        alt=""
                        width="90"
                      />
                    </span>
                  </a>
                </div>

                <button
                  type="button"
                  className="btn btn-sm px-3 font-size-16 header-item toggle-btn waves-effect"
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="vertical-menu">
          <div className="h-100">
            <div className="user-wid text-center pt-4 pb-2">
              <div className="user-img">
                <img
                  src="assets/images/default.png"
                  alt=""
                  className="avatar-md mx-auto rounded-circle"
                />
              </div>

              <div className="mt-3">
                <a
                  href={AdminRoutes.AdminDashboard}
                  className="text-dark fw-bold font-size-16 text-uppercase"
                ></a>
                <p className="mb-0">(Admin)</p>
              </div>
            </div>

            {/* Side Nav */}

            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                {/* Dashboard */}
                <li>
                  <NavLink
                    to={AdminRoutes.AdminDashboard}
                    className={
                      location.pathname === AdminRoutes.AdminDashboard
                        ? "activeSideTab waves-effect"
                        : "waves-effect"
                    }
                  >
                    <span className="dashIcons me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 25 25"
                      >
                        <g
                          id="Icon_material-dashboard"
                          data-name="Icon material-dashboard"
                          transform="translate(0.5 0.5)"
                        >
                          <path
                            id="Icon_material-dashboard-2"
                            data-name="Icon material-dashboard"
                            d="M4.5,17.833H15.167V4.5H4.5ZM4.5,28.5H15.167v-8H4.5Zm13.333,0H28.5V15.167H17.833Zm0-24v8H28.5v-8Z"
                            transform="translate(-4.5 -4.5)"
                            strokeWidth="1"
                          />
                        </g>
                      </svg>
                    </span>

                    <span>Dashboard</span>
                  </NavLink>
                </li>

                {/* User Management */}

                <li>
                  <NavLink
                    onClick={() => setUserManagementTab(!userManagementTab)}
                    to={AdminRoutes.AdminUserManagementConsumer}
                    className={
                      location.pathname ===
                        AdminRoutes.AdminUserManagementConsumer ||
                      location.pathname.includes(
                        AdminRoutes.AdminUserManagementMsme
                      ) ||
                      location.pathname.includes(
                        AdminRoutes.AdminUserManagementPartners
                      ) ||
                      location.pathname.includes(
                        AdminRoutes.AdminUserManagementWeStaff
                      )
                        ? "activeSideTab has-arrow waves-effect"
                        : "has-arrow waves-effect"
                    }
                    aria-expanded="true"
                  >
                    <span className="dashIcons me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="17"
                        viewBox="0 0 32.271 22.89"
                      >
                        <path
                          id="Icon_awesome-user-friends"
                          data-name="Icon awesome-user-friends"
                          d="M9.381,13.195A5.472,5.472,0,1,0,3.909,7.722,5.47,5.47,0,0,0,9.381,13.195Zm3.753,1.564h-.406a7.556,7.556,0,0,1-6.694,0H5.629A5.63,5.63,0,0,0,0,20.387v1.407A2.346,2.346,0,0,0,2.345,24.14H16.417a2.346,2.346,0,0,0,2.345-2.345V20.387A5.63,5.63,0,0,0,13.134,14.759Zm10.32-1.564A4.691,4.691,0,1,0,18.763,8.5,4.692,4.692,0,0,0,23.454,13.195ZM25.8,14.759h-.186a6.162,6.162,0,0,1-4.319,0h-.186a5.427,5.427,0,0,0-2.722.752,7.151,7.151,0,0,1,1.94,4.876v1.876c0,.107-.024.21-.029.313h8.629a2.346,2.346,0,0,0,2.345-2.345A5.47,5.47,0,0,0,25.8,14.759Z"
                          transform="translate(0.5 -1.75)"
                          strokeWidth="1"
                        />
                      </svg>
                    </span>
                    <span>User Management</span>
                  </NavLink>
                  <ul
                    className={
                      userManagementTab
                        ? "sub-menu mm-collapse mm-show"
                        : "sub-menu mm-collapse"
                    }
                    aria-expanded="true"
                  >
                    {/* Consumer */}
                    {adminauth?.data?.access_login === 9 ||
                    adminauth?.data?.roles.find((name) => {
                      if (name === "Consumer") {
                        return true;
                      } else {
                        return false;
                      }
                    }) ? (
                      <li>
                        <NavLink
                          to={AdminRoutes.AdminUserManagementConsumer}
                          className={
                            location.pathname ===
                            AdminRoutes.AdminUserManagementConsumer
                              ? "activeSideSubTab waves-effect"
                              : "waves-effect"
                          }
                        >
                          <span className="dashIcons me-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.461"
                              height="20.461"
                              viewBox="0 0 20.461 20.461"
                            >
                              <g
                                id="Icon_feather-edit"
                                data-name="Icon feather-edit"
                                transform="translate(-2.5 -2.164)"
                              >
                                <path
                                  id="Path_4855"
                                  data-name="Path 4855"
                                  d="M11.636,6H4.919A1.919,1.919,0,0,0,3,7.919V21.352a1.919,1.919,0,0,0,1.919,1.919H18.352a1.919,1.919,0,0,0,1.919-1.919V14.636"
                                  transform="translate(0 -1.147)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Path_4856"
                                  data-name="Path 4856"
                                  d="M22.075,3.414a2.035,2.035,0,1,1,2.879,2.879l-9.115,9.115L12,16.368l.96-3.838Z"
                                  transform="translate(-3.243 0)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </span>
                          <span>Consumer</span>
                        </NavLink>
                      </li>
                    ) : (
                      <></>
                    )}
                    {/* MSME */}
                    {/* <li>
                      <NavLink
                        to={AdminRoutes.AdminUserManagementMsme}
                        className={
                          location.pathname ===
                          AdminRoutes.AdminUserManagementMsme
                            ? "activeSideSubTab waves-effect"
                            : "waves-effect"
                        }
                      >
                        <span className="dashIcons me-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22.04"
                            height="22.04"
                            viewBox="0 0 22.04 22.04"
                          >
                            <g
                              id="Icon_ionic-ios-information-circle-outline"
                              data-name="Icon ionic-ios-information-circle-outline"
                              transform="translate(-3.375 -3.375)"
                            >
                              <path
                                id="Path_4858"
                                data-name="Path 4858"
                                d="M16.552,11.763a1.092,1.092,0,1,1,1.086,1.06A1.061,1.061,0,0,1,16.552,11.763Zm.074,1.891H18.65v7.634H16.626Z"
                                transform="translate(-3.248 -1.808)"
                              />
                              <path
                                id="Path_4859"
                                data-name="Path 4859"
                                d="M14.395,4.858A9.533,9.533,0,1,1,7.65,7.65a9.473,9.473,0,0,1,6.744-2.792m0-1.483a11.02,11.02,0,1,0,11.02,11.02,11.018,11.018,0,0,0-11.02-11.02Z"
                              />
                            </g>
                          </svg>
                        </span>
                        <span>MSME</span>
                      </NavLink>
                    </li> */}

                    {/* Patner link */}

                    {adminauth?.data?.access_login === 9 ||
                    adminauth?.data?.roles.find((name) => {
                      if (name === "Partner") {
                        return true;
                      }
                    }) ? (
                      <li>
                        <NavLink
                          to={AdminRoutes.PartnerLinkdata}
                          className={
                            location.pathname === AdminRoutes.PartnerLinkdata
                              ? "activeSideSubTab waves-effect"
                              : "waves-effect"
                          }
                        >
                          <span className="dashIcons me-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22.04"
                              height="22.04"
                              viewBox="0 0 22.04 22.04"
                            >
                              <g
                                id="Icon_ionic-ios-information-circle-outline"
                                data-name="Icon ionic-ios-information-circle-outline"
                                transform="translate(-3.375 -3.375)"
                              >
                                <path
                                  id="Path_4858"
                                  data-name="Path 4858"
                                  d="M16.552,11.763a1.092,1.092,0,1,1,1.086,1.06A1.061,1.061,0,0,1,16.552,11.763Zm.074,1.891H18.65v7.634H16.626Z"
                                  transform="translate(-3.248 -1.808)"
                                />
                                <path
                                  id="Path_4859"
                                  data-name="Path 4859"
                                  d="M14.395,4.858A9.533,9.533,0,1,1,7.65,7.65a9.473,9.473,0,0,1,6.744-2.792m0-1.483a11.02,11.02,0,1,0,11.02,11.02,11.018,11.018,0,0,0-11.02-11.02Z"
                                />
                              </g>
                            </svg>
                          </span>
                          <span>Partner Link</span>
                        </NavLink>
                      </li>
                    ) : null}
                    {/* Partners */}
                    {adminauth?.data?.access_login === 9 ||
                    adminauth?.data?.roles.find((name) => {
                      if (name === "Partner") {
                        return true;
                      }
                    }) ? (
                      <li>
                        <NavLink
                          to={AdminRoutes.AdminUserManagementPartners}
                          className={
                            location.pathname ===
                            AdminRoutes.AdminUserManagementPartners
                              ? "activeSideSubTab waves-effect"
                              : "waves-effect"
                          }
                        >
                          <span className="dashIcons me-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22.04"
                              height="22.04"
                              viewBox="0 0 22.04 22.04"
                            >
                              <g
                                id="Icon_ionic-ios-information-circle-outline"
                                data-name="Icon ionic-ios-information-circle-outline"
                                transform="translate(-3.375 -3.375)"
                              >
                                <path
                                  id="Path_4858"
                                  data-name="Path 4858"
                                  d="M16.552,11.763a1.092,1.092,0,1,1,1.086,1.06A1.061,1.061,0,0,1,16.552,11.763Zm.074,1.891H18.65v7.634H16.626Z"
                                  transform="translate(-3.248 -1.808)"
                                />
                                <path
                                  id="Path_4859"
                                  data-name="Path 4859"
                                  d="M14.395,4.858A9.533,9.533,0,1,1,7.65,7.65a9.473,9.473,0,0,1,6.744-2.792m0-1.483a11.02,11.02,0,1,0,11.02,11.02,11.018,11.018,0,0,0-11.02-11.02Z"
                                />
                              </g>
                            </svg>
                          </span>
                          <span>Partners</span>
                        </NavLink>
                      </li>
                    ) : null}
                    {/* We Staff */}
                    <li>
                      <NavLink
                        to={AdminRoutes.AdminUserManagementWeStaff}
                        className={
                          location.pathname ===
                          AdminRoutes.AdminUserManagementWeStaff
                            ? "activeSideSubTab waves-effect"
                            : "waves-effect"
                        }
                      >
                        <span className="dashIcons me-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22.04"
                            height="22.04"
                            viewBox="0 0 22.04 22.04"
                          >
                            <g
                              id="Icon_ionic-ios-information-circle-outline"
                              data-name="Icon ionic-ios-information-circle-outline"
                              transform="translate(-3.375 -3.375)"
                            >
                              <path
                                id="Path_4858"
                                data-name="Path 4858"
                                d="M16.552,11.763a1.092,1.092,0,1,1,1.086,1.06A1.061,1.061,0,0,1,16.552,11.763Zm.074,1.891H18.65v7.634H16.626Z"
                                transform="translate(-3.248 -1.808)"
                              />
                              <path
                                id="Path_4859"
                                data-name="Path 4859"
                                d="M14.395,4.858A9.533,9.533,0,1,1,7.65,7.65a9.473,9.473,0,0,1,6.744-2.792m0-1.483a11.02,11.02,0,1,0,11.02,11.02,11.018,11.018,0,0,0-11.02-11.02Z"
                              />
                            </g>
                          </svg>
                        </span>
                        <span>Wendians</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* Configaration */}
                {/* <li>
                  <NavLink
                    to={AdminRoutes.AdminConfigaration}
                    className={
                      location.pathname.includes(AdminRoutes.AdminConfigaration)
                        ? "activeSideTab waves-effect"
                        : "waves-effect"
                    }
                    aria-expanded="false"
                  >
                    <span className="dashIcons me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.666"
                        height="22.265"
                        viewBox="0 0 19.666 22.265"
                      >
                        <g
                          id="_4_Businessman"
                          data-name="4_Businessman"
                          transform="translate(-10.222 -7.33)"
                          opacity="0.496"
                        >
                          <path
                            id="Path_4822"
                            data-name="Path 4822"
                            d="M29.7,27.337a11.175,11.175,0,0,0-8.127-5.45v-1.16a8.391,8.391,0,0,0,3.111-4.4,1.468,1.468,0,0,0,.2.036,1.5,1.5,0,0,0,1.495-1.508,1.481,1.481,0,0,0-.655-1.21,1.44,1.44,0,0,0,.655-1.2V11.245a1.508,1.508,0,0,0-1.228-1.481,1.508,1.508,0,0,0-1.206-1.2A1.513,1.513,0,0,0,22.462,7.33H15.238a1.508,1.508,0,0,0-1.5,1.508V12.45a1.436,1.436,0,0,0,.659,1.2,1.476,1.476,0,0,0-.659,1.21,1.508,1.508,0,0,0,1.5,1.5,1.666,1.666,0,0,0,.208-.036,8.225,8.225,0,0,0,3.106,4.4v1.165a11.193,11.193,0,0,0-3.373,1.048h0a11.139,11.139,0,0,0-4.764,4.4,1.517,1.517,0,0,0,1.3,2.258h16.67a1.517,1.517,0,0,0,1.3-2.258ZM18.7,22.474l.993,1.928a1.255,1.255,0,0,0-.289.086l-1.093.546a.9.9,0,0,1-1.043-.167l-1.549-1.526a10.43,10.43,0,0,1,2.98-.867Zm.655-.045c.239,0,.479-.045.722-.045s.452.032.7.045l-.718,1.4Zm.722,3.883.452,2.709H19.6Zm1.355-3.838a10.561,10.561,0,0,1,2.994.867L22.9,24.867a.9.9,0,0,1-1.043.167l-1.093-.546a1.291,1.291,0,0,0-.293-.086Zm4.344-7.617a.9.9,0,0,1-.926.876,8.263,8.263,0,0,0,.3-1.72A.9.9,0,0,1,25.776,14.857Zm-11.442,0a.9.9,0,0,1,.628-.844,8.245,8.245,0,0,0,.3,1.72A.9.9,0,0,1,14.335,14.857Zm.93-1.5h-.027a.9.9,0,0,1-.9-.9V8.838a.9.9,0,0,1,.9-.9h7.224a.9.9,0,0,1,.9.9.3.3,0,0,0,.3.3.9.9,0,0,1,.9.9.3.3,0,0,0,.3.3.9.9,0,0,1,.9.9V12.45a.9.9,0,0,1-.831.9,4.457,4.457,0,0,1-2.289-1.436.3.3,0,0,0-.23-.1.384.384,0,0,0-.117,0,5.793,5.793,0,0,1-4.479,0,.312.312,0,0,0-.352.081,4.515,4.515,0,0,1-2.212,1.458Zm.275.519A5.025,5.025,0,0,0,17.8,12.482a6.439,6.439,0,0,0,4.556,0,5.066,5.066,0,0,0,2.217,1.355c-.1,2.623-2.158,6.723-4.515,6.723S15.662,16.5,15.54,13.873ZM19.152,21a2.348,2.348,0,0,0,1.806,0v.844c-.3-.027-.6-.063-.9-.063s-.6.036-.9.063Zm-8.218,7.554a.9.9,0,0,1,0-.9,10.548,10.548,0,0,1,4.208-4.014l1.675,1.671a1.5,1.5,0,0,0,1.734.284l1.1-.546h0l-.668,3.978H11.72a.9.9,0,0,1-.786-.47Zm18.242,0a.9.9,0,0,1-.786.452H21.117l-.664-3.978h0l1.1.546a1.531,1.531,0,0,0,.673.158,1.5,1.5,0,0,0,1.061-.452l1.675-1.671a10.584,10.584,0,0,1,4.217,4.028.9.9,0,0,1,0,.917Z"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>Configaration</span>
                  </NavLink>
                </li> */}

                {/* Subscription */}
                <li>
                  <NavLink
                    to={AdminRoutes.AdminSubscription}
                    className={
                      location.pathname.includes(AdminRoutes.AdminSubscription)
                        ? "activeSideTab waves-effect"
                        : "waves-effect"
                    }
                    aria-expanded="false"
                  >
                    <span className="dashIcons me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="subscriptions-svgrepo-com"
                          d="M18,2H6V5H18ZM4,6H20V9H4ZM2,10H22V22H2Z"
                          transform="translate(-2 -2)"
                        />
                      </svg>
                    </span>
                    <span>Subscription</span>
                  </NavLink>
                </li>

                {/* Products */}

                {adminauth?.data?.access_login === 9 ||
                adminauth?.data?.roles.find((name) => {
                  if (name === "Product") {
                    return true;
                  }
                }) ? (
                  <li>
                    <NavLink
                      to={AdminRoutes.AdminProducts}
                      className={
                        location.pathname.includes(AdminRoutes.AdminProducts)
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="18"
                          viewBox="0 0 30.34 21.535"
                        >
                          <path
                            id="Icon_awesome-box-open"
                            data-name="Icon awesome-box-open"
                            d="M19.506,12.513a2.221,2.221,0,0,1-1.9-1.072L14.662,6.556,11.72,11.441a2.229,2.229,0,0,1-1.9,1.077,2.108,2.108,0,0,1-.609-.087l-6.278-1.8v8.156a1.462,1.462,0,0,0,1.109,1.421l9.907,2.479a2.979,2.979,0,0,0,1.421,0l9.916-2.479a1.47,1.47,0,0,0,1.109-1.421V10.635l-6.278,1.792A2.108,2.108,0,0,1,19.506,12.513Zm9.742-5.141-2.36-4.711a.748.748,0,0,0-.765-.408L14.662,3.715l4.2,6.97a.753.753,0,0,0,.848.335L28.78,8.431a.755.755,0,0,0,.467-1.059ZM2.437,2.662.077,7.372A.747.747,0,0,0,.54,8.426l9.068,2.589a.753.753,0,0,0,.848-.335l4.207-6.965L3.2,2.254a.749.749,0,0,0-.761.408Z"
                            transform="translate(0.509 -1.743)"
                            strokeWidth="1"
                          />
                        </svg>
                      </span>
                      <span>Products</span>
                    </NavLink>
                  </li>
                ) : null}

                {/* Partner Data */}

                {adminauth?.data?.access_login === 9 ||
                adminauth?.data?.roles.find((name) => {
                  if (name === "Partner") {
                    return true;
                  }
                }) ? (
                  <li>
                    <NavLink
                      to={AdminRoutes.PartnerData}
                      className={
                        location.pathname.includes(AdminRoutes.PartnerData)
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.5"
                          height="20.5"
                          viewBox="0 0 20.5 20.5"
                        >
                          <g
                            id="_-System-Icons"
                            data-name="🔍-System-Icons"
                            transform="translate(-1.75 -1.75)"
                          >
                            <g id="ic_fluent_data_pie_24_filled">
                              <path
                                id="_-Color"
                                data-name="🎨-Color"
                                d="M10.25,4.25A.75.75,0,0,1,11,5v8h8a.75.75,0,0,1,.743.648l.007.1c0,4.971-4.029,8.5-9,8.5a9,9,0,0,1-9-9C1.75,8.279,5.279,4.25,10.25,4.25Zm3-2.5a9,9,0,0,1,9,9,.75.75,0,0,1-.75.75H13.25a.75.75,0,0,1-.75-.75h0V2.5A.75.75,0,0,1,13.25,1.75Z"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span>Partner Data</span>
                    </NavLink>
                  </li>
                ) : null}

                {/* Order Management */}
                {/* <li>
                  <NavLink
                    to={AdminRoutes.AdminOrderManagement}
                    className={
                      location.pathname.includes(
                        AdminRoutes.AdminOrderManagement
                      )
                        ? "activeSideTab waves-effect"
                        : "waves-effect"
                    }
                    aria-expanded="false"
                  >
                    <span className="dashIcons me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="22"
                        viewBox="0 0 20.534 22.704"
                      >
                        <g
                          id="Icon_feather-shopping-bag"
                          data-name="Icon feather-shopping-bag"
                          transform="translate(-4 -2.5)"
                        >
                          <path
                            id="Path_4785"
                            data-name="Path 4785"
                            d="M7.756,3,4.5,7.341V22.534A2.17,2.17,0,0,0,6.67,24.7H21.863a2.17,2.17,0,0,0,2.17-2.17V7.341L20.778,3Z"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                          <path
                            id="Path_4786"
                            data-name="Path 4786"
                            d="M4.5,9H24.034"
                            transform="translate(0 -1.659)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                          <path
                            id="Path_4787"
                            data-name="Path 4787"
                            d="M20.682,15A4.341,4.341,0,0,1,12,15"
                            transform="translate(-2.074 -3.318)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>Order Management</span>
                  </NavLink>
                </li> */}

                {/* We Products */}
                {adminauth?.data?.access_login === 9 ||
                adminauth?.data?.roles.find((name) => {
                  if (name === "Ew Plans") {
                    return true;
                  }
                }) ? (
                  <li>
                    <NavLink
                      to={AdminRoutes.EwPlans}
                      className={
                        location.pathname.includes(AdminRoutes.EwPlans)
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21.117"
                          height="21.066"
                          viewBox="0 0 21.117 21.066"
                        >
                          <path
                            id="asset-warranty-svgrepo-com"
                            d="M36.327,26.225A3.966,3.966,0,0,0,34.1,25.14a3.966,3.966,0,0,0-2.237,1.085,6,6,0,0,1-1.313.847,7.143,7.143,0,0,1-1.6.364,4.025,4.025,0,0,0-2.279.805,3.72,3.72,0,0,0-.847,2.288,6.474,6.474,0,0,1-.347,1.585,6,6,0,0,1-.847,1.313,3.966,3.966,0,0,0-1.085,2.237A3.966,3.966,0,0,0,24.625,37.9a6,6,0,0,1,.847,1.313,7.143,7.143,0,0,1,.364,1.6,4.084,4.084,0,0,0,.8,2.279,3.745,3.745,0,0,0,2.3.847,6.567,6.567,0,0,1,1.585.347,6.686,6.686,0,0,1,1.313.847A3.966,3.966,0,0,0,34.1,46.206a3.966,3.966,0,0,0,2.237-1.085,6.686,6.686,0,0,1,1.313-.847,7.143,7.143,0,0,1,1.6-.364,4.025,4.025,0,0,0,2.279-.8,3.728,3.728,0,0,0,.847-2.3,6.567,6.567,0,0,1,.347-1.585,6,6,0,0,1,.847-1.313,3.966,3.966,0,0,0,1.085-2.237,3.966,3.966,0,0,0-1.085-2.237,6,6,0,0,1-.847-1.313,7.144,7.144,0,0,1-.364-1.6,4.034,4.034,0,0,0-.8-2.271,3.754,3.754,0,0,0-2.3-.847,6.567,6.567,0,0,1-1.585-.347,6,6,0,0,1-1.347-.839Zm.924,6.016a.517.517,0,0,1,.771,0l.763.72a.585.585,0,0,1,0,.729l-4.847,5.381h0a1,1,0,0,1-.763.339,1.093,1.093,0,0,1-.771-.339l-2.627-2.618a.483.483,0,0,1,0-.729l.763-.72a.517.517,0,0,1,.771,0l1.864,1.9Z"
                            transform="translate(-23.54 -25.14)"
                            fillRule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>EW Plans</span>
                    </NavLink>
                  </li>
                ) : null}

              {/* Service Pack */}

          
                  {/* <li>
                    <NavLink
                      to={AdminRoutes.ServicePackList}
                      className={
                        location.pathname.includes(
                          AdminRoutes.ServicePackList
                        )
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.413"
                          height="26.518"
                          viewBox="0 0 24.413 26.518"
                        >
                          <path
                            id="service-counter-repair-service-repairman-svgrepo-com"
                            d="M14.154,2a2.381,2.381,0,1,0,2.38,2.381A2.385,2.385,0,0,0,14.154,2Zm3,5.261h-6a3.021,3.021,0,0,0-2.946,3.052V13.68h2.1V10.839a.316.316,0,1,1,.631,0V13.68h6.419V10.839a.316.316,0,0,1,.631,0V13.68h2.1V10.313A3.034,3.034,0,0,0,17.153,7.261ZM2,14.207v2.841H4.42v11.47H23.888V17.048h2.525V14.207Zm14.107,9.345a1.019,1.019,0,0,1,.321-.061,1.122,1.122,0,0,1,.829.3,1.05,1.05,0,0,1,.368.8,1,1,0,0,1-.025.321l-.92-.847-.829.9.93.844a1.019,1.019,0,0,1-.321.061,1.145,1.145,0,0,1-1.2-1.1,1.578,1.578,0,0,1,.036-.346l-.223-.214-1.339-1.222L11.47,25.481a.743.743,0,0,1-.595.31.717.717,0,0,1-.718-.718.7.7,0,0,1,.245-.545l2.351-2.437-1.421-1.3a.869.869,0,0,1-.346.076,1.124,1.124,0,0,1-.829-.3,1.074,1.074,0,0,1-.371-.793,1,1,0,0,1,.025-.321l.93.844.822-.9-.93-.844a.941.941,0,0,1,.321-.062,1.127,1.127,0,0,1,.829.3,1.092,1.092,0,0,1,.371.8.948.948,0,0,1-.025.321l1.465,1.342,1.07-1.158-1.6-1.41,1.453-1.655,4.114,3.621-1.453,1.655-1.645-1.45L14.5,22.081Z"
                            transform="translate(-2 -2)"
                          />
                        </svg>
                      </span>
                      <span>Service Pack</span>
                    </NavLink>
                  </li> */}
                

                {/* Service Provider */}

                {adminauth?.data?.access_login === 9 ||
                adminauth?.data?.roles.find((name) => {
                  if (name === "Service Center") {
                    return true;
                  }
                }) ? (
                  <li>
                    <NavLink
                      to={AdminRoutes.AdminServiceProvider}
                      className={
                        location.pathname.includes(
                          AdminRoutes.AdminServiceProvider
                        )
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.413"
                          height="26.518"
                          viewBox="0 0 24.413 26.518"
                        >
                          <path
                            id="service-counter-repair-service-repairman-svgrepo-com"
                            d="M14.154,2a2.381,2.381,0,1,0,2.38,2.381A2.385,2.385,0,0,0,14.154,2Zm3,5.261h-6a3.021,3.021,0,0,0-2.946,3.052V13.68h2.1V10.839a.316.316,0,1,1,.631,0V13.68h6.419V10.839a.316.316,0,0,1,.631,0V13.68h2.1V10.313A3.034,3.034,0,0,0,17.153,7.261ZM2,14.207v2.841H4.42v11.47H23.888V17.048h2.525V14.207Zm14.107,9.345a1.019,1.019,0,0,1,.321-.061,1.122,1.122,0,0,1,.829.3,1.05,1.05,0,0,1,.368.8,1,1,0,0,1-.025.321l-.92-.847-.829.9.93.844a1.019,1.019,0,0,1-.321.061,1.145,1.145,0,0,1-1.2-1.1,1.578,1.578,0,0,1,.036-.346l-.223-.214-1.339-1.222L11.47,25.481a.743.743,0,0,1-.595.31.717.717,0,0,1-.718-.718.7.7,0,0,1,.245-.545l2.351-2.437-1.421-1.3a.869.869,0,0,1-.346.076,1.124,1.124,0,0,1-.829-.3,1.074,1.074,0,0,1-.371-.793,1,1,0,0,1,.025-.321l.93.844.822-.9-.93-.844a.941.941,0,0,1,.321-.062,1.127,1.127,0,0,1,.829.3,1.092,1.092,0,0,1,.371.8.948.948,0,0,1-.025.321l1.465,1.342,1.07-1.158-1.6-1.41,1.453-1.655,4.114,3.621-1.453,1.655-1.645-1.45L14.5,22.081Z"
                            transform="translate(-2 -2)"
                          />
                        </svg>
                      </span>
                      <span>Service Center</span>
                    </NavLink>
                  </li>
                ) : null}

                {/* Direct Connect */}

                {adminauth?.data?.access_login === 9 ||
                adminauth?.data?.roles.find((name) => {
                  if (name === "Direct Connect") {
                    return true;
                  }
                }) ? (
                  <li>
                    <NavLink
                      to={AdminRoutes.AdminRMMapping}
                      className={
                        location.pathname.includes(AdminRoutes.AdminRMMapping)
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.33"
                          height="21.718"
                          viewBox="0 0 19.33 21.718"
                        >
                          <g id="XMLID_2_" transform="translate(-25.274)">
                            <g
                              id="Group_9063"
                              data-name="Group 9063"
                              transform="translate(25.274)"
                            >
                              <g
                                id="Group_9062"
                                data-name="Group 9062"
                                transform="translate(0)"
                              >
                                <path
                                  id="Path_5887"
                                  data-name="Path 5887"
                                  d="M41.069,281.113a4.723,4.723,0,0,1-3.823,1.951h-2.2a1.76,1.76,0,0,1-1.654-1.159,5.585,5.585,0,0,1-.89-.352,5.991,5.991,0,0,1-1.662-1.23,7.094,7.094,0,0,0-5.564,6.927v.443a1.1,1.1,0,0,0,1.1,1.1H43.5a1.1,1.1,0,0,0,1.1-1.1v-.443A7.089,7.089,0,0,0,41.069,281.113Z"
                                  transform="translate(-25.274 -267.079)"
                                />
                                <path
                                  id="Path_5888"
                                  data-name="Path 5888"
                                  d="M92.865,10.97a1.256,1.256,0,0,0,1.1-.651l.017.046.005.014a4.9,4.9,0,0,0,2.765,2.912,1.758,1.758,0,0,1,1.49-.824h2.2a1.233,1.233,0,0,0,.6-.162,2.489,2.489,0,0,0,.712-.822,6.352,6.352,0,0,0,.551-1.164,1.263,1.263,0,0,0,.347.4v.54a2.216,2.216,0,0,1-2.213,2.213h-2.2a.754.754,0,1,0,0,1.508h2.2a3.725,3.725,0,0,0,3.721-3.721v-.54a1.255,1.255,0,0,0,.5-1.005V6.568a1.255,1.255,0,0,0-.522-1.019,6.028,6.028,0,0,0-12.018,0,1.255,1.255,0,0,0-.522,1.019V9.713A1.257,1.257,0,0,0,92.865,10.97Zm5.274-9.462a4.526,4.526,0,0,1,4.5,4.072,1.261,1.261,0,0,0-.353.436A4.3,4.3,0,0,0,94,6.009l0,.007a1.261,1.261,0,0,0-.353-.436A4.527,4.527,0,0,1,98.139,1.508Z"
                                  transform="translate(-88.474)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span>Direct Connect</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}

                {/* AskExpert */}
                {adminauth?.data?.access_login === 9 ||
                adminauth?.data?.roles.find((name) => {
                  if (name === "Ask the Expert") {
                    return true;
                  }
                }) ? (
                  <li>
                    <NavLink
                      to={AdminRoutes.AskExpert}
                      className={
                        location.pathname.includes(AdminRoutes.AskExpert)
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 32 32"
                        >
                          <path
                            id="Path_10324"
                            data-name="Path 10324"
                            d="M83,0a6.523,6.523,0,0,1,1.584.4,5.917,5.917,0,0,1,3.745,4.732,6,6,0,0,1-3.823,6.5c-.049.018-.1.037-.147.051a.348.348,0,0,1-.449-.2.344.344,0,0,1,.212-.46,5.83,5.83,0,0,0,.918-.43l.017-.01c.68-.4.744-.524.575-1.3a2.436,2.436,0,0,0-1.4-1.751.239.239,0,0,0-.253.016,2.955,2.955,0,0,1-3.215,0,.243.243,0,0,0-.253-.016,2.517,2.517,0,0,0-1.449,2.527c.008.108.083.157.156.21A5.346,5.346,0,0,0,80.57,11a.378.378,0,0,1,.281.271.337.337,0,0,1-.1.34.366.366,0,0,1-.4.065,5.753,5.753,0,0,1-1.64-.89A5.883,5.883,0,0,1,76.4,6.766a5.818,5.818,0,0,1,1.415-4.695A5.872,5.872,0,0,1,81.671.022.446.446,0,0,0,81.745,0ZM78.35,9.432c.033-.021.023-.057.028-.087a3.187,3.187,0,0,1,1.457-2.256c.214-.139.216-.136.067-.35a3.021,3.021,0,1,1,4.921.016c-.14.194-.14.192.067.323a3.215,3.215,0,0,1,1.023,1.03,3.5,3.5,0,0,1,.468,1.337,5.291,5.291,0,0,0-.229-7.187,5.347,5.347,0,0,0-7.592.029,5.289,5.289,0,0,0-.209,7.145M84.672,5a2.3,2.3,0,1,0-2.306,2.3A2.28,2.28,0,0,0,84.672,5"
                            transform="translate(-72.37)"
                            strokeWidth="0.2"
                          />
                          <path
                            id="Path_10325"
                            data-name="Path 10325"
                            d="M104.007,222.385a.761.761,0,0,1-.425-.976c.122-.678.233-1.358.358-2.036a.269.269,0,0,0-.112-.268c-.506-.488-1.008-.98-1.518-1.464a.713.713,0,0,1-.253-.8.761.761,0,0,1,.714-.483c.689-.092,1.376-.2,2.065-.293a.307.307,0,0,0,.259-.2c.31-.631.63-1.256.939-1.888a.71.71,0,0,1,1.342.008c.3.614.612,1.222.909,1.837a.389.389,0,0,0,.331.251c.677.089,1.352.189,2.027.285.051.007.1.017.154.026a.674.674,0,0,1,.563.479.658.658,0,0,1-.194.712c-.38.374-.768.742-1.152,1.112-.155.149-.307.3-.464.447a.233.233,0,0,0-.075.242c.122.672.233,1.345.352,2.017a.767.767,0,0,1-.429.991h-.43q-1.064-.556-2.127-1.112a.253.253,0,0,0-.258-.006q-1.072.562-2.146,1.118Zm.256-.731a.534.534,0,0,0,.087-.022c.67-.348,1.345-.687,2.006-1.049a.651.651,0,0,1,.694,0c.623.339,1.256.659,1.886.984.059.03.113.089.2.062-.133-.77-.261-1.538-.4-2.3a.555.555,0,0,1,.176-.559c.531-.5,1.051-1.01,1.574-1.518.04-.039.115-.073.1-.133s-.1-.053-.151-.061c-.72-.105-1.44-.217-2.162-.308a.58.58,0,0,1-.506-.359c-.312-.652-.641-1.295-.964-1.941-.025-.051-.037-.133-.1-.136-.076,0-.085.087-.112.141q-.5.985-.986,1.973a.5.5,0,0,1-.394.309c-.573.078-1.147.155-1.718.245a3.586,3.586,0,0,0-.746.138c.083.068.137.105.183.15.459.442.9.91,1.383,1.321a.839.839,0,0,1,.3.993.137.137,0,0,0,0,.019l-.356,2.058"
                            transform="translate(-96.703 -202.385)"
                            strokeWidth="0.2"
                          />
                          <path
                            id="Path_10326"
                            data-name="Path 10326"
                            d="M248.331,195.664a1.227,1.227,0,0,1-.283.369c-.392.371-.779.749-1.17,1.121a.221.221,0,0,0-.067.226c.1.57.193,1.141.293,1.711a.609.609,0,0,1-.239.638.591.591,0,0,1-.663.05c-.486-.25-.973-.5-1.452-.762a.448.448,0,0,0-.487-.006c-.46.256-.931.494-1.4.736-.238.123-.44.08-.543-.109a.326.326,0,0,1,.133-.47c.625-.334,1.256-.66,1.887-.984a.428.428,0,0,1,.411.039q.717.377,1.436.75c.048.025.1.085.157.041s.009-.1,0-.147c-.091-.525-.179-1.051-.275-1.575a.5.5,0,0,1,.159-.492c.43-.406.852-.82,1.31-1.262-.323-.048-.607-.092-.892-.133q-.463-.067-.927-.128a.485.485,0,0,1-.4-.3c-.234-.487-.479-.968-.722-1.451-.023-.045-.034-.116-.086-.122-.074-.008-.079.075-.1.123-.24.477-.482.952-.713,1.433a.521.521,0,0,1-.434.314c-.637.088-1.274.182-1.911.272a.373.373,0,0,1-.466-.3c-.03-.207.121-.371.378-.408.547-.079,1.093-.162,1.642-.23a.318.318,0,0,0,.276-.2c.237-.485.483-.967.721-1.451a.65.65,0,0,1,.6-.426.642.642,0,0,1,.6.42c.244.489.492.976.73,1.468a.293.293,0,0,0,.258.192c.555.069,1.107.159,1.66.233a.719.719,0,0,1,.583.433Z"
                            transform="translate(-228.331 -182.5)"
                            strokeWidth="0.2"
                          />
                          <path
                            id="Path_10327"
                            data-name="Path 10327"
                            d="M0,195.289a.767.767,0,0,1,.68-.445c.542-.063,1.081-.155,1.622-.226.111-.015.152-.082.194-.166.247-.5.5-.988.741-1.485a.636.636,0,0,1,.6-.423.649.649,0,0,1,.6.425c.238.485.484.966.721,1.451a.317.317,0,0,0,.276.2c.535.066,1.068.15,1.6.228a.5.5,0,0,1,.32.127.327.327,0,0,1,.071.378.344.344,0,0,1-.38.213c-.31-.036-.618-.084-.927-.128-.341-.048-.681-.1-1.023-.144a.529.529,0,0,1-.454-.317c-.228-.475-.467-.945-.705-1.416-.027-.054-.033-.146-.11-.143-.06,0-.071.085-.1.136-.237.471-.48.939-.7,1.416a.54.54,0,0,1-.467.326c-.573.075-1.144.16-1.7.24.216.234.429.475.652.7.2.205.409.4.618.6a.453.453,0,0,1,.135.443c-.1.537-.187,1.076-.281,1.614-.008.044-.034.1,0,.13s.1-.006.14-.028c.479-.249.96-.5,1.435-.752a.524.524,0,0,1,.536,0c.569.3,1.142.6,1.712.9.229.121.3.3.211.494a.36.36,0,0,1-.535.135c-.486-.249-.972-.5-1.451-.763a.39.39,0,0,0-.417.008c-.487.263-.976.522-1.471.768a.62.62,0,0,1-.934-.523,3.56,3.56,0,0,1,.085-.578c.071-.417.138-.834.219-1.248a.3.3,0,0,0-.1-.306c-.4-.376-.787-.759-1.181-1.138A.99.99,0,0,1,0,195.679Z"
                            transform="translate(0 -182.515)"
                            strokeWidth="0.2"
                          />
                          <path
                            id="Path_10328"
                            data-name="Path 10328"
                            d="M150.466,264.822c-.266.011-.429-.118-.452-.316a.352.352,0,0,1,.3-.375c.275-.05.552-.09.829-.122a.381.381,0,0,0,.356-.247c.1-.252.232-.494.36-.736a.356.356,0,1,1,.631.316c-.174.357-.354.71-.531,1.065a.391.391,0,0,1-.291.238c-.417.065-.836.124-1.2.177"
                            transform="translate(-142.199 -249.097)"
                            strokeWidth="0.2"
                          />
                        </svg>
                      </span>
                      <span>Ask the Expert</span>
                    </NavLink>
                  </li>
                ) : null}

                {/* Banner Ads */}
                {adminauth?.data?.access_login === 9 ||
                adminauth?.data?.roles.find((name) => {
                  if (name === "Banner Ads") {
                    return true;
                  }
                }) ? (
                  <li>
                    <NavLink
                      to={AdminRoutes.BannerAds}
                      className={
                        location.pathname.includes(AdminRoutes.BannerAds)
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21.304"
                          height="18.834"
                          viewBox="0 0 21.304 18.834"
                        >
                          <g
                            id="Group_9740"
                            data-name="Group 9740"
                            transform="translate(-34.203 -758)"
                          >
                            <g
                              id="Group_9739"
                              data-name="Group 9739"
                              transform="translate(34.203 758)"
                            >
                              <path
                                id="Path_10070"
                                data-name="Path 10070"
                                d="M1.09,17.107c.843-.709,1.631-1.364,2.408-2.033a1.094,1.094,0,0,1,.761-.283q7.432.009,14.864,0a1.78,1.78,0,0,0,.47-.04.807.807,0,0,0,.611-.765c.013-.194-.093-.212-.243-.211-.631,0-1.263,0-1.894,0-.43,0-.859,0-1.289,0a.546.546,0,1,1,0-1.086c1.043,0,2.087-.008,3.131,0,.23,0,.3-.066.3-.3q-.01-5.143,0-10.286a.879.879,0,0,0-.985-.977c-3.893.016-7.787.008-11.68.008A1.416,1.416,0,0,1,7.074,1.1a.517.517,0,0,1-.388-.5A.525.525,0,0,1,7.073.061,1.124,1.124,0,0,1,7.436.016q5.919,0,11.838,0A1.962,1.962,0,0,1,21.3,2.048c-.026,3.946-.009,7.892-.011,11.838a1.868,1.868,0,0,1-2.009,2q-7.353,0-14.706,0a.772.772,0,0,0-.545.2c-.973.832-1.955,1.653-2.936,2.476a1.608,1.608,0,0,1-.321.226A.533.533,0,0,1,0,18.337c-.006-.061,0-.123,0-.184q0-8.09,0-16.179A1.841,1.841,0,0,1,1.393.1,5.138,5.138,0,0,1,3.067.04a.512.512,0,0,1,.493.523.514.514,0,0,1-.487.554c-.365.037-.735.021-1.1.026a.8.8,0,0,0-.878.833c0,.07,0,.14,0,.21q0,7.3,0,14.6Z"
                                transform="translate(0 -0.001)"
                                fill="#777"
                              />
                              <path
                                id="Path_10071"
                                data-name="Path 10071"
                                d="M214.227,99.86c0-.683,0-1.366,0-2.049,0-.606.2-.8.8-.794a5.629,5.629,0,0,1,1.516.079,2.4,2.4,0,0,1,1.862,2.048,3.4,3.4,0,0,1-.3,2.349,2.48,2.48,0,0,1-1.965,1.238,7.614,7.614,0,0,1-1.233.024c-.487-.029-.67-.226-.682-.714-.009-.394,0-.788,0-1.182q0-.5,0-1m1.124.039c0,.4-.005.806,0,1.208.009.595-.117.552.575.532a1.4,1.4,0,0,0,1.383-1.11,3.665,3.665,0,0,0,.039-.968,1.656,1.656,0,0,0-.26-.839,1.55,1.55,0,0,0-1.435-.594c-.221.007-.314.064-.307.3.014.49,0,.98,0,1.471"
                                transform="translate(-202.953 -91.9)"
                                fill="#777"
                              />
                              <path
                                id="Path_10072"
                                data-name="Path 10072"
                                d="M113.772,102.987a.548.548,0,0,1-.543-.415c-.085-.23-.162-.463-.233-.7a.255.255,0,0,0-.285-.208c-.333.009-.666.007-1,0a.241.241,0,0,0-.269.191c-.075.242-.154.484-.247.72a.559.559,0,0,1-.729.379.55.55,0,0,1-.312-.738q.75-2.255,1.516-4.5a.557.557,0,0,1,.552-.442.57.57,0,0,1,.545.453q.754,2.226,1.5,4.455c.149.45-.078.8-.494.806m-1.556-3.425c-.1.287-.155.505-.243.711-.1.237-.013.3.209.274a.793.793,0,0,1,.13,0c.161.01.228-.045.166-.214-.083-.227-.157-.458-.262-.77"
                                transform="translate(-104.322 -92.155)"
                                fill="#777"
                              />
                              <path
                                id="Path_10073"
                                data-name="Path 10073"
                                d="M87.065,1.087a.541.541,0,1,1,.547.556.554.554,0,0,1-.547-.556"
                                transform="translate(-82.484 -0.516)"
                                fill="#777"
                              />
                              <path
                                id="Path_10074"
                                data-name="Path 10074"
                                d="M269.163,241.792a.541.541,0,1,1-.542-.552.531.531,0,0,1,.542.552"
                                transform="translate(-253.976 -228.547)"
                                fill="#777"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span>Banner Ads</span>
                    </NavLink>
                  </li>
                ) : null}

                {/* Notifications Ads */}

                {adminauth?.data?.access_login === 9 ||
                adminauth?.data?.roles.find((name) => {
                  if (name === "Notification") {
                    return true;
                  }
                }) ? (
                  <li>
                    <NavLink
                      to={AdminRoutes.Notifications}
                      className={
                        location.pathname.includes(AdminRoutes.Notifications)
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg width="23" height="23" viewBox="0 0 23 23">
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectangle_4601"
                                data-name="Rectangle 4601"
                                width="20.679"
                                height="22.835"
                                fill="#707070"
                              />
                            </clipPath>
                            <clipPath id="clip-Notification_grey">
                              <rect width="23" height="23" />
                            </clipPath>
                          </defs>
                          <g
                            id="Notification_grey"
                            data-name="Notification grey"
                            clipPath="url(#clip-Notification_grey)"
                          >
                            <g
                              id="Group_10149"
                              data-name="Group 10149"
                              transform="translate(1)"
                            >
                              <g
                                id="Group_10067"
                                data-name="Group 10067"
                                clipPath="url(#clipPath)"
                              >
                                <path
                                  id="Path_10265"
                                  data-name="Path 10265"
                                  d="M9.849,22.835a3.51,3.51,0,0,1-1.658-.767,3.25,3.25,0,0,1-1.115-1.879c-.042-.211-.113-.291-.343-.289-1.367.012-2.734.007-4.1.005A2.589,2.589,0,0,1,0,17.325a2.623,2.623,0,0,1,2.2-2.572,2.752,2.752,0,0,1,.42-.044.281.281,0,0,0,.313-.318c0-.163,0-.327,0-.49,0-1.137,0-2.273,0-3.41a8.286,8.286,0,0,1,.548-3.316A7.3,7.3,0,0,1,7.57,3.1a.331.331,0,0,0,.243-.411,2.531,2.531,0,0,1,1.8-2.61L9.894,0h.892a2.727,2.727,0,0,1,1.338.714A2.447,2.447,0,0,1,12.855,2.8c-.021.171.055.231.2.292a7.54,7.54,0,0,1,4.326,4.732,7.764,7.764,0,0,1,.364,2.458c.006,1.33,0,2.66,0,3.991,0,.375.04.415.42.436a2.656,2.656,0,0,1,2.515,2.567A2.57,2.57,0,0,1,18.161,19.9c-1.419.012-2.839.006-4.258,0-.171,0-.248.047-.287.22a3.291,3.291,0,0,1-2.724,2.689.434.434,0,0,0-.063.023Zm.494-4.433q3.7,0,7.4,0a3.691,3.691,0,0,0,.532-.039,1.06,1.06,0,0,0,.851-1.275,1.116,1.116,0,0,0-1.095-.846,1.8,1.8,0,0,1-1.821-1.9c0-1.382,0-2.765,0-4.147a6.238,6.238,0,0,0-.983-3.4A5.787,5.787,0,0,0,7.046,5.1,5.71,5.71,0,0,0,4.473,9.933c-.026,1.5-.007,3,0,4.5a1.742,1.742,0,0,1-.388,1.114,1.889,1.889,0,0,1-1.505.694,1.1,1.1,0,0,0-1.009.78A1.067,1.067,0,0,0,2,18.2a1.444,1.444,0,0,0,.876.2q3.735,0,7.469,0m0,1.5c-.526,0-1.053,0-1.579,0-.166,0-.185.055-.14.2a1.815,1.815,0,0,0,3.439,0c.045-.145.023-.2-.141-.2-.526.007-1.053,0-1.579,0m.034-17.341a4.983,4.983,0,0,1,.765.039c.176.038.227-.035.213-.2a1.029,1.029,0,0,0-1-.906,1.04,1.04,0,0,0-1.025.9c-.015.164.037.245.21.209a5.5,5.5,0,0,1,.835-.045"
                                  fill="#707070"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span>Notifications</span>
                    </NavLink>
                  </li>
                ) : null}
                {/* Support */}
                {/* <li>
                  <NavLink
                    to={AdminRoutes.AdminSupport}
                    className={
                      location.pathname.includes(AdminRoutes.AdminSupport)
                        ?  "activeSideTab waves-effect"
                        : "waves-effect"
                    }
                    aria-expanded="false"
                  >
                    <span className="dashIcons me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.42"
                        height="22.684"
                        viewBox="0 0 17.42 22.684"
                      >
                        <g
                          id="_4_Businessman"
                          data-name="4_Businessman"
                          transform="translate(-11.838 -7.045)"
                        >
                          <g
                            id="Icon_ionic-ios-document"
                            data-name="Icon ionic-ios-document"
                            transform="translate(4.9 3.483)"
                          >
                            <path
                              id="Path_4812"
                              data-name="Path 4812"
                              d="M21.643,9.368h4.99a.272.272,0,0,0,.274-.274h0a1.623,1.623,0,0,0-.587-1.256L22.137,4.35a1.761,1.761,0,0,0-1.13-.406h0a.4.4,0,0,0-.406.406V8.326A1.041,1.041,0,0,0,21.643,9.368Z"
                              transform="translate(-2.925 -0.002)"
                              strokeWidth="0.75"
                            />
                            <path
                              id="Path_4813"
                              data-name="Path 4813"
                              d="M16.251,8.324V3.938H9.067A1.76,1.76,0,0,0,7.313,5.692V24.117a1.76,1.76,0,0,0,1.755,1.755h13.16a1.76,1.76,0,0,0,1.755-1.755V10.792H18.718A2.471,2.471,0,0,1,16.251,8.324Z"
                              transform="translate(0)"
                              strokeWidth="0.75"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span>Support</span>
                  </NavLink>
                </li> */}

                {/* Offer */}

                {adminauth?.data?.access_login === 9 ||
                adminauth?.data?.roles.find((name) => {
                  if (name === "Offer/Benefits") {
                    return true;
                  }
                }) ? (
                  <li>
                    <NavLink
                      to={AdminRoutes.OfferData}
                      className={
                        location.pathname.includes(AdminRoutes.OfferData)
                          ? "activeSideTab waves-effect"
                          : "waves-effect"
                      }
                      aria-expanded="false"
                    >
                      <span className="dashIcons me-3">
                        <svg width="23" height="23" viewBox="0 0 23 23">
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectangle_4601"
                                data-name="Rectangle 4601"
                                width="20.679"
                                height="22.835"
                                fill="#707070"
                              />
                            </clipPath>
                            <clipPath id="clip-Notification_grey">
                              <rect width="23" height="23" />
                            </clipPath>
                          </defs>
                          <g
                            id="Notification_grey"
                            data-name="Notification grey"
                            clipPath="url(#clip-Notification_grey)"
                          >
                            <g
                              id="Group_10149"
                              data-name="Group 10149"
                              transform="translate(1)"
                            >
                              <g
                                id="Group_10067"
                                data-name="Group 10067"
                                clipPath="url(#clipPath)"
                              >
                                <path
                                  id="Path_10265"
                                  data-name="Path 10265"
                                  d="M9.849,22.835a3.51,3.51,0,0,1-1.658-.767,3.25,3.25,0,0,1-1.115-1.879c-.042-.211-.113-.291-.343-.289-1.367.012-2.734.007-4.1.005A2.589,2.589,0,0,1,0,17.325a2.623,2.623,0,0,1,2.2-2.572,2.752,2.752,0,0,1,.42-.044.281.281,0,0,0,.313-.318c0-.163,0-.327,0-.49,0-1.137,0-2.273,0-3.41a8.286,8.286,0,0,1,.548-3.316A7.3,7.3,0,0,1,7.57,3.1a.331.331,0,0,0,.243-.411,2.531,2.531,0,0,1,1.8-2.61L9.894,0h.892a2.727,2.727,0,0,1,1.338.714A2.447,2.447,0,0,1,12.855,2.8c-.021.171.055.231.2.292a7.54,7.54,0,0,1,4.326,4.732,7.764,7.764,0,0,1,.364,2.458c.006,1.33,0,2.66,0,3.991,0,.375.04.415.42.436a2.656,2.656,0,0,1,2.515,2.567A2.57,2.57,0,0,1,18.161,19.9c-1.419.012-2.839.006-4.258,0-.171,0-.248.047-.287.22a3.291,3.291,0,0,1-2.724,2.689.434.434,0,0,0-.063.023Zm.494-4.433q3.7,0,7.4,0a3.691,3.691,0,0,0,.532-.039,1.06,1.06,0,0,0,.851-1.275,1.116,1.116,0,0,0-1.095-.846,1.8,1.8,0,0,1-1.821-1.9c0-1.382,0-2.765,0-4.147a6.238,6.238,0,0,0-.983-3.4A5.787,5.787,0,0,0,7.046,5.1,5.71,5.71,0,0,0,4.473,9.933c-.026,1.5-.007,3,0,4.5a1.742,1.742,0,0,1-.388,1.114,1.889,1.889,0,0,1-1.505.694,1.1,1.1,0,0,0-1.009.78A1.067,1.067,0,0,0,2,18.2a1.444,1.444,0,0,0,.876.2q3.735,0,7.469,0m0,1.5c-.526,0-1.053,0-1.579,0-.166,0-.185.055-.14.2a1.815,1.815,0,0,0,3.439,0c.045-.145.023-.2-.141-.2-.526.007-1.053,0-1.579,0m.034-17.341a4.983,4.983,0,0,1,.765.039c.176.038.227-.035.213-.2a1.029,1.029,0,0,0-1-.906,1.04,1.04,0,0,0-1.025.9c-.015.164.037.245.21.209a5.5,5.5,0,0,1,.835-.045"
                                  fill="#707070"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span>Offer/Benefits</span>
                    </NavLink>
                  </li>
                ) : null}

                {/* Logout */}
                <li>
                  <NavLink
                    to={AdminRoutes.AdminLogout}
                    className={
                      location.pathname.includes(AdminRoutes.AdminLogout)
                        ? "activeSideTab waves-effect"
                        : "waves-effect"
                    }
                  >
                    <span className="dashIcons me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 23 20.87"
                      >
                        <path
                          id="Icon_open-account-logout"
                          data-name="Icon open-account-logout"
                          d="M8.625,0V1.653H21.436v17.6H8.625V20.87H23V0ZM5.75,5.963,0,10.435l5.75,4.472V11.333l11.5-.211V9.537H5.75Z"
                        />
                      </svg>
                    </span>
                    <span>Logout</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {props.children}
      </div>
    </div>
  );
};

export default AdminDetailsLayout;
