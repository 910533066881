/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const AdminServiceProvider = () => {
  // Variables
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const [ServiceProvData, setServiceProvData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [BulkUpload, setBulkUpload] = useState("");
  // const [Pincode, setPincode] = useState();
  // const [State, setState] = useState("");
  const [Page, setPage] = useState(1);
  const [Totalcount, setTotalcount] = useState(0);
  const [pagecount, setpagecount] = useState(0);

  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date

  // API Call for Servide Provider Data LIst
  const getServiceProviderData = async (value) => {
    setLoading(true);
      console.log("object", value);
      try {
    var data = JSON.stringify({
      "filterData": {
        "access_login": 2,
        "authorized": 1,
        "status": value
      },
      "page": Page,
      "perpage": 50
    });
    
    var config = {
      method: 'post',
      url:`${BaseAPI.nodeAPI}users/userlist`,
      headers: { 
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data : data
    };
    
   await axios(config)
    .then(function (response) {
      setServiceProvData(response?.data?.data);
      setTotalcount(Math.ceil(response?.data?.totalCount[0]?.totalCount/10));
      setpagecount(response?.data?.pagescount_arr);
      console.log("ServiceCenterlist", response?.data?.data);
      setLoading(false);
    })
  }
    catch (error) {
      console.log(error);
      setLoading(false);
    };
  };

  useEffect(() => {
    getServiceProviderData();
  }, [Page]);

  // useEffect(() => {
  //   console.log("ServiceProvData--->", ServiceProvData);
  // }, [ServiceProvData]);

  //bulk upload api call
  const BulkUploadServiceCenter = (e) => {
    console.log(e.target.files);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", adminauth?.data?.token);
    var formdata = new FormData();
    formdata.append("file", e.target.files[0], e.target.files[0]?.name);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BaseAPI.nodeAPI}fileupload/csv`, requestOptions)
      .then((response) => response.text())
      .then((result) => handleFileState(result))
      .then((result) => console.log("response"))
      .catch((error) => console.log("error", error));
    window.$(".bulkupload").modal("hide");
  };

  const handleFileState = (result) => {
    setBulkUpload(JSON.parse(result));
    console.log("object=====>>>", BulkUpload);
  };

  const download = (e) => {
    e.preventDefault();
    // alert("Download");
    const response = {
      file_path:
        "https://shridhar-server.s3.ap-south-1.amazonaws.com/images/0.2892138094243526-Sampleaio.csv",
    };
    window.open(response.file_path);
  };

  // serach service probvider
  const getsearchServiceCenter = (e) => {
    var data = JSON.stringify({
      filterData: {
        access_login: 2,
        pincode: /^\d{6}$/.test(e.target.value)
          ? parseInt(e.target.value)
          : undefined,
        city:
          !/^\d{6}$/.test(e.target.value) && e.target.value
            ? e.target.value.toUpperCase()
            : undefined,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}users/userlist`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setServiceProvData(response?.data?.data?.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //pagination
  const pagination = (selectedpage) => {
    if (selectedpage === 1) {
      setPage(1);
      // } else if (pagecount[pagecount.length - 1] === Page) {
      //   setPage(pagecount[pagecount.length - 1]);
    } else {
      setPage(selectedpage);
    }
  };

  const paginationh = () => {
		let items = [];

		let i = Page - 1;
		while (i >= Page - 1 && i > 0) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page - 1)}>
          <a class="page-link">{i}</a>
				</li>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<li className="page-item active" key={Page}  onClick={() => setPage(Page)}>
				 <a class="page-link">{Page} </a>
			</li>,
		);

		i = Page + 1;
		while (i <= Page + 1 && i <= Totalcount) {
			items.push(
				<li class="page-item" key={i} onClick={() => setPage(Page + 1)}>
					          <a class="page-link">{i}</a>
				</li>,
			);

			i += 1;
		}

		return items;
	};
  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Service Center</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap">
                      <form className="app-search d-none d-xl-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by city,pincode"
                            onChange={(e) => {
                              getsearchServiceCenter(e);
                            }}
                          />
                          <span className="bx bx-search-alt"></span>
                        </div>
                      </form>

                      <div className="dropdown d-inline-block d-xl-none ms-2">
                        <button
                          type="button"
                          className="btn header-item noti-icon waves-effect"
                          id="page-header-search-dropdown"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="mdi mdi-magnify"></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                          aria-labelledby="page-header-search-dropdown"
                        >
                          <form className="p-3">
                            <div className="m-0">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  aria-label="Recipient's username"
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    <i className="mdi mdi-magnify"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <button
                        className="btn blueBtns borderradius10 waves-effect waves-light ms-3"
                        data-bs-toggle="modal"
                        data-bs-target=".bulkupload"
                      >
                        Bulk Upload
                      </button>
                      <NavLink
                        to={AdminRoutes.AddNewServiceProvider}
                        type="button"
                        className="btn addBtns waves-effect waves-light ms-3"
                      >
                        <i className="fas fa-plus"></i> Add New
                      </NavLink>
                    </div>

                    {/* <!-- Nav tabs --> */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                      role="tablist"
                    >
                      <li
                        className="nav-item"
                        onClick={() => getServiceProviderData()}
                      >
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#all"
                          role="tab"
                        >
                          <span>All</span>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => getServiceProviderData(1)}
                      >
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#active"
                          role="tab"
                        >
                          <span>Active</span>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => getServiceProviderData(0)}
                      >
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#inactive"
                          role="tab"
                        >
                          <span>Inactive</span>
                        </a>
                      </li>
                    </ul>

                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-3">
                      <div
                        className="tab-pane active serviceProvider emailBreak"
                        id="all"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 tablesWrap">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Outlet/shop Name</th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Pincode</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Status</th>
                                <th>Details</th>
                              </tr>
                            </thead>
                            {Loading ? (
                              <div
                                className="spinner-grow text-info position-absolute tableSpinner mt-6"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <>
                                {!ServiceProvData?.length ? 
                                  <>
                                    <span className="d-block text-center p-2 border">
                                      Data not found
                                    </span>
                                  </>
                                 : 
                                  <tbody>
                                    <>
                                    {ServiceProvData?.length && ServiceProvData?.map((val, index) => (
                                        <tr key={index}>
                                          {/* <td>{`${index === 9 || Page === 1? "" : Page - 1}${index + 1}`}</td> */}
                                          <td>{Page*50-50+index+1}</td>
                                          <td>{val?.shop_name}</td>
                                          <td className="">{val?.email}</td>
                                          <td>{val?.phone}</td>
                                          <td>{val?.pincode}</td>
                                          <td>{val?.city}</td>
                                          <td>{val?.state}</td>

                                          <td>
                                            <span
                                              className={
                                                val?.status === 1
                                                  ? "badge bg-success px-2 py-1"
                                                  : "badge rounded bg-warning"
                                              }
                                            >
                                              {val?.status === 1
                                                ? " Active"
                                                : " Inactive"}
                                            </span>
                                          </td>
                                          <td>
                                            <NavLink
                                              to={
                                                "/admin-service-provider-details/" +
                                                val._id
                                              }
                                              type="button"
                                              className="btn btn-sm waves-effect waves-light"
                                            >
                                              View More
                                            </NavLink>
                                          </td>
                                        </tr>
                                      ))}
                                    </>
                                  </tbody>
                                } </>)}
                          </table>
                        </div>
                         {/* /////////////// Pagination/////////////////////////// */}
                         {ServiceProvData?.length ? (
                                 <nav
                                 aria-label="Page navigation example"
                                 className="my-5"
                                 // style={{
                                 //   color: "#208d8e",
                                 // }}
                               >
                                 <ul className="pagination justify-content-end pe-5">
                                   <li
                                     className={
                                       Page === 1 ? "page-item disabled" : ""
                                     }
                                   >
                                     <a
                                       className="page-link"
                                       aria-label="Previous"
                                     >
                                       <span
                                         aria-hidden="true"
                                         onClick={() => pagination(1)}
                                       >
                                         &laquo;
                                       </span>
                                     </a>
                                   </li>
                                   {/* <>
                                     {Totalcount / 2 > 0 &&
                                       pagecount.length &&
                                       pagecount?.map((val, index) => {
                                         return (
                                           <>
                                             <li
                                               className={
                                                 Page === index + 1
                                                   ? "page-item active"
                                                   : ""
                                               }
                                               key={index}
                                             >
                                               <a
                                                 className="page-link "
                                                 onClick={() => pagination(val)}
                                               >
                                                 {val}
                                               </a>
                                             </li>
                                           </>
                                         );
                                       })}
                                   </> */}
                                   {Page - 1 > 1 && (
                                         <li class="page-item">
                                           <a class="page-link" onClick={() => setPage(Page - 2)}>...</a>
                                         </li>
                                         )}
                                   {paginationh()}
                                   {Page + 1 < Totalcount && (
                                   <li class="page-item">
                                     <a class="page-link" onClick={() => setPage(Page + 2)}>...</a>
                                   </li>
                                   )}
                                   <li
                                     className={
                                       pagecount[pagecount.length - 1] === Page
                                         ? "page-item disabled"
                                         : ""
                                     }
                                   >
                                     <a className="page-link" aria-label="Next">
                                       <span
                                         aria-hidden="true"
                                         onClick={() =>
                                           pagination(
                                             // pagecount[pagecount.length - 1] ===
                                             //   Page
                                             //   ? pagecount[pagecount.length - 1]
                                             //   : Page + 1
                                             Totalcount
                                           )
                                         }
                                       >
                                         &raquo;
                                       </span>
                                     </a>
                                   </li>
                                 </ul>
                               </nav>
                          ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade bulkupload"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style={{ height: "auto", width: "auto" }}

        // id="bulkupload"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title mt-0">Product Bulk Upload</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Upload the CSV file, in correct format</p>
              <div className="input-group mb-3">
                <input
                  type="file"
                  className="form-control"
                  id="bulkupload"
                  onChange={(e) => BulkUploadServiceCenter(e)}
                />
                {/* <label className="input-group-text" htmlFor="bulkupload">
                  Upload File
                </label>  */}
              </div>
              <p className="mb-0">
                <a
                  href="#"
                  className="blueText text-decoration-underline"
                  onClick={(e) => download(e)}
                >
                  Download a Blank Sample Template CSV file htmlFor Bulk upload
                </a>
              </p>
            </div>
            <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
              <button
                type="button"
                className="btn addBtns waves-effect waves-light px-4"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              {/* <button
                type="button"
                className="btn filledaddBtns waves-effect waves-light px-4"
              //onClick={(e) => BulkUploadServiceCenter(e)}
              >
                Add
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminServiceProvider;
