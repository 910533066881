/*eslint-disable*/
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseAPI } from "../../../../api/admin/BaseAPI";

const AdminUserManagementPartners = () => {
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  // auth
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  //Set state
  const [PartnerList, setPartnerList] = useState([]);
  const [Loading, setLoading] = useState(false);

  const [WithoutProductPartnerLIst, setWithoutProductPartnerLIst] = useState("")
  const [headerone, setheaderone] = useState(true)
  const [IsActive, setIsActive] = useState()
  const [partnerType, setpartnerType] = useState("")
  const [Page, setPage] = useState(1);
  const [Totalcount, setTotalcount] = useState(0);
  const [pagecount, setpagecount] = useState(0);

  //useEffect to call the api
  useEffect(() => {
    getPartnerLIstData("Seller");
    
  }, [Page]);

  //api call for partner list
  const getPartnerLIstData = async (type) => {
    console.log(type);
    setLoading(true)
    setpartnerType(type)
    try {
      var data = JSON.stringify({
        "filterData": {
          "access_login": 5,
          "citizen": "Channel Partner",
          "is_deleted": false,
          "state": {
            "$exists": true
          }
        },
        "sort_cond": {
          "createdAt": -1
        },
        "type_of_user": type,
        "page": 1,
        "perpage": 50
      });
      var config = {
        method: 'post',
        url: `${BaseAPI.nodeAPI}users/list`,
        headers: {
          'Authorization': adminauth?.data?.token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      await axios(config)
        .then(function (response) {
          console.log(response.data.data);
          setPartnerList(response?.data?.data)
          setTotalcount(Math.ceil(response?.data?.totalCount[0].totalCount/50));
          setpagecount(response?.data?.pagescount_arr);
          setLoading(false)
        })
    }
    catch (error) {
      console.log(error);
      setLoading(false)
    };
  };

  // function to set the user type
  const SellPartner = () => {
    console.log("1st tab");

    // setiswithoutpartner(false)
    setheaderone(true)
    setIsActive("Seller")
    getPartnerLIstData( "Seller")
  }

  const ServicePartner = () => {
    console.log("2nd tab");
    setheaderone(true)
    // setiswithoutpartner(false)
    setIsActive('Service Center')
    getPartnerLIstData( "Service Center")
  }

  const ReatilorPartner = () => {
    console.log("3rd tab");

    setheaderone(true)
    // setiswithoutpartner(false)
    setIsActive('Retailser')
    getPartnerLIstData(1)
    setIsActive('Both')
    getPartnerLIstData( "Both")
  }


  //delete api call partner list
  // const DeletePartner = (Partner_id) => {
  //   var data = JSON.stringify({
  //     "_id": Partner_id
  //   });

  //   var config = {
  //     method: 'post',
  //     url: `${BaseAPI.nodeAPI}data_remove/user`,
  //     headers: {
  //       'Authorization': adminauth?.data?.token,
  //       'Content-Type': 'application/json',

  //     },
  //     data: data
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response.data);
  //       toast.success(response.data.message, { position: 'top-right' });

  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       toast.error(error.response.data.message, { position: 'top-right' });
  //     });

  // }



  //api call for to show the partners without product
  const getPartnersWithoutProduct = async () => {
    setheaderone(false)
    setIsActive("Withoudata")
    // setiswithoutpartner(true)

    setLoading(true);
    try {
      var data = '';
      var config = {
        method: 'post',
        url: `${BaseAPI.nodeAPI}partner_product/without_product_partner`,
        headers: {
          'Authorization': adminauth?.data?.token,

        },
        data: data
      };

      await axios(config)
        .then(function (response) {
          console.log("Without product partner=>", response?.data?.prod_not_add_user);
          setWithoutProductPartnerLIst(response?.data?.prod_not_add_user)
          setLoading(false);


        })
    }
    catch (error) {
      console.log(error);
      setLoading(false);
    };

  }


  const Refresh = ()=>{
    window.location.reload(false);
}

//Soft delete partner data
const SoftDeletepartnerdata =  (id) => {
  var data = JSON.stringify({
    "_id": id,
    "access_login": 5
  });
  
  var config = {
    method: 'post',
    url: `${BaseAPI.nodeAPI}data_remove/user_soft_delete`,
    headers: { 
      'Authorization': adminauth?.data?.token,
      'Content-Type': 'application/json', 
      
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    console.log(response.data);
    toast.success(response.data.message, { position: 'top-right' });
    Refresh()
    
  })
  .catch(function (error) {
    console.log(error);
    toast.error(error.response.data.message, { position: 'top-right' });
  });
  
}


 console.log("=PartnerList===>",PartnerList[0]?.other_details[0]?.type_of_user );

//pagination 
 const pagination = (selectedpage) => {
    if (selectedpage === 1) {
      setPage(1);
      // } else if (pagecount[pagecount.length - 1] === Page) {
      //   setPage(pagecount[pagecount.length - 1]);
    } else {
      setPage(selectedpage);
    }
  };

  const paginationh = () => {
		let items = [];

		let i = Page - 1;
		while (i >= Page - 1 && i > 0) {
			items.push(
				<li className="page-item" key={i} onClick={() => setPage(Page - 1)}>
          <a className="page-link">{i}</a>
				</li>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<li className="page-item active" key={Page}  onClick={() => setPage(Page)}>
				 <a className="page-link">{Page} </a>
			</li>,
		);

		i = Page + 1;
		while (i <= Page + 1 && i <= Totalcount) {
			items.push(
				<li className="page-item" key={i} onClick={() => setPage(Page + 1)}>
					          <a className="page-link">{i}</a>
				</li>,
			);

			i += 1;
		}
		return items;
	};
  // JSX
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Partners</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card tableCard">
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="position-absolute tableSearchWrap d-flex">
                       {/* <!-- App Search--> */}
                       <form className="app-search d-none d-xl-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />
                          <span className="bx bx-search-alt"></span>
                        </div>
                      </form>
                    {/* dropdown */}
                    {/* <div className="d-flex d-none d-xl-inline-block position-relative mt-4 ml-2">
                            <select
                              className="form-select planSelect w-25"
                              aria-label="Default select example"
                            >
                              <option selected>Select</option>
                              <option value="Hrs">Hrs</option>
                              <option value="Days">Days</option>
                              <option value="Months"> Months</option>
                              <option value="Years">Years</option>
                            </select>
                          </div> */}
                     



                      <div className="dropdown d-inline-block d-xl-none ms-2">
                        <button
                          type="button"
                          className="btn header-item noti-icon waves-effect"
                          id="page-header-search-dropdown"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="mdi mdi-magnify"></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                          aria-labelledby="page-header-search-dropdown"
                        >
                          <form className="p-3">
                            <div className="m-0">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  aria-label="Recipient's username"
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    <i className="mdi mdi-magnify"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Nav tabs --> */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom mt-0 mt-5 mt-md-0"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#retailor"
                          role="tab"
                          onClick={() => SellPartner("Seller")}

                        >
                          <span>Seller</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#serviceCenter"
                          role="tab"
                          onClick={() => ServicePartner("Service Center")}
                        >
                          <span>Service Center</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#retailor+Service"
                          role="tab"
                          onClick={() => ReatilorPartner("Both")}
                        >
                          <span>Retailor + Service Center</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#warrantyAdviser"
                          role="tab"
                          onClick={() => getPartnersWithoutProduct()}
                        >
                          <span>Without Product Partner</span>
                        </a>
                      </li>
                    </ul>
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content text-muted mt-3">
                      {/* for sell and service center */}
                      <div
                        className="tab-pane active"
                        id="retailor"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          {headerone ?
                            <table className="table table-striped mb-0 tablesWrap">

                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Partner 16 digit code</th>
                                  <th>Company Name</th>
                                  <th>Partner Name</th>
                                  <th>Mobile Number</th>
                                  <th>Email ID</th>
                                  <th>Subscription</th>
                                  <th>Date</th>
                                  <th>State</th>
                                  <th>Details</th>
                                  {adminauth?.data?.access_login === 9
                                    ?
                                    <th>Delete</th>
                                    :
                                    <></>
                                  }
                                </tr>
                              </thead>
                              {Loading ? (
                                <div className="spinner-grow text-info position-absolute tableSpinner" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                                ) : (
                                  <>
                                    {!PartnerList?.length 
                                      ?
                                      <>
                                        <span className="d-block text-center p-2 border">Data not found</span>
                                      </>
                                      :
                                <tbody>
                                  {PartnerList?.length && PartnerList?.map((val, index) =>

                                    <>
                                      <tr key={index}>
                                      <td>{Page*50-50+index+1}</td>
                                        <td>{val?.code}</td>
                                        <td>{val?.title}</td>
                                        <td>{val?.other_details[0]?.name}</td>
                                        <td>{val?.phone}</td>
                                        <td className=" text-truncate">{val?.email}</td>
                                        {val?.paid_subsc_details ?
                                            <td> {val?.paid_subsc_details[0]?.title}</td>
                                            :
                                            <td> {val?.basic_subsc_data[0]?.title}</td>
                                        }
                                        <td>{moment(val?.createdAt).format('LLLL')}</td>
                                        <td>{val?.state}</td>

                                        <td>
                                          <NavLink
                                            to={"/partner-details/" + val?._id}
                                            type="button"
                                            className="btn btn-sm waves-effect waves-light"
                                          >
                                            View More
                                          </NavLink>
                                        </td>
                                        {adminauth?.data?.access_login === 9
                                          ?
                                          <td><i className="fas fa-trash" onClick={(e) => SoftDeletepartnerdata(val?.user_data?._id)}></i></td>
                                          :
                                          <></>
                                        }
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                               } </>)}  
                            </table>
                            : 
                            <table className="table table-striped mb-0 tablesWrap">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Title</th>
                                  <th>Mobile Number</th>
                                  <th>Email ID</th>
                                  <th>City</th>
                                  <th>Details</th>
                                  <th>Delete</th> 
                                </tr>
                              </thead>
                              {Loading ? (
                                <div className="spinner-grow text-info position-absolute tableSpinner" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                                ) : (
                                  <>
                                    {!WithoutProductPartnerLIst?.length
                                    
                                      ?
                                      <>
                                        <span className="d-block text-center p-2 border">Data not found</span>
                                      </>
                                      :
                                <tbody>
                                  <>
                                    {WithoutProductPartnerLIst?.length ? WithoutProductPartnerLIst?.map((val, index) =>
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{val?.title}</td>
                                        <td>{val?.phone}</td>
                                        <td>{val?.email}</td>
                                        <td>{val?.city}</td>
                                        <td>
                                          <NavLink
                                            to={"/partner-details/" + val?._id}
                                            type="button"
                                            className="btn btn-sm waves-effect waves-light"
                                          >
                                            View More
                                          </NavLink>
                                        </td>
                                         <td><i className="fas fa-trash" onClick={(e) => SoftDeletepartnerdata(val?._id)}></i></td> 
                                      </tr>
                                    ) : null}
                                  </>
                                </tbody>
                             } </>)}  
                            </table>}
                        </div>



                         {/* /////////////// Pagination/////////////////////////// */}
                         {PartnerList?.length  &&  IsActive !== "Withoudata"?
                        
                                  
                         (
                                 <nav
                                 aria-label="Page navigation example"
                                 className="my-5"
                                 // style={{
                                 //   color: "#208d8e",
                                 // }}
                               >
                                 <ul className="pagination justify-content-end pe-5">
                                   <li
                                     className={
                                       Page === 1 ? "page-item disabled" : ""
                                     }
                                   >
                                     <a
                                       className="page-link"
                                       aria-label="Previous"
                                     >
                                       <span
                                         aria-hidden="true"
                                         onClick={() => pagination(1)}
                                       >
                                         &laquo;
                                       </span>
                                     </a>
                                   </li>
                                   {/* <>
                                     {Totalcount / 2 > 0 &&
                                       pagecount.length &&
                                       pagecount?.map((val, index) => {
                                         return (
                                           <>
                                             <li
                                               className={
                                                 Page === index + 1
                                                   ? "page-item active"
                                                   : ""
                                               }
                                               key={index}
                                             >
                                               <a
                                                 className="page-link "
                                                 onClick={() => pagination(val)}
                                               >
                                                 {val}
                                               </a>
                                             </li>
                                           </>
                                         );
                                       })}
                                   </> */}
                                   {Page - 1 > 1 && (
                                         <li className="page-item">
                                           <a className="page-link" onClick={() => setPage(Page - 2)}>...</a>
                                         </li>
                                         )}
                                   {paginationh()}
                                   {Page + 1 < Totalcount && (
                                   <li className="page-item">
                                     <a className="page-link" onClick={() => setPage(Page + 2)}>...</a>
                                   </li>
                                   )}
                                   <li
                                     className={
                                       pagecount[pagecount.length - 1] === Page
                                         ? "page-item disabled"
                                         : ""
                                     }
                                   >
                                     <a className="page-link" aria-label="Next">
                                       <span
                                         aria-hidden="true"
                                         onClick={() =>
                                           pagination(
                                             // pagecount[pagecount.length - 1] ===
                                             //   Page
                                             //   ? pagecount[pagecount.length - 1]
                                             //   : Page + 1
                                             Totalcount
                                           )
                                         }
                                       >
                                         &raquo;
                                       </span>
                                     </a>
                                   </li>
                                 </ul>
                               </nav>
                          ) : null}

                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default AdminUserManagementPartners;
