/*eslint-disable*/
import React, { useState } from 'react'
import logoPartner from "../../../../assets/images/we-partner.jpg"
import imgFooter from "../../../../assets/images/pfooter.png"

const DisclaimerApplicationpdf = () => {

  //  const [numPages, setNumPages] = useState(null);
  //  const [pageNumber, setPageNumber] = useState(1); 


  //   function onDocumentLoadSuccess({ numPages }) {
  //    setNumPages(numPages);
  //    setPageNumber(1)
  //  }

  //  function changePage(offSet) {
  //    setPageNumber(prevPageNumber => prevPageNumber + offSet)
  //  }


  //  function ChangePageBack() {
  //    changePage(-1)
  //  }


  //  function ChangePageNext() {
  //    changePage(+1)
  //  }

  //JSX
  return (
    //   <div className='pdfView'>
    //   <Document file={Disclaimer} onLoadSuccess={onDocumentLoadSuccess}>
    //     <Page height="600" pageNumber={pageNumber}></Page>
    //   </Document>
    //   <p> Page {pageNumber} of {numPages}</p>
    //   <div className='pdfBtns position-absolute'>
    //     {pageNumber > 1 &&
    //       <button className='blueBtns mb-5px' onClick={ChangePageBack}>Previous Page</button>
    //     }
    //     {
    //       pageNumber < numPages &&
    //       <button className='blueBtns mb-5px' onClick={ChangePageNext}>Next</button>
    //     }
    //   </div>
    // </div>
    <div className="container custom-container">
      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h2 className='Disclaimerheading fst-normal text-center'> <strong>DISCLAIMER</strong></h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className='DisclaimerText'>Last updated: 2022-09-24
                  </p>
                  <h4 className='DisclaimerHeading my-5'>
                    <strong>WEBSITE DISCLAIMER</strong>
                  </h4>
                  <p className='DisclaimerText'>The information provided by <strong>Auxill Technologies Private Limited</strong> (“Company”, “we”,
                    “our”, “us”) on <strong>www.warrantyexpress.in</strong> and Application Warranty Express (the “Site/App”)
                    is for general informational purposes only. All information on the Site is provided in good
                    faith, however we make no representation or warranty of any kind, express or implied,
                    regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any
                    information on the Site.</p>
                  <p className='DisclaimerText text-uppercase mt-4'>UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR
                    ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
                    THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR
                    USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS
                    SOLELY AT YOUR OWN RISK.</p>
                  <h4 className='DisclaimerHeading my-5'>
                  <strong> EXTERNAL LINKS DISCLAIMER</strong>
                  </h4>
                  <p className='DisclaimerText mb-4'>The Site may contain (or you may be sent through the Site) links to other websites or content
                    belonging to or originating from third parties or links to websites and features. Such external
                    links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability,
                    availability or completeness by us.</p>
                  <p className='DisclaimerText'>For example, the outlined <a href=""><u>Disclaimer</u></a> has been created using <a href=""><u>PolicyMaker.io</u></a>, a free web
                    application for generating high-quality legal documents. PolicyMaker’s <a href=""><u>disclaimer generator</u></a>disclaimer generator
                    is an easy-to-use tool for creating an excellent sample Disclaimer template for a website, blog,
                    eCommerce store or app.</p>
                  <p className='DisclaimerText text-uppercase mt-4'>WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
                    FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY
                    THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR
                    FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE
                    A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
                    TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS
                    OR SERVICES.</p>
                  <h4 className='DisclaimerHeading my-5'>
                  <strong> AFFILIATES DISCLAIMER</strong>
                  </h4>
                  <p className='DisclaimerText'>The Site may contain links to affiliate websites, and we may receive an affiliate commission
                    for any purchases or actions made by you on the affiliate websites using such links.</p>

                  <h4 className='DisclaimerHeading my-5'>
                  <strong> TESTIMONIALS DISCLAIMER</strong>
                  </h4>
                  <p className='DisclaimerText'>The Site may contain testimonials by users of our products and/or services. These
                    testimonials reflect the real-life experiences and opinions of such users. However, the
                    experiences are personal to those particular users, and may not necessarily be representativeof all users of our products and/or services. We do not claim, and you should not assume that
                    all users will have the same experiences.</p>

                </div>
              </div>
            </div>
            <div className='pdfFooter mt-5'>
              <div className="row pt-5">
                <div className="col-12">
                  <h4 className='queHeading fst-normal'>
                    Ver 1.1
                  </h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-5 mt-3">
        <div class="card-body">
          <div className="pdfContent p-xxl-5">
            <div className="pdfBody">
              <div className="row">
                <div className="col-12">
                  <h4 className='DisclaimerHeading my-5'>
                    YOUR INDIVIDUAL RESULTS MAY VARY.
                  </h4>
                  <p className='DisclaimerText'>The testimonials on the Site are submitted in various forms such as text, audio and/or video,
                    and are reviewed by us before being posted. They appear on the Site verbatim as given by the
                    users, except for the correction of grammar or typing errors. Some testimonials may have
                    been shortened for the sake of brevity, where the full testimonial contained extraneous
                    information not relevant to the general public.</p><br></br>
                  <p className='DisclaimerText'>The views and opinions contained in the testimonials belong solely to the individual user and
                    do not reflect our views and opinions.</p>


                  <h4 className='DisclaimerHeading my-5'>
                  <strong>ERRORS AND OMISSIONS DISCLAIMER</strong>
                  </h4>
                  <p className='DisclaimerText'>While we have made every attempt to ensure that the information contained in this site has
                    been obtained from reliable sources, Auxill Technologies Private Limited is not responsible
                    for any errors or omissions or for the results obtained from the use of this information. All
                    information in this site is provided “as is”, with no guarantee of completeness, accuracy,
                    timeliness or of the results obtained from the use of this information, and without warranty of
                    any kind, express or implied, including, but not limited to warranties of performance,
                    merchantability, and fitness for a particular purpose.</p><br></br>
                  <p className='DisclaimerText'>In no event will Auxill Technologies Private Limited, its related partnerships or corporations,
                    or the partners, agents or employees thereof be liable to you or anyone else for any decision
                    made or action taken in reliance on the information in this Site or for any consequential,
                    special or similar damages, even if advised of the possibility of such damages.</p>


                  <h4 className='DisclaimerHeading my-5'>
                  <strong> LOGOS AND TRADEMARKS DISCLAIMER</strong>
                  </h4>
                  <p className='DisclaimerText'>All logos and trademarks of third parties referenced on www.warrantyexpress.in are the
                    trademarks and logos of their respective owners. Any inclusion of such trademarks or logos
                    does not imply or constitute any approval, endorsement or sponsorship of Auxill
                    Technologies Private Limited by such owners.</p>

                  <h4 className='DisclaimerHeading my-5'>
                  <strong>CONTACT US</strong>
                  </h4>
                  <p className='DisclaimerText'>Should you have any feedback, comments, requests for technical support or other inquiries,
                    please contact us by email: <a href='https://www.auxill.in/'>contactus@warrantyexpress.in</a> .</p>
                </div>
              </div>
            </div>
            <div className='pdfFooter mt-5 pt-5'>
              <div className="row pt-5 mt-5">
                <div className="col-12 pt-5 mt-5">
                  <h4 className='queHeading fst-normal pt-5 mt-5'>
                    Ver 1.1
                  </h4>
                  <img src={imgFooter} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisclaimerApplicationpdf