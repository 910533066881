/*eslint-disable*/
import React, { useState } from 'react'
import { Document, Page } from 'react-pdf';
import MobileappC from '../auth/login/SharedConsumerPDF/MobileappC.pdf'
import PDFViewer from 'pdf-viewer-reactjs';

import Slide1 from '../../../assets/images/ConsumerMobileApp/Slide1.PNG'
import Slide2 from '../../../assets/images/ConsumerMobileApp/Slide2.PNG'
import Slide3 from '../../../assets/images/ConsumerMobileApp/Slide3.PNG'
import Slide4 from '../../../assets/images/ConsumerMobileApp/Slide4.PNG'
import Slide5 from '../../../assets/images/ConsumerMobileApp/Slide5.PNG'
import Slide6 from '../../../assets/images/ConsumerMobileApp/Slide6.PNG'
import Slide7 from '../../../assets/images/ConsumerMobileApp/Slide7.PNG'
import Slide8 from '../../../assets/images/ConsumerMobileApp/Slide8.PNG'
import Slide9 from '../../../assets/images/ConsumerMobileApp/Slide9.PNG'
import Slide10 from '../../../assets/images/ConsumerMobileApp/Slide10.PNG'

import Slide11 from '../../../assets/images/ConsumerMobileApp/Slide11.PNG'
import Slide12 from '../../../assets/images/ConsumerMobileApp/Slide12.PNG'
import Slide13 from '../../../assets/images/ConsumerMobileApp/Slide13.PNG'
import Slide14 from '../../../assets/images/ConsumerMobileApp/Slide14.PNG'
import Slide15 from '../../../assets/images/ConsumerMobileApp/Slide15.PNG'
import Slide16 from '../../../assets/images/ConsumerMobileApp/Slide16.PNG'
import Slide17 from '../../../assets/images/ConsumerMobileApp/Slide17.PNG'
import Slide18 from '../../../assets/images/ConsumerMobileApp/Slide18.PNG'
import Slide19 from '../../../assets/images/ConsumerMobileApp/Slide19.PNG'
import Slide20 from '../../../assets/images/ConsumerMobileApp/Slide20.PNG'

import Slide21 from '../../../assets/images/ConsumerMobileApp/Slide21.PNG'
import Slide22 from '../../../assets/images/ConsumerMobileApp/Slide22.PNG'
import Slide23 from '../../../assets/images/ConsumerMobileApp/Slide23.PNG'
import Slide24 from '../../../assets/images/ConsumerMobileApp/Slide24.PNG'
import Slide25 from '../../../assets/images/ConsumerMobileApp/Slide25.PNG'
import Slide26 from '../../../assets/images/ConsumerMobileApp/Slide26.PNG'
import Slide27 from '../../../assets/images/ConsumerMobileApp/Slide27.PNG'
import Slide28 from '../../../assets/images/ConsumerMobileApp/Slide28.PNG'
import Slide29 from '../../../assets/images/ConsumerMobileApp/Slide29.PNG'
import Slide30 from '../../../assets/images/ConsumerMobileApp/Slide30.PNG'

import Slide31 from '../../../assets/images/ConsumerMobileApp/Slide31.PNG'
import Slide32 from '../../../assets/images/ConsumerMobileApp/Slide32.PNG'
import Slide33 from '../../../assets/images/ConsumerMobileApp/Slide33.PNG'
import Slide34 from '../../../assets/images/ConsumerMobileApp/Slide34.PNG'
import Slide35 from '../../../assets/images/ConsumerMobileApp/Slide35.PNG'
import Slide36 from '../../../assets/images/ConsumerMobileApp/Slide36.PNG'
import Slide37 from '../../../assets/images/ConsumerMobileApp/Slide37.PNG'
import Slide38 from '../../../assets/images/ConsumerMobileApp/Slide38.PNG'
import Slide39 from '../../../assets/images/ConsumerMobileApp/Slide39.PNG'
import Slide40 from '../../../assets/images/ConsumerMobileApp/Slide40.PNG'

import Slide41 from '../../../assets/images/ConsumerMobileApp/Slide41.PNG'
import Slide42 from '../../../assets/images/ConsumerMobileApp/Slide42.PNG'
import Slide43 from '../../../assets/images/ConsumerMobileApp/Slide43.PNG'
import Slide44 from '../../../assets/images/ConsumerMobileApp/Slide44.PNG'
import Slide45 from '../../../assets/images/ConsumerMobileApp/Slide45.PNG'
import Slide46 from '../../../assets/images/ConsumerMobileApp/Slide46.PNG'
import Slide47 from '../../../assets/images/ConsumerMobileApp/Slide47.PNG'
import Slide48 from '../../../assets/images/ConsumerMobileApp/Slide48.PNG'
import Slide49 from '../../../assets/images/ConsumerMobileApp/Slide49.PNG'
import Slide50 from '../../../assets/images/ConsumerMobileApp/Slide50.PNG'


const ConsumerMobileapppdf = () => {
//varibale for to show pdf
// const [numPages, setNumPages] = useState(null);
// const [pageNumber, setPageNumber] = useState(1);

// //function for pdf view 
// function onDocumentLoadSuccess({ numPages }) {
//   setNumPages(numPages);
//   setPageNumber(1)
// }

// //function for to change pages
// function changePage(offSet) {
//   setPageNumber(prevPageNumber => prevPageNumber + offSet)
// }

// //function for to change pages on back  button
// function ChangePageBack() {
//   changePage(-1)
// }

// //function for to change pdf pages on next buttopn 
// function ChangePageNext() {
//   changePage(+1)
// }

//jsx       
  return (
  //   <div className='pdfView'>
  //   <Document file={MobileappC} onLoadSuccess={onDocumentLoadSuccess}>
  //     <Page height="600" pageNumber={pageNumber}></Page>
  //   </Document>
  //   <p> Page {pageNumber} of {numPages}</p>
  //   <div className='pdfBtns position-absolute'>
  //     {pageNumber > 1 &&
  //       <button className='blueBtns' onClick={ChangePageBack}>Previous Page</button>
  //     }
  //     {
  //       pageNumber < numPages &&
  //       <button className='blueBtns' onClick={ChangePageNext}>Next</button>
  //     }
  //   </div>
  // </div>

  <>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">

      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
      <img src={Slide1} className="img-fluid SlideClass" alt="image" />
      <img src={Slide2} className="img-fluid SlideClass" alt="image" />
      <img src={Slide3} className="img-fluid SlideClass" alt="image" />
      <img src={Slide4} className="img-fluid SlideClass" alt="image" />
      <img src={Slide5} className="img-fluid SlideClass" alt="image" />
      <img src={Slide6} className="img-fluid SlideClass" alt="image" />
      <img src={Slide7} className="img-fluid SlideClass" alt="image" />
      <img src={Slide8} className="img-fluid SlideClass" alt="image" />
      <img src={Slide9} className="img-fluid SlideClass" alt="image" />
      <img src={Slide10} className="img-fluid SlideClass" alt="image" />

      <img src={Slide11} className="img-fluid SlideClass" alt="image" />
      <img src={Slide12} className="img-fluid SlideClass" alt="image" />
      <img src={Slide13} className="img-fluid SlideClass" alt="image" />
      <img src={Slide14} className="img-fluid SlideClass" alt="image" />
      <img src={Slide15} className="img-fluid SlideClass" alt="image" />
      <img src={Slide16} className="img-fluid SlideClass" alt="image" />
      <img src={Slide17} className="img-fluid SlideClass" alt="image" />
      <img src={Slide18} className="img-fluid SlideClass" alt="image" />
      <img src={Slide19} className="img-fluid SlideClass" alt="image" />
      <img src={Slide20} className="img-fluid SlideClass" alt="image" />

      <img src={Slide21} className="img-fluid SlideClass" alt="image" />
      <img src={Slide22} className="img-fluid SlideClass" alt="image" />
      <img src={Slide23} className="img-fluid SlideClass" alt="image" />
      <img src={Slide24} className="img-fluid SlideClass" alt="image" />
      <img src={Slide25} className="img-fluid SlideClass" alt="image" />
      <img src={Slide26} className="img-fluid SlideClass" alt="image" />
      <img src={Slide27} className="img-fluid SlideClass" alt="image" />
      <img src={Slide28} className="img-fluid SlideClass" alt="image" />
      <img src={Slide29} className="img-fluid SlideClass" alt="image" />
      <img src={Slide30} className="img-fluid SlideClass" alt="image" />

      <img src={Slide31} className="img-fluid SlideClass" alt="image" />
      <img src={Slide32} className="img-fluid SlideClass" alt="image" />
      <img src={Slide33} className="img-fluid SlideClass" alt="image" />
      <img src={Slide34} className="img-fluid SlideClass" alt="image" />
      <img src={Slide35} className="img-fluid SlideClass" alt="image" />
      <img src={Slide36} className="img-fluid SlideClass" alt="image" />
      <img src={Slide37} className="img-fluid SlideClass" alt="image" />
      <img src={Slide38} className="img-fluid SlideClass" alt="image" />
      <img src={Slide39} className="img-fluid SlideClass" alt="image" />
      <img src={Slide40} className="img-fluid SlideClass" alt="image" />

      <img src={Slide41} className="img-fluid SlideClass" alt="image" />
      <img src={Slide42} className="img-fluid SlideClass" alt="image" />
      <img src={Slide43} className="img-fluid SlideClass" alt="image" />
      <img src={Slide44} className="img-fluid SlideClass" alt="image" />
      <img src={Slide45} className="img-fluid SlideClass" alt="image" />
      <img src={Slide46} className="img-fluid SlideClass" alt="image" />
      <img src={Slide47} className="img-fluid SlideClass" alt="image" />
      <img src={Slide48} className="img-fluid SlideClass" alt="image" />
      <img src={Slide49} className="img-fluid SlideClass" alt="image" />
      <img src={Slide50} className="img-fluid SlideClass" alt="image" />
      </div>
    </div>
  </div>
  
  </>
  )
}

export default ConsumerMobileapppdf
