/*eslint-disable*/
import { computeHeadingLevel } from "@testing-library/react";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseAPI } from "../../../../api/admin/BaseAPI";
import AdminRoutes from "../../../../routes/admin/AdminRoutes";

const PartnerDetails = () => {
  const navigate = useNavigate();
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  const adminauth = JSON.parse(localStorage.getItem("adminauth"));
  const params = useParams();
  const [partnerAllFieldDetails, setPartnerAllFieldDetails] = useState("");
  const [planList, setplanList] = useState("");
  const [PriceAmount, setPriceAmount] = useState(0);
  const [ActivaePlan, setActivaePlan] = useState("");
  const [redioButton, setredioButton] = useState("");
  const initialValues = { Checknumber: "", AdvancePayment: "", remark: "" };
  const [formValues, setformValues] = useState(initialValues);
  const [id, setid] = useState("");
  const [pendingAmount, setpendingAmount] = useState(0);
  const [PartnerType, setPartnerType] = useState("");
  const [SetPrice, setSetPrice] = useState();
  const [Loading, setLoading] = useState(false);
  const [Old_id, setOld_id] = useState("");
  const [OldUser_id, setOldUser_id] = useState("");
  const [OldSub_id, setOldSub_id] = useState("");
  const [Oldpriceamount, setOldpriceamount] = useState("");
  const [NEWdate, setNEWdate] = useState("");

  // ======================== api call for partner all filed details
  useEffect(() => {
    setLoading(true);
    try {
      var config = {
        method: "get",
        url: `${BaseAPI.nodeAPI}users/partner/${params.partnerid}`,
        headers: {
          Authorization: adminauth?.data?.token,
        },
      };
      axios(config).then(function (response) {
        console.log("partnerAllFieldDetails", response?.data?.data);

        setPartnerAllFieldDetails(response?.data?.data);
        setPartnerType(response?.data?.data?.other_details?.type_of_user);
        setOld_id(response?.data?.data?.payment_details?._id);
        setOldUser_id(response?.data?.data?.payment_details?.user_id);
        setOldSub_id(
          response?.data?.data?.payment_details?.subscription_id?._id
        );
        setOldpriceamount(
          response?.data?.data?.payment_details?.advanced_amount
        );
        setNEWdate(response?.data?.data?.payment_details?.updatedAt);
        get_plan_list(response?.data?.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  // console.log("log===>", Old_id, OldUser_id, OldSub_id, Oldpriceamount)
  // // console.log("setOldUser_id", OldUser_id)
  // // console.log("setOldSub_id", OldSub_id)

  //api call for list partner plan (subscription) ACTIVATE PLAN
  const get_plan_list = (values) => {
    var data = JSON.stringify({
      filterData: {
        subscription_for: 3,
        status: 1,
      },
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}subscription/list`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("planlist", response?.data?.data?.rows);
        // setplanList(response?.data?.data?.rows)
        const my_plan = values?.payment_details?.subscription_id?.title;
        if (my_plan == "BEGINNER") {
          setplanList(
            response?.data?.data?.rows?.filter(
              (obj) => obj?.title !== "BEGINNER"
            )
          );
        } else if (my_plan == "ASSOCIATE") {
          setplanList(
            response?.data?.data?.rows?.filter(
              (obj) => obj?.title !== "BEGINNER" && obj?.title !== "ASSOCIATE"
            )
          );
        } else if (my_plan == "EXPERT") {
          setplanList(
            response?.data?.data?.rows?.filter(
              (obj) => obj?.title === "PREFERRED"
            )
          );
        } else {
          setplanList(response?.data?.data?.rows);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //api call for partner plan price by id ACTIVATE PLAN
  //Function for to store selected redio button to show amount
  const handleRedio = (id) => {
    var data = "";
    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}/subscription/${id}`,
      headers: {
        Authorization: adminauth?.data?.token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("planpriceid", response?.data?.data);
        setPriceAmount(response?.data?.data);
        setid(response?.data?.data?._id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //api call for to activate plan
  const getActivatePlan = () => {
    var data = JSON.stringify({
      advanced_pay: redioButton,
      cheque_no: redioButton === 1 ? parseInt(formValues.Checknumber) : "",
      advanced_amount:
        redioButton === 1 ? parseInt(formValues.AdvancePayment) : "",
      pending_amount: pendingAmount,
      total_amount_pay: SetPrice,
      pay_status: "Partially_active",
      user_id: params.partnerid,
      subscription_id: id,
      access_login: 5,
    });

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}buysubscription/add`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setActivaePlan(response?.data);
        toast.success(response.data.message, { position: "top-right" });
        navigate("/admin-user-management-partners");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message, { position: "top-right" });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
    console.log(formValues);
  };

  useEffect(() => {
    setpendingAmount(SetPrice - formValues.AdvancePayment);
  }, [SetPrice, formValues.AdvancePayment]);

  // console.log("pendingAmount", pendingAmount)

  useEffect(() => {
    if (PartnerType === "Seller" || PartnerType === "Service Center") {
      setSetPrice(PriceAmount.seller_or_sc_total_payble);
      console.log("PriceAmount", PriceAmount.seller_or_sc_total_payble);
      console.log("setSetPrice", SetPrice);
    }
    if (PartnerType === "Both") {
      setSetPrice(PriceAmount.seller_and_sc_total_payble);
      console.log("PriceAmount", PriceAmount.seller_and_sc_total_payble);
    }
    console.log("PartnerType", PartnerType);
  }, [PartnerType, PriceAmount]);

  useEffect(() => {
    console.log("redioButton", redioButton);
  }, [redioButton]);

  useEffect(() => {
    console.log(NEWdate);
    const date = moment(NEWdate).startOf("months").fromNow();

    console.log(
      "======================MOMENT==>>>>>>>>>>>>>>",
      parseFloat(date)
    );
    console.log("newplansetSetPrice", SetPrice);
    console.log("Oldpriceamount", Oldpriceamount);
    console.log("Oldpriceamount", Oldpriceamount / 12);

    console.log(
      "final amout",
      SetPrice - [Oldpriceamount - (Oldpriceamount / 12) * parseFloat(date)]
    );
  }, []);

  //api call to update partner subscription
  const getupdatePartnerSubscription = () => {
    var Advancepay =
      parseInt(formValues.AdvancePayment) + parseInt(Oldpriceamount);
    var pending_amount = SetPrice - Advancepay;
    console.log("Oldpriceamount", Oldpriceamount);
    console.log("setSetPrice", SetPrice);
    var PayStatus = "";
    if (SetPrice === Advancepay && pending_amount === 0) {
      PayStatus = "Fully_active";
    } else if (SetPrice !== Advancepay && pending_amount > 0) {
      PayStatus = "Partially_active";
    }
    console.log(
      "Advancepay====>",
      PayStatus,
      SetPrice,
      Advancepay,
      pending_amount
    );
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var expiry_date = new Date(year + 1, month, day);
    var da_te = `${expiry_date.getDate()}/${
      expiry_date.getMonth() + 1
    }/${expiry_date.getFullYear()}`;
    console.log("expiry date", typeof da_te);
    var data = JSON.stringify({
      old_subscription: {
        _id: Old_id,
        user_id: OldUser_id,
        subscription_id: OldSub_id,
      },
      new_subscription: {
        _id: Old_id,
        subscription_id: id,
        expiry_date: da_te,
        total_amount_pay: SetPrice,
        advanced_pay: redioButton,
        cheque_no: redioButton === 1 ? formValues.Checknumber : "",
        advanced_amount: redioButton === 1 ? Advancepay : "",
        pending_amount: pending_amount,
        amount: Advancepay,
        pay_status: PayStatus,
      },
      response_msg: {
        status: 1,
        message: "Plan Updated successfully",
      },
      error_msg: {
        status: 0,
        message: "Error",
      },
    });

    console.log(data);

    var config = {
      method: "post",
      url: `${BaseAPI.nodeAPI}buysubscription/plan_upgrade_for_partner`,
      headers: {
        Authorization: adminauth?.data?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message, { position: "top-right" });
        console.log(response.data?.message);
        navigate("/admin-user-management-partners");
      })
      .catch(function (error) {
        toast.error(error?.response?.data.message, { position: "top-right" });
      });
  };

  //jsx
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18 fw-normal">
                <span className="fw-bold">Partner</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="page-title-box d-flex align-items-center justify-content-end">
              <h4 className="page-title mb-0 font-size-18 fw-normal text-end">
                <span className="fw-normal">
                  {CurrentTime},{CurrentDate}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row pageBack mb-4">
                  <div className="float-start col-6">
                    <NavLink to={AdminRoutes.AdminUserManagementPartners}>
                      <i className="fas fa-arrow-left text-dark"></i>
                    </NavLink>
                    <span className="fw-bold h5 ps-3">Partner Details</span>
                  </div>
                  <div className="float-start col-6 text-end">
                    <span
                      className={
                        partnerAllFieldDetails?.user_data?.user_details
                          ?.status === 1
                          ? "badge bg-success px-2 py-1"
                          : "badge rounded bg-warning"
                      }
                    >
                      {partnerAllFieldDetails?.user_data?.user_details
                        ?.status === 1
                        ? " Verified"
                        : " Not-Verified"}
                    </span>
                  </div>
                </div>
                {Loading ? (
                  <div
                    className="spinner-grow text-info position-absolute tableSpinner"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="row">
                    <div className="mb-3 col-12">
                      <div className="col-md-4 col-sm-12 col-12 float-start">
                        <div className="col-12 float-start mt-2 mt-md-4 mb-4">
                          <div className="user-wid profilePic text-center pt-2 pb-2">
                            <div className="user-img">
                              <img
                                src={
                                  partnerAllFieldDetails?.user_data
                                    ?.company_details?.image
                                }
                                alt=""
                                className="mx-auto rounded-circle"
                              />
                            </div>
                          </div>
                          <p className="text-center font14 mb-1">
                            Profile Picture
                          </p>
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12 col-12 float-start mb-4">
                        <div className="row ps-0 ps-md-2">
                          <div className="col-12 float-start mb-3">
                            <div className="row">
                              <h6 className="  fw-bold regSecondHead px-2">
                                COMPANY DETAILS
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Company Name/Shop Name:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.company_details?.company_name
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Company Type:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.company_details?.company_type
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Pincode:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.company_details?.pincode
                                  }
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">City:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.company_details?.city
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">State:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.company_details?.state
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  Shop / office No Building Name:
                                </p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.company_details?.flat_no
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  Area Street, Sector, Village:
                                </p>
                                <p className="fw-bold">
                                  {" "}
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.company_details?.area
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">Landmark:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.company_details?.landmark
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-12 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  Is your outlet /shop address same as company
                                  address:
                                </p>
                                <p className="fw-bold">Yes</p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12 col-12 float-start mb-4">
                        <div className="row">
                          <div className="col-12 float-start mb-3">
                            <div className="row">
                              <h6 className="  fw-bold regSecondHead px-2">
                                SHOP DETAILS
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Company Name/Shop Name:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.shop_details
                                      ?.shop_name
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Company Type:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.company_details?.company_type
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Pincode:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.shop_details
                                      ?.pincode
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">City:</p>
                                <p className="fw-bold">
                                  {partnerAllFieldDetails?.shop_details?.city}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">State:</p>

                                <p className="fw-bold">
                                  {partnerAllFieldDetails?.shop_details?.state}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  Shop / office No Building Name:
                                </p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.shop_details
                                      ?.building_address
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  Area Street, Sector, Village:
                                </p>
                                <p className="fw-bold">
                                  {" "}
                                  {partnerAllFieldDetails?.shop_details?.area}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2 mb-3">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">Landmark:</p>

                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.shop_details
                                      ?.landmark
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12 col-12 float-start mb-4">
                        <div className="row">
                          <div className="col-12 float-start mb-3">
                            <div className="row">
                              <h6 className="  fw-bold regSecondHead px-2">
                                ADDITIONAL DETAILS
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Alternate email ID:</p>

                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.owner_details
                                      ?.alternate_email
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Alternate Mobile Number:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.owner_details
                                      ?.alternate_mobile
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">
                                  website address (optional):
                                </p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.owner_details
                                      ?.web_addres
                                  }
                                </p>
                                <br></br>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">Landline No. (optional):</p>
                                <p className="fw-bold"></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 float-start mb-3">
                            <div className="row">
                              <h6 className="  fw-bold regSecondHead px-2">
                                TYPES OF USER
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">Type of User:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.other_details
                                      ?.type_of_user
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">No of Sales person:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.other_details
                                      ?.no_of_sales_person
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  No of Service Technician:
                                </p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.other_details
                                      ?.no_of_service_technician
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  Do you sell extended warranty ?:
                                </p>
                                <p className="fw-bold">
                                  {" "}
                                  {partnerAllFieldDetails?.other_details
                                    ?.currently_sell_Ext_warrenty === 1
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  Are you an authorised seller ?
                                </p>
                                <p className="fw-bold">
                                  {" "}
                                  {partnerAllFieldDetails?.other_details
                                    ?.authorized_seller === 1
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  Are you an authorised service Centre ?
                                </p>
                                <p className="fw-bold">
                                  {partnerAllFieldDetails?.other_details
                                    ?.authorized_service_center === 1
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-1">
                                  Type of extended Warranty?
                                </p>
                                <p className="fw-bold margin-left">
                                  {
                                    partnerAllFieldDetails?.other_details
                                      ?.value1
                                  }{" "}
                                  {
                                    partnerAllFieldDetails?.other_details
                                      ?.value2
                                  }{" "}
                                  {
                                    partnerAllFieldDetails?.other_details
                                      ?.value3
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 my-4">
                        <div className="row">
                          <h6 className="  fw-bold regSecondHead px-2">
                            KYC DOCUMENTS
                          </h6>
                        </div>
                      </div>
                      <div className="col-12 float-start mb-4">
                        <div className="row">
                          <div className="col-md-3 col-sm-12 float-start px-2 mb-3 downloadDocsWrap">
                            <a
                              className="downloadDoc me-3"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                partnerAllFieldDetails?.kyc_details?.gst_cert
                              }
                            >
                              <i className="fas fa-download"></i>
                            </a>
                            GST Certificate
                          </div>
                          <div className="col-md-3 col-sm-12 float-start px-2 mb-3 downloadDocsWrap">
                            <a
                              className="downloadDoc me-3"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                partnerAllFieldDetails?.kyc_details?.pan_card
                              }
                            >
                              <i className="fas fa-download"></i>
                            </a>
                            {/* <a className="downloadDoc me-3">
                            <i className="fas fa-download"></i>
                          </a> */}
                            Company PAN Card
                          </div>
                          <div className="col-md-3 col-sm-12 float-start px-2 mb-3 downloadDocsWrap">
                            <a
                              className="downloadDoc me-3"
                              target="_blank"
                              href={
                                partnerAllFieldDetails?.kyc_details?.addr_proof
                              }
                            >
                              <i className="fas fa-download"></i>
                            </a>
                            Address Proof
                          </div>
                          <div className="col-md-3 col-sm-12 float-start px-2 mb-3 downloadDocsWrap">
                            <a
                              className="downloadDoc me-3"
                              target="_blank"
                              href={
                                partnerAllFieldDetails?.kyc_details?.shop_act
                              }
                            >
                              <i className="fas fa-download"></i>
                            </a>
                            Shop & Establishment Act
                          </div>
                          <div className="col-md-4 col-sm-12 float-start px-2 mb-3 downloadDocsWrap">
                            <a
                              className="downloadDoc me-3"
                              target="_blank"
                              href={
                                partnerAllFieldDetails?.kyc_details
                                  ?.oulet_addr_proof
                              }
                            >
                              <i className="fas fa-download"></i>
                            </a>
                            Upload Outlet/shop address proof
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 col-12 float-start mb-4">
                        <div className="row">
                          <div className="col-12 float-start mb-3">
                            <div className="row">
                              <h6 className="  fw-bold regSecondHead px-2">
                                OWNER DETAILS
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Select Title:</p>
                                <p className="fw-bold">
                                  {partnerAllFieldDetails?.other_details?.title}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Name:</p>
                                <p className="fw-bold">
                                  {partnerAllFieldDetails?.other_details?.name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">Mobile Number:</p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.other_details
                                      ?.mobile
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0">
                                  Registered Mobile Number:
                                </p>
                                <p className="fw-bold">
                                  {
                                    partnerAllFieldDetails?.user_data
                                      ?.user_details?.phone
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 col-12 float-start mb-4">
                        <div className="row">
                          <div className="col-12 float-start mb-3">
                            <div className="row">
                              <h6 className="  fw-bold regSecondHead px-2">
                                OWNER KYC
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12 float-start px-2">
                            <div className="row">
                              <div className="col-md-6 col-sm-12 float-start px-2 mb-3 downloadDocsWrap">
                                <span className="downloadDoc me-3">
                                  <a
                                    className="downloadDoc me-3"
                                    target="_blank"
                                    href={
                                      partnerAllFieldDetails?.other_details
                                        ?.aadhar_front_side
                                    }
                                  >
                                    <i className="fas fa-download"></i>
                                  </a>
                                </span>
                                Aadhar Card Front side
                              </div>
                              <div className="col-md-6 col-sm-12 float-start px-2 mb-3 downloadDocsWrap">
                                <span className="downloadDoc me-3">
                                  <a
                                    className="downloadDoc me-3"
                                    target="_blank"
                                    href={
                                      partnerAllFieldDetails?.other_details
                                        ?.aadhar_back_side
                                    }
                                  >
                                    <i className="fas fa-download"></i>
                                  </a>
                                </span>
                                Aadhar Card Back side
                              </div>
                              <div className="col-md-6 col-sm-12 float-start px-2 mb-3 downloadDocsWrap">
                                <span className="downloadDoc me-3">
                                  <a
                                    className="downloadDoc me-3"
                                    target="_blank"
                                    href={
                                      partnerAllFieldDetails?.other_details
                                        ?.pan_card
                                    }
                                  >
                                    <i className="fas fa-download"></i>
                                  </a>
                                </span>
                                Pan Card
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 float-start">
                        {partnerAllFieldDetails ? (
                          <>
                            {partnerAllFieldDetails?.product_details?.map(
                              (val) => {
                                return (
                                  <div className="col-md-6 col-sm-12 col-12 float-start mb-4">
                                    <div className="row">
                                      <div className="col-12 float-start mb-3">
                                        <div className="row">
                                          <h6 className="  fw-bold regSecondHead px-2 text-uppercase">
                                            PRODUCT DETAILS FOR {val?.type}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-sm-12 float-start px-2">
                                        <div className="row">
                                          <div className="col-12">
                                            <p className="mb-0">
                                              Product Category:
                                            </p>
                                            <p className="fw-bold">
                                              {val?.category_id?.name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-sm-12 float-start px-2">
                                        <div className="row">
                                          <div className="col-12">
                                            <p className="mb-0">
                                              Product Sub Category:
                                            </p>
                                            <p className="fw-bold">
                                              {val?.subcategory_id?.name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 col-sm-12 float-start px-2">
                                        <div className="row">
                                          <div className="col-12">
                                            <p className="mb-0">
                                              Brand Selection:
                                            </p>
                                            <p className="fw-bold mt-2 ">
                                              <span className="chequeDetails p-1 px-3 me-2 float-start mb-2">
                                                {val?.brand_id?.name}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        ) : null}
                      </div>
                      <div className="col-12 float-start">
                        <div className="col-md-6 col-sm-12 col-12 float-start mb-4">
                          <div className="row">
                            <div className="col-12 float-start mb-3">
                              <div className="row">
                                <h6 className="  fw-bold regSecondHead px-2">
                                  BANK DETAILS
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 float-start px-2">
                              <div className="row">
                                <div className="col-12">
                                  <p className="mb-0">Bank Name:</p>
                                  <p className="fw-bold">
                                    {
                                      partnerAllFieldDetails?.bank_details
                                        ?.bank_name
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 float-start px-2">
                              <div className="row">
                                <div className="col-12">
                                  <p className="mb-0">Branch Name:</p>
                                  <p className="fw-bold">
                                    {
                                      partnerAllFieldDetails?.bank_details
                                        ?.branch_name
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 float-start px-2">
                              <div className="row">
                                <div className="col-12">
                                  <p className="mb-0">IFSC Code:</p>
                                  <p className="fw-bold">
                                    {
                                      partnerAllFieldDetails?.bank_details
                                        ?.ifsc_code
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 float-start px-2">
                              <div className="row">
                                <div className="col-12">
                                  <p className="mb-0">Account Number:</p>
                                  <p className="fw-bold">
                                    {" "}
                                    {
                                      partnerAllFieldDetails?.bank_details
                                        ?.acc_no
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 float-start px-2">
                              <div className="row">
                                <div className="col-12">
                                  <p className="mb-0">
                                    Confirm Account Number:
                                  </p>
                                  <p className="fw-bold">
                                    {
                                      partnerAllFieldDetails?.bank_details
                                        ?.conf_acc_no
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 float-start px-2">
                              <div className="row">
                                <div className="col-12">
                                  <p className="mb-0">Account Holder Name:</p>
                                  <p className="fw-bold">
                                    {
                                      partnerAllFieldDetails?.bank_details
                                        ?.acc_holder_name
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-6 col-sm-12 float-start px-2">
                              <div className="row">
                                <div className="col-12">
                                  <p className="mb-0">Upload Cancelled Cheque:</p>
                                  <p className="fw-bold">Name</p>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-md-12 col-sm-12 float-start px-2 mb-3 downloadDocsWrap">
                              <a
                                className="downloadDoc me-3"
                                target="_blank"
                                href={
                                  partnerAllFieldDetails?.bank_details
                                    ?.cheque_image
                                }
                              >
                                <i className="fas fa-download"></i>
                              </a>
                              Dowload Uploaded Cancelled Cheque
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 float-start mb-3">
                        <div className="row">
                          <h6 className="  text-uppercase fw-bold regSecondHead px-2">
                            Current Plan
                          </h6>
                        </div>
                      </div>
                      {partnerAllFieldDetails?.payment_details === null ? (
                        <div className="col-md-12 col-sm-12 col-12 float-start">
                          <div className="row">
                            <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                              <div className="row">
                                <div className="col-4">Select Plan :</div>
                                <div className="col-8 fw-bold">
                                  <>
                                    {planList?.length &&
                                      planList?.map((val) => {
                                        return (
                                          <>
                                            <div className="form-check form-check-inline">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="selectPlan"
                                                id={val?._id}
                                                value="option1"
                                                onClick={(e) =>
                                                  handleRedio(val?._id)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="selectPlan1"
                                              >
                                                {val?.title}
                                              </label>
                                            </div>
                                          </>
                                        );
                                      })}
                                  </>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                              <div className="row">
                                <div className="col-4">Plan Price</div>

                                <div className="col-8 fw-bold">
                                  <span className="chequeDetails py-2 px-3">
                                    Rs.{SetPrice}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                              <div className="row">
                                <div className="col-4">Advanced Pay</div>
                                <div className="col-8 fw-bold">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="advancePay"
                                      id="advancePay1"
                                      value="option1"
                                      onChange={() => setredioButton(1)}
                                      //onChange={(e) => console.log(e.target.value)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="advancePay1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="advancePay"
                                      id="advancePay2"
                                      value="option2"
                                      onChange={() => setredioButton(0)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="advancePay2"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                              {/* <div className="row">
                            <div className="col-4">Pending Payment</div>
                            <div className="col-8 fw-bold">
                              <span className="chequeDetails py-2 px-3">
                                Rs. 10000
                              </span>
                            </div>
                          </div> */}
                            </div>
                            <div className="col-md-12 col-sm-12 float-start px-2 mb-4">
                              <div className="row">
                                <div className="col-2">Cheque Details</div>
                                {redioButton === 1 ? (
                                  <div className="col-8 fw-bold px-1">
                                    <div className="col-md-4 col-sm-12 float-start px-2 mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="bookTitle"
                                      >
                                        Cheque Number
                                      </label>
                                      <input
                                        type="number"
                                        id="Checknumber"
                                        className="form-control"
                                        name="Checknumber"
                                        value={formValues.Checknumber}
                                        placeholder="Cheque Number"
                                        onChange={handleChange}
                                      />
                                    </div>

                                    <div className="col-md-4 col-sm-12 float-start px-2 mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="bookTitle"
                                      >
                                        Advanced Payment
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="AdvancePayment"
                                        name="AdvancePayment"
                                        value={formValues.AdvancePayment}
                                        placeholder="Rs"
                                        onChange={handleChange}
                                      />
                                    </div>

                                    <div className="col-md-4 col-sm-12 float-start px-2 mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="bookTitle"
                                      >
                                        Remark
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="remark"
                                        name="remark"
                                        value={formValues.remark}
                                        placeholder="Enter Remark"
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="row">
                            <div
                              className="col-4 fw-bold"
                              style={{ color: "#208d8e" }}
                            >
                              {" "}
                              Current Plan : <></>
                              {
                                partnerAllFieldDetails?.payment_details
                                  ?.subscription_id?.title
                              }
                            </div>
                            {partnerAllFieldDetails ? (
                              <>
                                <div
                                  className="col-4 fw-bold"
                                  style={{ color: "#208d8e" }}
                                >
                                  {" "}
                                  Purchase Date : <></>
                                  {partnerAllFieldDetails?.payment_details
                                    .subscription_id?.title == "BEGINNER" ? (
                                    <>
                                      {moment(
                                        partnerAllFieldDetails?.payment_details
                                          ?.createdAt
                                      ).format("LL")}
                                    </>
                                  ) : (
                                    <>
                                      {moment(
                                        partnerAllFieldDetails?.payment_details
                                          ?.updatedAt
                                      ).format("LL")}
                                    </>
                                  )}
                                </div>
                                <div
                                  className="col-4 fw-bold"
                                  style={{ color: "#208d8e" }}
                                >
                                  {" "}
                                  Current Plan Price : <></>
                                  {
                                    partnerAllFieldDetails?.payment_details
                                      ?.total_amount_pay
                                  }
                                </div>
                              </>
                            ) : null}
                          </div>
                        </>
                      )}
                      {/* <div className="col-md-12 col-sm-12 float-start px-0  text-end">
                  <button
                    type="submit"
                    className="btn successBtns waves-effect waves-light me-2 px-4"
                  >
                    Save
                  </button>
                </div> */}
                    </div>

                    {partnerAllFieldDetails?.payment_details === null ? (
                      <div className="col-md-12 col-sm-12 float-start text-end">
                        <a
                          type="button"
                          className="btn successBtns borderradius10 waves-effect waves-light px-5"
                          href="#/admin-subscription/:ID"
                          onClick={(e) => getActivatePlan()}
                        >
                          Activate
                        </a>
                      </div>
                    ) : null}
                  </div>
                )}

                {/*====================================================== Update Subscription plan for partrner code============================================================== */}
                {!partnerAllFieldDetails?.payment_details ||
                partnerAllFieldDetails?.payment_details?.subscription_id
                  ?.title === "PREFERRED" ? null : (
                  <>
                    <div className="col-12 float-start mb-3">
                      <div className="row">
                        <h6 className="  text-uppercase fw-bold regSecondHead px-2">
                          Update Plan
                        </h6>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 float-start">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                          <div className="row">
                            <div className="col-4">Select Plan :</div>
                            <div className="col-8 fw-bold">
                              <div className="form-check form-check-inline">
                                <>
                                  {planList?.length &&
                                    planList?.map((val) => {
                                      return (
                                        <>
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="selectPlan"
                                              id={val?._id}
                                              value="option1"
                                              // on={e => handleRedio(e)}
                                              onClick={(e) =>
                                                handleRedio(val?._id)
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="selectPlan1"
                                            >
                                              {val?.title}
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                          <div className="row">
                            <div className="col-4">Plan Price</div>

                            <div className="col-8 fw-bold">
                              <span className="chequeDetails py-2 px-3">
                                Rs.{SetPrice}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                          <div className="row">
                            <div className="col-4">Advanced Pay</div>
                            <div className="col-8 fw-bold">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="advancePay"
                                  id="advancePay1"
                                  value="option1"
                                  onChange={() => setredioButton(1)}
                                  // onChange={(e) => console.log(e.target.value)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="advancePay1"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="advancePay"
                                  id="advancePay2"
                                  value="option2"
                                  onChange={() => setredioButton(0)}
                                  // onChange={(e) => console.log(e.target.value)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="advancePay2"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 float-start px-2 mb-4">
                          <div className="row">
                            <div className="col-4">Total Amount Pay</div>
                            <div className="col-8 fw-bold">
                              <span className="chequeDetails py-2 px-3">
                                Rs.
                                {Math.floor(
                                  SetPrice -
                                    [
                                      Oldpriceamount -
                                        (Oldpriceamount / 12) *
                                          parseFloat(
                                            moment(NEWdate)
                                              .startOf("months")
                                              .fromNow()
                                          ),
                                    ]
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12 float-start px-2 mb-4">
                          <div className="row">
                            <div className="col-2">Cheque Details</div>

                            {redioButton === 1 ? (
                              <div className="col-8 fw-bold px-1">
                                <div className="col-md-4 col-sm-12 float-start px-2 mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="bookTitle"
                                  >
                                    Cheque Number
                                  </label>
                                  <input
                                    type="number"
                                    id="Checknumber"
                                    className="form-control"
                                    name="Checknumber"
                                    value={formValues.Checknumber}
                                    placeholder="Cheque Number"
                                    onChange={handleChange}
                                  />
                                </div>

                                <div className="col-md-4 col-sm-12 float-start px-2 mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="bookTitle"
                                  >
                                    Advanced Payment
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="AdvancePayment"
                                    name="AdvancePayment"
                                    value={formValues.AdvancePayment}
                                    placeholder="Rs"
                                    onChange={handleChange}
                                  />
                                </div>

                                <div className="col-md-4 col-sm-12 float-start px-2 mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="bookTitle"
                                  >
                                    Remark
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="remark"
                                    name="remark"
                                    value={formValues.remark}
                                    placeholder="Enter Remark"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12 float-start px-0  text-end">
                      <button
                        type="submit"
                        className="btn successBtns waves-effect waves-light me-2 px-4"
                        onClick={(e) => getupdatePartnerSubscription(e)}
                      >
                        Update & Save
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerDetails;
